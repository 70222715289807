import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { getConfig } from '~config'
import { CustomCookieStorage } from '~common/auth/custom-cookie-storage'

//see https://github.com/aws-amplify/amplify-js/issues/8632
const tokenLogin = async ({ idToken }: { idToken: string }): Promise<void> => {
  const { USER_POOL_ID, USER_POOL_WEB_CLIENT_ID } = getConfig()

  //attempt log out to clear cruft from session
  try {
    await Auth.signOut()
  } catch (error) {
    //ignore any errors in log out, as we're just logging in again
  }

  const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: idToken,
  })

  const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: idToken, //use the idToken as an access token- not correct, but it reads properties from this and fails if it's not a valid jwt
  })

  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: '',
  })

  const sessionData: AmazonCognitoIdentity.ICognitoUserSessionData & { ClockDrift: number } = {
    IdToken,
    AccessToken,
    RefreshToken,
    /**
     * ClockDrift is normally automatically calculated as the difference between the current user's clock and the issue time on the
     * token.
     *
     * But our token was created some time ago, as the dashboard/ssop page just sends us the existing id token.
     * So if you are logged into the dashboard for 30 minutes and then click the PS icon, if we don't set this to 0, the app would believe we
     * are logged in for 30 minutes after the token has actually expired, causing errors on API calls.
     *
     * Unfortunately this means that we cannot handle clients with incorrect local clocks
     */
    ClockDrift: 0,
  }

  const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData)

  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: USER_POOL_ID as string,
    ClientId: USER_POOL_WEB_CLIENT_ID as string,
  })

  const userData = {
    Username: IdToken.payload['cognito:username'],
    Pool: userPool,
  }

  // create a cognito user using the userData object
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

  cognitoUser.setSignInUserSession(session)
}

export const useIdTokenLogin = (): void => {
  const router = useRouter()
  const idToken = (router.query?.id_token as string) || undefined

  useEffect(() => {
    if (idToken) {
      void tokenLogin({ idToken }).then(() => {
        CustomCookieStorage.createCookiesFromLocalStorage()
        delete router.query['id_token']
        return router
          .replace(router) //Replace route without the id_token
          .then(() => {
            router.reload() //Have to refresh as aws-amplify is not aware that it is now logged in
          })
      })
    }
  }, [idToken, router])
}
