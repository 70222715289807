import styled, { css } from 'styled-components'
import { InfoSvg } from '~common/svgs'
import { Placement, TooltipBreakpointWidth } from './InfoTooltip'

export const StyledInfoTooltip = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 1rem;
`

export const StyledTooltipTip = styled.div<{
  placement: Placement
  tooltipHeight: number
  tooltipWidth: number
  animation: boolean
  bpWidths?: TooltipBreakpointWidth[]
}>`
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.text.textPrimary};
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  width: ${({ tooltipWidth }) => `${tooltipWidth}px`};
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  white-space: pre-wrap;
  line-height: 1;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
  display: inline-block;
  margin-bottom: 5px;
  position: absolute;
  z-index: 999;

  ${({ animation }) =>
    animation &&
    css`
      transition: all 0.1s ease-in-out;
    `}

  ${({ bpWidths }) => {
    if (bpWidths?.length) {
      return bpWidths.map(
        (bpWidth) => css`
          @media (max-width: ${bpWidth.bp}px) {
            width: ${bpWidth.width}px;
          }
        `,
      )
    }
  }}

  ${({ placement, tooltipHeight }) => {
    if (placement === 'topLeft') {
      return css`
        top: calc((${tooltipHeight}px + 0.375rem) * -1);
        transform: translateX(-93%);
      `
    }

    if (placement === 'topCenter') {
      return css`
        top: calc((${tooltipHeight}px + 0.375rem) * -1);
      `
    }

    if (placement === 'topRight') {
      return css`
        top: calc((${tooltipHeight}px + 0.375rem) * -1);
        transform: translateX(-7%);
      `
    }

    if (placement === 'bottomLeft') {
      return css`
        bottom: calc((${tooltipHeight}px + 0.625rem) * -1);
        transform: translateX(-93%);
      `
    }

    if (placement === 'bottomCenter') {
      return css`
        bottom: calc((${tooltipHeight}px + 0.625rem) * -1);
      `
    }

    if (placement === 'bottomRight') {
      return css`
        bottom: calc((${tooltipHeight}px + 0.625rem) * -1);
        transform: translateX(-7%);
      `
    }
  }}
  &::after {
    content: ' ';
    left: 50%;
    border: 10px solid;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0.375rem;
    margin-left: calc(0.375rem * -1);
    ${({ placement }) => {
      if (placement === 'topLeft') {
        return css`
          top: 100%;
          left: 93%;
          border-color: ${({ theme }) => theme.colors.brand.backgroundSecondary} transparent transparent;
        `
      }

      if (placement === 'topCenter') {
        return css`
          top: 100%;
          border-color: ${({ theme }) => theme.colors.brand.backgroundSecondary} transparent transparent;
        `
      }

      if (placement === 'topRight') {
        return css`
          top: 100%;
          left: 7%;
          border-color: ${({ theme }) => theme.colors.brand.backgroundSecondary} transparent transparent;
        `
      }

      if (placement === 'bottomLeft') {
        return css`
          bottom: 100%;
          border-color: transparent transparent ${({ theme }) => theme.colors.brand.backgroundSecondary};
          left: 93%;
        `
      }

      if (placement === 'bottomCenter') {
        return css`
          bottom: 100%;
          border-color: transparent transparent ${({ theme }) => theme.colors.brand.backgroundSecondary};
        `
      }

      if (placement === 'bottomRight') {
        return css`
          bottom: 100%;
          border-color: transparent transparent ${({ theme }) => theme.colors.brand.backgroundSecondary};
          left: 7%;
        `
      }
    }}
  }
`

export const StyledInfoSvg = styled(InfoSvg)`
  vertical-align: super;
  fill: ${({ theme }) => theme.colors.buttons.link};
  path {
    fill: ${({ theme }) => theme.colors.buttons.link};
  }
`
