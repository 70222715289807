import { SubscriptionPermission } from '~common/types/common-link-types'

import { SoSVTimeframe } from '~common'
import { formatStartAndEndDatesParams } from '~components/helpers'
import { SelectOption } from '~components/ui/controlled/select/types'
import { List, ListForUser } from './types'

export const mapToListsOptions = (lists: List[]): SelectOption[] => {
  let listsOptions: SelectOption[] = []
  lists.forEach((listChild1) => {
    if (listChild1.children.length) {
      listChild1.children.forEach((listChild2) => {
        if (listChild2.permissions.includes(SubscriptionPermission.SoSV)) {
          listsOptions = [
            ...listsOptions,
            {
              value: listChild2.id,
              label: `${listChild1.name} - ${listChild2.name}`,
            },
          ]
        }
      })
    } else {
      if (listChild1.permissions.includes(SubscriptionPermission.SoSV)) {
        listsOptions = [
          ...listsOptions,
          {
            value: listChild1.id,
            label: `${listChild1.name}`,
          },
        ]
      }
    }
  })
  return listsOptions
}

export const findSelectedList = (lists: ListForUser[], listId: string): ListForUser | undefined => {
  for (const list of lists) {
    if (list.id === listId) {
      return list
    } else if ('children' in list && list.children.length > 0) {
      const desiredList = findSelectedList(list.children, listId)
      if (desiredList) {
        return desiredList
      }
    }
  }
  return undefined
}

export const selectedListHasSoSV = (lists: ListForUser[], listId: string): boolean => {
  for (const list of lists) {
    if (list.id === listId && list.permissions.includes(SubscriptionPermission.SoSV)) {
      return true
    } else if ('children' in list && list.children.length > 0) {
      const hasSoSV = selectedListHasSoSV(list.children, listId)
      if (hasSoSV) {
        return true
      }
    }
  }
  return false
}

export const selectedListHasSoSVScience = (lists: ListForUser[], listId: string): boolean => {
  for (const list of lists) {
    if (list.id === listId && list.permissions.includes(SubscriptionPermission.SoSVScience)) {
      return true
    } else if ('children' in list && list.children.length > 0) {
      const hasSoSVScience = selectedListHasSoSVScience(list.children, listId)
      if (hasSoSVScience) {
        return hasSoSVScience
      }
    }
  }
  return false
}

export const buildFiltersParamsString = ({
  path,
  id,
  listId,
  productGroupId,
  dataSet,
  contentTypes,
  timeframe,
  startDate,
  endDate,
  graphType,
  drugSelection,
  topicSelection,
  weighteningType,
  cumulatingType,
  rootSubscriptionId,
  journalId,
  meetingId,
  societyId,
  journalsSortBy,
  expertsSortBy,
  meetingsSortBy,
  city,
  state,
  country,
  southWestLng,
  southWestLat,
  northEastLng,
  northEastLat,
  savedListId,
  smartListId,
  sosvLaunchAnalogueRange,
}: {
  path: string
  listId: string
  productGroupId: string
  dataSet: string
  contentTypes: Record<string, boolean>
  graphType: string
  timeframe: SoSVTimeframe
  drugSelection: Record<string, boolean>
  topicSelection: Record<string, boolean>
  weighteningType: string
  cumulatingType: string
  rootSubscriptionId: string
  id?: string
  startDate?: Date
  endDate?: Date
  journalId?: string
  meetingId?: string
  societyId?: string
  journalsSortBy?: string
  expertsSortBy?: string
  meetingsSortBy?: string
  city?: string
  state?: string
  country?: string
  southWestLng?: string
  southWestLat?: string
  northEastLng?: string
  northEastLat?: string
  savedListId?: string
  smartListId?: string
  sosvLaunchAnalogueRange?: SoSVTimeframe
}): string => {
  const rootSubscriptionIdParam = rootSubscriptionId ? `rootSubscriptionId=${rootSubscriptionId}` : ''
  const listIdParam = `listId=${listId}`
  const dataSetParam = `dataSet=${dataSet}`
  const timeframeParam = `sosvTimeframe=${timeframe}`
  const graphTypeParam = `graphType=${graphType}`
  const weighteningTypeParam = `weighteningType=${weighteningType}`
  const cumulatingTypeParam = `cumulatingType=${cumulatingType}`
  let contentTypesParams, drugSelectionParams, topicSelectionParams
  let startAndEndDatesParams = ''

  const contentTypesConfiguration = Object.entries(contentTypes || {}).filter(([_, selected]) => selected)
  const drugSelectionConfiguration = Object.entries(drugSelection || {}).filter(([_, selected]) => selected)
  const topicSelectionConfiguration = Object.entries(topicSelection || {}).filter(([_, selected]) => selected)

  const isContentTypesSelected = !!contentTypesConfiguration.length
  const isDrugSelected = !!drugSelectionConfiguration.length
  const isTopicSelected = !!topicSelectionConfiguration.length

  if (isContentTypesSelected) {
    contentTypesParams = contentTypesConfiguration.map(([type, _]) => `sosvContentTypes=${type}`).join('&')
  }

  if (isDrugSelected) {
    drugSelectionParams = drugSelectionConfiguration
      ?.map(([drug, _]) => `drugSelection=${encodeURIComponent(drug)}`)
      .join('&')
  }

  if (isTopicSelected) {
    topicSelectionParams = topicSelectionConfiguration?.map(([topic, _]) => `topicSelection=${topic}`).join('&')
  }

  const isCustomDateRangeWithStartAndEndDates = timeframe === 'customDateRange' && !!startDate && !!endDate

  if (isCustomDateRangeWithStartAndEndDates) {
    startAndEndDatesParams = formatStartAndEndDatesParams(startDate, endDate)
  }

  return `${path.replace(
    '/[id]',
    `/${id}`,
  )}?${rootSubscriptionIdParam}&${listIdParam}&${dataSetParam}&${timeframeParam}${
    isCustomDateRangeWithStartAndEndDates ? startAndEndDatesParams : ''
  }${
    isContentTypesSelected ? `&${contentTypesParams}` : ''
  }&${graphTypeParam}&${weighteningTypeParam}&${cumulatingTypeParam}${
    productGroupId ? `&productGroupId=${productGroupId}` : ''
  }${isDrugSelected ? `&${drugSelectionParams}` : ''}${isTopicSelected ? `&${topicSelectionParams}` : ''}${
    journalsSortBy ? `&journalsSortBy=${journalsSortBy}` : ''
  }${expertsSortBy ? `&expertsSortBy=${expertsSortBy}` : ''}${journalId ? `&journalId=${journalId}` : ''}${
    meetingId ? `&meetingId=${meetingId}` : ''
  }${societyId ? `&societyId=${societyId}` : ''}${meetingsSortBy ? `&meetingsSortBy=${meetingsSortBy}` : ''}${
    city ? `&city=${city}` : ''
  }${state ? `&state=${state}` : ''}${country ? `&country=${country}` : ''}${
    southWestLng ? `&southWestLng=${southWestLng}` : ''
  }${southWestLat ? `&southWestLat=${southWestLat}` : ''}${northEastLng ? `&northEastLng=${northEastLng}` : ''}${
    northEastLat ? `&northEastLat=${northEastLat}` : ''
  }${savedListId ? `&savedListId=${savedListId}` : ''}${smartListId ? `&smartListId=${smartListId}` : ''}${
    sosvLaunchAnalogueRange ? `&sosvLaunchAnalogueRange=${sosvLaunchAnalogueRange}` : ''
  }`
}
