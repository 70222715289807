import React, { Dispatch, FC, useContext } from 'react'
import { useConfig, useIsTouchableDevice, useOutsideClick, useWindowSize, WindowSize } from '~common/hooks'
import { StyledHeader, StyledHeaderLoadingBlock, StyledIqviaBrandLogo, StyledPharmaspectraBrandLogo } from './styles'
import { GlobalNavigation } from './components/global-navigation'
import { MobileNavigation } from './components/mobile-navigation'
import { DataSet, Hubs } from '~common'
import { ExpertsSvg, PLISvg, OKAISvg, ScienceSvg, SoSVSvg } from '~common/svgs'
import { useFeatures } from '~common/hooks/use-features'
import { RedirectManagerContext } from '~common/hooks/use-redirect-manager'
import { getHubsForNavigation } from '~common/helpers/common-link-helpers'
import { useRouter } from 'next/router'
import { useZendeskUrl } from '~common/hooks/use-zendesk'
import { useHubs } from '~common/hooks/use-hubs'

export const Header: FC<{ setError?: Dispatch<Error | undefined> }> = ({ setError }): JSX.Element => {
  const router = useRouter()
  const { dataSet } = router.query as {
    dataSet?: DataSet
  }
  const { zendeskUrl, refetch } = useZendeskUrl()
  const { setManualLogout } = useContext(RedirectManagerContext)
  const { isIqviaBrand, isInternalTestingGroup, featuresForUserResponse } = useFeatures()
  const { PLI_URL = '' } = useConfig()

  const {
    expertsHubState: {
      permissionsForSelectedSubscription,
      hasSoSVExpertsForSelectedList,
      hasInitialised,
      sosvProducts,
      productGroupId,
      listId,
      rootSubscriptionId,
      smartListId,
      savedListId,
      subscriptions,
      userEmail,
    },
  } = useHubs(setError)

  const hasPanSubscriptionSearchMenuItem: boolean =
    subscriptions.reduce(
      (accumulator, current) => accumulator + (current.children?.length || 0 ? current.children.length : 1),
      0,
    ) > 1

  const { width: screenWidth }: WindowSize = useWindowSize()
  const { isTouchable, handleTouchStart, handleMouseMove } = useIsTouchableDevice()
  const { ref: menuRef, state: isMenuOpened, action: setIsMenuOpened } = useOutsideClick<HTMLLIElement>(false)

  const isRootOrHubLevel = ['/experts', '/sosv', '/'].includes(router.route)
  const hubs: Hubs = isRootOrHubLevel
    ? {}
    : {
        primary: getHubsForNavigation({
          listId,
          rootSubscriptionId,
          smartListId,
          savedListId,
          productGroupId,
          permissionsForSelectedSubscription:
            router.route === '/not-authorized' ? [] : permissionsForSelectedSubscription,
          isLoading: false,
          hasSoSVExpertsForSelectedList,
          sosvProducts,
          isInternalTestingGroup,
          dataSet,
          ExpertsHubIcon: <ExpertsSvg />,
          SoSVHubIcon: <SoSVSvg />,
          ScienceHubIcon: <ScienceSvg />,
          PLIHubIcon: <PLISvg />,
          OKAIListIcon: <OKAISvg />,
          userEmail,
          pliUrl: PLI_URL,
        }),
      }

  const handleLogout = () => {
    setManualLogout(true)
    window.location.href = (process?.env?.LOGOUT_URL || 'https://products.pharmaspectra.com') + '/logout'
  }

  const subscribedHubsCount: number = Object.keys(hubs).length
    ? Object.keys(hubs).reduce((acc: number, cur: string) => {
        acc += hubs[cur].length
        return acc
      }, 0)
    : 0

  const navigationItems = Object.entries(hubs)

  const isMobileView: boolean = screenWidth < 600

  if (!hasInitialised || featuresForUserResponse.loading) {
    return <StyledHeaderLoadingBlock />
  }

  return (
    <StyledHeader tabIndex={-1} onTouchStart={handleTouchStart} onMouseMove={handleMouseMove} data-testid="header">
      {isIqviaBrand ? (
        <StyledIqviaBrandLogo href="/" isTouchable={isTouchable} hubsCount={subscribedHubsCount} withNextLink />
      ) : (
        <StyledPharmaspectraBrandLogo
          hubsCount={subscribedHubsCount}
          isTouchable={isTouchable}
          logoSize="md"
          logoVariant="primary"
          href="/"
          withNextLink
        />
      )}
      <GlobalNavigation
        navigationItems={navigationItems}
        isSubMenuOpened={isMenuOpened}
        onSubMenuToggle={setIsMenuOpened}
        onLogout={handleLogout}
        isMobileView={isMobileView}
        isTouchable={isTouchable}
        subMenuRef={menuRef}
        zendeskUrl={zendeskUrl}
        refetchZendeskUrl={refetch}
        hasPanSubscriptionSearchMenuItem={hasPanSubscriptionSearchMenuItem}
      />
      <MobileNavigation
        isMobileNavigationOpened={isMenuOpened}
        onMobileNavigationToggle={setIsMenuOpened}
        onLogout={handleLogout}
        isMobileView={isMobileView}
        isTouchable={isTouchable}
        zendeskUrl={zendeskUrl}
        hasPanSubscriptionSearchMenuItem={hasPanSubscriptionSearchMenuItem}
      />
    </StyledHeader>
  )
}
