import React from 'react'
import { GetSoSVJournalsQuery } from '~apollo/queries/sosv/journals/__generated__/GetSoSVJournalsQuery'
import { formatUrl, generateSoSVModuleTitle, Mode, roundDecimals, SoSVTimeframe } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { getMentions } from '~components/pages/sosv-hub/helpers'
import { ChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { ChartLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { Module } from '~components/ui/molecules/module'
import { CollectionsProductsMentionsStackedBarChart } from '~components/ui/organisms/charts'
import { useFeatures } from '~common/hooks/use-features'
import { StyledJournalsCollectionTeaser } from '~components/pages/sosv-hub/styles'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'

export const TopJournals: React.FC<{
  styleMode: Mode
  data?: GetSoSVJournalsQuery
  isLoading?: boolean
  drugSelection?: string[]
}> = ({ data, isLoading, styleMode }) => {
  const { isIqviaBrand, showSosvChartDataExport } = useFeatures()
  const themeBrand = isIqviaBrand ? 'iqvia' : 'pharmaspectra'

  const {
    listId,
    rootListId,
    productGroupId,
    source,
    weighteningType,
    contentTypes,
    timeframe,
    cumulatingType,
    graphType,
    drugSelection = [],
    rootListName: subscriptionName = '',
    listName = '',
    startDate,
    endDate,
  } = useSoSVState()

  const fullVersionUrl = (journalId?: string): string => {
    const subpage = journalId ? '/sosv/journals/details' : '/sosv/journals'

    return formatUrl(getPlatformUrl() + subpage, {
      listId,
      rootSubscriptionId: rootListId,
      weighteningType: weighteningType,
      productGroupId: productGroupId,
      sosvTimeframe: timeframe,
      sosvContentTypes: contentTypes,
      drugSelection,
      expertsSortBy: 'impactScore',
      cumulatingType,
      graphType,
      dataSet: source,
      journalId,
      startDate,
      endDate,
    })
  }

  const hasSoSVJournals = (data?.getSoSVJournalsCount ?? 0) > 0

  if (isLoading) return <ChartLoadingBlock />

  return (
    <Module.Container>
      <Module.Header
        linkText={hasSoSVJournals ? 'View all' : undefined}
        linkTo={hasSoSVJournals ? fullVersionUrl() : undefined}
      >
        {generateSoSVModuleTitle({
          moduleType: 'journals',
          sosvTimeframe: SoSVTimeframe.ThreeYears,
          weighteningType,
          top: 5,
        })}
      </Module.Header>

      {!!data?.getSoSVJournalsCount ? (
        <CollectionsProductsMentionsStackedBarChart
          weighteningType={weighteningType}
          barHeightSize="md"
          collections={data.getSoSVJournals.map(({ id, title, impactScore }) => ({
            id,
            title,
            collectionUI: (
              <LinkThemeProvider mode={styleMode} brand={themeBrand}>
                <StyledJournalsCollectionTeaser
                  heading={title}
                  rank={`SJR score: ${roundDecimals(impactScore || 1, 1)}`}
                  linkTo={fullVersionUrl(id)}
                />
              </LinkThemeProvider>
            ),
          }))}
          exportChartConfig={
            showSosvChartDataExport
              ? {
                  collectionName: 'journals',
                  subscriptionName: subscriptionName,
                  listName: listName,
                  sosvTimeframe: SoSVTimeframe.ThreeYears,
                  contentTypes: ['journal-article'],
                  weighteningType: weighteningType,
                }
              : undefined
          }
          showLegend={true}
          categories={data.getSoSVJournals.map(({ id }) => id)}
          mentions={getMentions(drugSelection, data.getSoSVJournals)}
          themeMode={styleMode}
          themeBrand={themeBrand}
        />
      ) : (
        <ChartEmptyState isStackedBar />
      )}
      {hasSoSVJournals ? (
        <Module.Footer
          links={[
            {
              name: `View all ${data?.getSoSVJournalsCount} journals`,
              id: 'journals',
              href: fullVersionUrl(),
            },
          ]}
        />
      ) : null}
    </Module.Container>
  )
}
