/**
 * @param allItems  items must have a profileId OR id field that will match the lastItemId
 *                  unless we make this more generic
 * @param variables including lastItemId (past the first page) and pageSize
 */
export const fakePagination = <
  T extends { id?: string; profileId?: string | null; subscriptionId?: string; listId?: string | null },
>(
  allItems: T[],
  variables: { lastItemId?: string | null; pageSize: number },
): T[] => {
  const startingPoint = variables.lastItemId
    ? allItems.findIndex(({ profileId, subscriptionId, listId, id }) =>
        [profileId, subscriptionId, listId, id].includes(variables.lastItemId as string),
      ) + 1
    : 0
  return allItems.slice(startingPoint, startingPoint + variables.pageSize)
}
