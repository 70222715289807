import { OptStatus, SearchTimeframe } from '~apollo/generated-global-types'
import {
  SearchProfilesAcrossSubscriptionsQuery_searchProfilesAcrossSubscriptions_profiles_SearchProfileAcrossSubscriptionsType,
  SearchProfilesAcrossSubscriptionsQuery_searchProfilesAcrossSubscriptions_profiles_SearchProfileAcrossSubscriptionsType_subscriptionNetworks,
} from '~apollo/queries/pan-subscription-search/__generated__/SearchProfilesAcrossSubscriptionsQuery'
import { GetSmartListQuery_getSmartList } from '~apollo/queries/smart-list/__generated__/GetSmartListQuery'
import {
  GetSoSVJournalArticlesQuery_getSoSVJournalArticles,
  GetSoSVJournalArticlesQuery_getSoSVJournalArticles_mentions,
} from '~apollo/queries/sosv/journal-articles/__generated__/GetSoSVJournalArticlesQuery'
import { GetSoSVJournalsQuery_getSoSVJournals_mentions } from '~apollo/queries/sosv/journals/__generated__/GetSoSVJournalsQuery'
import {
  GetSoSVPresentationsQuery_getSoSVPresentations,
  GetSoSVPresentationsQuery_getSoSVPresentations_mentions,
} from '~apollo/queries/sosv/presentations/__generated__/GetSoSVPresentationsQuery'
import {
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType,
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_digitalAffinity,
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_influenceScores,
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_latestContribution,
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_sosvSummaries_sosvStats,
} from '~apollo/queries/subscriptions/__generated__/GetProfilesInListQuery'
import { GetSubscriptionsForUserQuery_getSubscriptionsForUser } from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { GetClinicalTrialByIdForProfileQuery_getClinicalTrialById } from '~components/pages/experts-hub/clinical-trials/__generated__/GetClinicalTrialByIdForProfileQuery'
import { GetClinicalTrialsForProfileQuery_getClinicalTrialsForProfile } from '~components/pages/experts-hub/clinical-trials/__generated__/GetClinicalTrialsForProfileQuery'
import {
  GetCVForProfileQuery_getBooksForProfile,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_awards,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_certifications,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_educationAndResidencies,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_fellowships,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_formerRoles,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_licenses,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_nihGrants,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_otherGrants,
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_patents,
} from '~components/pages/experts-hub/cv/__generated__/GetCVForProfileQuery'
import { GetDisclosuresForProfileQuery_getDisclosuresForProfile } from '~components/pages/experts-hub/disclosures/__generated__/GetDisclosuresForProfileQuery'
import {
  GetProfileQuery_getProfileByIdFromDDB_ProfileType,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_clinicalTrials,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_contacts,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_currentResearch,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_currentRoles,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_digitalAffinity,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_disclosures,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_editorialResponsibilities,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_journals,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_presentations,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_socialProfile,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_societyRoles,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_stats_clinicalTrials_phaseCounts,
  GetProfileQuery_getProfileByIdFromDDB_ProfileType_treatmentGuidelines,
} from '~components/pages/experts-hub/expert/__generated__/GetProfileQuery'
import {
  GetNetworkConnectionsQuery_getNetworkConnectionsForProfile,
  GetNetworkConnectionsQuery_getNetworkConnectionsForProfile_connections_NetworkConnectionType,
  GetNetworkConnectionsQuery_getNetworkConnectionsForProfile_connections_NetworkProfileLockedConnectionType,
} from '~components/pages/experts-hub/network/__generated__/GetNetworkConnectionsQuery'
import {
  GetPresentationByIdQuery_getPresentationById,
  GetPresentationByIdQuery_getPresentationById_authors,
} from '~components/pages/shared/presentations/__generated__/GetPresentationByIdQuery'
import { GetPresentationsForProfileQuery_getPresentationsForProfile } from '~components/pages/shared/presentations/__generated__/GetPresentationsForProfileQuery'
import {
  GetJournalArticlesByIdQuery_getJournalArticleById,
  GetJournalArticlesByIdQuery_getJournalArticleById_abstract,
  GetJournalArticlesByIdQuery_getJournalArticleById_affiliations,
} from '~components/pages/shared/journal-articles/__generated__/GetJournalArticlesByIdQuery'
import { GetJournalArticlesForProfileQuery_getJournalArticlesForProfile } from '~components/pages/shared/journal-articles/__generated__/GetJournalArticlesForProfileQuery'
import {
  arrayOf,
  randomArrayOf,
  randomBool,
  randomDate,
  randomDateOnly,
  randomFloat,
  randomFromYear,
  randomHCPIdentifier,
  randomISODate,
  randomItemFrom,
  randomItemFromEnum,
  randomLatitude,
  randomLengthArray,
  randomLongitude,
  randomNumber,
  randomString,
  randomStringArray,
  randomToYear,
  randomUrl,
  thingOrNull,
} from '~testing/test-helpers'
import {
  GetSubscriptionInfoForProfileQuery_getSubscriptionById,
  GetSubscriptionInfoForProfileQuery_getSubscriptionStatsForProfile,
} from './subscriptions/__generated__/GetSubscriptionInfoForProfileQuery'
import { getJournalArticlesAuthorsByJournalIdQuery_getJournalArticleAuthorsByJournalId } from '~components/pages/shared/journal-articles/__generated__/getJournalArticlesAuthorsByJournalIdQuery'
import {
  GetSavedSearchesForUserEmailQuery_getSavedSearchesForUserEmail,
  GetSavedSearchesForUserEmailQuery_getSavedSearchesForUserEmail_pills,
} from '~apollo/queries/saved-searches/__generated__/GetSavedSearchesForUserEmailQuery'
import { GetSavedListsForUserEmailQuery_getSavedListsForUserEmail } from './saved-lists/__generated__/GetSavedListsForUserEmailQuery'
import { GetSavedListQuery_getSavedList } from './saved-lists/__generated__/GetSavedListQuery'
import { GetSubscriptionByIdQuery_getSubscriptionById } from './subscriptions/__generated__/GetSubscriptionByIdQuery'
import {
  GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentJournalArticles,
  GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentJournalArticles_mentions,
  GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentPresentations,
  GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentPresentations_mentions,
} from '~components/pages/experts-hub/sosv-page/__generated__/GetProfileSoSVRightPaneDataQuery'
import {
  SearchProfilesQuery_searchProfiles_profiles_SearchProfileType,
  SearchProfilesQuery_searchProfiles_profiles_SearchProfileType_statsPerSubscription,
} from './search/__generated__/SearchProfilesQuery'
import { GetProfileContentForSearchQuery_searchContentForProfile_socials_items } from '~components/pages/experts-hub/expert/__generated__/GetProfileContentForSearchQuery'
import { roundDecimals } from '~common'

export class SubProfileBuilder {
  public static random(
    overrides: Partial<GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType> = {},
  ): GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType {
    const totalPresentations = overrides.totalPresentations ?? randomNumber()
    const totalJournals = overrides.totalJournals ?? randomNumber()
    const totalClinicalTrials = overrides.totalClinicalTrials ?? randomNumber()
    const totalTreatmentGuidelines = overrides.totalClinicalTrials ?? randomNumber()

    return {
      createdOn: randomISODate(),
      updatedOn: randomISODate(),
      profileId: randomString('profileId'),
      hcpIdentifier: {
        type: randomHCPIdentifier(),
        value: randomNumber().toString(),
      },
      fullName: randomString('fullName'),
      profileStatus: 'Live',
      specialities: randomArrayOf(() => randomString('speciality')),
      contacts: randomArrayOf(() => ContactBuilder.random()),
      degrees: randomArrayOf(() => randomString('degree')),
      areasOfInterest: randomArrayOf(() => randomString('areaOfInterestnterest')),
      totalPresentations: totalPresentations,
      totalJournals: totalJournals,
      totalClinicalTrials: totalClinicalTrials,
      currentResearch: randomArrayOf(() => CurrentResearchBuilder.random()),
      totalTreatmentGuidelines: totalTreatmentGuidelines,
      totalGrants: randomNumber(),
      totalPatents: randomNumber(),
      subscriptionStats: StatsInSubscriptionBuilder.random(),
      firstName: randomString('firstName'),
      middleName: thingOrNull(randomString('middleName')),
      lastName: thingOrNull(randomString('lastName')),
      suffix: thingOrNull(randomString('suffix')),
      socialProfile: thingOrNull(SocialProfileBuilder.random()),
      doesWorkWithIndustry: randomBool(),
      isPrimaryInvestigator: randomBool(),
      networkConnectionsTop3: NetworkConnectionsBuilder.random(3),
      latestContribution: thingOrNull(LatestContributionBuilder.random()),
      gdprExcluded: randomBool(),
      alsoInLists: randomArrayOf(() => randomString('listId')),
      optStatus: OptStatus.in,
      sosvSummaries: [],
      digitalAffinity: thingOrNull(DigitalAffinityInSubscriptionBuilder.random()),
      influenceScores: thingOrNull(InfluenceScoresInSubscriptionBuilder.random()),
      __typename: 'SubscriptionProfileType',
      ...overrides,
    }
  }
}

type DigitalAffinityInSubscription =
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_digitalAffinity

type InfluenceScoresInSubscription =
  GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_influenceScores

export class DigitalAffinityInSubscriptionBuilder {
  public static random(overrides: Partial<DigitalAffinityInSubscription> = {}): DigitalAffinityInSubscription {
    return {
      subscriptionId: randomString('subscritpion-id'),
      totalEarnedContent: randomNumber(0, 10000),
      totalOwnedContent: randomNumber(0, 10000),
      digitalRank: randomNumber(1),
      digitalOnTopicPercentage: randomNumber(0, 100),
      ...overrides,
    }
  }
}

export class InfluenceScoresInSubscriptionBuilder {
  public static random(overrides: Partial<InfluenceScoresInSubscription> = {}): InfluenceScoresInSubscription {
    return {
      subscriptionId: randomString('subscritpion-id'),
      overall: randomNumber(0, 10000),
      localityNational: randomNumber(0, 10000),
      localityInternational: randomNumber(0, 10000),
      ...overrides,
    }
  }
}

type DigitalAffinityInProfile = GetProfileQuery_getProfileByIdFromDDB_ProfileType_digitalAffinity
export class DigitalAffinityInProfileBuilder {
  public static random(overrides: Partial<DigitalAffinityInProfile> = {}): DigitalAffinityInProfile {
    return {
      digitalRank: randomNumber(1),
      totalEarnedContent: randomNumber(0, 10000),
      totalOwnedContent: randomNumber(0, 10000),
      digitalOnTopicPercentage: randomNumber(0, 100),
      profileOKAIUrl: thingOrNull(`https://www.test.com/${randomString('profileid')}`),
      ...overrides,
    }
  }
}

type FullProfile = GetProfileQuery_getProfileByIdFromDDB_ProfileType &
  GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType

export class ProfileBuilder {
  public static random(overrides: Partial<FullProfile> = {}): FullProfile {
    const totalPresentations = overrides.presentations?.length ?? overrides.totalPresentations ?? randomNumber()
    const totalJournals = overrides.journals?.length ?? overrides.totalJournals ?? randomNumber()
    const totalClinicalTrials = overrides.clinicalTrials?.length ?? overrides.totalClinicalTrials ?? randomNumber()
    const totalTreatmentGuidelines =
      overrides.treatmentGuidelines?.length ?? overrides.totalTreatmentGuidelines ?? randomNumber()

    return {
      profileId: randomString('profileId'),
      hcpIdentifier: {
        type: randomHCPIdentifier(),
        value: randomNumber().toString(),
      },
      fullName: randomString('fullName'),
      profileStatus: 'Live',
      specialities: randomArrayOf(() => randomString('speciality')),
      contacts: randomArrayOf(() => ContactBuilder.random()),
      degrees: randomArrayOf(() => randomString('degree')),
      areasOfInterest: randomArrayOf(() => randomString('areaOfInterestnterest')),
      totalPresentations: totalPresentations,
      organizationMostPresentedFor: randomString('orgMostPresentedFor'),
      presentations: arrayOf(() => PresentationBuilder.random(), totalPresentations),
      totalJournals: totalJournals,
      journals: arrayOf(() => JournalBuilder.random(), totalJournals),
      editorialResponsibilities: randomArrayOf(() => ResponsibilityBuilder.random()),
      societyRoles: randomArrayOf(() => SocietyRoleBuilder.random()),
      societyMemberships: randomArrayOf(() => SocietyRoleBuilder.random()),
      totalClinicalTrials: totalClinicalTrials,
      clinicalTrials: arrayOf(() => ClinicalTrialBuilder.random(), totalClinicalTrials),
      currentRoles: randomArrayOf(() => RoleBuilder.random()),
      disclosures: new DisclosuresBuilder().build(),
      currentResearch: randomArrayOf(() => CurrentResearchBuilder.random()),
      totalTreatmentGuidelines: totalTreatmentGuidelines,
      treatmentGuidelines: arrayOf(() => TreatmentGuidelineBuilder.random(), totalTreatmentGuidelines),
      stats: {
        clinicalTrials: {
          phaseCounts: randomArrayOf(() => ClinicalTrialStatsPhaseCountBuilder.random()),
        },
      },
      awards: randomArrayOf(() => AwardBuilder.random()),
      fellowships: randomArrayOf(() => FellowshipBuilder.random()),
      licenses: randomArrayOf(() => LicenseBuilder.random()),
      formerRoles: randomArrayOf(() => RoleBuilder.random()),
      nihGrants: randomArrayOf(() => GrantBuilder.randomNIHGrant()),
      otherGrants: randomArrayOf(() => GrantBuilder.randomOtherGrant()),
      patents: randomArrayOf(() => PatentBuilder.random()),
      certifications: randomArrayOf(() => CertificationBuilder.random()),
      totalAwards: randomNumber(),
      totalBooks: randomNumber(),
      totalGrants: randomNumber(),
      totalPatents: randomNumber(),
      educationAndResidencies: randomArrayOf(() => EducationAndResidencyBuilder.random()),
      firstName: randomString('firstName'),
      lastName: thingOrNull(randomString('lastName')),
      socialProfile: thingOrNull(SocialProfileBuilder.random()),
      gdprExcluded: false,
      optStatus: OptStatus.in,
      digitalAffinity: thingOrNull(DigitalAffinityInProfileBuilder.random()),
      influenceScores: thingOrNull(InfluenceScoresInSubscriptionBuilder.random()),
      sosvProductGroupsStats: [],
      __typename: 'ProfileType',
      ...overrides,
    }
  }
}

export class SearchProfileBuilder {
  public static random(
    overrides: Partial<SearchProfilesQuery_searchProfiles_profiles_SearchProfileType> = {},
  ): SearchProfilesQuery_searchProfiles_profiles_SearchProfileType {
    return {
      profileId: randomString('profileId'),
      fullName: randomString('fullName'),
      profileStatus: 'Live',
      specialities: randomArrayOf(() => randomString('speciality')),
      contacts: randomArrayOf(() => ContactBuilder.random()),
      degrees: randomArrayOf(() => randomString('degree')),
      areasOfInterest: randomArrayOf(() => randomString('areaOfInterestnterest')),
      currentResearch: randomArrayOf(() => CurrentResearchBuilder.random()),
      socialProfile: thingOrNull(SocialProfileBuilder.random()),
      doesWorkWithIndustry: randomBool(),
      isPrimaryInvestigator: randomBool(),
      networkConnectionsTop3: NetworkConnectionsBuilder.random(3),
      statsPerSubscription: randomArrayOf(() => StatsPerSubscriptionBuilder.random()),
      matches: {
        count: randomNumber(),
        percentage: randomNumber(),
      },
      alsoInLists: randomArrayOf(() => randomString('listId'), randomNumber(0, 15)),
      digitalAffinityPerSubscription: thingOrNull([DigitalAffinityInSubscriptionBuilder.random()]),
      influenceScoresPerSubscription: thingOrNull([InfluenceScoresInSubscriptionBuilder.random()]),
      __typename: 'SearchProfileType',
      ...overrides,
    }
  }
}

export class SearchProfileAcrossSubscriptionsBuilder {
  public static random(
    overrides: Partial<SearchProfilesAcrossSubscriptionsQuery_searchProfilesAcrossSubscriptions_profiles_SearchProfileAcrossSubscriptionsType> = {},
  ): SearchProfilesAcrossSubscriptionsQuery_searchProfilesAcrossSubscriptions_profiles_SearchProfileAcrossSubscriptionsType {
    return {
      profileId: randomString('profileId'),
      searchScore: randomNumber(0, 1000),
      fullName: randomString('fullName'),
      disclosuresTotalCount: randomNumber(),
      twitterFollowersCount: randomNumber(),
      profileStatus: 'Live',
      specialities: randomArrayOf(() => randomString('speciality')),
      contacts: randomArrayOf(() => ContactBuilder.random()),
      degrees: randomArrayOf(() => randomString('degree')),
      areasOfInterest: randomArrayOf(() => randomString('areaOfInterestnterest')),
      currentResearch: randomArrayOf(() => CurrentResearchBuilder.random()),
      socialProfile: thingOrNull(SocialProfileBuilder.random()),
      doesWorkWithIndustry: randomBool(),
      isPrimaryInvestigator: randomBool(),
      subscriptionNetworks: [NetworkConnectionsAcrossSubscriptionsBuilder.random()],
      subscriptionStats: randomArrayOf(() => ({
        ...StatsPerSubscriptionBuilder.random(),
        subscriptionName: randomString('subscriptionName'),
      })),
      matches: {
        count: randomNumber(),
        percentage: randomNumber(),
      },
      __typename: 'SearchProfileAcrossSubscriptionsType',
      ...overrides,
    }
  }
}

export class ContactBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_contacts> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_contacts {
    return {
      institutionName: thingOrNull(randomString('institutionName')),
      address1: thingOrNull(randomString('address1')),
      address2: thingOrNull(randomString('address2')),
      city: thingOrNull(randomString('city')),
      country: thingOrNull(randomString('country')),
      state: thingOrNull(randomString('state')),
      zip: thingOrNull(randomString('zip')),
      firstEmail: thingOrNull(randomString('firstEmail')),
      secondEmail: thingOrNull(randomString('secondEmail')),
      phoneNumber: thingOrNull(randomString('phoneNumber')),
      faxNumber: thingOrNull(randomString('faxNumber')),
      location: { lat: randomLatitude(), lon: randomLongitude() },
      ...overrides,
    }
  }
}

export class LatestContributionBuilder {
  public static random(
    overrides: Partial<GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_latestContribution> = {},
  ): GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_latestContribution {
    return {
      id: randomString('id'),
      contributionType: randomItemFrom(['journal-articles', 'presentations']),
      sourceName: thingOrNull(randomString('sourceName')),
      title: thingOrNull(randomString('title')),
      type: thingOrNull(randomItemFrom(['Poster', 'Article'])),
      date: randomDate().toISOString(),
      ...overrides,
    }
  }
}

type FullPresentation = GetProfileQuery_getProfileByIdFromDDB_ProfileType_presentations &
  GetPresentationsForProfileQuery_getPresentationsForProfile &
  GetPresentationByIdQuery_getPresentationById

export class PresentationBuilder {
  public static random(overrides: Partial<FullPresentation> = {}): FullPresentation {
    const affiliationKeys = Array(randomNumber(1, 6))
      .fill(null)
      .map((val, i) => 1 + i)
      .map(String)
    return {
      id: randomString('id'),
      date: randomISODate(),
      type: thingOrNull(randomString('type')),
      conferenceName: thingOrNull(randomString('conferenceName')),
      organization: thingOrNull(randomString('organization')),
      title: thingOrNull(randomString('title')),
      abstract: randomArrayOf(() => AbstractSectionBuilder.random()),
      affiliations: randomArrayOf((i) => AffiliationBuilder.random(affiliationKeys[i])),
      authors: randomArrayOf(() => AuthorBuilder.random(affiliationKeys)),
      medmemeId: randomNumber(100, 10000) + '',
      isOnTopic: false,
      ...overrides,
    }
  }
}

export class SocialContributionBuilder {
  public static random(
    overrides: Partial<GetProfileContentForSearchQuery_searchContentForProfile_socials_items> = {},
  ): GetProfileContentForSearchQuery_searchContentForProfile_socials_items {
    return {
      contributionDate: randomISODate(),
      contributionId: randomString('id'),
      contributionText: thingOrNull(randomString('contributionText')),
      contributionUrl: thingOrNull(randomUrl('contributionUrl')),
      listId: thingOrNull(randomString('listId')),
      profileId: thingOrNull(randomString('profileId')),
      mediaUrls: [{ mediaUrl: randomUrl('mediaUrl'), type: randomItemFrom(['image', 'video', 'pdf']) }],
      ...overrides,
    }
  }
}

type FullClinicalTrial = GetProfileQuery_getProfileByIdFromDDB_ProfileType_clinicalTrials &
  GetClinicalTrialByIdForProfileQuery_getClinicalTrialById &
  GetClinicalTrialsForProfileQuery_getClinicalTrialsForProfile

export class ClinicalTrialBuilder {
  public static random(overrides: Partial<FullClinicalTrial> = {}): FullClinicalTrial {
    const sourceTrialId = randomString('sourceTrialId')
    return {
      id: randomString('id'),
      externalTrialId: sourceTrialId,
      sourceTrialId: sourceTrialId,
      endDate: randomISODate(),
      startDate: randomISODate(),
      briefTitle: randomString('briefTitle'),
      acceptsHealthyVolunteers: randomBool(),
      biospecimenDescription: randomString('biospecimenDescription'),
      biospecimenRetention: randomString('biospecimenRetention'),
      collaborator: randomString('collaborator'),
      condition: randomString('condition'),
      detailedDescription: randomString('detailedDescription'),
      estimatedCompletionDate: randomISODate(),
      estimatedEnrollment: randomString('estimatedEnrollment'),
      exclusionCriteria: randomString('exclusionCriteria'),
      firstReceivedDate: randomISODate(),
      gender: randomItemFrom(['F', 'M', 'B']),
      healthAuthority: randomString('healthAuthority'),
      inclusionCriteria: randomString('inclusionCriteria'),
      informationProvidedBy: randomString('informationProvidedBy'),
      intervention: randomString('intervention'),
      lastUpdatedDate: randomISODate(),
      lastVerificationDate: randomISODate(),
      officialTitle: randomString('officialTitle'),
      otherRegistryID: randomString('otherRegistryID'),
      outcomeMeasures: randomString('outcomeMeasures'),
      phase: randomItemFrom([
        ['0'],
        ['I'],
        ['II'],
        ['III'],
        ['IV'],
        ['N/A'],
        ['I', 'II'],
        ['II', 'III'],
        ['III', 'IV'],
      ]),
      primaryCompletionDate: randomISODate(),
      purpose: randomString('purpose'),
      recruitmentCountry: randomString('recruitmentCountry'),
      responsiblePartyName: randomString('responsiblePartyName'),
      resultsFirstReceivedDate: randomISODate(),
      sponsorName: randomString('sponsorName'),
      studyDesign: randomString('studyDesign'),
      studyStatus: randomString('studyStatus'),
      studyType: randomString('studyType'),
      sourceName: randomString('sourceName'),
      isOnTopic: false,
      ...overrides,
    }
  }
}

export class TreatmentGuidelineBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_treatmentGuidelines> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_treatmentGuidelines {
    return {
      id: randomString('id'),
      title: randomString('title'),
      year: randomNumber(1900, new Date().getFullYear()),
      committee: randomString('committee'),
      position: randomString('position'),
      sponsor: randomString('sponsor'),
      isOnTopic: false,
      ...overrides,
    }
  }
}

export class GrantBuilder {
  public static randomNIHGrant(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_nihGrants> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_nihGrants {
    return {
      grantId: randomString('GrantID'),
      projectTitle: thingOrNull(randomString('ProjectTitle')),
      grantNumber: thingOrNull(randomString('GrantNumber')),
      institutionName: thingOrNull(randomString('InstitutionName')),
      societyName: thingOrNull(randomString('SocietyName')),
      department: thingOrNull(randomString('Department')),
      fiscalYear: thingOrNull(randomNumber(1960, 2021)),
      irg: thingOrNull(randomString('IRG')),
      ...overrides,
    }
  }

  public static randomOtherGrant(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_otherGrants> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_otherGrants {
    return {
      grantId: randomString('GrantID'),
      projectTitle: thingOrNull(randomString('ProjectTitle')),
      grantNumber: thingOrNull(randomString('GrantNumber')),
      institutionName: thingOrNull(randomString('InstitutionName')),
      societyName: thingOrNull(randomString('SocietyName')),
      projectStart: thingOrNull(randomDateOnly()),
      projectEnd: thingOrNull(randomDateOnly()),
      grantAmount: thingOrNull(randomString('GrantAmount')),
      otherAuthors: thingOrNull(randomString('OtherAuthors')),
      ...overrides,
    }
  }
}

export class PatentBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_patents> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_patents {
    return {
      id: randomString('id'),
      patentTitle: randomString('patentTitle'),
      patentNumber: randomString('patentNumber'),
      patentDate: thingOrNull(randomISODate()),
      ...overrides,
    }
  }
}

export class CertificationBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_certifications> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_certifications {
    return {
      organization: thingOrNull(randomString('organization')),
      department: thingOrNull(randomString('department')),
      certificationNo: thingOrNull(randomString('certificationNo')),
      fromYear: randomFromYear.toString(),
      toYear: randomToYear.toString(),
      ...overrides,
    }
  }
}

export class AwardBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_awards> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_awards {
    return {
      awardId: randomString('id'),
      award: thingOrNull(randomString('award')),
      description: thingOrNull(randomString('description')),
      organization: thingOrNull(randomString('organization')),
      fromYear: randomFromYear.toString(),
      toYear: randomToYear.toString(),
      ...overrides,
    }
  }
}

export class BookBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getBooksForProfile> = {},
  ): GetCVForProfileQuery_getBooksForProfile {
    return {
      id: randomString('id'),
      authors: randomArrayOf(() => randomString('author')),
      bookName: randomString('bookName'),
      chapterTitle: thingOrNull(randomString('chapterTitle')),
      year: thingOrNull(randomString('year')),
      ...overrides,
    }
  }
}

type FullJournal = GetProfileQuery_getProfileByIdFromDDB_ProfileType_journals &
  GetJournalArticlesForProfileQuery_getJournalArticlesForProfile &
  GetJournalArticlesByIdQuery_getJournalArticleById

export class JournalBuilder {
  public static random(overrides: Partial<FullJournal> = {}): FullJournal {
    const affiliationKeys = Array(randomNumber(1, 6))
      .fill(null)
      .map((val, i) => 1 + i)
      .map(String)
    return {
      id: randomString('id'),
      date: randomISODate(),
      pmid: randomString('pmid'),
      journalTitle: randomString('journalTitle'),
      articleTitle: randomString('articleTitle'),
      abstract: randomArrayOf(() => AbstractSectionBuilder.random()),
      affiliations: randomArrayOf((i) => AffiliationBuilder.random(affiliationKeys[i])),
      abstractType: randomString('abstractType'),
      isOnTopic: false,
      ...overrides,
    }
  }
}

type SoSVMention =
  | GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentJournalArticles_mentions
  | GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentPresentations_mentions
  | GetSoSVJournalArticlesQuery_getSoSVJournalArticles_mentions
  | GetSoSVPresentationsQuery_getSoSVPresentations_mentions
  | GetSoSVJournalsQuery_getSoSVJournals_mentions

type SoSVMentionWithoutShare = Omit<SoSVMention, 'share' | 'shareWeighted'>

export class MentionsBuilder {
  public static random(overrides: Partial<SoSVMention> = {}): SoSVMention {
    return {
      count: randomNumber(),
      countWeighted: randomFloat(1, 100),
      share: randomFloat(1, 100),
      shareWeighted: randomFloat(1, 100),
      productName: randomString('productName'),
      ...overrides,
    }
  }
}

export const calculateMentionsShare = <T extends SoSVMentionWithoutShare>(mentions: T[]): SoSVMention[] => {
  const { totalCount, totalCountWeighted } = mentions.reduce(
    (acc, next) => {
      return {
        totalCount: acc.totalCount + next.count,
        totalCountWeighted: acc.totalCountWeighted + next.countWeighted,
      }
    },
    { totalCount: 0, totalCountWeighted: 0 },
  )

  return mentions.map((m) => ({
    ...m,
    share: totalCount > 0 ? roundDecimals((m.count / totalCount) * 100, 1) : 0,
    shareWeighted: totalCountWeighted > 0 ? roundDecimals((m.countWeighted / totalCountWeighted) * 100, 1) : 0,
  }))
}

type SoSVPresentation =
  | GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentPresentations
  | GetSoSVPresentationsQuery_getSoSVPresentations

export class SoSVPresentationBuilder {
  public static random(overrides: Partial<SoSVPresentation> = {}): SoSVPresentation {
    return {
      id: randomString('id'),
      date: randomISODate(),
      conferenceName: randomString('conferenceName'),
      title: randomString('title'),
      type: randomString('type'),
      organization: randomString('organization'),
      mentions: randomArrayOf(() => MentionsBuilder.random()),
      medmemeId: randomNumber(100, 10000) + '',
      ...overrides,
    }
  }
}

type SoSVJournalArticle =
  | GetProfileSoSVRightPaneDataQuery_getProfileSoSVData_recentJournalArticles
  | GetSoSVJournalArticlesQuery_getSoSVJournalArticles

export class SoSVJournalArticleBuilder {
  public static random(overrides: Partial<SoSVJournalArticle> = {}): SoSVJournalArticle {
    return {
      id: randomString('id'),
      date: randomISODate(),
      journalTitle: randomString('journalTitle'),
      articleTitle: randomString('articleTitle'),
      abstractType: randomString('abstractType'),
      mentions: randomArrayOf(() => MentionsBuilder.random()),
      pmid: randomNumber(100, 10000) + '',
      ...overrides,
    }
  }
}

export class AbstractSectionBuilder {
  public static random(): GetJournalArticlesByIdQuery_getJournalArticleById_abstract {
    return {
      heading: thingOrNull('heading'),
      text: randomString('text'),
    }
  }
}

export class AuthorBuilder {
  public static random(affiliationKeys: string[]): GetPresentationByIdQuery_getPresentationById_authors {
    return {
      fullName: randomString('fullName'),
      affiliationKeys: [affiliationKeys[randomNumber(1, affiliationKeys.length - 1)]],
    }
  }
}

export class JournalArticleAuthorBuilder {
  public static random(
    overrides: Partial<getJournalArticlesAuthorsByJournalIdQuery_getJournalArticleAuthorsByJournalId>,
  ): getJournalArticlesAuthorsByJournalIdQuery_getJournalArticleAuthorsByJournalId {
    return {
      fullName: randomString('fullName'),
      affiliationKeys: randomArrayOf((i) => `${i + 1}`),
      ...overrides,
    }
  }
}

export class AffiliationBuilder {
  public static random(affiliationKey?: string): GetJournalArticlesByIdQuery_getJournalArticleById_affiliations {
    return {
      affiliationKey: affiliationKey || null,
      name: randomString('name'),
    }
  }
}

export class ResponsibilityBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_editorialResponsibilities> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_editorialResponsibilities {
    return {
      journal: randomString('journal'),
      position: randomString('position'),
      committee: thingOrNull(randomString('committee')),
      fromYear: thingOrNull(randomString('fromYear')),
      toYear: thingOrNull(randomString('toYear')),
      ...overrides,
    }
  }
}

export class SocietyRoleBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_societyRoles> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_societyRoles {
    return {
      society: randomString('society'),
      position: randomString('position'),
      committee: thingOrNull(randomString('committee')),
      fromYear: thingOrNull(randomString('fromYear')),
      toYear: thingOrNull(randomString('toYear')),
      ...overrides,
    }
  }
}

export class RoleBuilder {
  public static random(
    overrides: Partial<
      | GetProfileQuery_getProfileByIdFromDDB_ProfileType_currentRoles
      | GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_formerRoles
    > = {},
  ):
    | GetProfileQuery_getProfileByIdFromDDB_ProfileType_currentRoles
    | GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_formerRoles {
    return {
      institutionName: randomString('institutionName'),
      department: randomString('department'),
      position: randomString('position'),
      title: thingOrNull(randomString('title')),
      fromYear: thingOrNull(randomString('fromYear')),
      toYear: thingOrNull(randomString('toYear')),
      ...overrides,
    }
  }
}

export class CurrentResearchBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_currentResearch> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_currentResearch {
    return {
      title: randomString('title'),
      ...overrides,
    }
  }
}

export class ClinicalTrialStatsPhaseCountBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_stats_clinicalTrials_phaseCounts> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_stats_clinicalTrials_phaseCounts {
    return {
      phase: randomString('phase'),
      count: randomNumber(),
      percentageOfTotal: randomNumber(),
      ...overrides,
    }
  }
}

export class DisclosuresBuilder {
  private readonly disclosureCategories: Omit<
    GetProfileQuery_getProfileByIdFromDDB_ProfileType_disclosures,
    'totalCount'
  >

  public static random(override = randomNumber()): GetProfileQuery_getProfileByIdFromDDB_ProfileType_disclosures {
    return new DisclosuresBuilder(override).build()
  }

  constructor(override = 0) {
    this.disclosureCategories = {
      advisoryBoard: { count: override },
      consultant: { count: override },
      dataAndSafetyMonitor: { count: override },
      employee: { count: override },
      grantAndResearchSupport: { count: override },
      investigator: { count: override },
      otherAndHonoraria: { count: override },
      principalInvestigator: { count: override },
      speaker: { count: override },
      trialSteering: { count: override },
      uniqueCompanies: {
        count: override,
      },
    }
  }

  getTotalCount(): number {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(this.disclosureCategories).reduce((acc, [_, value]) => {
      return acc + value.count
    }, 0)
  }

  public build(): GetProfileQuery_getProfileByIdFromDDB_ProfileType_disclosures {
    return {
      ...this.disclosureCategories,
      totalCount: this.getTotalCount(),
    }
  }
}

export class DisclosuresListBuilder {
  private readonly disclosures: GetDisclosuresForProfileQuery_getDisclosuresForProfile[]

  constructor(disclosures: GetDisclosuresForProfileQuery_getDisclosuresForProfile[]) {
    this.disclosures = disclosures
  }

  public build(): GetDisclosuresForProfileQuery_getDisclosuresForProfile[] {
    return this.disclosures
  }
}

type FullSubscription = GetSubscriptionByIdQuery_getSubscriptionById &
  GetSubscriptionsForUserQuery_getSubscriptionsForUser &
  GetSubscriptionInfoForProfileQuery_getSubscriptionById

export class SubscriptionBuilder {
  public static random(overrides: Partial<FullSubscription> = {}): FullSubscription {
    return {
      name: randomString('name'),
      children: [],
      clientName: thingOrNull(randomString('clientName')),
      id: randomString('id'),
      parentId: randomString('parentId'),
      profileCount: randomNumber(0, 1000),
      contributionCount: randomNumber(100, 100000),
      rootId: randomString('rootId'),
      fullName: randomString('fullName'),
      subsTree: [{ name: randomString('name'), id: randomString('id') }],
      permissions: [
        SubscriptionPermission.Experts,
        SubscriptionPermission.Science,
        SubscriptionPermission.SoSVExperts,
        SubscriptionPermission.SoSVScience,
        SubscriptionPermission.SoSV,
        SubscriptionPermission.Digital,
      ],
      ...overrides,
    }
  }
}

export class FellowshipBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_fellowships> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_fellowships {
    return {
      id: randomString('id'),
      type: randomString('type'),
      startYear: thingOrNull(randomString('startYear')),
      endYear: thingOrNull(randomString('endYear')),
      department: thingOrNull(randomString('department')),
      organization: thingOrNull(randomString('organization')),
      directors: randomStringArray('director'),
      advisors: randomStringArray('advisor'),
      ...overrides,
    }
  }
}

export class LicenseBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_licenses> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_licenses {
    return {
      licenseNumber: randomString('licenseNumber'),
      organization: randomString('organization'),
      ...overrides,
    }
  }
}

export class EducationAndResidencyBuilder {
  public static random(
    overrides: Partial<GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_educationAndResidencies> = {},
  ): GetCVForProfileQuery_getProfileByIdFromDDB_ProfileType_educationAndResidencies {
    return {
      startYear: randomFromYear.toString(),
      endYear: randomToYear.toString(),
      degree: thingOrNull(randomString('degree')),
      area: thingOrNull(randomString('area')),
      department: thingOrNull(randomString('department')),
      organization: thingOrNull(randomString('organization')),
      ...overrides,
    }
  }
}

export class StatsInSubscriptionBuilder {
  public static random(
    overrides: Partial<GetSubscriptionInfoForProfileQuery_getSubscriptionStatsForProfile> = {},
    defaultValue?: number,
  ): GetSubscriptionInfoForProfileQuery_getSubscriptionStatsForProfile {
    return {
      onTopicOverallPercentage: defaultValue ?? randomNumber(0, 101),
      onTopicCountsTimeline: arrayOf(randomNumber, 10),
      onTopicCountRegressionSlope: randomFloat(-2, 2),
      onTopicCountRegressionYIntercept: randomFloat(),
      onTopicClinicalTrialsPercentage: randomNumber(0, 101),
      onTopicJournalsPercentage: randomNumber(0, 101),
      onTopicPresentationsPercentage: randomNumber(0, 101),
      profileRank: randomNumber(),
      profileRankByYearsAgo: arrayOf(randomNumber, 11),
      profileRankRegressionSlope: randomFloat(-2, 2),
      profileRankRegressionYIntercept: randomFloat(),
      tlScore: Number(randomFloat(0, 5).toFixed(2)),
      rankNormalization: true,
      rank: randomNumber(),
      rankScoreByYearsAgo: arrayOf(randomFloat, 11),
      rankScoreRegressionSlope: randomFloat(-2, 2),
      rankScoreRegressionYIntercept: randomFloat(),
      presentationScore: randomFloat(),
      journalScore: randomFloat(),
      treatmentGuidelineScore: randomFloat(),
      clinicalTrialScore: randomFloat(),
      grantScore: randomFloat(),
      institutionalScore: randomFloat(),
      editorialScore: randomFloat(),
      societyScore: randomFloat(),
      ...overrides,
    }
  }
}

export class StatsPerSubscriptionBuilder {
  public static random(
    overrides: Partial<SearchProfilesQuery_searchProfiles_profiles_SearchProfileType_statsPerSubscription> = {},
    defaultValue?: number,
  ): SearchProfilesQuery_searchProfiles_profiles_SearchProfileType_statsPerSubscription {
    return {
      onTopicOverallPercentage: defaultValue ?? randomNumber(0, 101),
      onTopicCountRegressionSlope: randomFloat(-2, 2),
      profileRank: randomNumber(),
      profileRankByYearsAgo: arrayOf(randomNumber, 11),
      subscriptionId: randomString('id'),
      tlScore: randomFloat(0, 5),
      ...overrides,
    }
  }
}

export class SocialProfileBuilder {
  public static random(
    overrides: Partial<GetProfileQuery_getProfileByIdFromDDB_ProfileType_socialProfile> = {},
  ): GetProfileQuery_getProfileByIdFromDDB_ProfileType_socialProfile {
    const twitterUsername = overrides.twitterUsername ?? thingOrNull(randomString('twitterUsername'))

    return {
      twitterUrl: twitterUsername ? randomString('twitterUrl') : null,
      twitterUsername: twitterUsername,
      twitterFollowersCount: randomNumber(),
      linkedInUrl: thingOrNull(randomString('linkedInUrl')),
      ...overrides,
    }
  }
}

export class NetworkConnectionsBuilder {
  public static random(
    count: number,
    overrides: Partial<GetNetworkConnectionsQuery_getNetworkConnectionsForProfile> = {},
  ): GetNetworkConnectionsQuery_getNetworkConnectionsForProfile {
    return {
      connectionsInListCount: randomNumber(),
      totalConnectionsCount: randomNumber(),
      connections: randomArrayOf(() => NetworkConnectionBuilder.random(), count),
      ...overrides,
    }
  }
}

export class NetworkConnectionsAcrossSubscriptionsBuilder {
  public static random(
    overrides: Partial<SearchProfilesAcrossSubscriptionsQuery_searchProfilesAcrossSubscriptions_profiles_SearchProfileAcrossSubscriptionsType_subscriptionNetworks> = {},
  ): SearchProfilesAcrossSubscriptionsQuery_searchProfilesAcrossSubscriptions_profiles_SearchProfileAcrossSubscriptionsType_subscriptionNetworks {
    return {
      subscriptionId: randomString('subscriptionId'),
      totalConnectionsCount: randomNumber(),
      ...overrides,
    }
  }
}

export class NetworkConnectionBuilder {
  public static random(
    overrides: Partial<GetNetworkConnectionsQuery_getNetworkConnectionsForProfile_connections_NetworkConnectionType> = {},
  ): GetNetworkConnectionsQuery_getNetworkConnectionsForProfile_connections_NetworkConnectionType {
    return {
      __typename: 'NetworkConnectionType',
      contacts: randomArrayOf(() => ContactBuilder.random()),
      degrees: randomStringArray('degree'),
      fullName: randomString('fullName'),
      profileId: randomString('profileId'),
      profileStatus: 'Live',
      socialProfile: thingOrNull(SocialProfileBuilder.random()),
      isInSameCity: randomBool(),
      isInSameInstitution: randomBool(),
      coContribPresentationsCount: randomNumber(),
      coContribJournalsCount: randomNumber(),
      coContribClinicalTrialsCount: randomNumber(),
      renderAsProfiled: randomBool(),
      networkScore: randomFloat(),
      ...overrides,
    }
  }
}

export class NetworkProfileLockedConnectionBuilder {
  public static random(
    overrides: Partial<GetNetworkConnectionsQuery_getNetworkConnectionsForProfile_connections_NetworkProfileLockedConnectionType> = {},
  ): GetNetworkConnectionsQuery_getNetworkConnectionsForProfile_connections_NetworkProfileLockedConnectionType {
    return {
      __typename: 'NetworkProfileLockedConnectionType',
      fullName: randomString('fullName'),
      profileId: randomString('profileId'),
      renderAsProfiled: randomBool(),
      networkScore: randomFloat(),
      optStatus: OptStatus.out,
      ...overrides,
    }
  }
}

export class PillBuilder {
  public static random(
    overrides: Partial<GetSavedSearchesForUserEmailQuery_getSavedSearchesForUserEmail_pills> = {},
  ): GetSavedSearchesForUserEmailQuery_getSavedSearchesForUserEmail_pills {
    return {
      id: randomString('id'),
      category: randomString('category'),
      value: randomString('value'),
      synonyms: null,
      ...overrides,
    }
  }
}

export class SavedSearchBuilder {
  public static random(
    overrides: Partial<GetSavedSearchesForUserEmailQuery_getSavedSearchesForUserEmail> = {},
  ): GetSavedSearchesForUserEmailQuery_getSavedSearchesForUserEmail {
    return {
      id: randomString('id'),
      name: randomString('name'),
      emailFrequency: 'weekly',
      listName: randomString('listName'),
      pills: randomArrayOf(() => PillBuilder.random(), 3),
      contentTypes: randomLengthArray(randomNumber(0, 6), () => randomString('contentType')),
      timeframe: randomItemFromEnum(SearchTimeframe),
      listId: randomString('listId'),
      savedListId: null,
      savedListName: null,
      smartListId: null,
      smartListName: null,
      startDate: null,
      endDate: null,
      ...overrides,
    }
  }
}

export class SavedListForUserEmailBuilder {
  public static random(
    overrides: Partial<GetSavedListsForUserEmailQuery_getSavedListsForUserEmail & { profileIds?: string[] }> = {},
  ): GetSavedListsForUserEmailQuery_getSavedListsForUserEmail & {
    profileIds: string[]
  } {
    const profileIds = randomArrayOf(() => randomString('profileId'), randomNumber(1, 100))
    return {
      id: randomString('id'),
      name: randomString('name'),
      listName: randomString('listName'),
      listId: randomString('listId'),
      subscriptionId: randomString('subscriptionId'),
      profilesCount: profileIds.length,
      profileIds,
      searchType: 'SL',
      emailFrequency: 'none',
      ...overrides,
    }
  }
}

export class SavedListBuilder {
  public static random(overrides: Partial<GetSavedListQuery_getSavedList> = {}): GetSavedListQuery_getSavedList {
    return {
      id: randomString('id'),
      name: randomString('name'),
      profilesCount: randomNumber(1, 50),
      listName: randomString('list name'),
      ...overrides,
    }
  }
}

export class SmartListBuilder {
  public static random(overrides: Partial<GetSmartListQuery_getSmartList> = {}): GetSmartListQuery_getSmartList {
    return {
      id: randomString('id'),
      name: randomString('name'),
      profilesCount: randomNumber(1, 50),
      ...overrides,
    }
  }
}

export class SoSVSummaryBuilder {
  public static random(
    overrides: Partial<GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_sosvSummaries_sosvStats> = {},
  ): GetProfilesInListQuery_getProfilesInList_profiles_SubscriptionProfileType_sosvSummaries_sosvStats {
    return {
      productName: randomString('productName'),
      percentage: randomNumber(0, 100),
      ...overrides,
    }
  }
}
