import { Subscription } from '~common/types/common-link-types'
import { actionTypes } from '.'
import { ProductGroup, SavedList, SmartList } from '../types'
import { ExpertsHubAction as Action } from './types'
import { SmartListType } from '~common'
import React from 'react'

export const initialise = ({
  expertsHubDispatch,
  rootSubscriptionId,
  listId,
  showClientNames,
  subscriptions,
  savedLists,
  smartLists,
  sosvProductGroups,
  sosvProducts,
  savedListId,
  smartListId,
  productGroupId,
  userEmail,
}: {
  expertsHubDispatch: React.Dispatch<Action>
  rootSubscriptionId: string
  listId: string
  showClientNames: boolean
  subscriptions: Subscription[]
  savedLists?: SavedList[]
  smartLists?: Partial<Record<SmartListType, SmartList[]>>
  sosvProductGroups?: ProductGroup[]
  sosvProducts?: string[]
  productGroupId?: string
  savedListId?: string
  smartListId?: string
  userEmail: string
}): void => {
  expertsHubDispatch({
    type: actionTypes.INITIALISE,
    payload: {
      rootSubscriptionId,
      listId,
      showClientNames,
      subscriptions,
      savedLists,
      savedListId,
      smartListId,
      smartLists,
      sosvProductGroups,
      sosvProducts,
      productGroupId,
      userEmail,
    },
  })
}
