import React, { RefObject } from 'react'
import { ButtonWithNoStyle, Hub } from '~common'
import {
  StyledMyAccountSubMenu,
  StyledNavigation,
  StyledNavigationList,
  StyledHamburgerTopBunPath,
  StyledHamburgerFillingPath,
  StyledHamburgerBottomBunPath,
} from './styles'
import { MyAccountList } from '../my-account-list'
import { NavigationItem } from '../navigation-item'
import { HidingErrorBoundary } from '~components/error-boundary/error-boundary'
import { CustomSVG } from '~components/ui/nucleons/custom-svg'

interface GlobalNavigationProps {
  navigationItems: [string, Hub[]][]
  isSubMenuOpened: boolean
  onSubMenuToggle: (isSubMenuOpened: boolean) => void
  subMenuRef: RefObject<HTMLLIElement>
  onLogout: () => void
  isMobileView: boolean
  isTouchable: boolean
  zendeskUrl: string
  refetchZendeskUrl?: () => void
  hasPanSubscriptionSearchMenuItem: boolean
}

export const GlobalNavigation = ({
  navigationItems,
  isSubMenuOpened,
  onSubMenuToggle,
  subMenuRef,
  onLogout,
  isMobileView,
  isTouchable,
  zendeskUrl,
  refetchZendeskUrl,
  hasPanSubscriptionSearchMenuItem,
}: GlobalNavigationProps): JSX.Element => {
  return (
    <StyledNavigation role="navigation" aria-label="Pharmaspectra">
      {navigationItems.map(([id, navigationList]: [string, Hub[]]) => (
        <StyledNavigationList key={id} aria-label={`${id} navigation list`} isSecondaryList={id === 'secondary'}>
          {navigationList.map((navigationItem) => (
            <NavigationItem
              key={navigationItem.id}
              linkTo={navigationItem.link}
              isDisabled={navigationItem.isDisabled}
              isTouchable={isTouchable}
              id={navigationItem.id}
            >
              {navigationItem.content}
            </NavigationItem>
          ))}
        </StyledNavigationList>
      ))}
      <StyledNavigationList aria-label="tertiary navigation list">
        <NavigationItem navigationItemRef={subMenuRef} isActive={isSubMenuOpened} isTouchable={isTouchable}>
          <>
            <ButtonWithNoStyle
              type="button"
              aria-label="My account sub menu"
              aria-haspopup={true}
              aria-expanded={isSubMenuOpened}
              aria-controls="id_my_account_sub_menu"
              onClick={() => onSubMenuToggle(!isSubMenuOpened)}
            >
              <HamburgerSVG isOpened={isSubMenuOpened} />
              Menu
            </ButtonWithNoStyle>
            <StyledMyAccountSubMenu
              id="id_my_account_sub_menu"
              isOpen={isSubMenuOpened}
              onFocus={() => onSubMenuToggle(true)}
              onBlur={() => onSubMenuToggle(false)}
              aria-label="My account menu"
              aria-hidden={isMobileView || !isSubMenuOpened}
              tabIndex={-1}
            >
              <HidingErrorBoundary>
                <MyAccountList
                  isMyAccountListVisible={isSubMenuOpened}
                  onLogout={onLogout}
                  isTouchable={isTouchable}
                  zendeskUrl={zendeskUrl}
                  refetchZendeskUrl={refetchZendeskUrl}
                  hasPanSubscriptionSearchMenuItem={hasPanSubscriptionSearchMenuItem}
                />
              </HidingErrorBoundary>
            </StyledMyAccountSubMenu>
          </>
        </NavigationItem>
      </StyledNavigationList>
    </StyledNavigation>
  )
}

export const HamburgerSVG = ({ isOpened }: { isOpened?: boolean }): JSX.Element => (
  <CustomSVG>
    <title>Menu</title>
    <StyledHamburgerTopBunPath d="M0 1H16V3H0V1Z" isOpened={isOpened} />
    <StyledHamburgerFillingPath d="M0 7H16V9H0V7Z" isOpened={isOpened} />
    <StyledHamburgerBottomBunPath d="M0 13H16V15H0V13Z" isOpened={isOpened} />
  </CustomSVG>
)
