import React from 'react'
import {
  StyledStatisticsBarItemWrapper,
  StyledItems,
  StyledBar,
  StyledPercentageWrapper,
  StyledStatisticsBar,
  StyledGroupHeading,
  StyledCustomLink,
} from './styles'
import { Statistic } from '~common/types'
import { TrendDirection } from '~components/ui/atoms/trend-direction'
import { getTrendDirectionFromSlope, StyledStatsDD, StyledStatsDT, StyledStatsDL } from '~common'

const Item = ({
  name,
  value,
  trendSlope,
  isBarChartEnabled,
  linkTo,
  isHighlighted,
  isMaxWidth,
  isSearchResultsPage,
  isWrapped,
}: Statistic) => {
  const ItemValue = ({ trendSlope, linkTo, value }: Pick<Statistic, 'trendSlope' | 'linkTo' | 'value'>) => {
    if (trendSlope !== undefined)
      return (
        <StyledPercentageWrapper>
          {value}
          <TrendDirection trendDirection={getTrendDirectionFromSlope(trendSlope)} />
        </StyledPercentageWrapper>
      )
    if (linkTo)
      return (
        <StyledCustomLink href={linkTo} onClick={(e) => e.stopPropagation()}>
          {value}
        </StyledCustomLink>
      )
    return <>{value}</>
  }
  return (
    <StyledStatisticsBarItemWrapper $isWrapped={isWrapped} $isBarChartEnabled={isBarChartEnabled}>
      <StyledStatsDL isHighlighted={isHighlighted}>
        <StyledStatsDT isHighlighted={isHighlighted}>{name}</StyledStatsDT>
        <StyledStatsDD isHighlighted={isHighlighted}>
          {isBarChartEnabled && (
            <StyledBar
              aria-label={`Bar chart: ${value}`}
              progress={value}
              isHighlighted={isHighlighted}
              isMaxWidth={isMaxWidth}
              isSearchResultsList={isSearchResultsPage}
            />
          )}
          <ItemValue trendSlope={trendSlope} value={value} linkTo={linkTo} />
        </StyledStatsDD>
      </StyledStatsDL>
    </StyledStatisticsBarItemWrapper>
  )
}

export interface ItemsProps extends React.HTMLAttributes<HTMLElement> {
  statistics: Statistic[]
  isHighlighted?: boolean
  wrap?: boolean
  isSearchResultsPage?: boolean
}

export const Items = ({
  statistics,
  style,
  className,
  isHighlighted,
  wrap = true,
  isSearchResultsPage,
}: ItemsProps): JSX.Element => {
  if (wrap && statistics.length > 3) {
    const statisticsChunks: Statistic[][] = []
    for (let i = 0; i < statistics.length; i += 3) {
      statisticsChunks.push(statistics.slice(i, i + 3))
    }

    return (
      <>
        {statisticsChunks.map((chunk, index) => (
          <StyledItems
            key={`stats-chunk-${chunk[0].name}-${index}`}
            style={style}
            className={className}
            $isHighlighted={isHighlighted}
            $wrap={wrap}
            data-testid="stats-bar-items"
          >
            {chunk.map((statistic: Statistic) => (
              <Item
                key={statistic.name}
                {...statistic}
                isSearchResultsPage={isSearchResultsPage}
                isWrapped={wrap && statistics.length > 3}
              />
            ))}
          </StyledItems>
        ))}
      </>
    )
  }

  return (
    <StyledItems
      style={style}
      className={className}
      $isHighlighted={isHighlighted}
      $wrap={wrap}
      data-testid="stats-bar-items"
    >
      {statistics.map((statistic: Statistic) => (
        <Item key={statistic.name} {...statistic} isSearchResultsPage={isSearchResultsPage} />
      ))}
    </StyledItems>
  )
}

export interface GroupProps extends React.HTMLAttributes<HTMLDivElement> {
  groupHeading?: string
  asTag?: string
}

const Group: React.FC<GroupProps> = ({ groupHeading, asTag = 'div', children, className }) => (
  <StyledStatisticsBar as={asTag as never} className={className}>
    {groupHeading && <StyledGroupHeading>{groupHeading}</StyledGroupHeading>}
    {children}
  </StyledStatisticsBar>
)

export const StatisticsBar = {
  Group,
  Items,
}
