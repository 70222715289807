import {
  GetSubscriptionsForUserQuery_getSubscriptionsForUser,
  GetSubscriptionsForUserQuery_getSubscriptionsForUser_children,
  GetSubscriptionsForUserQuery_getSubscriptionsForUser_children_children,
} from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'

export type List = GetSubscriptionsForUserQuery_getSubscriptionsForUser_children

export type ListForUser =
  | GetSubscriptionsForUserQuery_getSubscriptionsForUser
  | GetSubscriptionsForUserQuery_getSubscriptionsForUser_children
  | GetSubscriptionsForUserQuery_getSubscriptionsForUser_children_children

export enum SoSVFilterOption {
  DataSet = 'DataSet',
  MyExpertsAndSubscriptionLists = 'MyExpertsAndSubscriptionLists',
  GraphType = 'GraphType',
  CumulatingType = 'CumulatingType',
  WeighteningType = 'WeighteningType',
  DrugsList = 'DrugsList',
  Topics = 'Topics',
  ContentTypes = 'ContentTypes',
  Timeframe = 'Timeframe',
  DateRange = 'DateRange',
}
