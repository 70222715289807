import { TooltipFormatterContextObject } from 'highcharts'
import React, { useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { Timeframe, TimeframeRange } from '../../components/timeframe'
import { StyledProductsMentionsByTimeframeLineChart } from './styles'
import { useProductsMentionsByTimeframeLineChartOptions } from './use-products-mentions-by-timeframe-line-chart-options'
import { getChartCategories } from '../../helpers'
import {
  getProductColors,
  StyledScreenReadersOnly,
  getIntervalForTimeframe,
  SoSVTimeframe,
  DefaultSoSVTimeFrame,
  Mode,
} from '~common'
import eachYearOfInterval from 'date-fns/eachYearOfInterval'
interface MentionsCount {
  productName: string
  counts: number[]
  percentages: number[]
}
export interface ProductsMentionsByTimeframeLineChartProps {
  sosvStats: {
    timeframe: string
    breakDownBy: string
    mentionCounts: MentionsCount[]
  }[]
  defaultSoSVTimeFrame: SoSVTimeframe
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const ProductsMentionsByTimeframeLineChart = ({
  sosvStats,
  defaultSoSVTimeFrame,
  themeMode,
  themeBrand,
}: ProductsMentionsByTimeframeLineChartProps): JSX.Element => {
  const [selectedTimeRange, selectTimeRange] = useState<TimeframeRange>({
    key: 'Y',
    value: defaultSoSVTimeFrame === DefaultSoSVTimeFrame ? 3 : 10,
    timeframe: defaultSoSVTimeFrame === DefaultSoSVTimeFrame ? 'threeYears' : 'tenYears',
  })

  const dateInterval = getIntervalForTimeframe(selectedTimeRange.timeframe)
  const yearsRange = eachYearOfInterval({ start: dateInterval.startDate, end: dateInterval.endDate }).map((date) =>
    date.getFullYear(),
  )
  const categories = getChartCategories(dateInterval, yearsRange)
  const productMentionsSeries: Highcharts.SeriesOptionsType[] =
    sosvStats
      .find((item) => item.timeframe === selectedTimeRange.timeframe)
      ?.mentionCounts.map((el, i, arr) => ({
        name: el.productName,
        data: [...el.counts],
        className: `product-${i}`,
        type: 'area',
        lineColor: getProductColors(arr.length, themeBrand).reverse()[i],
      })) || []

  const timeRanges = [
    { key: 'M', value: 3, timeframe: 'twelveWeeks' },
    { key: 'Y', value: 1, timeframe: 'oneYear' },
    { key: 'Y', value: 3, timeframe: 'threeYears' },
    { key: 'Y', value: 10, timeframe: 'tenYears' },
  ].map((item) => ({
    ...item,
    isDisabled: !sosvStats.find((i) => i.timeframe === item.timeframe)?.mentionCounts.length,
  }))

  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">Product</span> <span className="value">{context.series.name}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Timeframe</span> <span className="value">{context.key}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Count</span> <span className="value">{context.y}</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const [options] = useProductsMentionsByTimeframeLineChartOptions({
    data: productMentionsSeries,
    categories,
    themeBrand,
    toolTipFormatter,
  })

  return (
    <StyledProductsMentionsByTimeframeLineChart
      productColors={getProductColors(productMentionsSeries.length, themeBrand)}
      series={productMentionsSeries}
    >
      <Timeframe timeRanges={timeRanges} selectTimeRange={selectTimeRange} selectedTimeRange={selectedTimeRange} />
      <HighchartsReact highcharts={Highcharts} options={options} />
      <StyledScreenReadersOnly>Products mentions by timeframe line chart</StyledScreenReadersOnly>
    </StyledProductsMentionsByTimeframeLineChart>
  )
}
