import { gql } from '@apollo/client'
import {
  GetSoSVSocietyInfoQuery,
  GetSoSVSocietyInfoQueryVariables,
} from '~apollo/queries/sosv/societies/__generated__/GetSoSVSocietyInfoQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { GetSoSVSocietiesQuery, GetSoSVSocietiesQueryVariables } from './__generated__/GetSoSVSocietiesQuery'
import { calculateMentionsShare } from '~apollo'

export const GET_SOSV_SOCIETIES = gql`
  query GetSoSVSocietiesQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $weighted: Boolean
    $sortBy: String
    $pageSize: Int!
    $currentPage: Int!
    $source: String
    $savedListId: String
    $smartListId: String
  ) {
    getSoSVSocieties(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      weighted: $weighted
      sortBy: $sortBy
      pageSize: $pageSize
      currentPage: $currentPage
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      id
      name
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
      mostRecentMeeting {
        id
        name
        date
      }
    }
    getSoSVSocietiesCount(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    )
    getSoSVSortByDrugs(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: ["presentation"]
      weighted: $weighted
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      topicSelection: $topicSelection
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    )
  }
`

export const GET_SOSV_SOCIETY_INFO = gql`
  query GetSoSVSocietyInfoQuery($listId: String!, $productGroupId: String, $societyId: String!) {
    getSoSVSocietyInfo(listId: $listId, productGroupId: $productGroupId, societyId: $societyId) {
      id
      name
    }
  }
`

export const SoSVSocietiesMock: GetSoSVSocietiesQuery = {
  getSoSVSocieties: [
    {
      id: '2b02dbc5-0fb1-441e-8a9a-284736b734d6',
      name: 'Society 1',
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 12, countWeighted: 12 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
      mostRecentMeeting: {
        id: 'f7fb9f5a-6f59-4723-88b7-b563cca3747b',
        name: 'Meeting 1',
        date: '2019-04-24T00:00:00.000Z',
      },
    },
    {
      id: '1db30ee2-dd34-4b8d-ad72-6c4120ee9f5e',
      name: 'Society 2',
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 9, countWeighted: 9 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
      mostRecentMeeting: {
        id: 'a66ce3f0-20ce-45a1-b20e-04914e8ff684',
        name: 'Meeting 2',
        date: '2016-04-01T00:00:00.000Z',
      },
    },
    {
      id: 'c5c8ca72-1718-49b4-9ab2-4e26f76224ad',
      name: 'Society 3',
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 6, countWeighted: 6 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
      mostRecentMeeting: {
        id: '8a0789d7-8ebe-40d5-94f3-0ee48cecae2b',
        name: 'Meeting 3',
        date: '2019-06-07T00:00:00.000Z',
      },
    },
    {
      id: '8734ff0b-ed51-4fe4-9c66-6df2f79cc30a',
      name: 'Society 4',
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 4, countWeighted: 4 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
      mostRecentMeeting: {
        id: '9bb54103-0749-4c9f-9ee6-009cf360f4bf',
        name: 'Meeting 4',
        date: '2014-09-15T00:00:00.000Z',
      },
    },
    {
      id: '13852e2a-22d7-4a7c-866a-2ec54c2cede1',
      name: 'Society 5',
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 4, countWeighted: 4 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
      mostRecentMeeting: {
        id: '4568662f-f350-45d0-a3ee-bf7edebab5fe',
        name: 'Meeting 5',
        date: '2015-03-05T00:00:00.000Z',
      },
    },
  ],
  getSoSVSocietiesCount: 12,
  getSoSVSortByDrugs: ['Lantus', 'Glooko', 'Soliqua', 'Toujeo', 'Tresiba', 'Victoza', 'Xultophy'],
}

export const getSoSVSocietyInfoMock = (id: string): GetSoSVSocietyInfoQuery => ({
  getSoSVSocietyInfo: SoSVSocietiesMock.getSoSVSocieties.filter((el) => el.id === id)[0],
})

export const getSoSVSocietiesQueryHandler: GraphqlHandler<GetSoSVSocietiesQueryVariables, GetSoSVSocietiesQuery> = (
  req,
  res,
  ctx,
) => {
  const result: GetSoSVSocietiesQuery = JSON.parse(JSON.stringify(SoSVSocietiesMock))
  const sortBy = req.variables.sortBy || 'all'
  const weighted = req.variables.weighted ?? false
  const drugSelection = req.variables.drugSelection || []
  result.getSoSVSocieties.forEach((e) =>
    e.mentions.forEach((m) => {
      if (!drugSelection.includes(m.productName)) {
        m.count = 0
      }
    }),
  )
  result.getSoSVSocieties.sort((a, b) => {
    const countA = a.mentions.reduce(
      (p, c) => p + (sortBy === 'all' || sortBy === c.productName ? (weighted ? c.countWeighted : c.count) : 0),
      0,
    )
    const countB = b.mentions.reduce(
      (p, c) => p + (sortBy === 'all' || sortBy === c.productName ? (weighted ? c.countWeighted : c.count) : 0),
      0,
    )
    return countB - countA
  })

  if (req.variables.savedListId) {
    return res(ctx.data({ ...result, getSoSVSocietiesCount: 3, getSoSVSocieties: result.getSoSVSocieties.slice(0, 3) }))
  }

  return res(ctx.data(result))
}

export const getSoSVSocietyInfoQueryHandler: GraphqlHandler<
  GetSoSVSocietyInfoQueryVariables,
  GetSoSVSocietyInfoQuery
> = (req, res, ctx) => res(ctx.data(getSoSVSocietyInfoMock(req.variables.societyId)))
