import { MapAction as Action, MapState } from './types'
import * as actionTypes from './action-types'
import { initialiseMap, setMapBounds } from './actions'

export const reducer = (state: MapState, action: Action): MapState => {
  switch (action.type) {
    case actionTypes.INITIALISE_MAP: {
      return initialiseMap(state, action.payload)
    }
    case actionTypes.SET_MAP_BOUNDS: {
      return setMapBounds(state, action.payload)
    }
    default:
      throw new Error('Action type not recognised')
  }
}
