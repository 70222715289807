import { gql } from '@apollo/client'
import { OptStatus } from '~apollo/generated-global-types'
import {
  GetSoSVExpertsQuery,
  GetSoSVExpertsQueryVariables,
} from '~apollo/queries/sosv/experts/__generated__/GetSoSVExpertsQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import {
  GetSoSVExpertsLocationsQuery,
  GetSoSVExpertsLocationsQueryVariables,
} from '~apollo/queries/sosv/experts/__generated__/GetSoSVExpertsLocationsQuery'
import { calculateMentionsShare } from '~apollo'

export const GET_SOSV_EXPERTS = gql`
  query GetSoSVExpertsQuery(
    $listId: String!
    $productGroupId: String
    $contentTypes: [String!]!
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $journalId: String
    $meetingId: String
    $weighted: Boolean
    $sortBy: String
    $geographyFilter: GeographyFilter
    $savedListId: String
    $smartListId: String
    $pageSize: Int!
    $currentPage: Int!
  ) {
    getSoSVHubExperts(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: $contentTypes
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      journalId: $journalId
      meetingId: $meetingId
      weighted: $weighted
      geographyFilter: $geographyFilter
      savedListId: $savedListId
      smartListId: $smartListId
      sortBy: $sortBy
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      profileId
      profileRank
      profileStatus
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
      firstName
      lastName
      fullName
      speciality
      country
      city
      location {
        lat
        lon
      }
      institution
      gdprExcluded
      optStatus
    }
    getSoSVHubExpertsCount(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: $contentTypes
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      journalId: $journalId
      meetingId: $meetingId
      geographyFilter: $geographyFilter
      savedListId: $savedListId
      smartListId: $smartListId
    )
    getSoSVSortByDrugs(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: $contentTypes
      weighted: $weighted
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      topicSelection: $topicSelection
      journalId: $journalId
      meetingId: $meetingId
      geographyFilter: $geographyFilter
      savedListId: $savedListId
      smartListId: $smartListId
    )
  }
`

export const GET_SOSV_EXPERTS_LOCATIONS = gql`
  query GetSoSVExpertsLocationsQuery(
    $listId: String!
    $productGroupId: String
    $contentTypes: [String!]!
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $journalId: String
    $meetingId: String
    $weighted: Boolean
    $geographyFilter: GeographyFilter
    $savedListId: String
    $smartListId: String
  ) {
    getSoSVHubExpertsLocations(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: $contentTypes
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      journalId: $journalId
      meetingId: $meetingId
      weighted: $weighted
      geographyFilter: $geographyFilter
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      type
      features {
        type
        geometry {
          type
          coordinates
        }
        properties {
          profileId
          profileRank
          profileStatus
          mentions {
            productName
            count
            countWeighted
            share
            shareWeighted
          }
          firstName
          fullName
          lastName
          speciality
          institution
          city
          country
          state
        }
      }
    }
  }
`

export const soSVExpertsMock: GetSoSVExpertsQuery = {
  getSoSVHubExpertsCount: 5,
  getSoSVSortByDrugs: ['Lantus', 'Glooko', 'Soliqua', 'Toujeo', 'Tresiba', 'Victoza', 'Xultophy'],
  getSoSVHubExperts: [
    {
      country: 'Ukraine',
      city: 'Kyiv',
      institution: 'Kyiv University',
      fullName: 'Expert 1',
      firstName: 'Expert',
      lastName: '1',
      gdprExcluded: false,
      optStatus: OptStatus.in,
      profileRank: 1,
      profileStatus: 'Live',
      location: {
        lat: 50.450001,
        lon: 30.523333,
      },
      mentions: calculateMentionsShare([
        {
          count: 181,
          countWeighted: 181,
          productName: 'Lantus',
        },
        {
          count: 4,
          countWeighted: 4,
          productName: 'Glooko',
        },
        {
          count: 49,
          countWeighted: 49,
          productName: 'Soliqua',
        },
        {
          count: 181,
          countWeighted: 181,
          productName: 'Toujeo',
        },
        {
          count: 41,
          countWeighted: 41,
          productName: 'Tresiba',
        },
        {
          count: 41,
          countWeighted: 41,
          productName: 'Victoza',
        },
        {
          count: 2,
          countWeighted: 2,
          productName: 'Xultophy',
        },
      ]),
      profileId: 'bd7cf571-a79e-4103-8936-7b95ed0aab28',
      speciality: 'Internal Medicine',
    },
    {
      country: 'UK',
      city: 'London',
      institution: 'London University',
      fullName: 'Test Expert',
      firstName: 'Expert',
      lastName: 'Test',
      gdprExcluded: false,
      optStatus: OptStatus.in,
      profileRank: 2,
      profileStatus: 'Live',
      location: {
        lat: 51.509865,
        lon: -0.118092,
      },
      mentions: calculateMentionsShare([
        {
          count: 113,
          countWeighted: 113,
          productName: 'Lantus',
        },
        {
          count: 14,
          countWeighted: 14,
          productName: 'Glooko',
        },
        {
          count: 7,
          countWeighted: 7,
          productName: 'Soliqua',
        },
        {
          count: 113,
          countWeighted: 113,
          productName: 'Toujeo',
        },
        {
          count: 18,
          countWeighted: 18,
          productName: 'Tresiba',
        },
        {
          count: 27,
          countWeighted: 27,
          productName: 'Victoza',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Xultophy',
        },
      ]),
      profileId: '9142f6bd-fffb-44e7-a14e-64b7c715670a',
      speciality: 'Internal Medicine',
    },
    {
      country: 'USA',
      city: 'New York',
      institution: 'New York University',
      fullName: 'Another Test Expert',
      firstName: 'Test Expert',
      lastName: 'Another',
      gdprExcluded: false,
      optStatus: OptStatus.in,
      profileRank: 3,
      profileStatus: 'Live',
      location: {
        lat: 40.73065,
        lon: -73.935242,
      },
      mentions: calculateMentionsShare([
        {
          count: 103,
          countWeighted: 103,
          productName: 'Lantus',
        },
        {
          count: 2,
          countWeighted: 2,
          productName: 'Glooko',
        },
        {
          count: 50,
          countWeighted: 50,
          productName: 'Soliqua',
        },
        {
          count: 103,
          countWeighted: 103,
          productName: 'Toujeo',
        },
        {
          count: 47,
          countWeighted: 47,
          productName: 'Tresiba',
        },
        {
          count: 65,
          countWeighted: 65,
          productName: 'Victoza',
        },
        {
          count: 31,
          countWeighted: 31,
          productName: 'Xultophy',
        },
      ]),
      profileId: '1116a27e-f7e7-46a3-bc58-0ee9ac55ebb0',
      speciality: 'Diabetes and Metabolism',
    },
    {
      country: 'USA',
      city: 'New York',
      institution: 'New York University',
      fullName: 'Expert 4',
      firstName: 'Expert',
      lastName: '4',
      gdprExcluded: false,
      optStatus: OptStatus.in,
      profileRank: 4,
      profileStatus: 'Live',
      location: {
        lat: 40.73061,
        lon: -73.935242,
      },
      mentions: calculateMentionsShare([
        {
          count: 92,
          countWeighted: 92,
          productName: 'Lantus',
        },
        {
          count: 8,
          countWeighted: 8,
          productName: 'Glooko',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Soliqua',
        },
        {
          count: 92,
          countWeighted: 92,
          productName: 'Toujeo',
        },
        {
          count: 118,
          countWeighted: 118,
          productName: 'Tresiba',
        },
        {
          count: 28,
          countWeighted: 28,
          productName: 'Victoza',
        },
        {
          count: 24,
          countWeighted: 24,
          productName: 'Xultophy',
        },
      ]),
      profileId: '9ad3e5bd-5181-4d3b-9157-57187f96cc08',
      speciality: 'Internal Medicine',
    },
    {
      country: 'USA',
      city: 'New York',
      institution: 'New York University',
      fullName: 'Expert 5',
      firstName: 'Expert',
      lastName: '5',
      gdprExcluded: false,
      optStatus: OptStatus.in,
      profileRank: 5,
      profileStatus: 'Live',
      location: {
        lat: 40.73061,
        lon: -73.935242,
      },
      mentions: calculateMentionsShare([
        {
          count: 92,
          countWeighted: 92,
          productName: 'Lantus',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Glooko',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Soliqua',
        },
        {
          count: 92,
          countWeighted: 92,
          productName: 'Toujeo',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Tresiba',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Victoza',
        },
        {
          count: 0,
          countWeighted: 0,
          productName: 'Xultophy',
        },
      ]),
      profileId: 'c06eebd4-f15f-49fe-a6ee-3fcd2fc1b950',
      speciality: 'Internal Medicine',
    },
  ],
}

export const sosvExpertsLocationsMock: GetSoSVExpertsLocationsQuery = {
  getSoSVHubExpertsLocations: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [30.523333, 50.450001],
        },
        properties: {
          country: 'Ukraine',
          city: 'Kyiv',
          state: '',
          institution: 'Kyiv University',
          fullName: 'Expert 1',
          firstName: 'Expert',
          lastName: '1',
          profileRank: 1,
          profileStatus: 'Live',
          mentions: calculateMentionsShare([
            {
              count: 181,
              countWeighted: 181,
              productName: 'Lantus',
            },
            {
              count: 4,
              countWeighted: 4,
              productName: 'Glooko',
            },
            {
              count: 49,
              countWeighted: 49,
              productName: 'Soliqua',
            },
            {
              count: 181,
              countWeighted: 181,
              productName: 'Toujeo',
            },
            {
              count: 41,
              countWeighted: 41,
              productName: 'Tresiba',
            },
            {
              count: 41,
              countWeighted: 41,
              productName: 'Victoza',
            },
            {
              count: 2,
              countWeighted: 2,
              productName: 'Xultophy',
            },
          ]),
          profileId: 'bd7cf571-a79e-4103-8936-7b95ed0aab28',
          speciality: 'Internal Medicine',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-0.136439, 51.509865],
        },
        properties: {
          profileRank: 2,
          state: '',
          country: 'UK',
          city: 'London',
          institution: 'London University',
          fullName: 'Test Expert',
          firstName: 'Expert',
          lastName: 'Test',
          profileStatus: 'Live',
          mentions: calculateMentionsShare([
            {
              count: 113,
              countWeighted: 113,
              productName: 'Lantus',
            },
            {
              count: 14,
              countWeighted: 14,
              productName: 'Glooko',
            },
            {
              count: 7,
              countWeighted: 7,
              productName: 'Soliqua',
            },
            {
              count: 113,
              countWeighted: 113,
              productName: 'Toujeo',
            },
            {
              count: 18,
              countWeighted: 18,
              productName: 'Tresiba',
            },
            {
              count: 27,
              countWeighted: 27,
              productName: 'Victoza',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Xultophy',
            },
          ]),
          profileId: '9142f6bd-fffb-44e7-a14e-64b7c715670a',
          speciality: 'Internal Medicine',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-73.935242, 40.73065],
        },
        properties: {
          country: 'USA',
          city: 'New York',
          state: '',
          institution: 'New York University',
          fullName: 'Another Test Expert',
          firstName: 'Test Expert',
          lastName: 'Another',
          profileRank: 3,
          profileStatus: 'Live',
          mentions: calculateMentionsShare([
            {
              count: 103,
              countWeighted: 103,
              productName: 'Lantus',
            },
            {
              count: 2,
              countWeighted: 2,
              productName: 'Glooko',
            },
            {
              count: 50,
              countWeighted: 50,
              productName: 'Soliqua',
            },
            {
              count: 103,
              countWeighted: 103,
              productName: 'Toujeo',
            },
            {
              count: 47,
              countWeighted: 47,
              productName: 'Tresiba',
            },
            {
              count: 65,
              countWeighted: 65,
              productName: 'Victoza',
            },
            {
              count: 31,
              countWeighted: 31,
              productName: 'Xultophy',
            },
          ]),
          profileId: '1116a27e-f7e7-46a3-bc58-0ee9ac55ebb0',
          speciality: 'Diabetes and Metabolism',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-73.935242, 40.73061],
        },
        properties: {
          country: 'USA',
          city: 'New York',
          state: '',
          institution: 'New York University',
          fullName: 'Expert 4',
          firstName: 'Expert',
          lastName: '4',
          profileRank: 4,
          profileStatus: 'Live',
          mentions: calculateMentionsShare([
            {
              count: 92,
              countWeighted: 92,
              productName: 'Lantus',
            },
            {
              count: 8,
              countWeighted: 8,
              productName: 'Glooko',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Soliqua',
            },
            {
              count: 92,
              countWeighted: 92,
              productName: 'Toujeo',
            },
            {
              count: 118,
              countWeighted: 118,
              productName: 'Tresiba',
            },
            {
              count: 28,
              countWeighted: 28,
              productName: 'Victoza',
            },
            {
              count: 24,
              countWeighted: 24,
              productName: 'Xultophy',
            },
          ]),
          profileId: '9ad3e5bd-5181-4d3b-9157-57187f96cc08',
          speciality: 'Internal Medicine',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-73.935242, 40.73061],
        },
        properties: {
          country: 'USA',
          city: 'New York',
          state: '',
          institution: 'New York University',
          fullName: 'Expert 5',
          firstName: 'Expert',
          lastName: '5',
          profileRank: 5,
          profileStatus: 'Live',
          mentions: calculateMentionsShare([
            {
              count: 92,
              countWeighted: 92,
              productName: 'Lantus',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Glooko',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Soliqua',
            },
            {
              count: 92,
              countWeighted: 92,
              productName: 'Toujeo',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Tresiba',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Victoza',
            },
            {
              count: 0,
              countWeighted: 0,
              productName: 'Xultophy',
            },
          ]),
          profileId: 'c06eebd4-f15f-49fe-a6ee-3fcd2fc1b950',
          speciality: 'Internal Medicine',
        },
      },
    ],
  },
}

export const getSoSVExpertsQueryHandler: GraphqlHandler<GetSoSVExpertsQueryVariables, GetSoSVExpertsQuery> = (
  req,
  res,
  ctx,
) => {
  const result: GetSoSVExpertsQuery = JSON.parse(JSON.stringify(soSVExpertsMock))
  const sortBy = req.variables.sortBy || 'all'
  const weighted = req.variables.weighted ?? false
  const drugSelection = req.variables.drugSelection || []

  result.getSoSVHubExperts.forEach((e) =>
    e.mentions.forEach((m) => {
      if (!drugSelection.includes(m.productName)) {
        m.count = 0
      }
    }),
  )

  result.getSoSVHubExperts.sort((a, b) => {
    if (sortBy === 'profileRank') {
      return a.profileRank - b.profileRank
    }

    const countA = a.mentions.reduce(
      (p, c) => p + (sortBy === 'all' || sortBy === c.productName ? (weighted ? c.countWeighted : c.count) : 0),
      0,
    )
    const countB = b.mentions.reduce(
      (p, c) => p + (sortBy === 'all' || sortBy === c.productName ? (weighted ? c.countWeighted : c.count) : 0),
      0,
    )
    return countB - countA
  })

  if (req.variables.listId === 'grandchild-sub' || req.variables.listId === 'child-sub-1') {
    return res(
      ctx.data({
        getSoSVHubExperts: [],
        getSoSVHubExpertsCount: 0,
      }),
    )
  }

  if (req.variables.savedListId) {
    return res(
      ctx.data({ ...result, getSoSVHubExpertsCount: 2, getSoSVHubExperts: result.getSoSVHubExperts.slice(0, 2) }),
    )
  }

  return res(ctx.data(result))
}

export const getSoSVExpertsLocationsQueryHandler: GraphqlHandler<
  GetSoSVExpertsLocationsQueryVariables,
  GetSoSVExpertsLocationsQuery
> = (req, res, ctx) => {
  const result: GetSoSVExpertsLocationsQuery = JSON.parse(JSON.stringify(sosvExpertsLocationsMock))
  const drugSelection = req.variables.drugSelection || []

  result.getSoSVHubExpertsLocations?.features.forEach((e) => {
    if (e.properties) {
      e.properties.mentions.forEach((m) => {
        if (!drugSelection.includes(m.productName)) {
          m.count = 0
        }
      })
    }
  })

  if (req.variables.listId === 'grandchild-sub' || req.variables.listId === 'child-sub-1') {
    return res(
      ctx.data({
        getSoSVHubExpertsLocations: { type: 'FeatureCollection', features: [] },
      }),
    )
  }

  if (req.variables.savedListId) {
    return res(
      ctx.data({
        getSoSVHubExpertsLocations: {
          ...result.getSoSVHubExpertsLocations,
          features: result.getSoSVHubExpertsLocations?.features.slice(0, 2) ?? [],
        },
      }),
    )
  }

  return res(ctx.data(result))
}
