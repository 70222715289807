import styled, { css } from 'styled-components'
import Masonry from 'react-masonry-component'

const mobilePadding = 1
const tabletSmallPadding = 1.5

export const StyledGutterSizer = styled.div`
  width: 1.5rem;
`

export const StyledContainer = styled(Masonry)`
  padding: ${mobilePadding}rem;
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: -1rem auto 0;
  & > * {
    margin-top: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    padding: ${tabletSmallPadding}rem;
    max-width: none;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    margin-top: -1.5rem;
    & > * {
      margin-top: 1.5rem;
    }
  }
`

export const StyledSizer = styled.div`
  width: calc(100% - (${mobilePadding}rem * 2));
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    width: calc(50% - 0.8125rem);
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    width: calc(100% / 3 - 1rem);
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    width: calc(100% / 4 - 1.125rem);
  }
`

export const StyledItem = styled.section<{
  itemWidth: 'narrow' | 'wide' | 'wider' | 'widest'
  isTransparent: boolean
  isNoPadding: boolean
}>`
  padding: ${({ isNoPadding }) => !isNoPadding && '0.5rem'};
  background-color: ${({ isTransparent, theme }) =>
    isTransparent ? 'transparent' : theme.colors.brand.backgroundSecondary};
  width: calc(100% - (${mobilePadding}rem * 2));
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${({ itemWidth, isNoPadding }) => {
      if (['narrow', 'wide'].includes(itemWidth)) {
        return css`
          width: calc(50% - 2.25rem);
          padding: ${isNoPadding && '0'};
        `
      }

      if (['wider', 'widest'].includes(itemWidth)) {
        return css`
          width: calc(100% - (${tabletSmallPadding}rem * 2));
        `
      }

      return
    }}
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    ${({ itemWidth }) => {
      if (itemWidth === 'narrow') {
        return css`
          width: calc(100% / 3 - 1.9375rem);
        `
      }

      if (['wide', 'wider'].includes(itemWidth)) {
        return css`
          width: calc(100% - 33.3% - 2.4375rem);
        `
      }

      return
    }}
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    padding: ${({ isNoPadding }) => !isNoPadding && '1rem'};
    ${({ itemWidth }) => {
      if (itemWidth === 'narrow') {
        return css`
          width: calc(100% / 4 - 1.875rem);
        `
      }

      if (itemWidth === 'wide') {
        return css`
          width: calc(50% - 2.25rem);
        `
      }

      return
    }}
  }
`
