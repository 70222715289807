import styled, { css } from 'styled-components'
import { Flex } from '~common'
import { CustomLink } from '~components/ui/atoms/custom-link'

export const StyledItems = styled(Flex)<{ $wrap?: boolean; $isHighlighted?: boolean }>`
  font-weight: normal;
  ${({ $wrap }) =>
    $wrap &&
    css`
      flex-wrap: wrap;

      &:not(:first-child) {
        margin-top: 0.5rem;
      }
    `};
  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.detail.searchHighlight};
    `};
`

interface StyledBarProps {
  progress: string
  isHighlighted?: boolean
  isMaxWidth?: boolean
  isSearchResultsList?: boolean
}

export const StyledBar = styled.div<StyledBarProps>`
  height: 1.5rem;
  margin-right: 0.25rem;
  background-color: ${({ theme }) => theme.colors.neutrals.neutral20};
  display: none;

  &:after {
    content: '';
    width: ${({ progress }) => progress};
    height: 100%;
    background-color: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.dataVisualisation.barGraphSecondary : theme.colors.warnings.success};
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.phoneMedium)}) {
    display: block;
    width: calc((153 / 400 * 100vw) - 4.6875rem);
    ${({ isMaxWidth }) =>
      isMaxWidth &&
      css`
        max-width: 3.125rem;
      `}
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(512)}) {
    display: block;
    width: 11.875rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    display: block;
    width: calc((133 / 768 * 100vw) - 4.6875rem);
    max-width: none;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    display: ${({ isSearchResultsList }) => (isSearchResultsList ? 'none' : 'block')};
    width: calc((157 / 1280 * 100vw) - 4.6875rem);
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    width: calc((123 / 1600 * 100vw) - 4.6875rem);
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(2104)}) {
    width: 10.875rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(2100)}) {
    ${({ isMaxWidth }) =>
      isMaxWidth &&
      css`
        max-width: 3.125rem;
      `};
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(3128)}) {
    width: 10.875rem;
  }
`

export const StyledStatisticsBarItemWrapper = styled(Flex)<{ $isWrapped?: boolean; $isBarChartEnabled?: boolean }>`
  &:not(:first-child) {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  }

  ${({ $isBarChartEnabled, $isWrapped }) =>
    !$isBarChartEnabled &&
    $isWrapped &&
    css`
      max-width: 4.8rem;
    `};
`

export const StyledPercentageWrapper = styled(Flex).attrs({ as: 'span' })`
  align-items: center;
`

export const StyledGroupHeading = styled.h3`
  ${({ theme }) => theme.textSizes.desktop.labelBold};
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

export const StyledCustomLink = styled(CustomLink)`
  line-height: 1.5rem;
`

export const StyledStatisticsBar = styled.div`
  &:not(:first-child) {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  }

  & > *:not(:first-child) {
    margin-top: 0.5rem;
  }
`
