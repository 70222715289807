import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  CumulatingType,
  DataSet,
  DataSetType,
  DefaultSoSVTimeFrame,
  getProductColors,
  GraphType,
  Product,
  ProductGroup,
  ProductGroupProduct,
  SoSVContentType,
  SoSVTimeframe,
  StyledSection,
  WeighteningType,
} from '~common'
import { FiltersHeading } from '~components/ui/molecules/filters-heading'
import { Controlled, RadioContainedButtonItem } from '~components/ui/controlled'
import { Tabs } from '~components/ui/molecules/tabs'
import { ProductBadge } from '~components/ui/atoms/product-badge'
import styled from 'styled-components'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { StyledSearchButton } from '~components/pages/experts-hub/components/search/components/search-pane/components/search-form/styles'
import { SoSVFilterOption } from '~components/ui/sosv-hub-layout/types'
import {
  StyledDatePickerDateRange,
  StyledFiltersGroup,
  StyledProductsGrid,
  StyledTopicsGrid,
} from '~components/ui/sosv-hub-layout/components/sosv-filters/styles'
import { buildFiltersParamsString } from '~components/ui/sosv-hub-layout/helpers'
import { IExpertEcosystemContext } from '~common/providers'
import { useRouter } from '~common/hooks'
import { useShallowRoutingState } from '~common/providers/shallow-routing-state-provider'

interface FiltersFormData {
  id: string
  contentTypes: Record<string, boolean>
  cumulatingType: string
  graphType: string
  timeframe: SoSVTimeframe
  startDate: string
  endDate: string
  drugSelection: Record<string, boolean>
  topicSelection: Record<string, boolean>
  weighteningType: string
  listId: string
  dataSet: string
  customDateRange: {
    startDate?: Date
    endDate?: Date
  }
  productGroupId?: string
}

const StyledHiddenProductGroupContainer = styled.div`
  display: flex;
  column-gap: 0.3125rem;
  align-items: start;
`

export const HiddenProductGroup: React.FC<{ name: string }> = ({ name }): JSX.Element => (
  <StyledHiddenProductGroupContainer>
    <ProductBadge name={'Hidden'} denominationColor={'red'} size={'md'} />
    {name}
  </StyledHiddenProductGroupContainer>
)

export const Filters = ({
  listId,
  hasSoSVForSelectedList,
  hasSoSVScienceForSelectedList,
  hasSoSVExpertsForSelectedList,
  topics,
  rootSubscriptionId,
  disabledFilters,
  isLimitedSoSVFilters,
  productGroups,
  productGroupId,
  onChangeProductGroup,
}: {
  listId: string
  hasSoSVForSelectedList: boolean
  hasSoSVScienceForSelectedList: boolean
  hasSoSVExpertsForSelectedList: boolean
  permissionsForSelectedSubscription: SubscriptionPermission[]
  rootSubscriptionId: string
  productGroups: ProductGroup[]
  onChangeProductGroup: (productGroupId: string) => void
  productGroupId?: string
  topics?: string[]
  disabledFilters?: SoSVFilterOption[]
  isLimitedSoSVFilters?: boolean
}): JSX.Element => {
  const { query, shallowRedirect } = useShallowRoutingState()

  const getSoSVProducts = (productGroups: ProductGroup[], productGroupId?: string) => {
    const selectedProductGroup = productGroups.find((el) => el.id === productGroupId)
    return selectedProductGroup?.products?.length
      ? selectedProductGroup.products.map((product: ProductGroupProduct, index: number) => ({
          name: product.label,
          denominationColor: getProductColors(selectedProductGroup.products.length, 'iqvia').reverse()[index],
        }))
      : []
  }

  const products: Product[] = productGroups.length ? getSoSVProducts(productGroups, productGroupId) : []

  const router = useRouter() as IExpertEcosystemContext['router']
  const {
    id,
    sosvTimeframe,
    sosvLaunchAnalogueRange,
    startDate,
    endDate,
    sosvContentTypes = [],
    graphType,
    drugSelection = products.map((product) => product.name),
    topicSelection = [],
    weighteningType,
    cumulatingType,
    dataSet,
    journalsSortBy,
    expertsSortBy,
    meetingId,
    journalId,
    societyId,
    city,
    state,
    country,
    meetingsSortBy,
    southWestLng,
    southWestLat,
    northEastLng,
    northEastLat,
  } = { ...(router?.query ?? {}), ...query } as {
    id: string
    sosvTimeframe: SoSVTimeframe
    sosvLaunchAnalogueRange?: SoSVTimeframe
    startDate: string
    endDate: string
    sosvContentTypes: [SoSVContentType]
    graphType: GraphType
    drugSelection: string[]
    topicSelection: string[]
    weighteningType: WeighteningType
    cumulatingType: CumulatingType
    dataSet: DataSetType
    journalsSortBy: string
    expertsSortBy: string
    meetingId: string
    journalId: string
    societyId: string
    city: string
    state: string
    country: string
    meetingsSortBy: string
    southWestLng: string
    southWestLat: string
    northEastLng: string
    northEastLat: string
  }

  const mapArrayToRecord = (values: string[], initialValue = true): Record<string, boolean> =>
    Object.fromEntries(values.map((v) => [v, initialValue]))

  const [initialFormData] = useState<FiltersFormData>({
    id,
    listId,
    dataSet,
    timeframe: sosvTimeframe,
    startDate,
    endDate,
    graphType,
    weighteningType,
    cumulatingType,
    contentTypes: mapArrayToRecord([sosvContentTypes].flat()),
    drugSelection: mapArrayToRecord([drugSelection].flat()),
    topicSelection: mapArrayToRecord([topicSelection].flat(), false),
    customDateRange: {
      startDate: startDate ? new Date(startDate) : undefined,
      endDate: endDate ? new Date(endDate) : undefined,
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    formState,
    getValues,
    reset,
    formState: { isValidating, isDirty, isValid },
  } = useForm({ mode: 'onChange' })

  useEffect(() => {
    if (!isLimitedSoSVFilters && !dataSet && productGroupId && products.length) {
      const resetState: FiltersFormData = {
        ...initialFormData,
        dataSet: DataSet.EXPERTS,
        contentTypes: mapArrayToRecord(['presentation', 'journal-article']),
        timeframe: DefaultSoSVTimeFrame,
        graphType: 'counts',
        weighteningType: 'weighted',
        cumulatingType: 'non-cumulative',
        drugSelection: (products ?? [])?.reduce((acc: Record<string, boolean>, cur) => {
          acc[cur.name] = true
          return acc
        }, {}),
        topicSelection: mapArrayToRecord(topics ?? [], false),
      }
      onSubmit({
        ...resetState,
        productGroupId: productGroupId,
      })
      reset(resetState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimitedSoSVFilters, dataSet, productGroupId, products])

  useEffect(() => {
    if (isDirty && isValid && !isValidating) {
      const data = watch()
      onSubmit(data as FiltersFormData)
      reset(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isValid, isValidating, formState])

  useEffect(() => {
    const data = watch()
    reset({
      ...data,
      listId,
      dataSet,
      drugSelection: products?.reduce((acc: Record<string, boolean>, cur) => {
        acc[cur.name] = drugSelection?.includes(cur.name) || false
        return acc
      }, {}),
      topicSelection: topics?.reduce((acc: Record<string, boolean>, cur) => {
        acc[cur] = topicSelection?.includes(cur) || false
        return acc
      }, {}),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId])

  useEffect(() => {
    if (sosvTimeframe !== 'customDateRange') {
      reset({
        ...getValues(),
        customDateRange: {
          startDate: undefined,
          endDate: undefined,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sosvTimeframe])

  useEffect(() => {
    reset({
      ...getValues(),
      dataSet,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet])

  const isFilterOptionDisabled = (option: SoSVFilterOption): boolean => {
    if (!disabledFilters || !disabledFilters.length) return false
    return disabledFilters.includes(option)
  }

  const onSubmit = (data: FiltersFormData) => {
    const isCustomDateRangeWithEmptyStartAndEndDates =
      data['timeframe'] === 'customDateRange' && (!data.customDateRange.startDate || !data.customDateRange.endDate)
    if (isCustomDateRangeWithEmptyStartAndEndDates) return

    const selectedProducts = new Set(
      getSoSVProducts(productGroups, data.productGroupId ?? productGroupId).map((p) => p.name),
    )

    const getCurrentOrInitial = <K extends keyof FiltersFormData>(
      filterOption: SoSVFilterOption,
      key: K,
    ): FiltersFormData[K] => (isFilterOptionDisabled(filterOption) ? initialFormData[key] : data[key])

    const params = buildFiltersParamsString({
      path: '',
      id,
      listId: listId,
      dataSet: getCurrentOrInitial(SoSVFilterOption.DataSet, 'dataSet'),
      timeframe: getCurrentOrInitial(SoSVFilterOption.Timeframe, 'timeframe'),
      contentTypes: getCurrentOrInitial(SoSVFilterOption.ContentTypes, 'contentTypes'),
      graphType: getCurrentOrInitial(SoSVFilterOption.GraphType, 'graphType'),
      productGroupId: data.productGroupId ?? (productGroupId as string),
      drugSelection: Object.fromEntries(
        Object.entries(getCurrentOrInitial(SoSVFilterOption.DrugsList, 'drugSelection')).filter(([key]) =>
          selectedProducts.has(key),
        ),
      ),
      topicSelection: getCurrentOrInitial(SoSVFilterOption.Topics, 'topicSelection'),
      weighteningType: getCurrentOrInitial(SoSVFilterOption.WeighteningType, 'weighteningType'),
      cumulatingType: getCurrentOrInitial(SoSVFilterOption.CumulatingType, 'cumulatingType'),
      startDate: getCurrentOrInitial(SoSVFilterOption.DateRange, 'customDateRange')?.startDate,
      endDate: getCurrentOrInitial(SoSVFilterOption.DateRange, 'customDateRange')?.endDate,
      rootSubscriptionId,
      journalId,
      meetingId,
      societyId,
      journalsSortBy,
      expertsSortBy,
      meetingsSortBy,
      city,
      state,
      country,
      southWestLng,
      southWestLat,
      northEastLng,
      northEastLat,
      sosvLaunchAnalogueRange,
    })

    shallowRedirect(params)
  }

  const handleResetAll = () => {
    const hasProductGroups = productGroups.length > 0
    const firstProductGroupId = hasProductGroups ? productGroups[0].id : undefined
    const productGroup = firstProductGroupId ? productGroups.find((pg) => pg.id === firstProductGroupId) : undefined
    const products = productGroup ? productGroup.products.map((p) => p.label) : []

    const getDefaultOrInitial = <K extends keyof FiltersFormData>(
      filterOption: SoSVFilterOption,
      key: K,
      defaultValue: FiltersFormData[K],
    ): FiltersFormData[K] => (isFilterOptionDisabled(filterOption) ? initialFormData[key] : defaultValue)

    const resetState: FiltersFormData = {
      ...initialFormData,
      dataSet: getDefaultOrInitial(
        SoSVFilterOption.DataSet,
        'dataSet',
        hasSoSVExpertsForSelectedList ? DataSet.EXPERTS : DataSet.SCIENCE,
      ),
      contentTypes: getDefaultOrInitial(
        SoSVFilterOption.ContentTypes,
        'contentTypes',
        mapArrayToRecord(['presentation', 'journal-article']),
      ),
      timeframe: getDefaultOrInitial(SoSVFilterOption.Timeframe, 'timeframe', DefaultSoSVTimeFrame),
      graphType: getDefaultOrInitial(SoSVFilterOption.GraphType, 'graphType', 'counts'),
      weighteningType: getDefaultOrInitial(SoSVFilterOption.WeighteningType, 'weighteningType', 'weighted'),
      cumulatingType: getDefaultOrInitial(SoSVFilterOption.CumulatingType, 'cumulatingType', 'non-cumulative'),
      drugSelection: getDefaultOrInitial(
        SoSVFilterOption.DrugsList,
        'drugSelection',
        firstProductGroupId ? mapArrayToRecord(products) : {},
      ),
      topicSelection: getDefaultOrInitial(
        SoSVFilterOption.Topics,
        'topicSelection',
        mapArrayToRecord(topics ?? [], false),
      ),
    }
    onSubmit({
      ...resetState,
      productGroupId: isFilterOptionDisabled(SoSVFilterOption.DrugsList) ? productGroupId : firstProductGroupId,
    })
    reset(resetState)
  }

  const showProducts = hasSoSVForSelectedList && !!products.length
  const showTopics = hasSoSVForSelectedList && !!topics?.length
  const getDataSetOptions = (): RadioContainedButtonItem[] => {
    const isDataSetDisabled = isFilterOptionDisabled(SoSVFilterOption.DataSet)
    const isSoSVForScienceDisabled = !hasSoSVScienceForSelectedList
    const isSoSVForExpertsDisabled = !hasSoSVExpertsForSelectedList
    return [
      {
        label: 'Experts',
        id: 'experts',
        value: 'experts',
        isDisabled: isDataSetDisabled || isSoSVForExpertsDisabled,
        hideTooltipOnDisabled: true,
      },
      {
        label: 'Science',
        id: 'science',
        value: 'science',
        isDisabled: isDataSetDisabled || isSoSVForScienceDisabled,
        hideTooltipOnDisabled: true,
      },
    ]
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="sosv-filters-form">
      {!isLimitedSoSVFilters && (
        <section>
          <FiltersHeading>Data set</FiltersHeading>
          <Controlled.RadioContainedButtonGroup
            name="dataSet"
            control={control}
            defaultValue={dataSet}
            radios={getDataSetOptions()}
          />
        </section>
      )}
      <StyledSection>
        <FiltersHeading>Format</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="graphType"
          isDisabled={isFilterOptionDisabled(SoSVFilterOption.GraphType)}
          hideTooltipOnDisabled={true}
          control={control}
          defaultValue={graphType}
          radios={[
            {
              label: 'Counts',
              id: 'counts',
              value: 'counts',
            },
            {
              label: 'Share',
              id: 'share',
              value: 'share',
            },
          ]}
        />
      </StyledSection>

      <StyledSection>
        <FiltersHeading>Over time</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="cumulatingType"
          isDisabled={isFilterOptionDisabled(SoSVFilterOption.CumulatingType)}
          hideTooltipOnDisabled={true}
          control={control}
          defaultValue={cumulatingType}
          radios={[
            {
              label: 'Non cumulative',
              id: 'non-cumulative',
              value: 'non-cumulative',
            },
            {
              label: 'Cumulative',
              id: 'cumulative',
              value: 'cumulative',
            },
          ]}
        />
      </StyledSection>

      <StyledSection>
        <FiltersHeading>Weighting</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="weighteningType"
          isDisabled={isFilterOptionDisabled(SoSVFilterOption.WeighteningType)}
          hideTooltipOnDisabled={true}
          control={control}
          defaultValue={weighteningType}
          radios={[
            {
              label: 'Weighted',
              id: 'weighted',
              value: 'weighted',
            },
            {
              label: 'Unweighted',
              id: 'unweighted',
              value: 'unweighted',
            },
          ]}
        />
      </StyledSection>

      {showProducts && (
        <StyledSection data-testid="products-filter">
          <FiltersHeading>Products</FiltersHeading>
          {productGroupId && productGroups.length > 1 ? (
            <Tabs
              tabs={productGroups.map((group) => ({
                id: group.id,
                name: group.hidden ? <HiddenProductGroup name={group.name} /> : group.name,
              }))}
              selectedTabId={productGroupId}
              onSelect={(id) => {
                onChangeProductGroup(id)
              }}
            >
              <section>
                <Controlled.CheckboxContained
                  key={products?.[0].name}
                  label={products?.[0].name}
                  id={`${products?.[0].name}-product`}
                  name={`drugSelection.${products?.[0].name}`}
                  isDisabled={isFilterOptionDisabled(SoSVFilterOption.DrugsList)}
                  control={control}
                  denominationColor={products?.[0].denominationColor}
                  defaultChecked={drugSelection?.includes(products?.[0].name || '')}
                />
                <StyledProductsGrid>
                  {products?.slice(1).map((product: Product) => (
                    <Controlled.CheckboxContained
                      key={product.name}
                      label={product.name}
                      id={`${product.name}-product`}
                      isDisabled={isFilterOptionDisabled(SoSVFilterOption.DrugsList)}
                      name={`drugSelection.${product.name}`}
                      denominationColor={product.denominationColor}
                      control={control}
                      defaultChecked={drugSelection?.includes(product.name)}
                    />
                  ))}
                </StyledProductsGrid>
              </section>
            </Tabs>
          ) : (
            <>
              <Controlled.CheckboxContained
                label={products?.[0].name}
                id={`${products?.[0].name}-product`}
                isDisabled={isFilterOptionDisabled(SoSVFilterOption.DrugsList)}
                name={`drugSelection.${products?.[0].name}`}
                control={control}
                denominationColor={products?.[0].denominationColor}
                defaultChecked={drugSelection?.includes(products?.[0].name || '')}
              />
              <StyledProductsGrid>
                {products?.slice(1).map((product: Product) => (
                  <Controlled.CheckboxContained
                    key={product.name}
                    label={product.name}
                    id={`${product.name}-product`}
                    isDisabled={isFilterOptionDisabled(SoSVFilterOption.DrugsList)}
                    name={`drugSelection.${product.name}`}
                    denominationColor={product.denominationColor}
                    control={control}
                    defaultChecked={drugSelection?.includes(product.name)}
                  />
                ))}
              </StyledProductsGrid>
            </>
          )}
        </StyledSection>
      )}
      <StyledSection>
        <FiltersHeading>Content types</FiltersHeading>
        <StyledFiltersGroup>
          <div>
            <Controlled.CheckboxContained
              label="Presentations"
              id={SoSVContentType.presentation}
              isDisabled={isFilterOptionDisabled(SoSVFilterOption.ContentTypes)}
              name={`contentTypes.${SoSVContentType.presentation}`}
              control={control}
              defaultChecked={
                sosvContentTypes?.length ? sosvContentTypes?.includes(SoSVContentType.presentation) : true
              }
            />
          </div>
          <div>
            <Controlled.CheckboxContained
              label="Journal articles"
              id={SoSVContentType.journalArticle}
              isDisabled={isFilterOptionDisabled(SoSVFilterOption.ContentTypes)}
              name={`contentTypes.${SoSVContentType.journalArticle}`}
              control={control}
              defaultChecked={
                sosvContentTypes?.length ? sosvContentTypes?.includes(SoSVContentType.journalArticle) : true
              }
            />
          </div>
        </StyledFiltersGroup>
      </StyledSection>

      <StyledSection>
        <FiltersHeading>Timeframe</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="timeframe"
          isDisabled={isFilterOptionDisabled(SoSVFilterOption.Timeframe)}
          hideTooltipOnDisabled={true}
          control={control}
          defaultValue={sosvTimeframe}
          radios={[
            {
              label: 'Last 3 months',
              id: 'twelveWeeks',
              value: 'twelveWeeks',
            },
            {
              label: 'Last year',
              id: 'oneYear',
              value: 'oneYear',
            },
            {
              label: 'Last 3 years',
              id: 'threeYears',
              value: 'threeYears',
            },
            {
              label: 'Last 10 years',
              id: 'tenYears',
              value: 'tenYears',
            },
            {
              label: 'Custom date range',
              id: 'customDateRange',
              value: 'customDateRange',
            },
          ]}
        />
        <StyledDatePickerDateRange
          name="customDateRange"
          startDate={initialFormData.customDateRange.startDate}
          endDate={initialFormData.customDateRange.endDate}
          watch={watch}
          reset={reset}
          getValues={getValues}
          control={control}
          isDisabled={
            isFilterOptionDisabled(SoSVFilterOption.DateRange) ||
            (watch('timeframe') !== 'customDateRange' && !startDate && !endDate)
          }
        />
      </StyledSection>

      {showTopics && (
        <StyledSection>
          <FiltersHeading>Topics</FiltersHeading>
          <StyledTopicsGrid>
            {topics?.map((topic: string) => (
              <Controlled.CheckboxContained
                key={topic}
                label={topic}
                id={`${topic}-topic`}
                isDisabled={isFilterOptionDisabled(SoSVFilterOption.Topics)}
                name={`topicSelection.${topic}`}
                control={control}
                defaultChecked={topicSelection?.includes(topic)}
              />
            ))}
          </StyledTopicsGrid>
        </StyledSection>
      )}
      <StyledSection>
        <StyledSearchButton variant="secondary" size="md" onClick={handleResetAll} type="button">
          Reset all
        </StyledSearchButton>
      </StyledSection>
    </form>
  )
}
