import { TooltipFormatterContextObject } from 'highcharts'
import { SeriesOptionsType } from 'highcharts'
import { getCommonChartOptions } from '../../common-charts-options'

export const useProductsMentionsByTimeframeColumnChartOptions = ({
  data,
  categories,
  toolTipFormatter,
}: {
  data: SeriesOptionsType[]
  categories: number[] | string[]
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): [Highcharts.Options] => {
  const commonChartOptions = getCommonChartOptions(categories)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      marginLeft: 40,
      height: 128,
      type: 'column',
      className: 'products-mentions-column',
    },
    tooltip: {
      ...commonChartOptions.tooltip,
      style: {
        width: 300,
      },
      distance: 11,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      gridLineWidth: 0,
      labels: {
        x: -4,
        style: {
          width: 25,
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return `${this.value}%`
          }
          return ''
        },
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
      },
      column: {
        stacking: 'percent',
      },
    },
    series: data.reverse(),
  }
  return [options]
}
