import { gql } from '@apollo/client'
import { SmartListBuilder } from '~apollo'
import { GetSmartListQuery, GetSmartListQueryVariables } from './__generated__/GetSmartListQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { SmartListType } from '~common'
import {
  GetSmartListsByTypeForListIdQuery,
  GetSmartListsByTypeForListIdQueryVariables,
} from '~apollo/queries/smart-list/__generated__/GetSmartListsByTypeForListIdQuery'

export const GET_SMART_LIST_BY_ID = gql`
  query GetSmartListQuery($listId: String!, $smartListId: String!) {
    getSmartList(listId: $listId, smartListId: $smartListId) {
      id
      name
      profilesCount
    }
  }
`

export const GET_SMART_LISTS_BY_TYPE_FOR_LIST_ID = gql`
  query GetSmartListsByTypeForListIdQuery($listId: String!, $type: String!) {
    getSmartListsByType(listId: $listId, type: $type) {
      id
      listId
      listName
      name
      count
      children {
        id
        listId
        listName
        name
        count
      }
    }
  }
`

const dummySmartList = SmartListBuilder.random({
  id: 'wanted-smart-list-id-1',
  name: 'Wanted Smart List 1',
  profilesCount: 10,
})

export const getSmartListHandler: GraphqlHandler<GetSmartListQueryVariables, GetSmartListQuery> = (req, res, ctx) => {
  const response: GetSmartListQuery = {
    getSmartList: dummySmartList,
  }
  if (req.variables.smartListId === 'wanted-smart-list-id-1') return res(ctx.data(response))
  else
    return res(
      ctx.data({
        getSmartList: SmartListBuilder.random({
          id: 'wanted-smart-list-id-2',
          name: 'Child Subscription 1 - Test',
          profilesCount: 0,
        }),
      }),
    )
}

const createSmartListId = (type: SmartListType, id: string, subId?: string): string =>
  `${type};${id}${subId ? `;${subId}` : ''}`

export const mockGeographicSmartLists = [
  {
    id: createSmartListId(SmartListType.Geographic, 'germany'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Germany',
    count: 2,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Geographic, 'japan'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Japan',
    count: 1,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Geographic, 'italy'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Italy',
    count: 3,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Geographic, 'uk'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'UK',
    count: 4,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Geographic, 'spain'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Spain',
    count: 7,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Geographic, 'usa'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'USA',
    count: 10,
    children: [
      {
        id: createSmartListId(SmartListType.Geographic, 'usa', 'ma'),
        listId: 'child-sub-1',
        listName: 'Top Level Subscription 1',
        name: 'Massachusetts',
        count: 4,
      },
      {
        id: createSmartListId(SmartListType.Geographic, 'usa', 'ca'),
        listId: 'child-sub-1',
        listName: 'Top Level Subscription 1',
        name: 'California',
        count: 4,
      },
      {
        id: createSmartListId(SmartListType.Geographic, 'usa', 'mn'),
        listId: 'child-sub-1',
        listName: 'Top Level Subscription 1',
        name: 'Minnesota',
        count: 2,
      },
    ],
  },
  {
    id: createSmartListId(SmartListType.Geographic, 'canada'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Canada',
    count: 15,
    children: [
      {
        id: createSmartListId(SmartListType.Geographic, 'canada', 'on'),
        listId: 'child-sub-1',
        listName: 'Top Level Subscription 1',
        name: 'Ontario',
        count: 12,
      },
      {
        id: createSmartListId(SmartListType.Geographic, 'canada', 'qc'),
        listId: 'child-sub-1',
        listName: 'Top Level Subscription 1',
        name: 'Quebec',
        count: 3,
      },
    ],
  },
]

export const mockSpecialitySmartLists = [
  {
    id: createSmartListId(SmartListType.Speciality, 'internal medicine'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Internal Medicine',
    count: 2,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Speciality, 'cardiology'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Cardiology',
    count: 1,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Speciality, 'diabetology'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Diabetology',
    count: 3,
    children: [],
  },
  {
    id: createSmartListId(SmartListType.Speciality, 'epidemiology'),
    listId: 'child-sub-1',
    listName: 'Top Level Subscription 1',
    name: 'Epidemiology',
    count: 4,
    children: [],
  },
]

export const getSmartListsByTypeForListIdHandler: GraphqlHandler<
  GetSmartListsByTypeForListIdQueryVariables,
  GetSmartListsByTypeForListIdQuery
> = (req, res, ctx) => {
  const response: GetSmartListsByTypeForListIdQuery = {
    getSmartListsByType:
      req.variables.type === SmartListType.Geographic ? mockGeographicSmartLists : mockSpecialitySmartLists,
  }
  return req.variables.listId === 'child-sub-1' ? res(ctx.data(response)) : res(ctx.data({ getSmartListsByType: [] }))
}
