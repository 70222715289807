import React, { useState } from 'react'
import styled from 'styled-components'
import { DevFeature } from '~common'
import { useFeatures } from '~common/hooks/use-features'

const StyledDiv = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: 0.625rem;
  z-index: 9999;
`

const StyledButton = styled.button`
  border: 1px grey;
  float: right;
  clear: both;
`

export const FeatureMenu = (): JSX.Element | null => {
  const { isInternalTestingGroup, isDevFeatureEnabled, setDevFeature } = useFeatures()
  const [menuOpen, setMenuOpen] = useState(false)
  const [buttonHidden, setButtonHidden] = useState(false)
  if (!isInternalTestingGroup || Object.keys(DevFeature).length === 0) return null

  return (
    <StyledDiv>
      {menuOpen && (
        <div>
          {Object.entries(DevFeature).map(([k, v]) => {
            const enabled = isDevFeatureEnabled(v)
            return (
              <StyledButton
                key={k}
                onClick={() => {
                  setDevFeature(v, !enabled)
                  setMenuOpen(false)
                }}
              >
                {enabled ? `Disable: ${k}` : `Enable: ${k}`}
              </StyledButton>
            )
          })}
          <StyledButton
            onClick={() => {
              setButtonHidden(true)
              setMenuOpen(false)
            }}
          >
            Hide feature button
          </StyledButton>
        </div>
      )}
      {!buttonHidden && <StyledButton onClick={() => setMenuOpen(!menuOpen)}>π</StyledButton>}
    </StyledDiv>
  )
}
