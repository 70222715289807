import React from 'react'
import { Dropdown } from '~components/ui/molecules/dropdown'
import { FormItem, FormItemSize, FormItemLabelPosition } from '~components/ui/atoms/form-item'
import { StyledCustomSelect, StyledCustomSelectWrapper } from './styles'
import { useOutsideClick } from '~common/hooks'
import { ChevronIcon } from '~components/ui/atoms/chevron-icon'
import { ControlledProps } from '~components/ui/controlled/types'
import { camelCaseToSentenceCase } from '~common'
import { chevronSize } from '../Select'

export interface SelectWithCheckboxesDropdownProps<TFormValues>
  extends React.HTMLAttributes<HTMLSelectElement>,
    ControlledProps<TFormValues> {
  id: string
  options: {
    id: string
    label: string
    isChecked: boolean
  }[]
  selectDropdownSize?: FormItemSize
  labelPosition?: FormItemLabelPosition
  label?: string
  isDisabled?: boolean
  customDropdownItems?: { value: string; label: string }[]
}

export const SelectWithCheckboxesDropdown = <TFormValues extends Record<string, unknown>>({
  id,
  label,
  selectDropdownSize = 'sm',
  labelPosition = 'top',
  isDisabled,
  style,
  className,
  options,
  control,
  rules,
  name,
  errors,
}: SelectWithCheckboxesDropdownProps<TFormValues>): JSX.Element => {
  const {
    ref: selectDropdownRef,
    state: isOpenDropdown,
    action: setIsOpenDropdown,
  } = useOutsideClick<HTMLDivElement>(false)
  const checkedOptions = Object.values(options || {}).filter((optionValue) => optionValue.isChecked)
  return (
    <FormItem
      className={className}
      formItemSize={selectDropdownSize}
      labelPosition={labelPosition}
      style={style}
      label={label}
      htmlFor={`select-${id}`}
    >
      <StyledCustomSelectWrapper ref={selectDropdownRef}>
        <StyledCustomSelect
          id={`select-${id}`}
          type="button"
          selectDropdownSize={selectDropdownSize}
          isOpened={isOpenDropdown}
          aria-expanded={isOpenDropdown}
          data-toggle="dropdown"
          aria-haspopup={true}
          disabled={isDisabled}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        >
          {`${camelCaseToSentenceCase(name)} (${checkedOptions.length}/${options.length})`}
          <ChevronIcon
            position="right"
            rotation="top-bottom"
            width={chevronSize[selectDropdownSize].size}
            height={chevronSize[selectDropdownSize].size}
            pathDValue={chevronSize[selectDropdownSize].dValue}
            isOpened={isOpenDropdown}
          />
        </StyledCustomSelect>
        <Dropdown.WithControlledCheckboxes
          isOpen={isOpenDropdown}
          name={name}
          options={options}
          ariaLabel={`${camelCaseToSentenceCase(name)} options`}
          onBlur={() => setIsOpenDropdown(false)}
          onFocus={() => setIsOpenDropdown(true)}
          control={control}
          rules={rules}
          errors={errors}
        />
      </StyledCustomSelectWrapper>
    </FormItem>
  )
}
