import { TooltipFormatterContextObject } from 'highcharts'
import React, { RefObject, useEffect, useRef } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import { getProductColors, StyledScreenReadersOnly, WeighteningType, SoSVTimeframe, Mode, roundDecimals } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { utcMidnight } from '~components/ui/organisms/charts/helpers'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { buildLaunchAnalogueDataSets, buildLaunchAnalogueSeries, LaunchAnalogueMentionsCount } from './helpers'
import { StyledLaunchAnalogueMultipleSeriesStockChart, StyledExportChartTrigger } from './styles'
import { useLaunchAnalogueMultipleSeriesStockChartOptions } from './use-launch-analogue-multiple-series-stock-chart'
import addYears from 'date-fns/addYears'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import startOfWeek from 'date-fns/startOfWeek'
import { ExportChartConfig } from '../..'
import { useState } from 'react'

export interface LaunchAnalogueMultipleSeriesStockChartProps {
  sosvStats: LaunchAnalogueMentionsCount[]
  sosvLaunchAnalogueRange: SoSVTimeframe
  sosvLaunchAnalogueRangeChanged?: (range: SoSVTimeframe) => void
  weighteningType: WeighteningType
  exportChartConfig?: ExportChartConfig
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const LaunchAnalogueMultipleSeriesStockChart = ({
  sosvStats,
  sosvLaunchAnalogueRange,
  sosvLaunchAnalogueRangeChanged,
  weighteningType,
  exportChartConfig,
  themeMode,
  themeBrand,
}: LaunchAnalogueMultipleSeriesStockChartProps): JSX.Element => {
  const [range, setRange] = useState<SoSVTimeframe>(sosvLaunchAnalogueRange)

  useEffect(() => {
    rangeChanged(range, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weighteningType])

  const chartRef = useRef<{ chart: Highcharts.Chart; container: RefObject<HTMLDivElement> }>(null)

  const tZeroDate = startOfWeek(utcMidnight(), { weekStartsOn: 1 }).getTime()

  const productSets = buildLaunchAnalogueDataSets(sosvStats, tZeroDate)

  const halfLength = Math.floor(Math.max(...productSets.map((set) => set.counts.length)) / 2)
  const minDate = productSets.length ? addWeeks(tZeroDate, -1 * halfLength).getTime() : tZeroDate
  const maxDate = productSets.length ? addWeeks(tZeroDate, halfLength).getTime() : tZeroDate

  const launchAnalogueSeries = buildLaunchAnalogueSeries(productSets, minDate, weighteningType, themeBrand)

  const productColors = getProductColors(launchAnalogueSeries.length, themeBrand)

  const rangeChanged = (range: SoSVTimeframe, bubble = true) => {
    if (!chartRef.current) return
    const chart = chartRef.current.chart

    let min: number
    let max: number
    switch (range) {
      case SoSVTimeframe.ThreeYears:
        min = addMonths(tZeroDate, -18).getTime()
        max = addMonths(tZeroDate, 18).getTime()
        break

      case SoSVTimeframe.OneYear:
        min = addMonths(tZeroDate, -6).getTime()
        max = addMonths(tZeroDate, 6).getTime()
        break

      case SoSVTimeframe.TwelveWeeks:
        min = addWeeks(tZeroDate, -6).getTime()
        max = addWeeks(tZeroDate, 6).getTime()
        break

      case SoSVTimeframe.TenYears:
      default:
        min = addYears(tZeroDate, -5).getTime()
        max = addYears(tZeroDate, 5).getTime()
        break
    }

    chart.xAxis[0].setExtremes(min, max, true, false)
    chart.reflow()

    if (bubble) {
      setRange(range)
      if (sosvLaunchAnalogueRangeChanged) sosvLaunchAnalogueRangeChanged(range)
    }
  }

  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]'
    const splat = (obj) => (isArray(obj) ? obj : [obj])
    const items: TooltipFormatterContextObject[] = context.points || splat(context.points)
    items.sort((a, b) => (a.y < b.y ? -1 : a.y > b.y ? 1 : 0)).reverse()

    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            {items.map((el, i) => (
              <div key={i} className="key-value-wrapper">
                <span key={i} className="key">
                  {el.series.name}
                </span>{' '}
                <span key={i} className="value">
                  {!el.y ? 0 : weighteningType === 'weighted' ? roundDecimals(el.y, 1) : roundDecimals(el.y, 0)}
                </span>
                <br key={i} />
              </div>
            ))}
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const options = useLaunchAnalogueMultipleSeriesStockChartOptions({
    data: launchAnalogueSeries,
    productColors,
    tZeroDate,
    minDate,
    maxDate,
    range,
    rangeChanged,
    weighteningType,
    ...(exportChartConfig
      ? {
          exportChartConfig: {
            ...exportChartConfig,
            sosvTimeframe: range,
          },
        }
      : {}),
    toolTipFormatter,
  })

  return (
    <StyledLaunchAnalogueMultipleSeriesStockChart productColors={productColors} series={launchAnalogueSeries}>
      {!!exportChartConfig && (
        <StyledExportChartTrigger exportTypes={exportChartConfig.exportFormatTypes} chartRef={chartRef} />
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        ref={chartRef}
        constructorType="stockChart"
        containerProps={{ style: { height: '100%' } }}
      />
      <StyledScreenReadersOnly>Launc Analogue multiple series stock chart</StyledScreenReadersOnly>
    </StyledLaunchAnalogueMultipleSeriesStockChart>
  )
}
