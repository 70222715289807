import React, { useCallback, useMemo } from 'react'
import { MapAction as Action, MapState } from '~common/hooks/use-map/map-state-managment/types'
import { ChartLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { Module } from '~components/ui/molecules/module'
import { ChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { defaultBoundsEntireWorld } from '~components/ui/organisms/cluster-map/types'
import { formatUrl, generateSoSVModuleTitle, GeographyFilter, MapBounds, Mode } from '~common'
import { initialiseMap } from '~common/hooks/use-map/map-state-managment/action-creators'
import { useFeatures } from '~common/hooks/use-features'
import { useMode } from '~common/hooks/use-mode'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  ClusterMarkerIcon,
  SocietyEventMarkerIcon,
  SocietyEventsLocationTeaser,
} from '~components/pages/sosv-hub/components/society-events-by-location/components'
import { SocietyEventsFeature } from '~components/pages/sosv-hub/components/society-events-by-location/types'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'
import {
  GetSoSVGeographyMeetingsCountQuery,
  GetSoSVGeographyMeetingsCountQuery_getSoSVGeographyMeetingsCount,
} from '~apollo/queries/sosv/meetings/__generated__/GetSoSVGeographyMeetingsCountQuery'

const ClusterMap = dynamic(() => import('~components/ui/organisms/cluster-map/ClusterMap'), { ssr: false })

const toSocietyEventsFeatures = (
  pageUrlFnc: (geography: GeographyFilter) => string,
  styleMode: Mode,
  isIqviaBrand: boolean,
  data?: GetSoSVGeographyMeetingsCountQuery_getSoSVGeographyMeetingsCount[] | null,
): SocietyEventsFeature[] => {
  const features = data ?? []
  const societyEventFeatures: SocietyEventsFeature[] = []
  for (const feature of features) {
    if (feature.location && feature.location.lat && feature.location.lon) {
      const { city, country, state, meetingCount } = feature
      societyEventFeatures.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [feature.location.lon, feature.location.lat],
        },
        properties: {
          id: `${city}${country}${[feature.location.lon, feature.location.lat].join()}`,
          country,
          city,
          state,
          meetingCount,
          popupUI: (
            <SocietyEventsLocationTeaser
              city={city}
              state={state}
              country={country}
              meetingCount={meetingCount}
              styleMode={styleMode}
              isIqviaBrand={isIqviaBrand}
              href={pageUrlFnc({ city, country, state })}
            />
          ),
          closeIconColor: 'primary',
        },
      })
    }
  }

  return societyEventFeatures
}

export interface TopSocietyEventsByLocationProps {
  isLoading: boolean
  data?: GetSoSVGeographyMeetingsCountQuery | null
  mapState: MapState
  mapDispatch: React.Dispatch<Action>
}

export const TopSocietyEventsByLocation: React.FC<TopSocietyEventsByLocationProps> = ({
  isLoading,
  data,
  mapState,
  mapDispatch,
}) => {
  const router = useRouter()
  const { isIqviaBrand } = useFeatures()
  const [styleMode] = useMode()

  const {
    listId,
    rootListId,
    contentTypes,
    drugSelection,
    cumulatingType,
    graphType,
    productGroupId,
    weighteningType,
    timeframe,
    source,
    startDate,
    endDate,
  } = useSoSVState()

  const uniqueIdByFilters = `${styleMode}-${listId}-${productGroupId}-${source}`

  const getSocietyEventByLocationPageUrl = useCallback(
    (geography: GeographyFilter) => {
      return formatUrl(getPlatformUrl() + `/sosv/society-events-by-location/details`, {
        listId,
        rootSubscriptionId: rootListId,
        weighteningType: weighteningType,
        productGroupId: productGroupId,
        sosvTimeframe: timeframe,
        sosvContentTypes: contentTypes,
        drugSelection,
        cumulatingType,
        graphType,
        dataSet: source,
        city: geography.city,
        state: geography.state,
        country: geography.country,
        meetingsSortBy: 'all',
        startDate,
        endDate,
      })
    },
    [
      weighteningType,
      productGroupId,
      listId,
      rootListId,
      timeframe,
      contentTypes,
      drugSelection,
      cumulatingType,
      graphType,
      source,
      startDate,
      endDate,
    ],
  )

  const societyEventsFeatures: SocietyEventsFeature[] = useMemo(() => {
    return toSocietyEventsFeatures(
      getSocietyEventByLocationPageUrl,
      styleMode,
      isIqviaBrand,
      data?.getSoSVGeographyMeetingsCount,
    )
  }, [data?.getSoSVGeographyMeetingsCount, getSocietyEventByLocationPageUrl, styleMode, isIqviaBrand])

  const onMapChanges = useCallback(
    (visiblePointsTotal: number, bounds: MapBounds) => {
      initialiseMap({
        mapDispatch,
        router,
        visiblePointsTotal,
        mapBounds: bounds,
      })
    },
    [mapDispatch, router],
  )

  const societyEventsAllPageUrl = formatUrl(getPlatformUrl() + '/sosv/society-events-by-location', {
    listId,
    rootSubscriptionId: rootListId,
    weighteningType: weighteningType,
    productGroupId: productGroupId,
    sosvTimeframe: timeframe,
    sosvContentTypes: contentTypes,
    drugSelection,
    cumulatingType,
    graphType,
    dataSet: source,
    southWestLng: mapState?.mapBounds ? mapState.mapBounds.southWest.lng : undefined,
    southWestLat: mapState?.mapBounds ? mapState.mapBounds.southWest.lat : undefined,
    northEastLng: mapState?.mapBounds ? mapState.mapBounds.northEast.lng : undefined,
    northEastLat: mapState?.mapBounds ? mapState.mapBounds.northEast.lat : undefined,
  })

  if (isLoading) {
    return <ChartLoadingBlock withNoPadding />
  }

  return (
    <Module.Container>
      <Module.Header
        linkText={!!mapState.visiblePointsTotal ? 'View all found' : undefined}
        linkTo={mapState.visiblePointsTotal ? societyEventsAllPageUrl : undefined}
      >
        {generateSoSVModuleTitle({
          moduleType: 'societyEventsLocation',
          sosvTimeframe: timeframe,
          weighteningType,
        })}
      </Module.Header>
      {Boolean(societyEventsFeatures.length) ? (
        <ClusterMap
          key={uniqueIdByFilters}
          features={societyEventsFeatures}
          scalingProperty="meetingCount"
          onMapChanges={onMapChanges}
          FeatureIcon={SocietyEventMarkerIcon}
          ClusterIcon={ClusterMarkerIcon}
          fitBounds
          bounds={
            mapState.mapBounds
              ? [
                  [mapState.mapBounds.southWest.lat, mapState.mapBounds.southWest.lng],
                  [mapState.mapBounds.northEast.lat, mapState.mapBounds.northEast.lng],
                ]
              : defaultBoundsEntireWorld
          }
        />
      ) : (
        <ChartEmptyState isStackedBar />
      )}
      {Boolean(data?.getSoSVMeetingsLocationsCount) ? (
        <Module.Footer
          links={[
            {
              name: `View all ${data?.getSoSVMeetingsLocationsCount} society events location`,
              id: 'society-events-by-location',
              href: societyEventsAllPageUrl,
            },
          ]}
        />
      ) : null}
    </Module.Container>
  )
}
