import { gql } from '@apollo/client'
import { calculateMentionsShare, PresentationBuilder } from '~apollo'
import {
  GetSoSVPresentationsQuery,
  GetSoSVPresentationsQueryVariables,
} from '~apollo/queries/sosv/presentations/__generated__/GetSoSVPresentationsQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { arrayOf, randomFloat, randomNumber } from '~testing/test-helpers'

export const GET_SOSV_PRESENTATIONS = gql`
  query GetSoSVPresentationsQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $meetingId: String
    $pageSize: Int!
    $currentPage: Int!
    $geographyFilter: GeographyFilter
    $source: String
    $savedListId: String
    $smartListId: String
  ) {
    getSoSVPresentations(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      geographyFilter: $geographyFilter
      meetingId: $meetingId
      pageSize: $pageSize
      currentPage: $currentPage
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      id
      title
      conferenceName
      organization
      type
      date
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
      medmemeId
    }
  }
`

const dummyPresentations = [
  PresentationBuilder.random({
    id: 'wanted-presentation-1',
    title: 'Wanted Presentation 1',
    abstract: [{ heading: null, text: 'Wanted Abstract 1' }],
  }),
  ...arrayOf((i) => PresentationBuilder.random({ id: `presentation-id-${i + 2}` }), 60),
  PresentationBuilder.random({
    id: 'wanted-presentation-2',
    title: 'Wanted Presentation 2',
    abstract: [{ heading: null, text: 'Wanted Abstract 2' }],
  }),
]

export const soSVPresentationsMock: GetSoSVPresentationsQuery = {
  getSoSVPresentations: dummyPresentations.map((el) => ({
    ...el,
    mentions: calculateMentionsShare([
      {
        productName: 'Lantus',
        count: randomNumber(),
        countWeighted: randomFloat(1, 100),
      },
      {
        productName: 'Glooko',
        count: randomNumber(),
        countWeighted: randomFloat(1, 100),
      },
      {
        productName: 'Wanted',
        count: randomNumber(),
        countWeighted: randomFloat(1, 100),
      },
    ]),
  })),
}

export const getSoSVPresentationsQueryHandler: GraphqlHandler<
  GetSoSVPresentationsQueryVariables,
  GetSoSVPresentationsQuery
> = (req, res, ctx) => {
  if (req.variables.savedListId) {
    return res(
      ctx.data({
        ...soSVPresentationsMock,
        getSoSVPresentations: soSVPresentationsMock.getSoSVPresentations.slice(0, 1),
      }),
    )
  }
  return res(ctx.data(soSVPresentationsMock))
}
