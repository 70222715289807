import React from 'react'
import styled from 'styled-components'
import { ButtonWithNoStyle, flexMixin } from '~common'
import { HamburgerSVG } from '../global-navigation/GlobalNavigation'
import { NavigationItem } from '../navigation-item'

export const StyledLatestMyAccountList = styled.ul`
  ${flexMixin}
  flex-direction: column;
`

const StyledLatestNavigationItemWithMarginTop = styled(NavigationItem)`
  margin-top: 0.5rem;
  button {
    align-items: flex-start;
  }
`

const ssopUrl = {
  dev: 'https://uat-products.pharmaspectra.com',
  prod: 'https://products.pharmaspectra.com',
}
const allProductsUrl = process.env.ENV === 'dev' ? ssopUrl.dev : ssopUrl.prod

interface MyAccountListProps {
  isMyAccountListVisible: boolean
  onLogout: () => void
  isTouchable: boolean
  zendeskUrl: string
  hasPanSubscriptionSearchMenuItem: boolean
  refetchZendeskUrl?: () => void
}

export const MyAccountList = ({
  isMyAccountListVisible,
  onLogout,
  isTouchable,
  zendeskUrl,
  hasPanSubscriptionSearchMenuItem,
  refetchZendeskUrl,
}: MyAccountListProps): JSX.Element => (
  <StyledLatestMyAccountList>
    <NavigationItem hasNoAction={true} isInlineSmall={true} isTouchable={isTouchable}>
      <>
        <HamburgerSVG />
        My Account
      </>
    </NavigationItem>
    <NavigationItem
      linkTo="/my-account/saved-searches"
      hasIcon={false}
      isInlineSmall={true}
      tabIndex={isMyAccountListVisible ? 0 : -1}
      isTouchable={isTouchable}
      id="my-account/saved-searches"
    >
      Saved searches
    </NavigationItem>
    {hasPanSubscriptionSearchMenuItem && (
      <NavigationItem
        linkTo="/pan-subscription-search"
        hasIcon={false}
        isInlineSmall={true}
        tabIndex={isMyAccountListVisible ? 0 : -1}
        isTouchable={isTouchable}
        id="my-account/pan-subscription-search"
      >
        Pan subscription search
      </NavigationItem>
    )}
    <NavigationItem
      linkTo={allProductsUrl}
      hasIcon={false}
      isInlineSmall={true}
      tabIndex={isMyAccountListVisible ? 0 : -1}
      isTouchable={isTouchable}
    >
      All products
    </NavigationItem>
    {zendeskUrl && (
      <NavigationItem
        linkTo={zendeskUrl}
        isOpenInNewTab={true}
        hasIcon={false}
        isInlineSmall={true}
        onClick={() => {
          refetchZendeskUrl && refetchZendeskUrl()
        }}
        tabIndex={isMyAccountListVisible ? 0 : -1}
        isTouchable={isTouchable}
      >
        Help center
      </NavigationItem>
    )}
    <StyledLatestNavigationItemWithMarginTop hasIcon={false} isInlineSmall={true} isTouchable={isTouchable}>
      <ButtonWithNoStyle type="button" onClick={onLogout} tabIndex={isMyAccountListVisible ? 0 : -1}>
        Sign out
      </ButtonWithNoStyle>
    </StyledLatestNavigationItemWithMarginTop>
  </StyledLatestMyAccountList>
)
