import { GetSubscriptionsForUserQuery_getSubscriptionsForUser } from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'

export type Subscription = GetSubscriptionsForUserQuery_getSubscriptionsForUser & {
  defaultSubscriptionId: string
}

export enum SubscriptionPermission {
  Experts = 'experts',
  Science = 'science',
  SoSV = 'sosv',
  SoSVExperts = 'sosv-experts',
  SoSVScience = 'sosv-science',
  Digital = 'digital',
  AcceleratedInsights = 'accelerated-insights',
  PLI = 'pli',
  XLegacyModule = 'x-legacy-module',
}
