import { useRouter } from 'next/router'
import React, { RefObject } from 'react'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { StyledNavigationItemLi } from './styles'

interface NavigationItemProps {
  children: React.ReactElement | string
  isTouchable: boolean
  id?: string
  linkTo?: string
  isOpenInNewTab?: boolean
  isActive?: boolean
  hasNoAction?: boolean
  hasIcon?: boolean
  isInlineSmall?: boolean
  style?: Record<string, string>
  ariaLabel?: string
  tabIndex?: number
  navigationItemRef?: RefObject<HTMLLIElement>
  className?: string
  isDisabled?: boolean
  onClick?: () => void
}

export const NavigationItem = ({
  children,
  linkTo,
  isOpenInNewTab,
  isActive,
  hasNoAction,
  style,
  ariaLabel,
  hasIcon = true,
  tabIndex,
  navigationItemRef,
  isInlineSmall = false,
  isTouchable,
  isDisabled,
  className,
  id,
  onClick,
}: NavigationItemProps): JSX.Element => {
  const router = useRouter()
  const isSelected = (linkTo && router.pathname.indexOf(`/${id}`) === 0) || false
  const linkTarget = isOpenInNewTab ? '_blank' : undefined
  return (
    <StyledNavigationItemLi
      className={className}
      ref={navigationItemRef}
      style={style}
      hasNoAction={hasNoAction}
      isSelected={isSelected}
      hasIcon={hasIcon}
      isInlineSmall={isInlineSmall}
      isActive={isActive}
      isTouchable={isTouchable}
      isDisabled={isDisabled}
    >
      {linkTo ? (
        <CustomLink
          type="custom"
          onLinkClick={onClick}
          aria-label={ariaLabel}
          href={linkTo}
          tabIndex={tabIndex}
          target={linkTarget}
        >
          {children}
        </CustomLink>
      ) : (
        children
      )}
    </StyledNavigationItemLi>
  )
}
