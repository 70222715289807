import React from 'react'
import { MasonryGrid } from '~components/ui/templates/masonry-grid'
import { ExpertLoadingBlock } from './components/ExpertLoadingBlock'
import { ListLoadingBlocks } from './components/ListLoadingBlocks'
import styled from 'styled-components'
import { useExpertEcosystemContext } from '~common/providers'

const StyledTopGridItem = styled(MasonryGrid.Item)`
  padding: 0;
`

const StyledGridItem = styled(MasonryGrid.Item)`
  height: 35rem;
  padding: 0;
`

export const ExpertPageLoadingBlocks = ({
  displayOnlySections = false,
}: { displayOnlySections?: boolean } = {}): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <MasonryGrid.Container>
      {!displayOnlySections ? (
        <StyledTopGridItem itemWidth="widest" isTransparent={true}>
          <ExpertLoadingBlock isTransparent={true} />
        </StyledTopGridItem>
      ) : null}
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
        <StyledGridItem key={item} itemWidth="narrow">
          <ListLoadingBlocks id="page-loading-blocks" />
        </StyledGridItem>
      ))}
    </MasonryGrid.Container>
  )
}
