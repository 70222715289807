/* eslint-disable @typescript-eslint/no-var-requires */

const initMocks = async function () {
  if (typeof window === 'undefined') {
    const { server } = await import('./server')
    server.listen()
  } else {
    const { worker } = await import('./browser')
    await worker.start()
  }
}

await initMocks()

export {}
