import styled from 'styled-components'
import * as Highcharts from 'highcharts'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'
import { Flex } from '~common'

export const StyledExportWrapper = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 0.5rem 0;
`

export const StyledProductsMentionsTreemapChart = styled(StyledChart)<{
  productColors: string[]
  series: Highcharts.PointOptionsObject[]
}>`
  height: 12.5rem;
  .highcharts-graph {
    stroke-width: 2px;
  }
  .highcharts-point {
    stroke-width: 0;
  }
  .custom-products-mentions-treemap-chart {
    height: 12.5rem !important;
    .custom-data-label {
      font-family: ${({ theme }) => theme.fontFamily} !important;
      display: flex;
      flex-direction: column;
    }
    .custom-data-label-key {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 400;
    }
    .custom-data-label-value {
      line-height: 1.5rem;
      font-weight: 700;
    }
    & > svg {
      height: 12.5rem !important;
    }
  }
  ${({ productColors, series }) => getProductColorsCss({ productColors, series, isLineChart: true })};
`
