import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import * as Highcharts from 'highcharts'
import HighchartsStock from 'highcharts/highstock'
import { useWidgetAuth } from '~components/pages/widgets/use-widget-auth'
import { Content, defaultListLocalId } from '~components/pages/widgets/sosv/content'
import { useFrameMessageBus } from '~components/pages/widgets/use-frame-message-bus'
import { ChartLoadingBlock, ListLoadingBlocks, PageTitleBarLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { SoSVGrid } from '~components/ui/templates/sosv-grid'
import { FrameEvents, SoSVSource } from '~components/pages/widgets/sosv/types'
import { useUserSettings } from '~components/pages/widgets/sosv/hooks'
import { useConfig, useOutsideClick } from '~common/hooks'
import {
  StyledLoadingListContentBlock,
  StyledLoadingTitleBlock,
} from '~components/ui/atoms/loading-blocks/pages/styles'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { useRouter } from 'next/router'
import { useExpertEcosystemContext } from '~common/providers'
import {
  SoSVSettingsProvider,
  initialSoSVState,
  useSoSVStateDispatch,
} from '~components/pages/widgets/sosv/settings/settings'
import { MultiItemsInBoxGrid } from '~components/ui/templates/multi-items-inbox-grid'
import { BackArrowSvg, ControlsSvg } from '~common/svgs'
import {
  StyledFiltersSection,
  StyledHideFiltersButton,
  StyledMobileNavAndBackBarWrapper,
} from '~components/ui/sosv-hub-layout/styles'
import { PageTitleBar } from '~components/ui/organisms/page-title-bar'
import { StickyBackBar } from '~components/ui/molecules/sticky-back-bar'
import { Filters } from '~components/pages/widgets/sosv/Filters'

const SoSVContent: React.FC = () => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}
  const { listId: listIdFromUrl, source: sourceFromUrl } = useRouter().query as { listId?: string; source?: string }
  const { ENV } = useConfig()
  const { error, isAuthenticated, isLoading: isAuthenticationInProgress } = useWidgetAuth()
  const [listId, setListId] = useState<string | undefined>(
    listIdFromUrl ? listIdFromUrl : ENV === 'local' ? defaultListLocalId : undefined,
  )

  const [source, setSource] = useState<SoSVSource | undefined>(sourceFromUrl as SoSVSource)
  const {
    listId: listIdFromSettings,
    source: sourceFromSettings,
    listName,
    rootListId,
    rootListName,
    productGroupId,
    productGroups,
    drugSelection,
    topics,
    subscriptions,
    expertsCount,
    isLoading: isLoadingSettings,
  } = useUserSettings(isAuthenticated, listId, source)

  const allSettingsLoaded =
    [subscriptions, productGroupId, drugSelection, listId, listName, rootListId, rootListName].every(Boolean) &&
    !isLoadingSettings

  const frameMessageBus = useFrameMessageBus<FrameEvents>()

  useEffect(() => {
    setListId(listIdFromSettings)
  }, [listIdFromSettings])

  useEffect(() => {
    setSource(sourceFromSettings)
  }, [sourceFromSettings])

  useEffect(() => {
    if (listIdFromUrl) {
      setListId(listIdFromUrl)
    }
  }, [listIdFromUrl])

  useEffect(() => {
    if (sourceFromUrl) {
      setSource(sourceFromUrl as SoSVSource)
    }
  }, [sourceFromUrl])

  useEffect(() => {
    const unsubscribeOnListChange = frameMessageBus.on('changeList', (payload) => {
      setListId(payload.listId)
      setSource(payload.source)
    })

    return () => {
      unsubscribeOnListChange()
    }
  }, [frameMessageBus, setListId])

  useEffect(() => {
    if (window && window.parent && !isAuthenticationInProgress && (error || subscriptions?.length)) {
      frameMessageBus.emit(window.parent, 'authComplete', {
        isSuccess: isAuthenticated,
        error,
        lists: (subscriptions ?? []).map((s) => ({
          id: s.id,
          name: s.name,
          sources: s.permissions.reduce<SoSVSource[]>((acc, next) => {
            if (next === SubscriptionPermission.SoSVScience) {
              acc.push(SoSVSource.SCIENCE)
            } else if (next === SubscriptionPermission.SoSVExperts) {
              acc.push(SoSVSource.EXPERTS)
            }
            return acc
          }, []),
        })),
      })
    }
  }, [frameMessageBus, isAuthenticated, error, subscriptions, isAuthenticationInProgress])

  const loadingSoSVDetails = !!EELoadingComponent ? (
    <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  ) : (
    <article data-testid="sosv-widget-loading-blocks">
      <SoSVGrid.Container>
        <SoSVGrid.Item itemWidth="widest" isTransparent={true}>
          <PageTitleBarLoadingBlock isTransparent={true} />
        </SoSVGrid.Item>
        <SoSVGrid.Item itemWidth="widest">
          <StyledLoadingTitleBlock />
          <StyledLoadingListContentBlock height="180px" />
        </SoSVGrid.Item>
        <SoSVGrid.Item itemWidth="widest" isTransparent={true}>
          <ChartLoadingBlock id="page-loading-blocks" />
        </SoSVGrid.Item>
        {[1, 2].map((item) => (
          <SoSVGrid.Item key={item} itemWidth="narrow">
            <ListLoadingBlocks id="page-loading-blocks" />
          </SoSVGrid.Item>
        ))}
        <SoSVGrid.Item itemWidth="widest" isTransparent={true}>
          <ChartLoadingBlock id="page-loading-blocks" />
        </SoSVGrid.Item>
      </SoSVGrid.Container>
    </article>
  )

  const dispatchSoSVState = useSoSVStateDispatch()

  useEffect(() => {
    if (!dispatchSoSVState) return
    dispatchSoSVState({
      ...initialSoSVState,
      listId,
      listName,
      rootListId,
      rootListName,
      productGroupId,
      productGroups,
      drugSelection,
      topics,
      expertsCount,
      source: source ?? SoSVSource.EXPERTS,
      subscriptions,
    })
  }, [
    dispatchSoSVState,
    listId,
    source,
    listName,
    rootListId,
    rootListName,
    productGroupId,
    productGroups,
    drugSelection,
    topics,
    subscriptions,
    expertsCount,
  ])

  const isReadyToRenderContent = isAuthenticated && allSettingsLoaded

  const {
    ref: listPaneItemRef,
    state: isListPaneExpanded,
    action: setIsListPaneExpanded,
  } = useOutsideClick<HTMLElement>(false)

  return (
    <>
      <Head>
        <title>SoSV Widget | Pharmaspectra</title>
      </Head>
      <MultiItemsInBoxGrid.Container
        showBackdrop={isListPaneExpanded}
        onClickBackdrop={() => setIsListPaneExpanded(false)}
        childrenLength={2}
      >
        <MultiItemsInBoxGrid.Item
          data-testid="sosv-filters"
          itemWidth="wide"
          isExpanded={isListPaneExpanded}
          gridItemRef={listPaneItemRef}
          isOutOfFlow={true}
          withMinimise={isReadyToRenderContent}
          minimisedPaneIcon={<ControlsSvg />}
          onMinimise={() => {
            setIsListPaneExpanded(false)
            setTimeout(() => {
              for (let i = 0; i < HighchartsStock.charts.length; i++) {
                if (typeof HighchartsStock.charts[i] !== undefined) {
                  HighchartsStock?.charts?.[i]?.reflow()
                }
              }

              for (let i = 0; i < Highcharts.charts.length; i++) {
                if (typeof Highcharts.charts[i] !== undefined) {
                  Highcharts?.charts?.[i]?.reflow()
                }
              }
            }, 300)
          }}
        >
          {isReadyToRenderContent ? (
            <>
              <PageTitleBar
                asTag="header"
                isSticky={false}
                isFullContentView={false}
                isTitleAsSubtitle={true}
                titleBarHeadingText="Filters"
              />
              <StyledFiltersSection>
                <Filters />
                <StyledHideFiltersButton
                  size="md"
                  variant="secondary"
                  icon={<BackArrowSvg />}
                  onClick={() => setIsListPaneExpanded(false)}
                >
                  Hide filters
                </StyledHideFiltersButton>
              </StyledFiltersSection>
            </>
          ) : (
            <ListLoadingBlocks />
          )}
        </MultiItemsInBoxGrid.Item>
        <MultiItemsInBoxGrid.Item
          data-testid="sosv-details"
          onTouchStart={() => setIsListPaneExpanded(false)}
          isScrollable={!isListPaneExpanded}
          //onScroll={onScrollDetails}
          itemWidth="widest"
          backgroundVariation="primary"
        >
          <StyledMobileNavAndBackBarWrapper>
            <StickyBackBar
              isHidden={isListPaneExpanded}
              ctaText="Open filters"
              onClickBack={() => setIsListPaneExpanded(true)}
              isMobileWidthRestricted={false}
            />
          </StyledMobileNavAndBackBarWrapper>
          {isReadyToRenderContent ? <Content frameMessageBus={frameMessageBus} /> : loadingSoSVDetails}
        </MultiItemsInBoxGrid.Item>
      </MultiItemsInBoxGrid.Container>
    </>
  )
}

export const SoSV: React.FC = () => {
  return (
    <>
      <Head>
        <title>SoSV Widget | Pharmaspectra</title>
      </Head>
      <SoSVSettingsProvider>
        <SoSVContent />
      </SoSVSettingsProvider>
    </>
  )
}
