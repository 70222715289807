export const ANNA_ID = 'anna123'
export const ANNA_HCP_ID = '1231231234'
export const ANNA = 'Anna Vihrogonova'

export const TAMARA_ID = 'tam123'
export const TAMARA = 'Tamara Jordan'

export const ANTONIO_ID = 'antonio123'
export const ANTONIO = 'Antonio Terreno'

export const WOJCIECH_ID = 'wojciech123'
export const WOJCIECH = 'Wojciech Kowalski'

export const JOHN_ID = 'john123'
export const JOHN = 'John Smith'

export const LANTUS_PRODUCT_GROUP_ID = '123-123-123'
