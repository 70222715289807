import React, { createContext, useContext } from 'react'

export interface UserSession {
  idToken: {
    payload: {
      // https://stackoverflow.com/q/41828359/5362826
      'cognito:groups'?: string[]
      'cognito:username'?: string
      sub?: string
      email?: string
      given_name?: string
      family_name?: string
    }
  }
}

const SessionContext = createContext<UserSession | undefined>(undefined)

export interface PageRestrictionOpts {
  isPrototypePage: boolean
}

export const useSession = (): UserSession | undefined => {
  return useContext(SessionContext)
}

export const SessionProvider: React.FC<{ session: UserSession | undefined } & PageRestrictionOpts> = (props) => {
  const cognitoGroups = props.session?.idToken.payload['cognito:groups'] ?? []

  if (cognitoGroups.includes('PrototypeAccessOnly') && !props.isPrototypePage) {
    return <h1>Unauthorized access</h1>
  }

  return <SessionContext.Provider value={props.session}>{props.children}</SessionContext.Provider>
}
