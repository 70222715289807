import React from 'react'
import { useExpertEcosystemContext } from '~common/providers'
import { ExpertLoadingBlock } from './ExpertLoadingBlock'
import { PageTitleBarLoadingBlock } from './PageTitleBarLoadingBlock'

export const SubscriptionDetailsLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <>
      <PageTitleBarLoadingBlock isTransparent={true} />
      <ExpertLoadingBlock />
      <ExpertLoadingBlock />
      <ExpertLoadingBlock />
      <ExpertLoadingBlock />
      <ExpertLoadingBlock />
      <ExpertLoadingBlock />
    </>
  )
}
