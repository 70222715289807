import React from 'react'
import { useExpertEcosystemContext } from '~common/providers'
import { StyledChartLoadingBlock, StyledLoadingListContentBlock, StyledLoadingTitleBlock } from '../styles'

export const ChartLoadingBlock = ({ id, withNoPadding }: { id?: string; withNoPadding?: boolean }): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <StyledChartLoadingBlock data-testid={id} withNoPadding={withNoPadding}>
      <StyledLoadingTitleBlock />
      <StyledLoadingListContentBlock height="70%" />
    </StyledChartLoadingBlock>
  )
}
