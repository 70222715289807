import React, { useState } from 'react'
import { imgCache } from '~utils/img-cache-utils'
import styled from 'styled-components'

export const StyledImage = styled.img`
  width: auto;
  &:after {
    content: 'No Image';
  }
`

const StyledTag = styled.div`
  position: absolute;
  padding: 2px;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.always.alwaysOverlay};
  color: ${({ theme }) => theme.colors.textOn.textOnPrimary};
`

export interface CustomImageProps {
  src: string
  alt: string
  tag?: string
  style?: Record<string, unknown>
  isSuspense?: boolean
  fallbackSrc?: string
  fallbackAlt?: string
  className?: string
}

export const CustomImage: React.FC<CustomImageProps> = (props) => {
  const [hasError, setHasError] = useState(false)
  const { src, alt, tag, style, isSuspense, fallbackSrc, fallbackAlt, className } = props
  if (isSuspense) imgCache.read(src)

  return (
    <>
      <StyledImage
        alt={hasError && fallbackAlt ? fallbackAlt : alt}
        src={hasError && fallbackSrc ? fallbackSrc : src}
        style={style}
        onError={() => !hasError && setHasError(true)}
        className={className}
      />
      {tag ? <StyledTag>{tag}</StyledTag> : <></>}
    </>
  )
}
