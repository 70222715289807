import React from 'react'
import { ListLoadingBlocks } from './components/ListLoadingBlocks'
import { SoSVGrid } from '~components/ui/templates/sosv-grid'
import { ChartLoadingBlock } from './components/ChartLoadingBlock'
import { useExpertEcosystemContext } from '~common/providers'

export const SoSVInboxPageLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <article data-testid="sosv-inbox-abstract-loading-blocks">
      <SoSVGrid.Container>
        <SoSVGrid.Item itemWidth="widest">
          <ChartLoadingBlock id="page-loading-blocks" />
        </SoSVGrid.Item>
        {[1, 2].map((item) => (
          <SoSVGrid.Item key={item} itemWidth="narrow">
            <ListLoadingBlocks id="page-loading-blocks" />
          </SoSVGrid.Item>
        ))}
      </SoSVGrid.Container>
    </article>
  )
}
