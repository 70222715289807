import { TooltipFormatterContextObject } from 'highcharts'
import React from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { StyledPhaseInvolvementChart } from './styles'
import { usePhaseInvolvementOptions } from './use-phase-involvement-chart-options'
import { Mode, StyledScreenReadersOnly } from '~common'

export interface PhaseInvolvementChartProps {
  series: {
    name: string
    data: {
      y: number
    }[]
    type: string
    className?: string
  }[]
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const PhaseInvolvementChart = ({ series, themeMode, themeBrand }: PhaseInvolvementChartProps): JSX.Element => {
  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">{context.series.name}</span> <span className="value">{context.y}%</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const [options] = usePhaseInvolvementOptions(
    series as Array<Highcharts.SeriesOptionsType>,
    themeBrand,
    toolTipFormatter,
  )

  return (
    <StyledPhaseInvolvementChart>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <StyledScreenReadersOnly>Phase involvement bar chart</StyledScreenReadersOnly>
    </StyledPhaseInvolvementChart>
  )
}
