import { gql } from '@apollo/client'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { GetExpertNameQuery, GetExpertNameQueryVariables } from './__generated__/GetExpertNameQuery'

export const GET_EXPERT_NAME = gql`
  query GetExpertNameQuery($profileId: String!, $listId: String!) {
    getProfileByIdFromDDB(profileId: $profileId, listId: $listId) {
      ... on ProfileType {
        fullName
        profileId
        __typename
      }
      ... on ProfileLockedStubType {
        fullName
        __typename
      }
    }
  }
`

export const getExpertNameHandler: GraphqlHandler<GetExpertNameQueryVariables, GetExpertNameQuery> = (
  req,
  res,
  ctx,
) => {
  const response: GetExpertNameQuery = {
    getProfileByIdFromDDB: {
      fullName: 'Anna Vihrogonova',
      profileId: 'anna-id',
      __typename: 'ProfileType',
    },
  }
  return res(ctx.data(response))
}
