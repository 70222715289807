import { gql } from '@apollo/client'
import { calculateMentionsShare, JournalBuilder } from '~apollo'
import {
  GetSoSVJournalArticlesQuery,
  GetSoSVJournalArticlesQueryVariables,
} from '~apollo/queries/sosv/journal-articles/__generated__/GetSoSVJournalArticlesQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { arrayOf, randomFloat, randomNumber } from '~testing/test-helpers'

export const GET_SOSV_JOURNAL_ARTICLES = gql`
  query GetSoSVJournalArticlesQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $geographyFilter: GeographyFilter
    $journalId: String
    $pageSize: Int!
    $currentPage: Int!
    $savedListId: String
    $smartListId: String
    $source: String
  ) {
    getSoSVJournalArticles(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      geographyFilter: $geographyFilter
      journalId: $journalId
      pageSize: $pageSize
      currentPage: $currentPage
      savedListId: $savedListId
      smartListId: $smartListId
      source: $source
    ) {
      id
      journalTitle
      articleTitle
      date
      abstractType
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
      pmid
    }
  }
`

const dummyJournalArticles = [
  JournalBuilder.random({
    articleTitle: 'Wanted Article 1',
    id: 'wanted-article-1',
    abstract: [{ heading: null, text: 'Wanted Abstract 1' }],
  }),
  ...arrayOf((i) => JournalBuilder.random({ id: `random-article-${i}` }), 50),
  JournalBuilder.random({
    articleTitle: 'Wanted Article 2',
    id: 'wanted-article-2',
    abstract: [{ heading: null, text: 'Wanted Abstract 2' }],
  }),
]

export const soSVJournalArticlesMock: GetSoSVJournalArticlesQuery = {
  getSoSVJournalArticles: dummyJournalArticles.map((el) => ({
    ...el,
    mentions: calculateMentionsShare([
      { count: randomNumber(), countWeighted: randomNumber(), productName: 'Lantus' },
      {
        productName: 'Glooko',
        count: randomNumber(),
        countWeighted: randomFloat(1, 100),
      },
      {
        productName: 'Wanted',
        count: randomNumber(),
        countWeighted: randomFloat(1, 100),
      },
    ]),
  })),
}

export const getSoSVJournalArticlesQueryHandler: GraphqlHandler<
  GetSoSVJournalArticlesQueryVariables,
  GetSoSVJournalArticlesQuery
> = (req, res, ctx) => {
  return res(ctx.data(soSVJournalArticlesMock))
}
