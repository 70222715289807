import React from 'react'
import styled from 'styled-components'
import { smartTrim } from '~common'

const StyledPairs = styled.ul`
  ${({ theme }) => theme.textSizes.desktop.bodyS}
  list-style-type: disc;
  margin-left: 1.625rem;
  & > li:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const StyledPair = styled.p`
  ${({ theme }) => theme.textSizes.desktop.bodyS}
`

const StyledKey = styled.em`
  font-weight: 600;
  font-style: normal;
`

interface KeyValuePair {
  key: string
  value: string | React.ReactNode
  isTruncating?: boolean
}

export interface KeyValuePairsProps extends React.HTMLAttributes<HTMLParagraphElement> {
  pairs: KeyValuePair[]
  keyValueDivider?: ':' | ','
}

export interface KeyValuePairsUListProps extends React.HTMLAttributes<HTMLUListElement> {
  pairs: KeyValuePair[]
  keyValueDivider?: ':' | ','
}

export const KeyValuePairs = ({ pairs, keyValueDivider = ':', ...rest }: KeyValuePairsProps): JSX.Element => (
  <>
    {pairs.length > 1 ? (
      <StyledPairs {...(rest as KeyValuePairsUListProps)}>
        {pairs.map((keyValuePair: KeyValuePair, i) => (
          <li key={i}>
            {keyValuePair.key && <StyledKey>{`${keyValuePair.key}${keyValueDivider} `}</StyledKey>}
            {keyValuePair.isTruncating && typeof keyValuePair.value === 'string'
              ? smartTrim(keyValuePair.value, 175)
              : keyValuePair.value}
          </li>
        ))}
      </StyledPairs>
    ) : (
      <StyledPair {...rest}>
        {pairs[0].key && <StyledKey>{`${pairs[0].key}${keyValueDivider} `}</StyledKey>}
        {pairs[0].isTruncating && typeof pairs[0].value === 'string' ? smartTrim(pairs[0].value, 175) : pairs[0].value}
      </StyledPair>
    )}
  </>
)
