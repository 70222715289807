import { TooltipFormatterContextObject } from 'highcharts'
import { getCommonChartOptions, getCommonExportChartOptions } from '../../common-charts-options'
import { getProductColors, GraphType, roundDecimals } from '~common'
import { CustomPointOptionsObject } from './ProductsMentionsTreemapChart'
import { ExportChartConfig } from '../../types'
import { generateExportChartFileName, generateExportChartSourceText, generateExportChartTitleText } from '../../helpers'

type SeriesTreemapOptions = Highcharts.SeriesTreemapOptions & { legendType: string }

export const useProductsMentionsTreemapChartOptions = ({
  data,
  categories,
  totalMentions,
  exportChartConfig,
  graphType,
  series,
  themeBrand,
  toolTipFormatter,
}: {
  data: SeriesTreemapOptions[]
  categories: (number | string)[]
  totalMentions: number
  exportChartConfig?: ExportChartConfig
  graphType: GraphType
  series: CustomPointOptionsObject[]
  themeBrand: 'pharmaspectra' | 'iqvia'
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): [Highcharts.Options] => {
  const commonExportOptions = getCommonExportChartOptions(data)
  const commonChartOptions = getCommonChartOptions(categories)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    exporting: {
      ...(exportChartConfig
        ? {
            ...commonExportOptions,
            chartOptions: {
              ...commonExportOptions.chartOptions,
              title: {
                ...commonExportOptions.chartOptions?.title,
                text: generateExportChartTitleText({ exportChartConfig, isTotal: true }),
              },
              subtitle: {
                ...commonExportOptions.chartOptions?.subtitle,
                text: generateExportChartSourceText({ exportChartConfig, series: series.reverse(), isTotal: true }),
              },
              chart: {
                ...commonExportOptions.chartOptions?.chart,
                marginLeft: 50,
                marginRight: 50,
              },
            },
            filename: generateExportChartFileName({ exportChartConfig, isTotal: true }),
          }
        : { enabled: false }),
    },
    chart: {
      ...commonChartOptions.chart,
      marginLeft: 0,
      reflow: true,
      className: 'custom-products-mentions-treemap-chart',
    },
    colors: getProductColors(data.length, themeBrand),
    tooltip: {
      ...commonChartOptions.tooltip,
      distance: 30,
      style: {
        width: 300,
      },
      useHTML: true,
      outside: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderWidth: 0,
      },
      treemap: {
        dataLabels: {
          enabled: true,
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          style: {
            color: 'white',
            textOverflow: 'allow',
          },
          formatter: function () {
            const value = this.point.options.value || 0
            const percentage = Number((value / totalMentions) * 100)
            const graphTypeDataLabel =
              graphType === 'counts' ? (value != 0 ? roundDecimals(value, 1) : '') : `${roundDecimals(percentage, 1)}%`
            const extendedPoint: CustomPointOptionsObject = {
              ...this.point,
              value,
            }
            const fontSize =
              extendedPoint.shapeArgs &&
              (getTextWidth(this.key) >= extendedPoint.shapeArgs.width || extendedPoint.shapeArgs?.height < 40)
                ? '12px'
                : '20px'
            const display =
              extendedPoint.shapeArgs &&
              (getTextWidth(this.key) >= extendedPoint.shapeArgs.width || extendedPoint.shapeArgs?.height < 40)
                ? 'none'
                : 'block'
            return `<div class="custom-data-label"><span class="custom-data-label-key" style="display:${display}">${this.key}</span>
              <span class="custom-data-label-value" style="font-size:${fontSize}">${graphTypeDataLabel}</span></div>`
          },
        },
      },
    },
    series: data,
  }

  return [options]
}

const getTextWidth = (text?: string, font?: string) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font || getComputedStyle(document.body).font
    return context.measureText(text || '').width
  }
  return 0
}
