import styled from 'styled-components'
import { flexMixin } from '~common'

export const StyledCustomProductMentionContent = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
  opacity: 1;
  transition: all 0.05s ease 0.05s;
`

export const StyledCustomProductsMentionsBarChart = styled.div`
  height: 24px;
  width: 100%;
  ${flexMixin}
  &:hover {
    ${StyledCustomProductMentionContent} {
      opacity: 0.2;
    }
  }
`

export const StyledCustomProductMention = styled.div<{ elementWidth: number }>`
  width: ${({ elementWidth }) => `${elementWidth}%`};
  position: relative;
  cursor: pointer;
  &:hover {
    ${StyledCustomProductMentionContent} {
      opacity: 0.4;
    }
  }
  &:before {
    content: ' ';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0.375rem;
    top: -30%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    border-top-color: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
  }
  &:hover:before {
    opacity: 1;
    transition: all 0.05s ease 0.05s;
    visibility: visible;
  }
  &:hover:after {
    opacity: 1;
    transition: all 0.05s ease 0.05s;
    visibility: visible;
  }
  &:after {
    content: attr(title);
    ${({ theme }) => theme.textSizes.desktop.label}
    color: ${({ theme }) => theme.colors.textOn.textOnInverseSecondary};
    background: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
    position: absolute;
    padding: 0.3125rem 0.25rem;
    left: 50%;
    border-radius: 0.09375rem;
    transform: translateX(-50%);
    line-height: 1;
    top: -120%;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
  }
`
