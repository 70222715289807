import { gql } from '@apollo/client'
import {
  GetSoSVMentionsBreakdownForProductLaunchQuery,
  GetSoSVMentionsBreakdownForProductLaunchQueryVariables,
} from '~apollo/queries/sosv/mentions-breakdown/__generated__/GetSoSVMentionsBreakdownForProductLaunchQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import {
  GetSoSVMentionsBreakdownQuery,
  GetSoSVMentionsBreakdownQueryVariables,
} from './__generated__/GetSoSVMentionsBreakdownQuery'

export const GET_SOSV_MENTIONS_BREAKDOWN = gql`
  query GetSoSVMentionsBreakdownQuery(
    $listId: String!
    $timeframe: String
    $startDate: String
    $endDate: String
    $productGroupId: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $contentTypes: [String!]!
    $journalId: String
    $meetingId: String
    $societyId: String
    $geographyFilter: GeographyFilter
    $source: String
    $savedListId: String
    $smartListId: String
  ) {
    getSoSVMentionsBreakdown(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      contentTypes: $contentTypes
      journalId: $journalId
      meetingId: $meetingId
      societyId: $societyId
      geographyFilter: $geographyFilter
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      productName
      counts
      countsWeighted
      percentages
      percentagesWeighted
      cumulativeCounts
      cumulativeCountsWeighted
      cumulativePercentages
      cumulativePercentagesWeighted
    }
  }
`

export const GET_SOSV_MENTIONS_BREAKDOWN_FOR_PRODUCT_LAUNCH = gql`
  query GetSoSVMentionsBreakdownForProductLaunchQuery(
    $listId: String!
    $productGroupId: String
    $drugSelection: [String!]!
    $contentTypes: [String!]!
    $source: String
    $breakDownBy: String
  ) {
    getSoSVMentionsBreakdownForProductLaunch(
      listId: $listId
      productGroupId: $productGroupId
      drugSelection: $drugSelection
      contentTypes: $contentTypes
      source: $source
      breakDownBy: $breakDownBy
    ) {
      productName
      launchDate
      counts
      countsWeighted
    }
  }
`

export const soSVMentionsBreakdownMock: GetSoSVMentionsBreakdownQuery = {
  getSoSVMentionsBreakdown: [
    {
      counts: [3, 3, 8, 4, 1, 4, 6, 3, 3, 0],
      countsWeighted: [3.4, 9.11, 14.2, 4.566, 1.11, 4.88, 20.33, 3, 3, 0],
      cumulativeCounts: [3, 6, 14, 18, 19, 23, 29, 32, 35, 35],
      cumulativeCountsWeighted: [3, 6, 14, 18, 19, 23, 29, 32, 35, 35],
      cumulativePercentages: [9.4, 10.5, 14.6, 15.1, 12.5, 11.4, 11.7, 11.7, 12.3, 12.3],
      cumulativePercentagesWeighted: [9.4, 10.5, 14.6, 15.1, 12.5, 11.4, 11.7, 11.7, 12.3, 12.3],
      percentages: [9.4, 12, 20.5, 17.4, 3, 8, 13, 12, 27.3, 0],
      percentagesWeighted: [9.4, 12, 20.5, 17.4, 3, 8, 13, 12, 27.3, 0],
      productName: 'Lantus',
    },
    {
      counts: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
      countsWeighted: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
      cumulativeCounts: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
      cumulativeCountsWeighted: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
      cumulativePercentages: [0, 0, 0, 0, 0, 0.5, 0.4, 0.4, 0.4, 0.4],
      cumulativePercentagesWeighted: [0, 0, 0, 0, 0, 0.5, 0.4, 0.4, 0.4, 0.4],
      percentages: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
      percentagesWeighted: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
      productName: 'Glooko',
    },
    {
      counts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      countsWeighted: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cumulativeCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cumulativeCountsWeighted: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cumulativePercentages: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cumulativePercentagesWeighted: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      percentages: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      percentagesWeighted: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      productName: 'Soliqua',
    },
    {
      counts: [3, 3, 8, 4, 1, 4, 6, 3, 3, 0],
      countsWeighted: [3, 3, 8, 4, 1, 4, 6, 3, 3, 0],
      cumulativeCounts: [3, 6, 14, 18, 19, 23, 29, 32, 35, 35],
      cumulativeCountsWeighted: [3, 6, 14, 18, 19, 23, 29, 32, 35, 35],
      cumulativePercentages: [9.4, 10.5, 14.6, 15.1, 12.5, 11.4, 11.7, 11.7, 12.3, 12.3],
      cumulativePercentagesWeighted: [9.4, 10.5, 14.6, 15.1, 12.5, 11.4, 11.7, 11.7, 12.3, 12.3],
      percentages: [9.4, 12, 20.5, 17.4, 3, 8, 13, 12, 27.3, 0],
      percentagesWeighted: [9.4, 12, 20.5, 17.4, 3, 8, 13, 12, 27.3, 0],
      productName: 'Toujeo',
    },
    {
      counts: [8, 6, 7, 4, 1, 7, 9, 3, 2, 0],
      countsWeighted: [8, 6, 7, 4, 1, 7, 9, 3, 2, 0],
      cumulativeCounts: [8, 14, 21, 25, 26, 33, 42, 45, 47, 47],
      cumulativeCountsWeighted: [8, 14, 21, 25, 26, 33, 42, 45, 47, 47],
      cumulativePercentages: [25, 24.6, 21.9, 21, 17.1, 16.3, 16.9, 16.5, 16.5, 16.5],
      cumulativePercentagesWeighted: [25, 24.6, 21.9, 21, 17.1, 16.3, 16.9, 16.5, 16.5, 16.5],
      percentages: [25, 24, 17.9, 17.4, 3, 14, 19.6, 12, 18.2, 0],
      percentagesWeighted: [25, 24, 17.9, 17.4, 3, 14, 19.6, 12, 18.2, 0],
      productName: 'Tresiba',
    },
    {
      counts: [11, 7, 9, 8, 30, 34, 25, 16, 3, 1],
      countsWeighted: [11, 7, 9, 8, 30, 34, 25, 16, 3, 1],
      cumulativeCounts: [11, 18, 27, 35, 65, 99, 124, 140, 143, 144],
      cumulativeCountsWeighted: [11, 18, 27, 35, 65, 99, 124, 140, 143, 144],
      cumulativePercentages: [34.4, 31.6, 28.1, 29.4, 42.8, 49, 50, 51.3, 50.4, 50.5],
      cumulativePercentagesWeighted: [34.4, 31.6, 28.1, 29.4, 42.8, 49, 50, 51.3, 50.4, 50.5],
      percentages: [34.4, 28, 23.1, 34.8, 90.9, 68, 54.3, 64, 27.3, 100],
      percentagesWeighted: [34.4, 28, 23.1, 34.8, 90.9, 68, 54.3, 64, 27.3, 100],
      productName: 'Victoza',
    },
    {
      counts: [7, 6, 7, 3, 0, 0, 0, 0, 0, 0],
      countsWeighted: [7, 6, 7, 3, 0, 0, 0, 0, 0, 0],
      cumulativeCounts: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
      cumulativeCountsWeighted: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
      cumulativePercentages: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
      cumulativePercentagesWeighted: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
      percentages: [21.9, 24, 17.9, 13, 0, 0, 0, 0, 0, 0],
      percentagesWeighted: [21.9, 24, 17.9, 13, 0, 0, 0, 0, 0, 0],
      productName: 'Xultophy',
    },
  ],
}

export const soSVMentionsBreakdownMockForProductLaunch = {
  getSoSVMentionsBreakdownForProductLaunch: [
    {
      productName: 'Lantus',
      launchDate: '2018-01-16T22:00:00.000Z',
      counts: [
        -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 3, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, 5, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 2, -1, 1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 4, -1, 1, 1, -1, -1, -1, -1, -1, -1, 1, -1, -1, 1, 7, -1, -1, -1, -1, 24, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, 2, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3, 1, -1, -1, -1, -1, -1, -1, 9, -1,
        -1, -1, 1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, 7,
        -1, -1, -1, 9, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1,
        -1, 2, -1, -1, -1, -1, -1, -1, 3, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1,
        -1, -1, 3, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, 10, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 14, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1, 2, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 1, -1, 2, 2, -1, 1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1,
        -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 5, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1, -1, -1,
        1, 2, -1, -1, 4, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 1, 1, -1, -1, 1, 1, -1, 8, 1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 1, 3, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1,
        -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 15, -1,
        -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, 1, -1, -1, -1, -1, -1, -1, 1,
        -1, -1, -1, 6, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, 1, -1,
        -1, -1, 2, -1, 1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 5, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 5, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 2, -1, -1, -1, -1, 5, -1, -1, 6, -1, 1,
        1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3,
        -1, -1, 1, -1, -1, 1, -1, -1, -1, 5, 1, -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, 1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 3, 1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 3, -1, -1, -1, -1, -1, 6, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 22, -1, 1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1,
        -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, 2, 3, -1,
        -1, -1, -1, -1, 1, 1, -1, 3, -1, 2, -1, 6, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, 2, -1, 2,
        -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, 2, 1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, 1, -1, 6, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, 1, -1, 2, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, 9, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, 7, -1, -1, -1, -1, -1, -1, -1, -1, 15, -1, -1, 1, -1, -1, -1, 2,
        2, -1, 1, -1, -1, -1, -1, -1, 2, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, 1, -1, -1, -1,
        -1, 2, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 19, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, 6, -1, -1, -1, -1, 1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 2, -1, 1, -1, -1, -1, -1, -1, -1, -1, 1, 3, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, 2, -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, 1, -1, -1, -1, -1, -1, 3, -1, -1,
        -1, 2, -1, -1, 2, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1,
        6, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, 1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, 1, 2, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 5, -1, 1,
        -1, 2, -1, -1, 2, -1, -1, -1, -1, 9, -1, -1, -1, -1, -1, -1, -1, -1, -1, 7, -1, 1, -1, -1, -1, -1, 1, -1, 1, -1,
        -1, -1, -1, -1, -1, 3, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, 2,
        -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 22, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, 9, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 2, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1,
        -1, -1, -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1, 7, -1, -1, -1, -1, 1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, 3, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 11, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, 8, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1,
        -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1,
        -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 15, -1,
        1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 6, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, 2, -1,
        2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 7, -1,
        -1, -1, -1, 1, -1, 1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1,
        -1, 4, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, 2, -1, -1, 3, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1,
        -1, -1, -1, -1, -1, -1, 1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, 3, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, 2, 3, -1, -1, -1, -1, -1, -1, 2, -1, -1, 9, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, 2, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, 1, -1, -1, -1, 1, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 20, -1, -1, -1, -1, -1, -1, -1, -1,
        1, -1, -1, -1, -1, -1, -1, 7, 1, -1, -1, -1, -1, -1, -1, 1, -1, 1, -1, -1, -1, -1, 3, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3, -1, -1, -1, -1, 1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 5, 1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        3, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 4, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, 1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, 20, -1, 1, -1, -1, -1, -1, -1, -1, -1, 1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 4, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 3, -1,
        -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, 1, -1, -1, -1, -1, -1, 1, 3,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, 3, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1,
        -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, 4, 1, -1, -1, -1, -1, 1, -1, -1, -1, 1, 1, -1, 1, -1, -1, -1, 5, -1, -1, -1, -1, -1,
        1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, 1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1,
        1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, 1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1,
        -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 2, 1, -1, 1, -1,
        -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, 2, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, 1, 2, -1, 2, -1, -1, -1, -1, -1, -1, -1, 1, -1,
        -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
      ],
      countsWeighted: [
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, -1.0, 0.1, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, -1.0, -1.0, -1.0, 0.8, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2,
        -1.0, -1.0, -1.0, -1.0, 2.9, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.2, 1.2, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.0, -1.0, 0.8, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0,
        -1.0, -1.0, 1.0, 0.9, -1.0, -1.0, -1.0, -1.0, 19.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.4, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, -1.0, -1.0, 0.2, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.9, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 1.2, -1.0, -1.0, -1.0, 0.2, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.1, -1.0, -1.0, -1.0, 0.6, -1.0, -1.0, -1.0, 6.8, -1.0, -1.0,
        -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        0.2, -1.0, -1.0, -1.0, -1.0, 0.8, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.5, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 3.5, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 0.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 0.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 6.7, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.8, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, -1.0,
        2.0, 0.4, -1.0, 0.1, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, -1.0, 1.2, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, 3.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 1.9, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 2.0,
        -1.0, -1.0, 4.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        0.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 1.0, -1.0, -1.0, 0.6, 1.0, -1.0, 7.1, 1.0, -1.0,
        -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, 0.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 6.8, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, 1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0,
        1.2, -1.0, 0.1, -1.0, 0.4, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.2, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.5, -1.0, -1.0, -1.0, 0.1, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, 2.0, -1.0, -1.0, -1.0, -1.0, 3.5, -1.0, -1.0, 4.8, -1.0, 1.0,
        1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.2,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.0, -1.0,
        -1.0, 1.0, -1.0, -1.0, 1.4, -1.0, -1.0, -1.0, 3.7, 1.2, -1.0, 2.9, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 5.0, 1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, 3.0,
        1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.8, -1.0, -1.0, -1.0, -1.0,
        -1.0, 3.0, -1.0, -1.0, -1.0, -1.0, -1.0, 13.8, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 40.9, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 6.2, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.1, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 0.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 6.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, -1.0, -1.0,
        -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, 2.2, 3.9, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 0.5, -1.0, 1.6, -1.0, 2.0,
        -1.0, 6.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 2.0, -1.0,
        1.5, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.9, 1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 1.2, -1.0, 5.3, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.2, -1.0, 1.1, -1.0,
        -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 5.5, 7.2, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.0, 7.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 15.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, 2.0, 2.0, -1.0, 1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 3.7, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.8, -1.0, -1.0,
        1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.9, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 19.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, 5.3,
        -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 6.1,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.1, -1.0, 0.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 3.0, -1.0, 1.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 2.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.5, 3.5, 1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, 3.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, 1.3, -1.0, -1.0, -1.0, -1.0, -1.0, 3.0, -1.0, -1.0, -1.0, 2.0, -1.0,
        -1.0, 1.5, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.5, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 1.2, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        4.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 5.2, -1.0, 1.7, -1.0,
        2.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, 9.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 7.0,
        -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, 0.5, -1.0, 0.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.0, -1.0, -1.0, 1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0,
        1.9, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 31.3, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 1.0, -1.0, -1.0, 9.0, -1.0, -1.0, 1.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 2.9, -1.0, 1.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.5, -1.0, -1.0, -1.0, 3.6, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 5.8, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        1.5, -1.0, -1.0, -1.0, 7.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, 1.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.6, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.3, -1.0, 1.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 10.5, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 8.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.4, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.8, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 24.3, -1.0, 2.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        7.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.3, -1.0, -1.0, -1.0, -1.0, 3.0, -1.0, 2.9, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 6.5, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, 1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.5, -1.0,
        -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, 3.0, 0.5, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 2.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 6.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, 3.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.2, -1.0, 2.0, 3.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, 8.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 1.6, -1.0, -1.0, -1.0, -1.0, -1.0, 1.3, 1.0, -1.0, -1.0, -1.0, 3.7, 1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 22.4, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.7,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 6.5, 2.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, 0.1, -1.0, -1.0,
        -1.0, -1.0, 3.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 2.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.4, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.9, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, -1.0, -1.0, -1.0, -1.0, 4.5, 0.1, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.5, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 2.5, -1.0, -1.0, 1.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.7, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.9, -1.0, -1.0, -1.0, -1.0, 0.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, 28.7,
        -1.0, 0.8, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.3, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.8, -1.0, 1.4, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.8, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.4, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.9, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.6,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.4, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, 3.1, 3.1, -1.0, -1.0, -1.0, -1.0, -1.0, 1.1, 4.9, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 2.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.1, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 4.3, 1.0, -1.0, -1.0, -1.0, -1.0, 0.3, -1.0, -1.0, -1.0, 1.0,
        2.3, -1.0, 2.1, -1.0, -1.0, -1.0, 4.5, -1.0, -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 2.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 3.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 3.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        4.4, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.7, -1.0, -1.0, -1.0, -1.0, 1.5, -1.0, -1.0, -1.0, -1.0, 2.1, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 2.0, 1.1, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.7, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, 1.6, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.5, 2.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.6, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.1, 1.1,
        -1.0, 1.7, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.5, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 7.2, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, 1.5, -1.0, 1.5, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 0.1, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, 1.0, -1.0, -1.0,
        -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0, -1.0,
      ],
    },
  ],
}

export const getSoSVMentionsBreakdownQueryHandler: GraphqlHandler<
  GetSoSVMentionsBreakdownQueryVariables,
  GetSoSVMentionsBreakdownQuery
> = (req, res, ctx) => {
  if (req.variables.listId === 'child-sub-1') {
    return res(ctx.data({ getSoSVMentionsBreakdown: [] }))
  }

  if (req.variables.listId === 'grandchild-sub') {
    return res(
      ctx.data({
        getSoSVMentionsBreakdown: [
          {
            counts: [3, 3, 8, 4, 1, 4, 6, 3, 3, 0],
            countsWeighted: [3, 3, 8, 4, 1, 4, 6, 3, 3, 0],
            cumulativeCounts: [3, 6, 14, 18, 19, 23, 29, 32, 35, 35],
            cumulativeCountsWeighted: [3, 6, 14, 18, 19, 23, 29, 32, 35, 35],
            cumulativePercentages: [9.4, 10.5, 14.6, 15.1, 12.5, 11.4, 11.7, 11.7, 12.3, 12.3],
            cumulativePercentagesWeighted: [9.4, 10.5, 14.6, 15.1, 12.5, 11.4, 11.7, 11.7, 12.3, 12.3],
            percentages: [9.4, 12, 20.5, 17.4, 3, 8, 13, 12, 27.3, 0],
            percentagesWeighted: [9.4, 12, 20.5, 17.4, 3, 8, 13, 12, 27.3, 0],
            productName: 'Lantus',
          },
        ],
      }),
    )
  }

  if (!req.variables.contentTypes.includes('journal-article') && !req.variables.contentTypes.includes('presentation')) {
    return res(ctx.data({ getSoSVMentionsBreakdown: [] }))
  }

  if (!req.variables.contentTypes.includes('journal-article')) {
    return res(
      ctx.data({
        getSoSVMentionsBreakdown: [
          {
            counts: [18, 6, 7, 20, 10, 10, 9, 2, 16, 3],
            countsWeighted: [18, 6, 7, 20, 10, 10, 9, 2, 16, 3],
            percentages: [49, 37, 30, 35, 34, 34, 27.1, 36.4, 32.7, 33.3],
            percentagesWeighted: [49, 37, 30, 35, 34, 34, 27.1, 36.4, 32.7, 33.3],
            cumulativeCounts: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativeCountsWeighted: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativePercentages: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            cumulativePercentagesWeighted: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            productName: 'Lantus',
          },
        ],
      }),
    )
  }

  if (!req.variables.contentTypes.includes('presentation')) {
    return res(
      ctx.data({
        getSoSVMentionsBreakdown: [
          {
            counts: [18, 6, 7, 20, 10, 10, 9, 2, 16, 3],
            countsWeighted: [18, 6, 7, 20, 10, 10, 9, 2, 16, 3],
            percentages: [49, 37, 30, 35, 34, 34, 27.1, 36.4, 32.7, 33.3],
            percentagesWeighted: [49, 37, 30, 35, 34, 34, 27.1, 36.4, 32.7, 33.3],
            cumulativeCounts: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativeCountsWeighted: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativePercentages: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            cumulativePercentagesWeighted: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            productName: 'Lantus',
          },
        ],
      }),
    )
  }

  if (req.variables.timeframe === 'oneYear') {
    return res(
      ctx.data({
        getSoSVMentionsBreakdown: [
          {
            counts: [18, 6, 7, 20, 10, 10, 9, 2, 16, 3],
            countsWeighted: [18, 6, 7, 20, 10, 10, 9, 2, 16, 3],
            percentages: [49, 37, 30, 35, 34, 34, 27.1, 36.4, 32.7, 33.3],
            percentagesWeighted: [49, 37, 30, 35, 34, 34, 27.1, 36.4, 32.7, 33.3],
            cumulativeCounts: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativeCountsWeighted: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativePercentages: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            cumulativePercentagesWeighted: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            productName: 'Lantus',
          },
          {
            counts: [0, 0, 0, 0, 0, 3, 1, 0, 0, 0],
            countsWeighted: [0, 0, 0, 0, 0, 3, 1, 0, 0, 0],
            percentages: [0, 0, 0, 0, 0, 5.2, 1.4, 0, 0, 0],
            percentagesWeighted: [0, 0, 0, 0, 0, 5.2, 1.4, 0, 0, 0],
            cumulativeCounts: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativeCountsWeighted: [7, 13, 20, 23, 23, 23, 23, 23, 23, 23],
            cumulativePercentages: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            cumulativePercentagesWeighted: [21.9, 22.8, 20.8, 19.3, 15.1, 11.4, 9.3, 8.4, 8.1, 8.1],
            productName: 'Glooko',
          },
        ],
      }),
    )
  }

  if (req.variables.savedListId) {
    return res(
      ctx.data({
        ...soSVMentionsBreakdownMock,
        getSoSVMentionsBreakdown: soSVMentionsBreakdownMock.getSoSVMentionsBreakdown.slice(0, 2),
      }),
    )
  }

  return res(ctx.data(soSVMentionsBreakdownMock))
}

export const getSoSVMentionsBreakdownQueryHandlerForProductLaunch: GraphqlHandler<
  GetSoSVMentionsBreakdownForProductLaunchQueryVariables,
  GetSoSVMentionsBreakdownForProductLaunchQuery
> = (req, res, ctx) => {
  if (req.variables.breakDownBy === 'week') {
    return res(
      ctx.data({
        getSoSVMentionsBreakdownForProductLaunch: [
          {
            productName: 'Lantus',
            launchDate: '2018-01-16T22:00:00.000Z',
            counts: [
              0, 1, 2, 0, 0, 1, 3, 1, 0, 0, 0, 7, 0, 1, 0, 5, 0, 4, 2, 9, 24, 2, 2, 0, 4, 0, 0, 2, 2, 0, 0, 4, 9, 1, 1,
              1, 2, 16, 1, 2, 1, 2, 5, 0, 0, 4, 4, 1, 0, 0, 10, 0, 0, 0, 2, 0, 0, 1, 14, 2, 0, 0, 0, 4, 3, 0, 1, 5, 2,
              0, 1, 2, 2, 0, 4, 0, 6, 0, 0, 0, 5, 0, 0, 0, 0, 4, 7, 0, 0, 2, 2, 11, 1, 4, 0, 2, 0, 0, 5, 0, 1, 0, 15, 1,
              4, 0, 2, 0, 0, 0, 1, 0, 0, 3, 1, 6, 0, 1, 1, 4, 0, 5, 0, 5, 1, 4, 5, 8, 1, 0, 0, 0, 2, 0, 0, 4, 7, 3, 0,
              1, 0, 5, 1, 6, 0, 0, 0, 0, 1, 3, 7, 0, 0, 0, 23, 0, 1, 0, 0, 2, 1, 0, 0, 6, 1, 0, 0, 1, 1, 0, 0, 6, 2, 11,
              0, 1, 4, 1, 1, 0, 4, 0, 0, 0, 1, 7, 0, 0, 0, 3, 2, 15, 0, 0, 11, 15, 3, 3, 4, 0, 2, 2, 1, 0, 1, 0, 19, 0,
              7, 1, 0, 6, 0, 0, 0, 5, 0, 0, 0, 5, 0, 0, 3, 1, 4, 1, 5, 0, 3, 5, 4, 1, 1, 6, 0, 0, 0, 5, 0, 0, 1, 3, 0,
              4, 0, 5, 5, 9, 0, 8, 2, 4, 0, 0, 4, 0, 0, 0, 0, 22, 10, 1, 0, 4, 0, 0, 1, 3, 0, 0, 1, 3, 0, 0, 0, 1, 8, 1,
              1, 0, 5, 0, 0, 0, 1, 1, 0, 0, 0, 2, 0, 0, 0, 3, 0, 0, 11, 0, 2, 1, 1, 8, 2, 2, 0, 1, 1, 1, 0, 0, 0, 16, 0,
              6, 1, 4, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 0, 7, 3, 0, 2, 0, 6, 2, 4, 1, 1, 3, 0, 0, 0, 2, 0, 0, 1, 1, 0, 4,
              0, 0, 6, 2, 9, 4, 3, 0, 0, 1, 3, 2, 0, 0, 0, 20, 1, 8, 1, 4, 0, 0, 1, 3, 1, 0, 0, 0, 4, 0, 0, 1, 6, 0, 0,
              0, 0, 5, 0, 0, 0, 6, 0, 0, 0, 2, 0, 1, 0, 0, 1, 2, 1, 0, 0, 0, 4, 0, 2, 0, 0, 0, 0, 2, 0, 0, 1, 22, 0, 1,
              1, 2, 0, 0, 1, 1, 0, 0, 0, 0, 4, 0, 1, 0, 3, 0, 1, 0, 0, 3, 1, 0, 2, 4, 0, 1, 0, 3, 0, 0, 0, 1, 2, 0, 0,
              0, 5, 1, 3, 6, 0, 3, 0, 0, 0, 3, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 2, 0, 3, 1, 0, 1, 1, 0, 0, 0, 2, 0, 0, 0,
              0, 1, 1, 0, 0, 3, 1, 1, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 1, 3, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
            ],
            countsWeighted: [
              0.0, 0.1, 0.2, 0.0, 0.0, 0.1, 0.3, 0.8, 0.0, 0.0, 0.0, 3.1, 0.0, 1.0, 0.0, 3.4, 0.0, 4.0, 1.8, 2.9, 19.3,
              2.0, 2.0, 0.0, 0.4, 0.0, 0.0, 0.7, 0.2, 0.0, 0.0, 1.9, 1.2, 0.2, 1.0, 1.0, 1.1, 7.4, 1.0, 2.0, 0.2, 0.8,
              4.5, 0.0, 0.0, 1.3, 3.5, 1.0, 0.0, 0.0, 1.6, 0.0, 0.0, 0.0, 0.1, 0.0, 0.0, 0.1, 6.7, 2.0, 0.0, 0.0, 0.0,
              0.8, 2.0, 0.0, 1.0, 2.5, 1.1, 0.0, 1.0, 0.3, 1.2, 0.0, 4.0, 0.0, 3.9, 0.0, 0.0, 0.0, 0.7, 0.0, 0.0, 0.0,
              0.0, 0.2, 7.0, 0.0, 0.0, 0.3, 2.0, 9.7, 1.0, 0.4, 0.0, 2.0, 0.0, 0.0, 1.3, 0.0, 1.0, 0.0, 6.8, 1.0, 4.0,
              0.0, 0.3, 0.0, 0.0, 0.0, 0.2, 0.0, 0.0, 3.0, 0.2, 1.0, 0.0, 0.2, 1.0, 1.7, 0.0, 4.2, 0.0, 4.5, 0.1, 4.0,
              3.5, 6.8, 2.6, 0.0, 0.0, 0.0, 2.2, 0.0, 0.0, 4.0, 6.3, 2.9, 0.0, 0.2, 0.0, 6.0, 1.0, 6.0, 0.0, 0.0, 0.0,
              0.0, 1.8, 3.0, 14.8, 0.0, 0.0, 0.0, 41.9, 0.0, 6.2, 0.0, 0.0, 3.1, 0.5, 0.0, 0.0, 6.6, 1.0, 0.0, 0.0, 0.1,
              1.0, 0.0, 0.0, 7.1, 1.5, 9.6, 0.0, 1.0, 3.5, 1.0, 1.0, 0.0, 4.9, 0.0, 0.0, 0.0, 1.2, 6.3, 0.0, 0.0, 0.0,
              2.4, 2.0, 12.7, 0.0, 0.0, 11.0, 15.0, 3.0, 3.0, 5.7, 0.0, 3.8, 2.0, 0.9, 0.0, 1.0, 0.0, 19.6, 0.0, 5.7,
              1.0, 0.0, 6.1, 0.0, 0.0, 0.0, 4.6, 0.0, 0.0, 0.0, 4.6, 0.0, 0.0, 3.0, 0.5, 4.5, 1.0, 5.0, 0.0, 3.3, 5.0,
              3.5, 1.0, 3.0, 4.3, 0.0, 0.0, 0.0, 5.5, 0.0, 0.0, 1.0, 3.2, 0.0, 4.0, 0.0, 5.2, 5.7, 9.0, 0.0, 8.0, 1.2,
              4.0, 0.0, 0.0, 3.9, 0.0, 0.0, 0.0, 0.0, 31.3, 10.0, 1.5, 0.0, 4.5, 0.0, 0.0, 0.5, 3.6, 0.0, 0.0, 1.0, 5.8,
              0.0, 0.0, 0.0, 1.0, 8.5, 1.0, 0.3, 0.0, 3.1, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 0.0, 0.0, 2.6, 0.0, 0.0, 0.0,
              2.6, 0.0, 0.0, 10.5, 0.0, 2.0, 1.0, 1.0, 8.0, 1.4, 2.0, 0.0, 1.0, 0.7, 0.8, 0.0, 0.0, 0.0, 26.8, 0.0, 7.1,
              1.3, 5.9, 0.0, 3.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.0, 0.0, 0.0, 6.5, 3.0, 0.0, 1.5, 0.0, 5.5, 2.0, 3.5,
              1.0, 1.0, 2.0, 0.0, 0.0, 0.0, 2.0, 0.0, 0.0, 0.5, 6.1, 0.0, 4.0, 0.0, 0.0, 5.2, 2.0, 8.5, 4.0, 2.0, 0.0,
              0.0, 1.6, 5.9, 2.0, 0.0, 0.0, 0.0, 22.4, 1.7, 9.2, 0.7, 3.1, 0.0, 0.0, 2.7, 2.4, 1.0, 0.0, 0.0, 0.0, 3.9,
              0.0, 0.0, 0.7, 4.6, 0.0, 0.0, 0.0, 0.0, 4.5, 0.0, 0.0, 0.0, 3.8, 0.0, 0.0, 0.0, 1.7, 0.0, 1.0, 0.0, 0.0,
              2.0, 2.0, 1.0, 0.0, 0.0, 0.0, 3.5, 0.0, 2.0, 0.0, 0.0, 0.0, 0.0, 1.4, 0.0, 0.0, 1.3, 30.5, 0.0, 1.3, 1.0,
              2.2, 0.0, 0.0, 0.1, 1.0, 0.0, 0.0, 0.0, 0.0, 3.8, 0.0, 0.4, 0.0, 2.9, 0.0, 1.0, 0.0, 0.0, 4.6, 1.4, 0.0,
              6.2, 5.9, 0.0, 1.0, 0.0, 2.1, 0.0, 0.0, 0.0, 1.0, 2.1, 0.0, 0.0, 0.0, 5.3, 0.3, 5.4, 5.2, 0.0, 3.0, 0.0,
              0.0, 0.0, 4.7, 0.0, 0.0, 0.0, 3.6, 0.0, 4.4, 0.0, 0.0, 0.0, 0.7, 3.5, 0.0, 3.1, 1.7, 0.0, 1.0, 1.6, 0.0,
              0.0, 0.0, 3.1, 0.0, 0.0, 0.0, 0.0, 1.0, 1.6, 0.0, 0.0, 2.2, 1.7, 0.5, 0.0, 0.0, 0.0, 7.2, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 1.0, 2.5, 1.5, 1.0, 1.0, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
            ],
          },
        ],
      }),
    )
  }

  return res(ctx.data(soSVMentionsBreakdownMockForProductLaunch))
}
