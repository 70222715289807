import { useState } from 'react'

export const useIsTouchableDevice = (): {
  isTouchable: boolean
  handleTouchStart: () => void
  handleMouseMove: () => void
} => {
  const [lastTouchTime, setLastTouchTime] = useState<number>(0)
  const [isTouchable, setIsTouchable] = useState<boolean>(false)

  const handleTouchStart = () => {
    setLastTouchTime(new Date().valueOf())
    setIsTouchable(true)
  }

  const handleMouseMove = () => {
    if (new Date().valueOf() - lastTouchTime < 500) return
    setIsTouchable(false)
  }

  return { isTouchable, handleTouchStart, handleMouseMove }
}
