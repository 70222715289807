/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum OptStatus {
  in = "in",
  out = "out",
}

export enum SearchTimeframe {
  allTime = "allTime",
  customDateRange = "customDateRange",
  fiveYears = "fiveYears",
  oneYear = "oneYear",
  sixMonths = "sixMonths",
  tenYears = "tenYears",
  threeYears = "threeYears",
  twoYears = "twoYears",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export interface GeographyFilter {
  country?: string | null;
  state?: string | null;
  city?: string | null;
}

export interface SearchPillInput {
  category: string;
  value: string;
  synonyms?: string[] | null;
}

export interface SortBy {
  field: string;
  direction: SortDirection;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
