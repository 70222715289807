import styled from 'styled-components'
import { StyledChart } from '../../styles'

export const StyledRankChart = styled(StyledChart)`
  .highcharts-color-0 {
    fill: ${({ theme }) => theme.colors.dataVisualisation.lineGraphFill};
  }
  .highcharts-graph {
    stroke-width: 1px;
  }
  .highcharts-point {
    fill: #fff;
    stroke: #fff;
    stroke-width: 0px;
  }
  .highcharts-halo {
    fill: #99aaff;
    stroke: #99aaff;
    fill-opacity: 1;
    stroke-width: 3px;
  }
`
