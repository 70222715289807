import styled, { css } from 'styled-components'
import { browserTruncateMixin, Flex, flexMixin, loadingStateBackgroundMixin } from '~common'
import { Select } from '~components/ui/molecules/select'

export const StyledHubsNavigationWrapper = styled(Flex)`
  height: 3rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    height: 3.5rem;
  }
`

export const StyedSubscription = styled.span`
  ${browserTruncateMixin}
`
export const StyledSubscriptionsWrapper = styled.div`
  ${({ theme }) => theme.textSizes.desktop.navigation}
  flex-shrink: 0;
  display: inline-flex;
  display: -ms-inline-flexbox;
  align-items: center;
  padding: 0.75rem;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.pill.pillBackgroundPrimary};
  color: ${({ theme }) => theme.colors.textOn.textOnPill};
  max-width: calc(7rem + 1.5rem);
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: calc(13.875rem + 1.5rem);
  }
`

export const StyledSelectWithSystemDropdown = styled(Select.WithSystemDropdown)`
  & > select {
    max-width: 7rem;
    @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
      max-width: 13.875rem;
    }
  }
`

export const StyledNavigation = styled.nav`
  ${flexMixin}
  width: 100%;
  align-items: center;
  height: 100%;
  margin-left: 0.5rem;
`

export const StyledNavigationList = styled.ul`
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  ${flexMixin};
`

export const StyledNavigationItem = styled.li<{
  isSelected: boolean
  isFullWidth: boolean
  isTouchable: boolean
  isDisabled?: boolean
  isLoading?: boolean
}>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
  height: 100%;
  ${({ isLoading }) => isLoading && loadingStateBackgroundMixin};
  & > span,
  a {
    ${flexMixin};
    ${({ theme }) => theme.textSizes.desktop.buttonL};
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: left;
    padding: 1rem;
    color: ${({ theme }) => theme.colors.text.textSecondary};
    transition: all 0.2s ease-in-out;
    cursor: default;
    & > span,
    & > div > span {
      display: none;
      ${({ isDisabled }) =>
        isDisabled &&
        css`
          opacity: 0.4;
        `}
    }
    & > svg,
    & > div > svg {
      display: inline;
      vertical-align: middle;
      fill: ${({ theme }) => theme.colors.text.textSecondary};
      ${({ isDisabled }) =>
        isDisabled &&
        css`
          opacity: 0.4;
        `}
    }

    ${({ isLoading }) =>
      isLoading &&
      css`
        opacity: 0;
      `}
    ${({ isSelected, theme }) =>
      isSelected &&
      css`
        background-color: ${theme.colors.pill.pillBackgroundPrimary};
        color: ${theme.colors.textOn.textOnPill};
        pointer-events: none;
        svg {
          fill: ${theme.colors.textOn.textOnPill};
        }
      `}
    &:hover {
      ${({ isSelected, isTouchable, isDisabled }) =>
        !isSelected &&
        !isTouchable &&
        !isDisabled &&
        css`
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.pill.pillBackgroundHover};
          color: ${({ theme }) => theme.colors.textOn.textOnPill};
          svg {
            fill: ${({ theme }) => theme.colors.textOn.textOnPill};
          }
        `}
    }
    &:focus-visible {
      ${({ isSelected, isTouchable, isDisabled }) =>
        !isSelected &&
        !isTouchable &&
        !isDisabled &&
        css`
          background-color: ${({ theme }) => theme.colors.pill.pillBackgroundHover};
          color: ${({ theme }) => theme.colors.textOn.textOnPill};
          svg {
            fill: ${({ theme }) => theme.colors.textOn.textOnPill};
          }
        `}
    }
    @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
      & > div > svg,
      & > svg {
        margin-right: 1rem;
      }
      & > span,
      & > div > span {
        display: inline;
      }
    }
  }
`
