import React, { useEffect, useState } from 'react'
import { useSoSVState, useSoSVStateDispatch } from '~components/pages/widgets/sosv/settings/settings'
import {
  CumulatingType,
  getProductColors,
  GraphType,
  Product,
  ProductGroup,
  ProductGroupProduct,
  SoSVContentType,
  SoSVTimeframe,
  StyledSection,
  WeighteningType,
} from '~common'
import { FiltersHeading } from '~components/ui/molecules/filters-heading'
import { Controlled } from '~components/ui/controlled'
import { useForm } from 'react-hook-form'
import { StyledSearchButton } from '~components/pages/experts-hub/components/search/components/search-pane/components/search-form/styles'
import {
  StyledDatePickerDateRange,
  StyledFiltersGroup,
  StyledProductsGrid,
  StyledTopicsGrid,
} from '~components/ui/sosv-hub-layout/components/sosv-filters/styles'
import { Tabs } from '~components/ui/molecules/tabs'
import { HiddenProductGroup } from '~components/exposed/sosv/Filters'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

interface CustomDateRangeData {
  startDate?: Date
  endDate?: Date
}

interface FiltersFormData {
  contentTypes: Record<string, boolean>
  cumulatingType: string
  graphType: string
  timeframe: SoSVTimeframe
  drugSelection: Record<string, boolean>
  topicSelection: Record<string, boolean>
  weighteningType: string
  source: string
  productGroupId?: string
  startDate?: string
  endDate?: string
  customDateRange: CustomDateRangeData
}

const mapArrayToRecord = (values: string[], initialValue = true): Record<string, boolean> =>
  Object.fromEntries(values.map((v) => [v, initialValue]))

export const Filters = (): JSX.Element => {
  const soSVState = useSoSVState()
  const dispatchSoSVState = useSoSVStateDispatch()

  const {
    timeframe,
    startDate,
    endDate,
    weighteningType,
    contentTypes,
    source,
    graphType,
    cumulatingType,
    productGroupId,
    productGroups,
    drugSelection,
    topics,
    topicSelection,
  } = soSVState

  const getSoSVProducts = (productGroups: ProductGroup[], productGroupId?: string) => {
    const selectedProductGroup = productGroups.find((el) => el.id === productGroupId)
    return selectedProductGroup?.products?.length
      ? selectedProductGroup.products.map((product: ProductGroupProduct, index: number) => ({
          name: product.label,
          denominationColor: getProductColors(selectedProductGroup.products.length, 'iqvia').reverse()[index],
        }))
      : []
  }

  const products: Product[] = productGroups.length ? getSoSVProducts(productGroups, productGroupId) : []
  const showProducts = !!products.length
  const showTopics = !!topics?.length

  const onSubmit = (data: FiltersFormData) => {
    const timeframe = data['timeframe'] as SoSVTimeframe

    const isCustomDateRangeWithEmptyStartAndEndDates =
      timeframe === SoSVTimeframe.CustomDateRange && (!data.customDateRange.startDate || !data.customDateRange.endDate)
    if (isCustomDateRangeWithEmptyStartAndEndDates) return

    const contentTypes = Object.entries((data['contentTypes'] as Record<string, boolean>) || {})
      .filter(([_, selected]) => selected)
      .map(([type, _]) => type)
    const drugSelection = Object.entries((data['drugSelection'] as Record<string, boolean>) || {})
      .filter(([_, selected]) => selected)
      .map(([type, _]) => type)
    const topicSelection = Object.entries((data['topicSelection'] as Record<string, boolean>) || {})
      .filter(([_, selected]) => selected)
      .map(([type, _]) => type)

    let startDate: string | undefined = undefined
    let endDate: string | undefined = undefined
    if (timeframe === SoSVTimeframe.CustomDateRange) {
      const customDateRange = data['customDateRange'] as CustomDateRangeData
      if (customDateRange.startDate)
        startDate = new Date(format(customDateRange.startDate, "yyyy-MM-dd'T00:00:00'")).toISOString()
      if (customDateRange.endDate)
        endDate = new Date(format(customDateRange.endDate, "yyyy-MM-dd'T23:59:59'")).toISOString()
    }

    dispatchSoSVState({
      ...soSVState,
      graphType: data['graphType'] as GraphType,
      cumulatingType: data['cumulatingType'] as CumulatingType,
      weighteningType: data['weighteningType'] as WeighteningType,
      timeframe,
      startDate,
      endDate,
      contentTypes,
      drugSelection,
      topicSelection,
    })
  }

  const [initialFormData] = useState<FiltersFormData>({
    source,
    timeframe,
    graphType,
    weighteningType,
    cumulatingType,
    contentTypes: mapArrayToRecord([contentTypes].flat()),
    productGroupId,
    drugSelection: mapArrayToRecord([drugSelection ?? []].flat()),
    topicSelection: mapArrayToRecord([topicSelection ?? []].flat(), false),
    customDateRange: {
      startDate: startDate ? parseISO(startDate) : undefined,
      endDate: endDate ? parseISO(endDate) : undefined,
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    formState,
    getValues,
    reset,
    formState: { isValidating, isDirty, isValid },
  } = useForm({ mode: 'onChange' })

  useEffect(() => {
    if (isDirty && isValid && !isValidating) {
      const data = watch()
      onSubmit(data as FiltersFormData)
      reset(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isValid, isValidating, formState])

  useEffect(() => {
    if (timeframe !== 'customDateRange') {
      reset({
        ...getValues(),
        customDateRange: {
          startDate: undefined,
          endDate: undefined,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe])

  const handleResetAll = () => {
    onSubmit(initialFormData)
    reset(initialFormData)
  }

  const onChangeProductGroup = (selectedProductGroupId: string) => {
    const drugSelection = getSoSVProducts(productGroups, selectedProductGroupId).map((p) => p.name)

    dispatchSoSVState({
      ...soSVState,
      productGroupId: selectedProductGroupId,
      drugSelection,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="sosv-filters-form">
      <StyledSection>
        <FiltersHeading>Format</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="graphType"
          control={control}
          defaultValue={graphType}
          radios={[
            {
              label: 'Counts',
              id: 'counts',
              value: 'counts',
            },
            {
              label: 'Share',
              id: 'share',
              value: 'share',
            },
          ]}
        />
      </StyledSection>

      <StyledSection>
        <FiltersHeading>Over time</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="cumulatingType"
          control={control}
          defaultValue={cumulatingType}
          radios={[
            {
              label: 'Non cumulative',
              id: 'non-cumulative',
              value: 'non-cumulative',
            },
            {
              label: 'Cumulative',
              id: 'cumulative',
              value: 'cumulative',
            },
          ]}
        />
      </StyledSection>

      <StyledSection>
        <FiltersHeading>Weighting</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="weighteningType"
          control={control}
          defaultValue={weighteningType}
          radios={[
            {
              label: 'Weighted',
              id: 'weighted',
              value: 'weighted',
            },
            {
              label: 'Unweighted',
              id: 'unweighted',
              value: 'unweighted',
            },
          ]}
        />
      </StyledSection>

      {showProducts && (
        <StyledSection data-testid="products-filter">
          <FiltersHeading>Products</FiltersHeading>
          {productGroupId && productGroups.length > 1 ? (
            <Tabs
              tabs={productGroups.map((group) => ({
                id: group.id,
                name: group.hidden ? <HiddenProductGroup name={group.name} /> : group.name,
              }))}
              selectedTabId={productGroupId}
              onSelect={(id) => {
                onChangeProductGroup(id)
              }}
            >
              <section>
                <Controlled.CheckboxContained
                  key={products?.[0].name}
                  label={products?.[0].name}
                  id={`${products?.[0].name}-product`}
                  name={`drugSelection.${products?.[0].name}`}
                  control={control}
                  denominationColor={products?.[0].denominationColor}
                  defaultChecked={drugSelection?.includes(products?.[0].name || '')}
                />
                <StyledProductsGrid>
                  {products?.slice(1).map((product: Product) => (
                    <Controlled.CheckboxContained
                      key={product.name}
                      label={product.name}
                      id={`${product.name}-product`}
                      name={`drugSelection.${product.name}`}
                      denominationColor={product.denominationColor}
                      control={control}
                      defaultChecked={drugSelection?.includes(product.name)}
                    />
                  ))}
                </StyledProductsGrid>
              </section>
            </Tabs>
          ) : (
            <>
              <Controlled.CheckboxContained
                label={products?.[0].name}
                id={`${products?.[0].name}-product`}
                name={`drugSelection.${products?.[0].name}`}
                control={control}
                denominationColor={products?.[0].denominationColor}
                defaultChecked={drugSelection?.includes(products?.[0].name || '')}
              />
              <StyledProductsGrid>
                {products?.slice(1).map((product: Product) => (
                  <Controlled.CheckboxContained
                    key={product.name}
                    label={product.name}
                    id={`${product.name}-product`}
                    name={`drugSelection.${product.name}`}
                    denominationColor={product.denominationColor}
                    control={control}
                    defaultChecked={drugSelection?.includes(product.name)}
                  />
                ))}
              </StyledProductsGrid>
            </>
          )}
        </StyledSection>
      )}

      <StyledSection>
        <FiltersHeading>Content types</FiltersHeading>
        <StyledFiltersGroup>
          <div>
            <Controlled.CheckboxContained
              label="Presentations"
              id={SoSVContentType.presentation}
              name={`contentTypes.${SoSVContentType.presentation}`}
              control={control}
              defaultChecked={contentTypes?.length ? contentTypes?.includes(SoSVContentType.presentation) : true}
            />
          </div>
          <div>
            <Controlled.CheckboxContained
              label="Journal articles"
              id={SoSVContentType.journalArticle}
              name={`contentTypes.${SoSVContentType.journalArticle}`}
              control={control}
              defaultChecked={contentTypes?.length ? contentTypes?.includes(SoSVContentType.journalArticle) : true}
            />
          </div>
        </StyledFiltersGroup>
      </StyledSection>

      <StyledSection>
        <FiltersHeading>Timeframe</FiltersHeading>
        <Controlled.RadioContainedButtonGroup
          name="timeframe"
          control={control}
          defaultValue={timeframe}
          radios={[
            {
              label: 'Last 3 months',
              id: 'twelveWeeks',
              value: 'twelveWeeks',
            },
            {
              label: 'Last year',
              id: 'oneYear',
              value: 'oneYear',
            },
            {
              label: 'Last 3 years',
              id: 'threeYears',
              value: 'threeYears',
            },
            {
              label: 'Last 10 years',
              id: 'tenYears',
              value: 'tenYears',
            },
            {
              label: 'Custom date range',
              id: 'customDateRange',
              value: 'customDateRange',
            },
          ]}
        />
        <StyledDatePickerDateRange
          name="customDateRange"
          startDate={initialFormData.customDateRange.startDate}
          endDate={initialFormData.customDateRange.endDate}
          watch={watch}
          reset={reset}
          getValues={getValues}
          control={control}
          isDisabled={watch('timeframe') !== 'customDateRange' && !startDate && !endDate}
        />
      </StyledSection>

      {showTopics && (
        <StyledSection>
          <FiltersHeading>Topics</FiltersHeading>
          <StyledTopicsGrid>
            {topics?.map((topic: string) => (
              <Controlled.CheckboxContained
                key={topic}
                label={topic}
                id={`${topic}-topic`}
                name={`topicSelection.${topic}`}
                control={control}
                defaultChecked={topicSelection?.includes(topic)}
              />
            ))}
          </StyledTopicsGrid>
        </StyledSection>
      )}

      <StyledSection>
        <StyledSearchButton variant="secondary" size="md" onClick={handleResetAll} type="button">
          Reset all
        </StyledSearchButton>
      </StyledSection>
    </form>
  )
}
