import { TooltipFormatterContextObject } from 'highcharts'
import { getThemes } from '~common/theme'
import { getCommonChartOptions } from '../../common-charts-options'

export const useProductsMentionsBarChartOptions = (
  series: Array<Highcharts.SeriesOptionsType>,
  themeBrand: 'pharmaspectra' | 'iqvia',
  toolTipFormatter: (context: TooltipFormatterContextObject) => string,
): [options: Highcharts.Options] => {
  const commonChartOptions = getCommonChartOptions()
  const lightModeColors = getThemes('light', themeBrand).colors
  const options: Highcharts.Options = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      type: 'bar',
      height: 93,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      shadow: false,
      enabled: true,
      className: 'custom-tooltip',
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
      borderWidth: 0,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      lineColor: lightModeColors.neutrals.neutral20,
      lineWidth: 0,
      tickColor: lightModeColors.neutrals.neutral20,
      minorTickLength: 0,
      tickLength: 0,
      height: 24,
    },
    yAxis: {
      gridLineColor: lightModeColors.neutrals.neutral10,
      gridLineWidth: 1,
      height: 0,
      min: 0,
      max: 100,
      tickAmount: 11,
      endOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      column: {
        pointWidth: 35,
      },
      series: {
        stacking: 'normal',
        borderWidth: 0,
      },
    },
    series: series.reverse(),
  }
  return [options]
}
