import { OperationVariables, QueryResult, useQuery } from '@apollo/client'
import { DevFeature, Features, GeneralFeatures, SoSVFeatures } from '~common'
import { GET_FEATURES_FOR_USER } from '~common/hooks/use-features/query'
import { GetFeaturesForUserQuery } from './__generated__/GetFeaturesForUserQuery'
import { useExpertEcosystemContext } from '~common/providers'
import { redirectToEe } from '~common/ee-redirect'

export interface UseFeaturesReturn {
  featuresForUserResponse: QueryResult<GetFeaturesForUserQuery, OperationVariables>
  isInternalTestingGroup: boolean
  isIqviaBrand: boolean
  hasCommercialCompliance: boolean
  isDevFeatureEnabled: (featureName: DevFeature) => boolean
  setDevFeature: (featurename: DevFeature, enabled: boolean) => void
  expertsEnabledForUser: boolean
  sosvEnabledForUser: boolean
  scienceEnabledForUser: boolean
  showClientNames: boolean
  showSocialInfo: boolean
  showGrants: boolean
  showPDFDownloadButton: boolean
  showSoSVStats: boolean
  showPowerpointButton: boolean
  showSosvChartDataExport: boolean
  viewExtraContentIds: boolean
  showWordDownloadButton: boolean
  customizableRanking: boolean
  shouldRedirectToExpertsEcosystem: boolean
  influenceScoresEnabledForUser: boolean
}

export const useFeatures = (): UseFeaturesReturn => {
  const isEE = useExpertEcosystemContext() != null

  const featuresForUserResponse = useQuery<GetFeaturesForUserQuery>(GET_FEATURES_FOR_USER)
  const isInternalTestingGroup =
    featuresForUserResponse.data?.getFeaturesForUser.experts.includes(Features.InternalTestingGroup) || false

  const customizableRanking =
    isEE ||
    (!isEE && (featuresForUserResponse.data?.getFeaturesForUser.experts ?? []).includes(Features.CustomizableRanking))

  const hasCommercialCompliance =
    featuresForUserResponse.data?.getFeaturesForUser.general.includes(GeneralFeatures.CommercialCompliance) || false

  const showClientNames =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.experts.includes(Features.ShowClientNames)) ||
    false

  const showSocialInfo =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.experts.includes(Features.SocialInformation)) ||
    false

  const showGrants =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.experts.includes(Features.DisplayGrants)) ||
    false

  const isIqviaBrand =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.experts.includes(Features.IqviaColours)) ||
    false

  const expertsEnabledForUser =
    featuresForUserResponse.data?.getFeaturesForUser.experts.includes(Features.Experts) || false

  const influenceScoresEnabledForUser =
    !isEE && Boolean(featuresForUserResponse.data?.getFeaturesForUser.experts.includes(Features.InfluenceScores))

  const sosvEnabledForUser =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.sosv.includes(SoSVFeatures.SoSV)) ||
    false

  const scienceEnabledForUser =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.science.includes(Features.Science)) ||
    false

  const showPDFDownloadButton =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.experts.includes(Features.PDFSummaryExport)) ||
    false

  const showWordDownloadButton =
    (featuresForUserResponse.data &&
      featuresForUserResponse.data.getFeaturesForUser.experts.includes(Features.WordExport)) ||
    false

  const showSoSVStats = sosvEnabledForUser

  const showPowerpointButton =
    featuresForUserResponse.data?.getFeaturesForUser.sosv.includes(SoSVFeatures.PowerPointReport) || false
  const showSosvChartDataExport =
    featuresForUserResponse.data?.getFeaturesForUser.sosv.includes(SoSVFeatures.SosvChartDataExport) || false

  const viewExtraContentIds =
    featuresForUserResponse.data?.getFeaturesForUser.general.includes(GeneralFeatures.ViewExtraContentIds) || false

  const shouldRedirectToExpertsEcosystem =
    featuresForUserResponse.data?.getFeaturesForUser.general.includes(GeneralFeatures.RedirectToExpertsEcosystem) ||
    false

  const isDevFeatureEnabled = (featureName: DevFeature): boolean => {
    if (!isInternalTestingGroup) {
      return false
    }
    const features = window.localStorage.getItem('features')
    if (!features) {
      return false
    }
    try {
      const featureObject: Record<string, boolean> = JSON.parse(features)
      return featureObject[featureName] || false
    } catch (e) {
      return false
    }
  }

  const setDevFeature = (featureName: DevFeature, enabled: boolean) => {
    let features
    try {
      features = JSON.parse(window.localStorage.getItem('features') || '{}')
    } catch (e) {
      features = {}
    }
    features[featureName] = enabled
    window.localStorage.setItem('features', JSON.stringify(features))
  }

  if (shouldRedirectToExpertsEcosystem && !isEE) {
    redirectToEe(window.location.href)
  }

  return {
    featuresForUserResponse,
    isInternalTestingGroup,
    isIqviaBrand,
    hasCommercialCompliance,
    isDevFeatureEnabled,
    setDevFeature,
    expertsEnabledForUser,
    sosvEnabledForUser,
    scienceEnabledForUser,
    showClientNames,
    showSocialInfo,
    showGrants,
    showPDFDownloadButton,
    showSoSVStats,
    showPowerpointButton,
    showSosvChartDataExport,
    viewExtraContentIds,
    showWordDownloadButton,
    customizableRanking,
    shouldRedirectToExpertsEcosystem,
    influenceScoresEnabledForUser,
  }
}
