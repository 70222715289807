import React from 'react'
import { createContext, useContext } from 'react'
import { Config } from '~common/types'

const ConfigContext = createContext({} as Config)

export const ConfigProvider: React.FC<{ config: Config }> = ({ config, children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
)

export const useConfig = (): Config => {
  return useContext(ConfigContext)
}
