import styled from 'styled-components'
import { Flex } from '~common'
import { Button } from '~components/ui/atoms/button'
import { Controlled } from '~components/ui/controlled'

export const StyledSearchForm = styled(Flex).attrs({ as: 'form' })`
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
`

export const StyledFormGrid = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`

export const StyledSearchButton = styled(Button)`
  width: max-content;
`

export const StyledCTAWrapper = styled(Flex)`
  width: 100%;
  & > *:first-child {
    margin-right: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(1176)}) {
    width: auto;
  }
`

export const StyledContentTypesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.5rem;
`

export const StyledDatePickerDateRange = styled(Controlled.DatePickerDateRange)`
  margin-top: 0.5rem;
`
