import React from 'react'
import { StyledLoadingSubtitleBlock, StyledLoadingTitleBlock } from '../styles'
import styled from 'styled-components'
import { flexMixin } from '~common'
import { useExpertEcosystemContext } from '~common/providers'

const StyledPageTitleBarLoadingBlock = styled.div<{ isTransparent?: boolean }>`
  background: ${({ isTransparent, theme }) => !isTransparent && theme.colors.brand.backgroundSecondary};
  & > div {
    ${flexMixin}
    padding: ${({ isTransparent }) => !isTransparent && '1rem'};
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: ${({ theme }) => theme.pxsToEms(512)};
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    & > div {
      max-width: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    & > div {
      padding: ${({ isTransparent }) => !isTransparent && '1.5rem'};
      max-width: ${({ theme }) => theme.pxsToEms(2040)};
    }
  }
`

export const PageTitleBarLoadingBlock = ({ isTransparent }: { isTransparent?: boolean }): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <StyledPageTitleBarLoadingBlock isTransparent={isTransparent}>
      <div>
        <StyledLoadingTitleBlock />
        <StyledLoadingSubtitleBlock />
      </div>
    </StyledPageTitleBarLoadingBlock>
  )
}
