import { ReactElement } from 'react'
import { LatLngBoundsExpression } from 'leaflet'
import { DefaultCoordinates, Mode, Scale } from '~common'

export const defaultBoundsEntireWorld: LatLngBoundsExpression = [
  [DefaultCoordinates.SouthWestLng, DefaultCoordinates.SouthWestLat],
  [DefaultCoordinates.NorthEastLng, DefaultCoordinates.NorthEastLat],
]

export interface Point {
  type: 'Point'
  coordinates: number[]
}

export interface FeatureProperties {
  id: string | number
  popupUI: ReactElement
  closeIconColor?: 'primary' | 'secondary'
}

export interface Feature<T extends FeatureProperties> {
  type: 'Feature'
  properties: T
  geometry: Point
}

export interface FeatureIconProps {
  properties: Record<string, unknown>
  scale: Scale
  isIqviaBrand: boolean
  mode: Mode
}

export interface ClusterIconProps {
  properties: Record<string, unknown>[]
  size: number
  count: number
  isIqviaBrand: boolean
  scale: Scale
  mode: Mode
}

export const isPoint = (possiblePoint: unknown): possiblePoint is Point => {
  return (possiblePoint as Point).type === 'Point'
}
