import React from 'react'
import styled from 'styled-components'
import { Flex } from '~common'
import { CustomLink } from '../../atoms/custom-link'

const StyledList = styled(Flex).attrs({ as: 'ul' })`
  ${({ theme }) => theme.textSizes.desktop.label};
  & > li:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }
  & > li:last-child {
    flex-grow: 1;
  }
`
export interface OnwardLinksProps {
  links: {
    name: string
    href: string
    id?: string
  }[]
}

export const OnwardLinks = ({ links }: OnwardLinksProps): JSX.Element => (
  <>
    {links.length > 1 ? (
      <StyledList>
        {links.map((link) => (
          <li key={link.href}>
            <CustomLink size="sm" href={link.href} id={link.id}>
              {link.name}
            </CustomLink>
          </li>
        ))}
      </StyledList>
    ) : (
      <CustomLink size="sm" href={links[0].href} id={links[0].id}>
        {links[0].name}
      </CustomLink>
    )}
  </>
)
