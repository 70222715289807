import styled, { css } from 'styled-components'

export const StyledBunPath = styled.path`
  transition: all 0.32s ease-in-out 0s;
`

export const StyledAccountHeadPath = styled(StyledBunPath)<{ isOpened?: boolean }>`
  transform-origin: top left;
  ${({ isOpened }) =>
    isOpened &&
    css`
      opacity: 0;
      transform: rotate(-45deg);
    `}
`

export const StyledAccountBodyPath = styled(StyledBunPath)<{ isOpened?: boolean }>`
  transform-origin: bottom left;
  ${({ isOpened }) =>
    isOpened &&
    css`
      opacity: 0;
      transform: rotate(45deg);
    `}
`

export const StyledAccountBunPath = styled.path`
  opacity: 0;
  transition: opacity 0.16s ease-in-out 0s, all 0.32s ease-in-out 0s;
`

export const StyledAccountTopBunPath = styled(StyledAccountBunPath)<{ isOpened?: boolean }>`
  transform-origin: 0.0625rem 0.25rem;
  ${({ isOpened }) =>
    isOpened &&
    css`
      opacity: 1;
      transform: rotate(45deg) scale(1.1);
    `}
`

export const StyledAccountBottomBunPath = styled(StyledAccountBunPath)<{ isOpened?: boolean }>`
  transform-origin: 0.0625rem 0.75rem;
  transition: opacity 0.16s ease-in-out 0s;
  ${({ isOpened }) =>
    isOpened &&
    css`
      opacity: 1;
      transform: rotate(-45deg) scale(1.1);
    `}
`

export const StyledHiddenOnDesktop = styled.span`
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    display: none;
  }
`

export const StyledDisplayedOnDesktop = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    display: inline;
  }
`
