import React, { Dispatch, FC } from 'react'
import { useIsTouchableDevice } from '~common/hooks'
import { StyledHeader, StyledHeaderLoadingBlock, StyledIqviaBrandLogo, StyledPharmaspectraBrandLogo } from './styles'
import { useFeatures } from '~common/hooks/use-features'

export const SimpleHeader: FC<{ setError?: Dispatch<Error | undefined> }> = (): JSX.Element => {
  const { isIqviaBrand, featuresForUserResponse } = useFeatures()
  const { isTouchable, handleTouchStart, handleMouseMove } = useIsTouchableDevice()

  if (featuresForUserResponse.loading) {
    return <StyledHeaderLoadingBlock />
  }

  return (
    <StyledHeader tabIndex={-1} onTouchStart={handleTouchStart} onMouseMove={handleMouseMove} data-testid="header">
      {isIqviaBrand ? (
        <StyledIqviaBrandLogo href="/" isTouchable={isTouchable} withNextLink />
      ) : (
        <StyledPharmaspectraBrandLogo
          isTouchable={isTouchable}
          logoSize="md"
          logoVariant="primary"
          href="/"
          withNextLink
        />
      )}
    </StyledHeader>
  )
}
