import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Header } from '~components/ui/header'
import { StyledLatestLayout, StyledLatestMain } from './styles'
import { FeatureMenu } from '~components/ui/molecules/feature-menu'
import { GlobalStyles, LinkThemeProvider } from '~common/theme'
import { useFeatures } from '~common/hooks/use-features'
import { useMode } from '~common/hooks/use-mode'
import { ServerError } from '~components/pages/error/ServerError'
import { datadogRum } from '@datadog/browser-rum'

export const Layout = ({ children }: { children: ReactNode }): JSX.Element => {
  const router = useRouter()
  const [mode] = useMode()
  const { isIqviaBrand } = useFeatures()
  const [error, setError] = useState<Error | undefined>(undefined)

  const ref = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    ref.current?.focus()
  }, [router.pathname])

  useEffect(() => {
    if (error) {
      datadogRum.addError(error)
    }
  }, [error])

  return (
    <LinkThemeProvider mode={mode} brand={isIqviaBrand ? 'iqvia' : 'pharmaspectra'}>
      <GlobalStyles />
      <StyledLatestLayout>
        <span ref={ref} tabIndex={-1} />
        <Header setError={setError} />
        {error ? (
          <ServerError
            error={error}
            resetErrorBoundary={() => {
              setError(undefined)
            }}
          />
        ) : (
          <StyledLatestMain>{children}</StyledLatestMain>
        )}
        <FeatureMenu />
      </StyledLatestLayout>
    </LinkThemeProvider>
  )
}
