import { AxisLabelsFormatterContextObject, TooltipFormatterContextObject } from 'highcharts'
import { getCommonChartOptions, getCommonExportChartOptions } from '../../common-charts-options'
import { BarHeightSize, Collection } from '~components/ui/organisms/charts'
import { renderToStaticMarkup } from 'react-dom/server'
import { GraphType, roundDecimals } from '~common'
import { ExportChartConfig } from '../../types'
import { generateExportChartFileName, generateExportChartSourceText, generateExportChartTitleText } from '../../helpers'
import { useCallback } from 'react'

const columnHeightSizes: Record<BarHeightSize, number> = {
  sm: 60,
  md: 80,
  lg: 100,
}

type UseCollectionsProductsMentionsStackedBarChartInput = {
  data: Highcharts.SeriesOptionsType[]
  categories: number[] | string[]
  showLegend: boolean
  collections: Collection[]
  exportChartConfig?: ExportChartConfig
  barHeightSize: BarHeightSize
  graphType?: GraphType
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}

export const useCollectionsProductsMentionsStackedBarChart = ({
  data,
  categories,
  showLegend,
  collections,
  exportChartConfig,
  barHeightSize,
  toolTipFormatter,
  graphType = 'counts',
}: UseCollectionsProductsMentionsStackedBarChartInput): [options: Highcharts.Options] => {
  const commonExportOptions = getCommonExportChartOptions(data)
  const commonChartOptions = getCommonChartOptions(categories)
  const columnHeightSize = columnHeightSizes[barHeightSize]
  const marginBetweenColumns = 26
  const legendHeightSize = showLegend ? 51 : 0

  const xAxisFormatter = useCallback(
    function (this: AxisLabelsFormatterContextObject) {
      const id = this.value
      const collection = collections.filter((collection) => collection.id === id)[0]
      if (collection && collection.collectionUIPlaceholder) {
        return collection.collectionUIPlaceholder
      }
      return renderToStaticMarkup(collection?.collectionUI)
    },
    [collections],
  )

  const options: Highcharts.Options = {
    ...commonChartOptions,
    exporting: {
      ...(exportChartConfig
        ? {
            ...commonExportOptions,
            chartOptions: {
              ...commonExportOptions.chartOptions,
              title: {
                ...commonExportOptions.chartOptions?.title,
                text: generateExportChartTitleText({ exportChartConfig, isTotal: false, top: 5 }),
              },
              subtitle: {
                ...commonExportOptions.chartOptions?.subtitle,
                text: generateExportChartSourceText({
                  exportChartConfig,
                  series: data.filter((el) => el.showInLegend),
                  isTotal: false,
                }),
              },
              chart: {
                ...commonExportOptions.chartOptions?.chart,
                marginLeft: 380,
                marginRight: 50,
                plotBackgroundImage: 'https://app.pharmaspectra.com/stacked-bar-horizontal-pharmaspectra-watermark.png',
              },
              yAxis: {
                gridLineWidth: 1,
                labels: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
              xAxis: {
                labels: {
                  style: {
                    width: 380,
                    fontSize: '16px',
                    padding: '0 10px 0 50px',
                    textAlign: 'left',
                  },
                  useHTML: true,
                  formatter: function () {
                    const id = this.value
                    const collection = collections.filter((collection) => collection.id === id)[0]
                    return `${collection.title}`
                  },
                  align: 'left',
                  reserveSpace: true,
                },
              },
            },
            filename: generateExportChartFileName({ exportChartConfig, top: 5, isTotal: false }),
          }
        : { enabled: false }),
    },
    chart: {
      ...commonChartOptions.chart,
      height:
        (collections.length <= 2 ? 3 : collections.length) * (columnHeightSize + marginBetweenColumns) +
        legendHeightSize,
      type: 'bar',
      className: 'custom-collections-products-mentions-stacked-bar-chart',
    },
    tooltip: {
      ...commonChartOptions.tooltip,
      style: {
        width: 300,
      },
      outside: false,
      distance: 11,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    legend: {
      verticalAlign: 'bottom',
      enabled: showLegend,
      y: 0,
      x: 0,
      reversed: true,
      itemMarginBottom: 5,
      itemDistance: 8,
      itemWidth: 120,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolRadius: 0,
      align: 'center',
      borderColor: 'none',
      width: 400,
      itemStyle: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: '400',
      },
    },
    xAxis: {
      ...commonChartOptions.xAxis,
      labels: {
        useHTML: true,
        formatter: xAxisFormatter,
        align: 'left',
        reserveSpace: true,
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      gridLineWidth: 0,
      tickAmount: 11,
      labels: {
        x: -10,
        style: {
          width: 40,
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return `${this.value}`
          }
          return ''
        },
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderWidth: 0,
      },
      column: {
        pointWidth: columnHeightSize,
        pointPadding: 10,
        groupPadding: 10,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none',
            color: 'white',
            fontSize: '12px',
            fontWeight: '400',
          },
          formatter: function () {
            return this.y ? (graphType === 'counts' ? roundDecimals(this.y, 1) : `${this.y}%`) : ''
          },
        },
        events: {
          legendItemClick: function () {
            return false
          },
        },
      },
    },
    series: data.reverse(),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
        },
      ],
    },
  }

  return [options]
}
