import styled, { css } from 'styled-components'
import { browserTruncateMixin, Flex } from '~common'
import { Heading, HeadingProps } from '~components/ui/atoms/heading'
import { CustomLink, CustomLinkProps } from '~components/ui/atoms/custom-link'

interface StyledContentTeaserProps {
  isClickable: boolean
  isActive?: boolean
  isTouchable?: boolean
  isWithDivider: boolean
}

export const StyledContentTeaser = styled.div<StyledContentTeaserProps>`
  ${({ isClickable, isActive, theme, isTouchable }) =>
    isClickable &&
    css`
      padding: 0.5rem ${isActive ? '0.5rem' : '1rem'} 0 1rem;
      background: ${isActive && theme.colors.states.active};
      border-right: ${isActive && `8px solid ${theme.colors.states.activeAccent}`};
      transition: background-color 0.24s ease-in-out;
      cursor: ${isActive ? 'default' : 'pointer'};
      & hr {
        margin-bottom: 0;
      }
      &:hover {
        background: ${!isActive && !isTouchable && theme.colors.states.hoverPrimary};
        border-right: ${!isActive && !isTouchable && `8px solid ${theme.colors.states.hoverAccent}`};
        padding-right: ${!isTouchable && '0.5rem'};
        outline: none;
      }
      &:focus-visible {
        background: ${!isActive && !isTouchable && theme.colors.states.hoverPrimary};
        border-right: ${!isActive && !isTouchable && `8px solid ${theme.colors.states.hoverAccent}`};
        padding-right: ${!isTouchable && '0.5rem'};
      }
      & a,
      & a:visited {
        color: ${isActive && theme.colors.text.textPrimary};
      }
    `};

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    ${({ isClickable, isActive, isTouchable }) =>
      isClickable &&
      css`
        padding-right: ${isActive ? '1rem' : '1.5rem'};
        padding-left: 1.5rem;
        &:hover {
          padding-right: ${!isTouchable && '1rem'};
        }
        &:focus-visible {
          padding-right: ${!isTouchable && '1rem'};
        }
      `};
  }
`

export const StyledHeading = styled(Heading)<HeadingProps>`
  ${({ theme }) => theme.textSizes.desktop.bodyS}
`

export const StyledHeadingList = styled(Flex).attrs({ as: 'ul' })`
  ${({ theme }) => theme.textSizes.desktop.bodyS}
  & > li:first-child {
    flex-grow: 1;
  }
`

export const StyledOnTopicIconWrapper = styled(Flex).attrs({
  as: 'span',
})`
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
`

export const StyledHeadingCount = styled.span`
  font-weight: 600;
  margin-left: 0.25rem;
`

const reusableMixin = css`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

export const StyledText = styled.p<{ hasHighlight?: boolean }>`
  ${reusableMixin}
  ${browserTruncateMixin}
  ${({ theme, hasHighlight }) =>
    hasHighlight ? theme.textSizes.desktop.labelBoldWithHighlight : theme.textSizes.desktop.labelBold};
`

export const ContentIdentifierText = styled.p`
  ${reusableMixin}
  ${browserTruncateMixin}
  ${({ theme }) => theme.textSizes.desktop.label}
`

type StyledIntenalLinkProps = CustomLinkProps & {
  $isTeaserClickable: boolean
}

export const StyledCustomLink = styled(CustomLink)<StyledIntenalLinkProps>`
  ${({ $isTeaserClickable }) =>
    $isTeaserClickable &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`

export const StyledMentionedProducts = styled(Flex)`
  flex-flow: row wrap;
  margin: 0.25rem -0.25rem -0.25rem -0.25rem;
  & > * {
    margin: 0.25rem;
  }
`
