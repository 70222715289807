import format from 'date-fns/format'

export const formatStartAndEndDatesParams = (startDate: Date, endDate: Date): string => {
  const localeStart = format(startDate, "yyyy-MM-dd'T00:00:00'")
  const localeEnd = format(endDate, "yyyy-MM-dd'T23:59:59'")
  return `&startDate=${localeStart}&endDate=${localeEnd}`
}

export const formatDateForFilterSettings = (date: Date, startDate = true): string => {
  return format(date, `yyyy-MM-dd'T${startDate ? '00:00:00' : '23:59:59'}'`)
}

export const addTrailingSlash = (path: string): string => {
  if (!path.endsWith('/')) path += '/'

  return path
}

export const buildSubViewUrl = (rootPath?: string, location?: string): string | undefined => {
  return rootPath ? `${addTrailingSlash(rootPath)}${location ?? ''}` : undefined
}
