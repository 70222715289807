import { getModifiedSubscriptionsWithRootAndListIds } from '~common/helpers/common-link-helpers'
import { ApolloError, useQuery } from '@apollo/client'
import { GET_SUBSCRIPTIONS_FOR_USER } from '~apollo/queries/subscriptions/query'
import { GetSubscriptionsForUserQuery } from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { Subscription } from '~common/types/common-link-types'

export const useRootSubscriptionId = (
  rootSubscriptionId?: string,
  listId?: string,
): {
  rootSubscriptionId: string
  isLoading: boolean
  error?: ApolloError
} => {
  const {
    data: subscriptionsData,
    error: subscriptionsDataError,
    loading,
  } = useQuery<GetSubscriptionsForUserQuery>(GET_SUBSCRIPTIONS_FOR_USER)

  const { selectedRootSubscriptionId } = getModifiedSubscriptionsWithRootAndListIds({
    subscriptions: subscriptionsData?.getSubscriptionsForUser as Subscription[],
    rootSubscriptionId,
    listId,
  })

  return { rootSubscriptionId: selectedRootSubscriptionId, isLoading: loading, error: subscriptionsDataError }
}
