import styled, { css } from 'styled-components'
import { Theme } from '~common/theme'
import { StyledSVG } from '~components/ui/nucleons/custom-svg'

const navItemHoverMixin = css`
  ${({ theme }) =>
    css`
      color: ${theme.colors.textOn.textOnPrimary};
      padding: 1rem 0 0.75rem;
      background-color: ${theme.colors.navigation.navHover};
    `}
`
const inlineNavItemMixin = ({ hasIcon, theme }: { theme: Theme; hasIcon?: boolean }) =>
  css`
    ${theme.textSizes.desktop.navigation};
    display: block;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    text-align: left;
    color: ${theme.colors.textOn.textOnPrimary};
    padding: 1rem 0 1rem 1rem;
    ${!hasIcon &&
    css`
      padding-left: 3rem;
      font-weight: 400;
    `}
  `

export const StyledNavigationItemLi = styled.li<{
  hasNoAction?: boolean
  isSelected?: boolean
  isActive?: boolean
  hasIcon?: boolean
  isInlineSmall?: boolean
  isTouchable: boolean
  isDisabled?: boolean
}>`
  overflow-y: hidden;
  overflow-x: hidden;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
  ${({ hasNoAction, isInlineSmall }) =>
    hasNoAction &&
    css`
      ${({ theme }) => theme.textSizes.desktop.navigation}
      padding: 1rem 0 1rem 1rem;
      display: block;
      width: 100%;
      text-align: left;
      color: ${({ theme }) => theme.colors.navigation.iconOnNav};
      height: ${isInlineSmall ? 'auto' : '4.7375rem'};
      ${StyledSVG} {
        display: inline;
        vertical-align: text-bottom;
        margin: 0 1rem 0 0;
      }
    `}

  & > a,
  & > button {
    ${({ theme }) => theme.textSizes.mobile.navigation}
    overflow-y: hidden;
    overflow-x: hidden;
    height: ${({ isInlineSmall }) => (isInlineSmall ? 'auto' : '4.7375rem')};
    color: ${({ theme }) => theme.colors.neutrals.neutral0};
    padding: 2rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4.7375rem;
    text-align: center;
    transition: color 0.24s ease-in-out 0.16s, padding 0.24s ease-out 0.16s, background-color 0.24s ease-in-out;
    ${({ isActive, theme }) =>
      isActive &&
      css`
        background-color: ${theme.colors.navigation.navHover};
      `}

    ${({ isSelected, theme }) =>
      isSelected &&
      css`
        background-color: ${theme.colors.navigation.navSecondary};
      `}

    ${StyledSVG} {
      display: block;
      margin: 0 auto 0.25rem;
      ${({ isInlineSmall }) =>
        isInlineSmall &&
        css`
          display: inline;
          vertical-align: top;
          margin: 0 1rem 0 0;
        `}
    }

    &:hover {
      ${({ isTouchable, isInlineSmall }) =>
        !isTouchable &&
        css`
          ${navItemHoverMixin}
          ${isInlineSmall && inlineNavItemMixin}
        `}
    }

    &:focus-visible {
      outline-offset: -5px;
      ${navItemHoverMixin}
    }

    &:focus {
      outline-offset: -5px;
    }

    & > span {
      display: ${({ isInlineSmall }) => (isInlineSmall ? 'inline' : 'none')};
    }
  }

  & > a,
  & > button {
    ${({ isInlineSmall }) => isInlineSmall && inlineNavItemMixin}
  }

  & > a:focus-visible,
  & > button:focus-visible {
    ${({ isInlineSmall }) => isInlineSmall && inlineNavItemMixin}
  }

  ${StyledSVG} {
    fill: ${({ theme }) => theme.colors.navigation.iconOnNav};
  }
`
