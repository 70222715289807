import styled, { css } from 'styled-components'

export const StyledContainer = styled.section`
  padding: 1rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    column-gap: 1.5rem;
    padding: 1.5rem;
    max-width: none;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    row-gap: 1.5rem;
  }

  #exposed-styled-wrapper > & {
    height: inherit;
  }
`

export const StyledItem = styled.section<{
  itemWidth: 'narrow' | 'widest'
  isTransparent: boolean
}>`
  padding: ${({ isTransparent }) => !isTransparent && '0.5rem'};
  align-self: start;
  background-color: ${({ theme, isTransparent }) => !isTransparent && theme.colors.brand.backgroundSecondary};
  ${({ itemWidth }) =>
    itemWidth &&
    css`
      grid-column: span 12;
    `}

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${({ itemWidth }) => {
      if (itemWidth === 'narrow') {
        return css`
          grid-column: span 6;
        `
      }
      return
    }}
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    padding: ${({ isTransparent }) => !isTransparent && '1rem'};
  }
`
