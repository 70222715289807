import React from 'react'
import { StyledDropdownWithCheckboxesItem, StyledDropdown } from '../styles'
import { Checkbox } from '~components/ui/atoms/checkbox'
import { Option } from '../types'

export interface DropdownWithCheckboxesProps extends React.HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  options: Option[]
  ariaLabel: string
  dropdownTitle?: string
  onCheckOption?: (id: string) => void
}

export const DropdownWithCheckboxes = ({
  dropdownTitle,
  options,
  ariaLabel,
  isOpen,
  onCheckOption,
  ...rest
}: DropdownWithCheckboxesProps): JSX.Element => (
  <StyledDropdown
    tabIndex={-1}
    aria-label={ariaLabel}
    aria-hidden={!isOpen}
    isOpen={isOpen}
    isFixedMaxWidth={true}
    {...rest}
  >
    {dropdownTitle && (
      <StyledDropdownWithCheckboxesItem hasNoAction={true}>{dropdownTitle}</StyledDropdownWithCheckboxesItem>
    )}
    {options.map((item: Option) => (
      <StyledDropdownWithCheckboxesItem key={item.id}>
        <Checkbox
          id={item.id}
          checkboxSize="sm"
          label={item.label}
          tabIndex={isOpen ? 0 : -1}
          defaultChecked={item.isChecked}
          isChecked={item.isChecked}
          onChange={() => onCheckOption && onCheckOption(item.id)}
        />
      </StyledDropdownWithCheckboxesItem>
    ))}
  </StyledDropdown>
)
