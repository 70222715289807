import styled from 'styled-components'
import { Button } from '~components/ui/atoms/button'
import { HubsNavigation } from '~components/ui/hubs-navigation'
import { flexMixin } from '~common'

export const StyledSoSVHubSection = styled.section`
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colors.brand.backgroundTertiary};
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    padding: 1rem 1rem 0 1rem;
  }
`

export const StyledFiltersSection = styled.section`
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  padding: 0 1rem 1rem 1rem;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`

export const StyledHideFiltersButton = styled(Button)`
  margin-top: 1.5rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    display: none;
  }
`

export const StyledDesktopHubNavigation = styled(HubsNavigation)`
  margin-bottom: 1rem;
  display: none;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    ${flexMixin};
  }
`

export const StyledMobileHubNavigation = styled(HubsNavigation)`
  margin-bottom: 1rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    display: none;
    margin: 0;
  }
`

export const StyledMobileNavAndBackBarWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.brand.backgroundTertiary};
  padding-top: 1rem;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    display: none;
  }
`
