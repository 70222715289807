import React from 'react'
import { StyledDropdownWithAutocompleteItem, StyledDropdown } from '../styles'
import { capitalizeFirstLetter, SearchQueryValue } from '~common'

export interface DropdownWithAutocompleteProps extends React.HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  options: Record<string, SearchQueryValue[]> | never[]
  ariaLabel: string
  searchTerm: string
  onSelectOption: (option: SearchQueryValue) => void
  restrictFreeText?: boolean
}

export const DropdownWithAutocomplete = ({
  options,
  searchTerm,
  ariaLabel,
  onSelectOption,
  isOpen,
  restrictFreeText,
  ...rest
}: DropdownWithAutocompleteProps): JSX.Element => (
  <StyledDropdown aria-label={ariaLabel} aria-hidden={!isOpen} isOpen={isOpen} isFixedMaxWidth={true} {...rest}>
    {Object.entries(options).map(([key, value]) => (
      <li key={key}>
        <StyledDropdownWithAutocompleteItem as="h2" isGroupTitle={true}>
          {capitalizeFirstLetter(key)}
        </StyledDropdownWithAutocompleteItem>
        <ul>
          {value.map((item) => (
            <StyledDropdownWithAutocompleteItem
              aria-label={item.value}
              tabIndex={isOpen ? 0 : -1}
              key={item.value}
              dangerouslySetInnerHTML={{ __html: matchAndWrapWithSpan(searchTerm, item.value) }}
              onKeyPress={(e) => e.key === 'Enter' && onSelectOption(item)}
              onClick={() => onSelectOption(item)}
            />
          ))}
        </ul>
      </li>
    ))}
    {searchTerm && !restrictFreeText && (
      <li>
        <StyledDropdownWithAutocompleteItem as="h2" isGroupTitle={true}>
          Custom term
        </StyledDropdownWithAutocompleteItem>
        <StyledDropdownWithAutocompleteItem
          as="div"
          dangerouslySetInnerHTML={{ __html: `"<span>${searchTerm}</span>"` }}
          tabIndex={isOpen ? 0 : -1}
          onKeyPress={(e: { key: string }) =>
            e.key === 'Enter' && onSelectOption({ category: 'free text', value: searchTerm })
          }
          onClick={() => onSelectOption({ category: 'free text', value: searchTerm })}
        ></StyledDropdownWithAutocompleteItem>
      </li>
    )}
  </StyledDropdown>
)

// escapeRegExp function from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
const escapeRegExp = (string: string): string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const matchAndWrapWithSpan = (searchTerm: string, item: string): string => {
  const escapedSearchTerms = searchTerm
    .split(' ')
    .map(escapeRegExp)
    .map((term) => `\\b${term}`)
    .join('|')
  return item.replace(new RegExp(`(${escapedSearchTerms})+`, 'igm'), (str) => `<span>${str}</span>`)
}
