import { SeriesOptionsType } from 'highcharts'
import styled from 'styled-components'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'

export const StyledProductsMentionsByTimeframeColumnChart = styled(StyledChart)<{
  productColors: string[]
  series: SeriesOptionsType[]
}>`
  height: 8.9375rem;
  .highcharts-point {
    fill: unset;
    stroke-width: 0px;
  }
  ${({ productColors, series }) => getProductColorsCss({ productColors, series })};
`
