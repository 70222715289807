import { ClusterIconProps } from '~components/ui/organisms/cluster-map/types'
import { Cluster } from '~components/ui/organisms/cluster-map/components/markers/components'
import React from 'react'
import { SocietyEventsFeature } from '~components/pages/sosv-hub/components/society-events-by-location/types'

export const ClusterMarkerIcon: React.FC<ClusterIconProps> = ({ properties, scale, isIqviaBrand, mode }) => {
  const features = properties as unknown as SocietyEventsFeature[]
  const aggregatedEventsCount = features.reduce((acc, next) => {
    acc += next.properties.meetingCount
    return acc
  }, 0)
  return <Cluster size={25} count={aggregatedEventsCount} isIqviaBrand={isIqviaBrand} scale={scale} mode={mode} />
}
