import React from 'react'
import styled from 'styled-components'
import { BackArrowSvg } from '~common/svgs'
import { Button } from '../../atoms/button'

const StyledStickyBackBar = styled.div<{ isHidden?: boolean }>`
  z-index: inherit;
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  position: sticky;
  left: 0;
  top: 0;
  transition: all 0.32s ease-in-out;
  opacity: ${({ isHidden }) => (isHidden ? '0' : 'initial')};
`

const StyledBackButton = styled(Button)<{ isMobileWidthRestricted: boolean }>`
  width: 100%;
  max-width: ${({ theme, isMobileWidthRestricted }) => (isMobileWidthRestricted ? theme.pxsToEms(512) : '100%')};
  padding: 0.5rem 1rem;
  text-align: left;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
    padding: ${({ isMobileWidthRestricted }) => !isMobileWidthRestricted && '0.5rem 1.5rem'};
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    padding: 0.5rem 1.5rem;
  }
`

export interface StickyBackBarProps extends React.HTMLAttributes<HTMLElement> {
  ctaText: string
  onClickBack: () => void
  asTag?: string
  isHidden?: boolean
  isMobileWidthRestricted?: boolean
}

export const StickyBackBar = ({
  ctaText,
  onClickBack,
  isHidden = false,
  isMobileWidthRestricted = true,
  asTag,
  className,
}: StickyBackBarProps): JSX.Element => (
  <StyledStickyBackBar as={asTag as never} isHidden={isHidden} className={className}>
    <StyledBackButton
      icon={<BackArrowSvg />}
      size="lg"
      variant="link"
      onClick={onClickBack}
      isMobileWidthRestricted={isMobileWidthRestricted}
    >
      {ctaText}
    </StyledBackButton>
  </StyledStickyBackBar>
)
