import React from 'react'
import styled from 'styled-components'
import { Flex } from '~common'

const StyledProductBadge = styled(Flex)<{ denominationColor: string; size: 'sm' | 'md' | 'lg' }>`
  color: ${({ theme }) => theme.colors.text.textPrimary};
  ${({ theme, size }) => {
    if (size === 'sm') return theme.textSizes.desktop.dataAxis
    if (size === 'md') return theme.textSizes.desktop.label
    if (size === 'lg') return theme.textSizes.desktop.body
  }};
  border-radius: 1px;
  border: 2px solid ${({ denominationColor }) => denominationColor};
  padding: 0 0.25rem;
  width: max-content;
  display: inline-flex;
`

const StyledCount = styled.span`
  font-weight: 600;
  margin-left: 0.25rem;
`

export interface ProductBadgeProps {
  name: string
  count?: number
  denominationColor: string
  size?: 'sm' | 'md' | 'lg'
}

export const ProductBadge = ({ name, count, denominationColor, size = 'sm' }: ProductBadgeProps): JSX.Element => (
  <StyledProductBadge denominationColor={denominationColor} size={size}>
    {name}
    {count && <StyledCount>{count}</StyledCount>}
  </StyledProductBadge>
)
