import { useQuery } from '@apollo/client'
import { NextRouter, useRouter } from 'next/router'
import { GET_EXPERT_NAME } from '~apollo/queries/profile/query'
import {
  GetExpertNameQuery,
  GetExpertNameQuery_getProfileByIdFromDDB_ProfileType,
  GetExpertNameQueryVariables,
} from '~apollo/queries/profile/__generated__/GetExpertNameQuery'
import { GET_SOSV_JOURNAL_INFO } from '~apollo/queries/sosv/journals/query'
import {
  GetSoSVJournalInfoQuery,
  GetSoSVJournalInfoQueryVariables,
} from '~apollo/queries/sosv/journals/__generated__/GetSoSVJournalInfoQuery'
import { GET_SOSV_MEETING_INFO } from '~apollo/queries/sosv/meetings/query'
import {
  GetSoSVMeetingInfoQuery,
  GetSoSVMeetingInfoQueryVariables,
} from '~apollo/queries/sosv/meetings/__generated__/GetSoSVMeetingInfoQuery'
import { GET_SOSV_SOCIETY_INFO } from '~apollo/queries/sosv/societies/query'
import {
  GetSoSVSocietyInfoQuery,
  GetSoSVSocietyInfoQueryVariables,
} from '~apollo/queries/sosv/societies/__generated__/GetSoSVSocietyInfoQuery'
import { convertGeographyToFullName, convertGeographyToName } from '~components/pages/sosv-hub/helpers'
import { useRootSubscriptionId } from '../use-root-subscription-id'
import { DEFAULT_SORT_TYPE } from '../use-sort'
import { GET_SUBSCRIPTIONS_NAMES } from './query'
import {
  GetSubscriptionsNamesQuery,
  GetSubscriptionsNamesQueryVariables,
} from './__generated__/GetSubscriptionsNamesQuery'
import { getFullSmartListName, getSmartListType } from '~components/pages/experts-hub/helpers'
import { GET_SMART_LISTS_BY_TYPE_FOR_LIST_ID } from '~apollo/queries/smart-list/query'
import { GetSmartListsByTypeForListIdQuery } from '~apollo/queries/smart-list/__generated__/GetSmartListsByTypeForListIdQuery'
import { SmartListType } from '~common'

const sosvHubExcludedQueryParameters = [
  'societyId',
  'journalId',
  'meetingId',
  'city',
  'state',
  'country',
  'southWestLng',
  'southWestLat',
  'northEastLng',
  'northEastLat',
]

export const useCrumbtrail = (
  listId?: string,
): {
  crumbs: { name: string; href: string }[]
  isLoading: boolean
  isError: boolean
} => {
  const router = useRouter()
  const { id, coAuthorProfileId, ...restQueryParams } = router.query
  listId = listId || (router.query.listId as string) || (router.query.subscriptionId as string) || undefined
  const expertId = (id as string) || undefined
  const coAuthorId = (coAuthorProfileId as string) || undefined
  const smartListType: SmartListType | undefined = getSmartListType(restQueryParams.smartListId as string)
  const {
    data: subsTreeData,
    loading: subsTreeIsLoading,
    error: subsTreeError,
  } = useQuery<GetSubscriptionsNamesQuery, GetSubscriptionsNamesQueryVariables>(GET_SUBSCRIPTIONS_NAMES, {
    variables: { listId: listId as string, savedListId: restQueryParams.savedListId as string },
    skip: !listId,
  })

  const {
    data: coExpertData,
    loading: coExpertDataIsLoading,
    error: coExpertError,
  } = useQuery<GetExpertNameQuery, GetExpertNameQueryVariables>(GET_EXPERT_NAME, {
    variables: { profileId: coAuthorId as string, listId: listId as string },
    skip: !coAuthorId || !listId,
  })

  const {
    data: expertData,
    loading: expertDataIsLoading,
    error: expertError,
  } = useQuery<GetExpertNameQuery, GetExpertNameQueryVariables>(GET_EXPERT_NAME, {
    variables: { profileId: expertId as string, listId: listId as string },
    skip: !expertId || !listId,
  })

  const coAuthorName = (coExpertData?.getProfileByIdFromDDB as GetExpertNameQuery_getProfileByIdFromDDB_ProfileType)
    ?.fullName
  const expertName = (expertData?.getProfileByIdFromDDB as GetExpertNameQuery_getProfileByIdFromDDB_ProfileType)
    ?.fullName
  const linkPath = getLinkPath(router)
  const linkNames = router.pathname?.split('/') || []
  const sortType = router.query.sort
  linkPath.shift()
  linkNames.shift()

  const isSoSVHub = linkNames.indexOf('sosv') === 0

  const {
    data: journalInfo,
    loading: journalInfoIsLoading,
    error: journalInfoError,
  } = useQuery<GetSoSVJournalInfoQuery, GetSoSVJournalInfoQueryVariables>(GET_SOSV_JOURNAL_INFO, {
    variables: {
      listId: listId || '',
      journalId: restQueryParams.journalId as string,
    },
    skip: !isSoSVHub || !listId || !restQueryParams.journalId,
  })

  const {
    data: meetingInfo,
    loading: meetingInfoIsLoading,
    error: meetingInfoError,
  } = useQuery<GetSoSVMeetingInfoQuery, GetSoSVMeetingInfoQueryVariables>(GET_SOSV_MEETING_INFO, {
    variables: { listId: listId || '', meetingId: restQueryParams.meetingId as string },
    skip: !isSoSVHub || !listId || !restQueryParams.meetingId,
  })

  const {
    data: societyInfo,
    loading: societyInfoIsLoading,
    error: societyInfoError,
  } = useQuery<GetSoSVSocietyInfoQuery, GetSoSVSocietyInfoQueryVariables>(GET_SOSV_SOCIETY_INFO, {
    variables: { listId: listId || '', societyId: restQueryParams.societyId as string },
    skip: !isSoSVHub || !listId || !restQueryParams.societyId,
  })

  const {
    rootSubscriptionId,
    isLoading: rootSubscriptionIdLoading,
    error: rootSubscriptionIdError,
  } = useRootSubscriptionId(undefined, listId)
  const subscriptionsNamesForCrumbtrail =
    !isSoSVHub &&
    subsTreeData?.getSubscriptionById.subsTree.map((el, i) => ({
      href: `/experts?listId=${el.subId ? el.subId : i === 0 ? rootSubscriptionId : el.id}${
        el.subId ? `&savedListId=${el.id}` : ''
      }${sortType && sortType !== DEFAULT_SORT_TYPE ? `&sort=${sortType}` : ''}`,
      name: el.name,
    }))

  // geographic smart list to crumbtrail
  const {
    data: geographicSmartListsData,
    loading: geographicSmartListsLoading,
    error: geographicSmartListsError,
  } = useQuery<GetSmartListsByTypeForListIdQuery>(GET_SMART_LISTS_BY_TYPE_FOR_LIST_ID, {
    variables: { listId, type: SmartListType.Geographic },
    skip: !restQueryParams.smartListId || smartListType !== SmartListType.Geographic || !listId,
  })
  if (smartListType === SmartListType.Geographic && subscriptionsNamesForCrumbtrail && geographicSmartListsData) {
    subscriptionsNamesForCrumbtrail.push({
      href: `${
        subscriptionsNamesForCrumbtrail[subscriptionsNamesForCrumbtrail.length - 1].href
      }&smartListId=${encodeURIComponent(restQueryParams.smartListId as string)}`,
      name:
        getFullSmartListName(geographicSmartListsData.getSmartListsByType, restQueryParams.smartListId as string) || '',
    })
  }

  // speciality smart list to crumbtrail
  const {
    data: specialitySmartListsData,
    loading: specialitySmartListsLoading,
    error: specialitySmartListsError,
  } = useQuery<GetSmartListsByTypeForListIdQuery>(GET_SMART_LISTS_BY_TYPE_FOR_LIST_ID, {
    variables: { listId, type: SmartListType.Speciality },
    skip: !restQueryParams.smartListId || smartListType !== SmartListType.Speciality || !listId,
  })
  if (smartListType === SmartListType.Speciality && subscriptionsNamesForCrumbtrail && specialitySmartListsData) {
    subscriptionsNamesForCrumbtrail.push({
      href: `${
        subscriptionsNamesForCrumbtrail[subscriptionsNamesForCrumbtrail.length - 1].href
      }&smartListId=${encodeURIComponent(restQueryParams.smartListId as string)}`,
      name:
        getFullSmartListName(specialitySmartListsData.getSmartListsByType, restQueryParams.smartListId as string) || '',
    })
  }

  const getCrumbtrailItemName = (linkName: string, linkPath: string, i: number) => {
    if (linkName === '[id]' && expertName) {
      return expertName
    }

    if (isSoSVHub && linkName === 'sosv') {
      const subscriptionParts = subsTreeData?.getSubscriptionById.subsTree.map((el) => el.name) || []
      return [...subscriptionParts, 'Share of Scientific Voice™'].join(' - ')
    }

    if (linkName === 'sosv') {
      return 'Share of Scientific Voice™'
    }

    if (linkName === 'details' && linkNames[i - 1] === 'societies') {
      return societyInfo?.getSoSVSocietyInfo.name || 'Society details'
    }

    if (linkName === 'details' && linkNames[i - 1] === 'journals') {
      return journalInfo?.getSoSVJournalInfo.title || 'Journal details'
    }

    if (linkName === 'details' && linkNames[i - 1] === 'locations') {
      return convertGeographyToName({
        city: restQueryParams.city as string,
        state: restQueryParams.state as string,
        country: restQueryParams.country as string,
      })
    }

    if (linkName === 'details' && linkNames[i - 1] === 'society-events-by-location') {
      return convertGeographyToFullName({
        city: restQueryParams.city as string,
        state: restQueryParams.state as string,
        country: restQueryParams.country as string,
      })
    }

    if (linkName === 'meeting' && linkNames[i - 1] === 'details' && linkNames[i - 2] === 'society-events-by-location') {
      return meetingInfo?.getSoSVMeetingInfo.name || 'Meeting details'
    }

    if (linkName === 'meeting' && linkNames[i - 1] === 'details' && linkNames[i - 2] === 'societies') {
      return meetingInfo?.getSoSVMeetingInfo.name || 'Meeting details'
    }

    if (linkPath.includes('coAuthorProfileId')) {
      return `${linkName} with ${coAuthorName}`
    }
    return linkName
  }

  const pathArray = linkPath.reduce((acc: Record<number, { name: string; href: string }>, curr, i: number) => {
    if (!isSoSVHub && linkNames[i] === 'experts') return acc
    if (linkNames[i] === 'my-account') return acc
    if (linkNames[i] === '[id]' && !expertName) return acc

    const searchFilterParams = new URLSearchParams()

    Object.keys(restQueryParams).forEach((key) => {
      if (isSoSVHub && linkNames[i] === 'sosv' && sosvHubExcludedQueryParameters.includes(key)) {
        return
      }

      const params = [restQueryParams[key]].flat()

      params.forEach((param) => {
        if (param) {
          searchFilterParams.append(key, param)
        }
      })
    })

    const searchFilters = searchFilterParams.toString()

    const path = linkPath
      .slice(0, i + 1)
      .join('/')
      .split('?')[0]
    const pathWithOrWithoutSubscriptionId = searchFilters ? path + `?${searchFilters}` : path

    acc[i] = {
      href: linkNames[i] ? '/' + pathWithOrWithoutSubscriptionId : '/',
      name: getCrumbtrailItemName(linkNames[i], linkPath[i], i),
    }

    return acc
  }, {})

  const isError =
    !!subsTreeError ||
    !!coExpertError ||
    !!expertError ||
    !!journalInfoError ||
    !!meetingInfoError ||
    !!societyInfoError ||
    !!rootSubscriptionIdError ||
    !!geographicSmartListsError ||
    !!specialitySmartListsError

  const isLoading = Boolean(
    subsTreeIsLoading ||
      coExpertDataIsLoading ||
      expertDataIsLoading ||
      journalInfoIsLoading ||
      meetingInfoIsLoading ||
      societyInfoIsLoading ||
      rootSubscriptionIdLoading ||
      geographicSmartListsLoading ||
      specialitySmartListsLoading,
  )

  return {
    crumbs: [...(subscriptionsNamesForCrumbtrail || []), ...Object.values(pathArray)],
    isLoading,
    isError,
  }
}

const getLinkPath = (router: NextRouter): string[] => {
  if (!router.asPath) return []
  const linkPathSplit = router.asPath.split('?', 2) //split the path and query, so that we don't split query string with '/' in them
  const linkPath = linkPathSplit[0].split('/') //split the path, excluding the query string, by '/'
  if (linkPathSplit.length > 1) {
    //add the query string back if it exists
    linkPath[linkPath.length - 1] += '?' + linkPathSplit[1]
  }
  return linkPath
}
