import { TooltipFormatterContextObject } from 'highcharts'
import { getCommonChartOptions, getCommonExportChartOptions } from '../../common-charts-options'
import { DateInterval, getProductColors, roundDecimals } from '~common'
import { ExportChartConfig } from '../../types'
import { generateExportChartFileName, generateExportChartSourceText, generateExportChartTitleText } from '../../helpers'

export const useProductsMentionsLineChartOptions = ({
  data,
  categories,
  exportChartConfig,
  dateInterval,
  themeBrand,
  toolTipFormatter,
}: {
  data: Highcharts.SeriesOptionsType[]
  categories: number[] | string[]
  exportChartConfig?: ExportChartConfig
  dateInterval: DateInterval
  themeBrand: 'pharmaspectra' | 'iqvia'
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): [Highcharts.Options] => {
  const commonExportOptions = getCommonExportChartOptions(data, dateInterval.breakDownBy)
  const commonChartOptions = getCommonChartOptions(categories)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    exporting: {
      ...(exportChartConfig
        ? {
            ...commonExportOptions,
            tableCaption: generateExportChartTitleText({ exportChartConfig, isTotal: false }),
            chartOptions: {
              ...commonExportOptions.chartOptions,
              plotOptions: {
                series: {
                  dataLabels: {
                    ...commonExportOptions.chartOptions?.plotOptions?.series?.dataLabels,
                    formatter: function (): string {
                      return `${this.y && this.y != 0 ? roundDecimals(this.y, 1) : ''}`
                    },
                  },
                },
              },
              title: {
                ...commonExportOptions.chartOptions?.title,
                text: generateExportChartTitleText({ exportChartConfig, isTotal: false }),
              },
              subtitle: {
                ...commonExportOptions.chartOptions?.subtitle,
                text: generateExportChartSourceText({
                  exportChartConfig,
                  series: data.filter((el) => el.showInLegend),
                  isTotal: false,
                }),
              },
              chart: {
                ...commonExportOptions.chartOptions?.chart,
                marginLeft: 70,
                marginRight: 50,
                plotBackgroundImage: 'https://app.pharmaspectra.com/line-graph-pharmaspectra-watermark.png',
              },
              yAxis: {
                gridLineWidth: 1,
                labels: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
            },
            filename: generateExportChartFileName({ exportChartConfig, isTotal: false }),
          }
        : { enabled: false }),
    },
    chart: {
      ...commonChartOptions.chart,
      events: {
        load() {
          setTimeout(this.reflow.bind(this), 0)
        },
      },
      type: 'area',
      marginLeft: 30,
      className: 'custom-products-mentions-area-chart',
    },
    colors: getProductColors(data.length, themeBrand),
    tooltip: {
      ...commonChartOptions.tooltip,
      distance: 19,
      style: {
        width: 300,
      },
      outside: true,
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    legend: {
      verticalAlign: 'bottom',
      enabled: true,
      y: 0,
      x: 0,
      reversed: true,
      itemMarginBottom: 5,
      itemDistance: 8,
      itemWidth: 120,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolRadius: 0,
      borderColor: 'none',
      width: 400,
      itemStyle: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: '400',
      },
    },
    xAxis: {
      ...commonChartOptions.xAxis,
      title: {
        text: dateInterval.breakDownBy === 'week' ? 'Week commencing' : undefined,
        textAlign: 'center',
      },
      labels: {
        skew3d: true,
        rotation: 0,
        style: {
          textOverflow: 'none',
        },
        formatter: function () {
          return `${this.value}`
        },
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      tickAmount: 11,
      gridLineWidth: 0,
      labels: {
        x: -2,
        style: {
          width: 27,
        },
        formatter: function () {
          if (this.isLast || this.isFirst || this.pos === (this.axis.max || 0) / 2) {
            return `${this.value}`
          }
          return ''
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: {
              enabled: false,
              size: 0,
            },
          },
        },
      },
      area: {
        opacity: 1,
        fillOpacity: 0,
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick: function () {
            return false
          },
        },
      },
    },
    series: data.reverse(),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              enabled: false,
            },
            xAxis: {
              labels: {
                x: -5,
                formatter: function () {
                  if (this.isLast || this.isFirst) {
                    return `${this.value}`
                  }
                  return ''
                },
              },
            },
          },
        },
      ],
    },
  }

  return [options]
}
