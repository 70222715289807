import { MapState, InitialiseMap, SetMapBounds } from './types'

export const initialiseMap = (state: MapState, payload: InitialiseMap['payload']): MapState => {
  const { visiblePointsTotal, mapBounds } = payload
  return {
    ...state,
    visiblePointsTotal,
    mapBounds,
    isInitialised: true,
  }
}

export const setMapBounds = (state: MapState, payload: SetMapBounds['payload']): MapState => {
  const { mapBounds } = payload
  return {
    ...state,
    mapBounds,
  }
}
