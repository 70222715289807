import { Auth } from 'aws-amplify'
import { CognitoIdToken } from 'amazon-cognito-identity-js'

let token: CognitoIdToken | null = null

export const getJwtTokenOrSignOut = async (): Promise<string> => {
  if (process.env.USE_MOCKS === 'true') return 'test.mocked.token.1234'

  // check if token is expired and avoid returning it
  if (token) {
    if (token.getExpiration() > Math.floor(Date.now() / 1000)) {
      return token.getJwtToken()
    } else {
      token = null // reset token
    }
  }

  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = cognitoUser.signInUserSession
    if (!currentSession) {
      return Auth.signOut()
    }
    const jwtTokenObject = (await Auth.currentSession()).getIdToken()
    const jwtToken = jwtTokenObject.getJwtToken()

    if (!jwtToken || jwtToken === 'undefined') {
      throw new Error('Invalid JWT value')
    }

    token = jwtTokenObject

    return jwtToken
  } catch (e) {
    return Auth.signOut()
  }
}
