import React from 'react'
import { StyledContainer, StyledItem, StyledSizer, StyledGutterSizer } from './styles'

const Container = ({ children, className }: { children: React.ReactNode; className?: string }): JSX.Element => {
  const masonryOptions = {
    percentPosition: true,
    transitionDuration: 0,
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    gutter: '.gutter-sizer',
  }

  return (
    <StyledContainer options={masonryOptions} enableResizableChildren={true} className={`grid ${className}`}>
      <StyledSizer aria-hidden={true} className="grid-sizer" />
      <StyledGutterSizer aria-hidden={true} className="gutter-sizer" />
      {children}
    </StyledContainer>
  )
}

export interface ItemProps extends React.HTMLAttributes<HTMLElement> {
  itemWidth: 'narrow' | 'wide' | 'wider' | 'widest'
  className?: string
  isTransparent?: boolean
  isNoPadding?: boolean
  asTag?: string
}

const Item: React.FC<ItemProps> = ({
  itemWidth,
  asTag = 'section',
  isTransparent = false,
  isNoPadding = false,
  children,
  className,
  ...rest
}) => (
  <StyledItem
    as={asTag as never}
    className={`grid-item ${className || ''}`}
    itemWidth={itemWidth}
    isTransparent={isTransparent}
    isNoPadding={isNoPadding}
    {...rest}
  >
    {children}
  </StyledItem>
)

export const MasonryGrid = {
  Container,
  Item,
}
