import React, { createContext, useState } from 'react'

export const RedirectManagerContext = createContext({
  manualLogout: true,
  setManualLogout: (_manualLogout: boolean) => {
    return
  },
})

export const withRedirectManager = (WrappedComponent: React.ComponentType): React.ComponentType => {
  return (props) => {
    const [manualLogout, setManualLogout] = useState(false)
    return (
      <RedirectManagerContext.Provider
        value={{
          manualLogout,
          setManualLogout,
        }}
      >
        <WrappedComponent {...props} />
      </RedirectManagerContext.Provider>
    )
  }
}
