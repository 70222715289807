import styled from 'styled-components'
import { StyledContentInfo } from '../collection-teaser/styles'

export const StyledMapExpertTeaser = styled.div`
  background-color: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  width: 20rem;
  li:last-child {
    overflow-x: initial;
  }
  ${StyledContentInfo} {
    overflow-x: scroll;
  }
`

export const StyledChildren = styled.div`
  padding: 0.5rem;
`
