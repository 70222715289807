import React from 'react'
import { StyledHeadingTab } from './styles'

export interface HeadingTabProps extends React.HTMLAttributes<HTMLDivElement> {
  isSelected: boolean
  asTag?: string
}

export const HeadingTab: React.FC<HeadingTabProps> = ({ children, isSelected, asTag, ...rest }): JSX.Element => (
  <StyledHeadingTab as={asTag as never} isSelected={isSelected} tabIndex={0} {...rest}>
    {children}
  </StyledHeadingTab>
)
