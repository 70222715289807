import React from 'react'
import {
  StyledDivider,
  StyledEmptyChartPlaceholder,
  StyledEmptyStackedBarChartPlaceholder,
  StyledSectionLine,
  StyledTreeMapChartEmptyState,
} from './styles'

export interface YAxisLabels {
  top: string
  bottom: string
}
export interface ChartEmptyStateProps {
  yAxisLabels?: YAxisLabels
  isPercentages?: boolean
  isStackedBar?: boolean
}
export const ChartEmptyState = ({ isStackedBar, yAxisLabels, isPercentages }: ChartEmptyStateProps): JSX.Element => (
  <>
    {isStackedBar ? (
      <StyledEmptyStackedBarChartPlaceholder>
        {[...new Array(50)].map((_item, i) => (
          <StyledDivider borderDirection="vertical" key={i} />
        ))}
      </StyledEmptyStackedBarChartPlaceholder>
    ) : (
      <StyledEmptyChartPlaceholder yAxisLabels={yAxisLabels} isPercentages={isPercentages}>
        <section>
          <StyledDivider />
        </section>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
          <StyledSectionLine key={item}>
            <StyledDivider />
          </StyledSectionLine>
        ))}
      </StyledEmptyChartPlaceholder>
    )}
  </>
)

export const TreemapChartEmptyState = (): JSX.Element => (
  <StyledTreeMapChartEmptyState>
    {[...new Array(50)].map((item, i) => (
      <StyledDivider borderDirection="vertical" key={i} />
    ))}
  </StyledTreeMapChartEmptyState>
)
