import { gql } from '@apollo/client'
import {
  GetSoSVConfigsQuery,
  GetSoSVConfigsQueryVariables,
} from '~apollo/queries/sosv/configs/__generated__/GetSoSVConfigsQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { LANTUS_PRODUCT_GROUP_ID } from '~mocks/handlers/ids'

export const GET_SOSV_CONFIGS = gql`
  query GetSoSVConfigsQuery($listId: String!) {
    getSoSVConfigs(listId: $listId) {
      topics
      productGroups {
        id
        name
        hidden
        products {
          label
          searchTerms
        }
      }
    }
  }
`

export const configsMock: GetSoSVConfigsQuery = {
  getSoSVConfigs: {
    productGroups: [
      {
        id: LANTUS_PRODUCT_GROUP_ID,
        name: 'Test product group',
        hidden: false,
        products: [
          { label: 'Lantus', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Glooko', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Soliqua', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Toujeo', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Tresiba', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Victoza', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Xultophy', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Wanted', searchTerms: ['Wanted', 'Toujeo'] },
        ],
      },
      {
        id: '234-234-234',
        name: 'Second test product group',
        hidden: true,
        products: [
          { label: 'Lantus', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Glooko', searchTerms: ['Lantus', 'Toujeo'] },
          { label: 'Some other product', searchTerms: ['Lantus', 'Toujeo'] },
        ],
      },
    ],

    topics: ['Topic 1', 'Topic 2'],
  },
}

export const getSoSVConfigsQueryHandler: GraphqlHandler<GetSoSVConfigsQueryVariables, GetSoSVConfigsQuery> = (
  req,
  res,
  ctx,
) => {
  return res(ctx.data(configsMock))
}
