import {
  GetSoSVConfigsQuery,
  GetSoSVConfigsQueryVariables,
} from '~apollo/queries/sosv/configs/__generated__/GetSoSVConfigsQuery'
import { GET_SOSV_CONFIGS } from '~apollo/queries/sosv/configs/query'
import { useQuery } from '@apollo/client'
import {
  GetSubscriptionByIdQuery,
  GetSubscriptionByIdQueryVariables,
} from '~apollo/queries/subscriptions/__generated__/GetSubscriptionByIdQuery'
import { GET_SUBSCRIPTION_BY_ID, GET_SUBSCRIPTIONS_FOR_USER } from '~apollo/queries/subscriptions/query'
import { useMemo } from 'react'
import {
  GetSubscriptionsForUserQuery,
  GetSubscriptionsForUserQuery_getSubscriptionsForUser,
} from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { useFeatures } from '~common/hooks'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { SoSVSource } from '~components/pages/widgets/sosv/types'
import { findAllSoSVLists, findFirstSoSVList, findSoSVListById } from '~components/pages/widgets/sosv/utils'
import { ProductGroup } from '~common'

export interface UseUserSettingsReturn {
  isLoading: boolean
  listId?: string
  listName?: string
  expertsCount: number
  rootListId?: string
  rootListName?: string
  productGroupId?: string
  productGroups: ProductGroup[]
  drugSelection: string[]
  topics: string[]
  subscriptions?: GetSubscriptionsForUserQuery_getSubscriptionsForUser[]
  isSoSVEnabled: boolean
  source: SoSVSource
}

export const useUserSettings = (
  isAuthenticated: boolean,
  listId?: string,
  source?: SoSVSource,
): UseUserSettingsReturn => {
  const { sosvEnabledForUser } = useFeatures()
  const {
    data: subscriptionsData,
    error: errorSubscriptionsData,
    loading: isLoadingSubscriptionsData,
  } = useQuery<GetSubscriptionsForUserQuery>(GET_SUBSCRIPTIONS_FOR_USER, {
    skip: !isAuthenticated,
  })

  const {
    data: configs,
    loading: isLoadingConfigs,
    error: errorConfigs,
  } = useQuery<GetSoSVConfigsQuery, GetSoSVConfigsQueryVariables>(GET_SOSV_CONFIGS, {
    variables: { listId: listId as string },
    skip: !listId || !isAuthenticated,
  })

  const {
    data: listDetailsData,
    loading: isLoadingListDetails,
    error: errorListDetails,
  } = useQuery<GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables>(GET_SUBSCRIPTION_BY_ID, {
    variables: { listId: listId as string },
    skip: !listId || !isAuthenticated,
  })

  const {
    data: subscriptionDetailsData,
    loading: isLoadingSubscriptionDetails,
    error: errorSubscriptionDetails,
  } = useQuery<GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables>(GET_SUBSCRIPTION_BY_ID, {
    variables: { listId: listDetailsData?.getSubscriptionById?.rootId as string },
    skip: !listDetailsData?.getSubscriptionById?.rootId || !isAuthenticated,
  })

  const isLoading =
    isLoadingSubscriptionsData ||
    isLoadingSubscriptionDetails ||
    isLoadingListDetails ||
    isLoadingConfigs ||
    isLoadingSubscriptionDetails

  const error =
    errorConfigs || errorSubscriptionDetails || errorListDetails || errorSubscriptionDetails || errorSubscriptionsData

  if (error) {
    throw error
  }

  const allSoSVLists = useMemo<GetSubscriptionsForUserQuery_getSubscriptionsForUser[]>(() => {
    if (subscriptionsData && subscriptionsData.getSubscriptionsForUser) {
      return findAllSoSVLists(
        subscriptionsData.getSubscriptionsForUser,
      ) as GetSubscriptionsForUserQuery_getSubscriptionsForUser[]
    }
    return []
  }, [subscriptionsData])

  const [productGroupId, drugSelection, productGroups, topics] = useMemo<
    [string | undefined, string[], ProductGroup[], string[]]
  >(() => {
    if (configs && configs.getSoSVConfigs) {
      const topics = configs.getSoSVConfigs.topics
      const productGroups = configs.getSoSVConfigs.productGroups.filter((group) => group.products.length)
      if (productGroups.length) {
        const [majorProductGroup] = productGroups
        return [majorProductGroup.id, majorProductGroup.products.map((p) => p.label), productGroups, topics]
      }
    }
    return [undefined, [], [], []]
  }, [configs])

  const [defaultListId, listName, expertsCount, defaultSource] = useMemo(() => {
    if (allSoSVLists) {
      const defaultSoSVList = listId
        ? findSoSVListById(allSoSVLists, listId, source)
        : findFirstSoSVList(allSoSVLists, source)
      if (defaultSoSVList) {
        return [
          defaultSoSVList.id,
          defaultSoSVList.name,
          defaultSoSVList.profileCount,
          source
            ? source
            : defaultSoSVList.permissions.includes(SubscriptionPermission.SoSVExperts)
            ? SoSVSource.EXPERTS
            : SoSVSource.SCIENCE,
        ]
      }
    }
    return [undefined, undefined, 0, SoSVSource.EXPERTS]
  }, [allSoSVLists, listId, source])

  return {
    listId: defaultListId,
    listName,
    expertsCount,
    subscriptions: allSoSVLists,
    isLoading,
    productGroupId,
    productGroups,
    drugSelection,
    topics,
    rootListId: listDetailsData?.getSubscriptionById?.rootId,
    rootListName: subscriptionDetailsData?.getSubscriptionById?.name,
    isSoSVEnabled: sosvEnabledForUser,
    source: defaultSource,
  }
}
