import styled from 'styled-components'
import { Size } from '~components/ui/atoms/button'
import { Dropdown } from '../dropdown'
import { MenuDropdownSize } from './HoverMenuButton'

const menuDropdownSizes = {
  sm: '9.6875rem',
  md: '14.5625rem',
  lg: '17.8125rem',
}

export const StyledHoverMenuButton = styled.div`
  position: relative;
  display: inline-flex;
`

export const StyledMenuDropdown = styled(Dropdown.WithOptions)<{ size: Size; menuDropdownSize: MenuDropdownSize }>`
  width: 100%;
  min-width: ${({ menuDropdownSize }) => menuDropdownSizes[menuDropdownSize]};
  transform: ${({ size }) => {
    if (size === 'xs') {
      return `translate3d(0px, 22px, 0px)`
    }
    if (size === 'sm') {
      return `translate3d(0px, 24px, 0px)`
    }
    if (size === 'md') {
      return `translate3d(0px, 34px, 0px)`
    }
    if (size === 'lg') {
      return `translate3d(0px, 42px, 0px)`
    }
  }};
`
