import { TooltipFormatterContextObject } from 'highcharts'
import { getThemes } from '~common/theme'
import { getCommonChartOptions } from '../../common-charts-options'

export const usePhaseInvolvementOptions = (
  series: Array<Highcharts.SeriesOptionsType>,
  themeBrand: 'pharmaspectra' | 'iqvia',
  toolTipFormatter: (context: TooltipFormatterContextObject) => string,
): [options: Highcharts.Options] => {
  const lightModeColors = getThemes('light', themeBrand).colors
  const commonChartOptions = getCommonChartOptions()
  const options: Highcharts.Options = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      type: 'bar',
      height: 93,
      reflow: true,
      marginTop: 0,
      marginLeft: 11,
      marginRight: 11,
    },
    tooltip: {
      padding: 0,
      useHTML: true,
      headerFormat: undefined,
      shadow: false,
      className: 'custom-tooltip',
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
      borderWidth: 0,
    },
    legend: {
      verticalAlign: 'middle',
      enabled: true,
      y: 35,
      x: 10,
      reversed: true,
      itemMarginBottom: 5,
      itemDistance: 8,
      itemWidth: 80,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolRadius: 0,
      width: 255,
      itemStyle: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: '400',
      },
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      lineColor: lightModeColors.neutrals.neutral20,
      lineWidth: 1,
      tickColor: lightModeColors.neutrals.neutral20,
      minorTickLength: 0,
      tickLength: 0,
      height: 45,
    },
    yAxis: {
      gridLineColor: lightModeColors.neutrals.neutral10,
      gridLineWidth: 1,
      height: 45,
      min: 0,
      max: 100,
      tickAmount: 11,
      endOnTick: false,
      labels: {
        style: {
          textOverflow: 'none',
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return `${this.value}%`
          }
          return ''
        },
      },
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      column: {
        pointWidth: 35,
      },
      series: {
        stacking: 'normal',
        borderWidth: 0,
      },
    },
    series: series.reverse(),
  }
  return [options]
}
