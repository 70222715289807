import React from 'react'
import styled, { css } from 'styled-components'
import { MyAccountList } from '../my-account-list'
import { HidingErrorBoundary } from '~components/error-boundary/error-boundary'

const StyledMobileNavigation = styled.nav<{ isOpen: boolean }>`
  order: 9999;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.navigation.navPrimary};
  transition: max-height 0.48s ease-in-out;
  max-height: 0rem;
  z-index: 99;
  top: 4.7375rem;
  width: 100%;
  display: block;
  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 52rem;
    `}
  ul:not(:first-child) {
    margin-top: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(600)}) {
    display: none;
  }
`

interface MobileNavigationProps {
  isMobileNavigationOpened: boolean
  onLogout: () => void
  onMobileNavigationToggle: (isSubMenuOpened: boolean) => void
  isMobileView: boolean
  isTouchable: boolean
  zendeskUrl: string
  hasPanSubscriptionSearchMenuItem: boolean
}

export const MobileNavigation = ({
  isMobileNavigationOpened,
  onLogout,
  onMobileNavigationToggle,
  isMobileView,
  isTouchable,
  zendeskUrl,
  hasPanSubscriptionSearchMenuItem,
}: MobileNavigationProps): JSX.Element => (
  <StyledMobileNavigation
    role="navigation"
    aria-label="Pharmaspectra Mobile"
    aria-hidden={!isMobileNavigationOpened || !isMobileView}
    id="id_mobile_global_navigation"
    isOpen={isMobileNavigationOpened}
    onFocus={() => onMobileNavigationToggle(true)}
    onBlur={() => onMobileNavigationToggle(false)}
  >
    <HidingErrorBoundary>
      <MyAccountList
        isMyAccountListVisible={isMobileNavigationOpened}
        onLogout={onLogout}
        isTouchable={isTouchable}
        zendeskUrl={zendeskUrl}
        hasPanSubscriptionSearchMenuItem={hasPanSubscriptionSearchMenuItem}
      />
    </HidingErrorBoundary>
  </StyledMobileNavigation>
)
