import styled, { css } from 'styled-components'
import { browserTruncateMixin, Flex, shineAnimation } from '~common'
import { ChevronIcon } from '~components/ui/atoms/chevron-icon'

export const StyledCrumbtrailNav = styled.nav<{ isLoading: boolean }>`
  background: ${({ theme }) => theme.colors.navigation.crumbBackground};
  padding: 0.5rem 1rem;
  min-height: 2rem;
  ${({ isLoading }) =>
    isLoading &&
    css`
      background: ${({ theme }) =>
        `linear-gradient(110deg, ${theme.colors.neutrals.neutral10} 8%, ${theme.colors.neutrals.neutral5} 18%, ${theme.colors.neutrals.neutral10} 33%)`};
      background-size: 200% 100%;
      animation: 1s ${shineAnimation} linear infinite;
    `}
`

export const StyledCrumbtrailList = styled(Flex).attrs({ as: 'ul' })``

export const StyledItem = styled(Flex).attrs({ as: 'li' })`
  align-items: center;
  font-weight: 500;
  & > a > span,
  & > span {
    display: none;
  }
  & > a {
    font-weight: 500;
    z-index: 998;
    ${browserTruncateMixin}
  }
  &:not(:nth-last-child(-n + 2)) > a {
    width: 0.625rem;
    &:before {
      content: '...';
    }
  }
  &:nth-last-child(-n + 2) {
    ${browserTruncateMixin}
    min-width: 2.1875rem;
    flex-shrink: 1;
    & > svg {
      flex-shrink: 0;
    }
  }
  &:not(:last-child) > a {
    color: ${({ theme }) => theme.colors.textOn.textOnCrumb};
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.text.textHoverSecondary};
    }
  }
  &:last-child {
    ${({ theme }) => theme.textSizes.desktop.buttonS};
    color: ${({ theme }) => theme.colors.textOn.textOnPrimary};
    width: fit-content;
    display: inline-block;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(480)}) {
    ${browserTruncateMixin}
    min-width: 2.1875rem;
    & > svg {
      flex-shrink: 0;
    }
    &:last-child {
      flex-shrink: 0;
    }
    &:not(:nth-last-child(-n + 2)) {
      flex-shrink: 2;
      & > a {
        width: auto;
        &:before {
          content: none;
        }
      }
    }
    & > a > span,
    & > span {
      display: inline;
    }
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    &:nth-last-child(-n + 2) {
      flex-shrink: 0;
    }
  }
`

export const StyledChevronIcon = styled(ChevronIcon)`
  fill: ${({ theme }) => theme.colors.textOn.textOnPrimary};
`
