import styled, { css } from 'styled-components'
import { Flex, StyledSection } from '~common'
import { Divider } from '~components/ui/atoms/divider'
import { YAxisLabels } from './ChartEmptyState'

export const StyledEmptyStackedBarChartPlaceholder = styled(Flex)`
  height: 345px;
  margin-left: 20px;
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  margin-top: 2.0312rem;
  overflow: hidden;
`

export const StyledEmptyChartPlaceholder = styled.div<{ yAxisLabels?: YAxisLabels; isPercentages?: boolean }>`
  height: 91%;
  padding-top: 0.125rem;
  margin-left: ${({ isPercentages }) => (isPercentages ? '30px' : '20px')};
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  &:before {
    content: ${({ yAxisLabels }) => yAxisLabels && `'${yAxisLabels.top}'`};
    position: absolute;
    top: 3px;
    left: ${({ isPercentages }) => (isPercentages ? '-30px' : '-10px')};
    color: ${({ theme }) => theme.colors.text.textSecondary};
    font-size: 0.6875rem;
    line-height: 1rem;
  }
  &:after {
    content: ${({ yAxisLabels }) => yAxisLabels && `'${yAxisLabels.bottom}'`};
    position: absolute;
    bottom: -9px;
    left: ${({ isPercentages }) => (isPercentages ? '-20px' : '-10px')};
    color: ${({ theme }) => theme.colors.text.textSecondary};
    font-size: 0.6875rem;
    line-height: 1rem;
  }
`

export const StyledSectionLine = styled(StyledSection)`
  margin-top: 2.0312rem;
`

export const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutrals.neutral10};
  ${({ borderDirection }) =>
    borderDirection &&
    css`
      margin-right: 75px;
      margin-left: 0;
    `}
`

export const StyledTreeMapChartEmptyState = styled.div`
  height: 15rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  margin-bottom: 1rem;
`
