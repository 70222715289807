import React from 'react'
import { StyledContainer, StyledItem } from './styles'

const Container = ({ children, className }: { children: React.ReactNode; className?: string }): JSX.Element => (
  <StyledContainer className={className}>{children}</StyledContainer>
)

export interface ItemProps extends React.HTMLAttributes<HTMLElement> {
  itemWidth: 'narrow' | 'widest'
  className?: string
  asTag?: string
  isTransparent?: boolean
}

const Item: React.FC<ItemProps> = ({
  itemWidth,
  asTag = 'section',
  children,
  className,
  isTransparent = false,
  ...rest
}) => (
  <StyledItem
    as={asTag as never}
    className={`grid-item ${className || ''}`}
    itemWidth={itemWidth}
    isTransparent={isTransparent}
    {...rest}
  >
    {children}
  </StyledItem>
)

export const SoSVGrid = {
  Container,
  Item,
}
