import React from 'react'
import styled from 'styled-components'
import { Flex } from '~common'

const StyledCollectionRank = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.responsive.textOnRankHeader};
  background-color: ${({ theme }) => theme.colors.responsive.rankHeaderBackground};
  ${({ theme }) => theme.textSizes.desktop.rankXs}
  padding: 0.25rem;
  width: 100%;
`

export interface CollectionRankProps extends React.HTMLAttributes<HTMLDivElement> {
  rank: number | string
  asTag?: string
}

export const CollectionRank = ({ rank, asTag, ...rest }: CollectionRankProps): JSX.Element => (
  <StyledCollectionRank as={asTag as never} {...rest}>
    {rank}
  </StyledCollectionRank>
)
