export const getProfileStatusDisplay = (profileStatus: string | null | undefined): string | undefined => {
  if (!profileStatus) return undefined

  switch (profileStatus.trim().toLowerCase()) {
    case 'deceased':
      return 'Deceased'

    case 'retired':
      return 'Retired'

    case 'move to pharma':
      return 'Moved to industry'

    case 'live':
    default:
      return undefined
  }
}
