import React, { RefObject, useRef } from 'react'
import { useViewportHeight } from '~common/hooks'
import { StyledContainer, StyledItemWrapper } from './styles'

export type TGridItemWidth = 'narrow' | 'wide' | 'wider' | 'widest'
export type TGridItemBackgroundVariation = 'transparent' | 'primary' | 'secondary'

const Container = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <StyledContainer onTouchEnd={() => window.scrollTo(0, 0)}>{children}</StyledContainer>
)

type ItemProps = React.HTMLAttributes<HTMLElement> & {
  itemWidth: TGridItemWidth
  asTag?: string
  isOutOfFlow?: boolean
  isExpanded?: boolean
  gridItemRef?: RefObject<HTMLElement>
  onScroll?: React.UIEventHandler<Element>
  isScrollable?: boolean
  backgroundVariation?: TGridItemBackgroundVariation
}

const Item: React.FC<ItemProps> = ({
  children,
  gridItemRef,
  itemWidth,
  onScroll,
  asTag = 'section',
  backgroundVariation = 'secondary' as TGridItemBackgroundVariation,
  isOutOfFlow = false,
  isExpanded = true,
  isScrollable = true,
  ...rest
}) => {
  const itemRef = useRef<HTMLElement | null>(null)
  const viewportHeight = useViewportHeight()
  return (
    <StyledItemWrapper
      as={asTag as never}
      itemWidth={itemWidth}
      backgroundVariation={backgroundVariation}
      isOutOfFlow={isOutOfFlow}
      isScrollable={isScrollable}
      isExpanded={isExpanded}
      viewportHeight={viewportHeight}
      containerOffsetTop={gridItemRef?.current?.offsetTop || itemRef.current?.offsetTop || 0}
      ref={gridItemRef || itemRef}
      onScroll={onScroll}
      {...rest}
    >
      {children}
    </StyledItemWrapper>
  )
}

export const InBoxGrid = {
  Container,
  Item,
}
