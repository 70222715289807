import { ApolloClient, ApolloLink, FieldPolicy, from, HttpLink, InMemoryCache, split } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { Config } from '~common'
import { getJwtTokenOrSignOut } from '~common/auth'

/* Annoyingly there is an issue currently with appsync and apollo 3 (https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/448)
 * so until that get resolved we have a workaround to make it work with the solution
 * provided here (https://github.com/awslabs/aws-mobile-appsync-sdk-js/pull/561)
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let sharedApolloClient: any | null

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createClient = (config: Config) => {
  if (sharedApolloClient) {
    return sharedApolloClient
  }

  const httpLink = new HttpLink({
    uri: config.GRAPHQL_ENDPOINT,
    // Use explicit `window.fetch` so that outgoing requests
    // are captured and deferred until the Service Worker is ready.
    fetch: (...args) => fetch(...args),
  })

  const scopeTokenLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: Record<string, string> }) => {
      const scopeToken = window.localStorage.getItem('scopeToken')
      if (scopeToken) {
        return {
          headers: {
            'x-scope-token': scopeToken,
            ...headers,
          },
        }
      }
    })
    return forward(operation)
  })

  // https://www.apollographql.com/docs/react/pagination/key-args/
  const createArrayCacheOptions = (keyArgs: false | string[] = false): FieldPolicy => ({
    keyArgs,
    merge: (existing = [], incoming = []) => {
      return [...existing, ...incoming]
    },
  })

  const createArrayWithMetaCacheOptions = (keyArgs: false | string[] = false): FieldPolicy => ({
    keyArgs,
    merge: (existing = { total: 0, items: [] }, incoming = { total: 0, items: [] }) => ({
      total: incoming.total,
      items: [...existing.items, ...incoming.items],
    }),
  })

  const createPaginatedArrayCacheOptions = (
    keyArgs: false | string[] = false,
    pageNumberArg = 'currentPage',
    pageSizeArg = 'pageSize',
  ): FieldPolicy => ({
    keyArgs,
    merge: (existing: unknown[], incoming: unknown[], { args }) => {
      if (!args) return [...existing, ...incoming]
      const merged = existing ? existing.slice(0) : []
      merged.splice(args[pageNumberArg] * args[pageSizeArg], incoming.length, ...incoming)
      return merged
    },
  })

  const createSearchContentCacheOptions = (keyArgs: false | string[] = false): FieldPolicy => ({
    keyArgs,
    merge: (
      existing = { profileId: '', fullName: '', totalItems: 0, items: [] },
      incoming = { profileId: '', fullName: '', totalItems: 0, items: [] },
    ) => ({
      profileId: incoming.profileId,
      fullName: incoming.fullName,
      totalItems: incoming.totalItems,
      searched: incoming.searched,
      highlightedTerms: incoming.highlightedTerms,
      items: [...existing.items, ...incoming.items],
    }),
  })

  const createSearchSocialsContentCacheOptions = (keyArgs: false | string[] = false): FieldPolicy => ({
    keyArgs,
    merge: (
      existing = { profileId: '', fullName: '', totalItems: 0, totalListItems: 0, items: [] },
      incoming = { profileId: '', fullName: '', totalItems: 0, totalListItems: 0, items: [] },
    ) => ({
      profileId: incoming.profileId,
      fullName: incoming.fullName,
      searched: incoming.searched,
      totalItems: incoming.totalItems,
      totalListItems: incoming.totalListItems,
      items: [...existing.items, ...incoming.items],
    }),
  })

  const createObjectCacheOptions = (keyArgs: false | string[] = false): FieldPolicy => ({
    keyArgs,
    merge: (existing = {}, incoming = {}) => ({ ...existing, ...incoming }),
  })

  const apolloClient = new ApolloClient({
    connectToDevTools: process.env.ENV != 'prod',
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getPresentationsForProfile: createArrayCacheOptions(['profileId', 'listId']),
            getSubscriptionById: createObjectCacheOptions(['listId', 'savedListId']),
            getProfileByIdFromDDB: createObjectCacheOptions(['profileId', 'listId']),
            getClinicalTrialsForProfile: createArrayCacheOptions(['profileId', 'listId']),
            getJournalArticlesForProfile: createArrayCacheOptions(['profileId', 'listId']),
            getCoJournalArticles: createArrayWithMetaCacheOptions(['profileId', 'listId', 'coAuthorProfileId']),
            getCoPresentations: createArrayWithMetaCacheOptions(['profileId', 'listId', 'coAuthorProfileId']),
            getCoClinicalTrials: createArrayWithMetaCacheOptions(['profileId', 'listId', 'getCoClinicalTrials']),
            getBooksForProfile: createArrayCacheOptions(['profileId', 'listId']),
            getProfilesInList: {
              keyArgs: ['listId', 'savedListId', 'smartListId', 'sortBy', 'disclosureCategories', 'dataType'],
              merge: (
                existing = { totalProfiles: 0, profiles: [] },
                incoming = { totalProfiles: 0, profiles: [] },
                { args },
              ) => {
                if (!args) return { totalProfiles: 0, profiles: [...existing.profiles, ...incoming.profiles] }
                const merged = existing.profiles ? existing.profiles.slice(0) : []
                const offset = args.currentPage * args.pageSize
                merged.splice(offset, incoming.profiles.length, ...incoming.profiles)
                return {
                  totalProfiles: existing.totalProfiles > 0 ? existing.totalProfiles : incoming.totalProfiles,
                  profiles: merged,
                }
              },
            },
            searchProfilesAcrossSubscriptions: {
              keyArgs: ['sortBy', 'pills', 'contentTypes', 'disclosureCategories', 'timeframe', 'startDate', 'endDate'],
              merge: (existing = { totalProfiles: 0, profiles: [] }, incoming = { totalProfiles: 0, profiles: [] }) => {
                return {
                  totalProfiles: existing.totalProfiles > 0 ? existing.totalProfiles : incoming.totalProfiles,
                  profiles: [...existing.profiles, ...incoming.profiles],
                }
              },
            },
            searchClinicalTrialsForProfile: createSearchContentCacheOptions([
              'profileId',
              'listId',
              'pills',
              'timeframe',
              'startDate',
              'endDate',
            ]),
            searchJournalArticlesForProfile: createSearchContentCacheOptions([
              'profileId',
              'listId',
              'pills',
              'timeframe',
              'startDate',
              'endDate',
            ]),
            searchPresentationsForProfile: createSearchContentCacheOptions([
              'profileId',
              'listId',
              'pills',
              'timeframe',
              'startDate',
              'endDate',
            ]),
            searchSocialsForProfile: createSearchSocialsContentCacheOptions([
              'profileId',
              'listId',
              'pills',
              'timeframe',
              'startDate',
              'endDate',
            ]),
            getNetworkConnectionsForProfile: {
              keyArgs: ['profileId', 'listId', 'dataType'],
              merge: (
                existing = { connectionsInListCount: 0, totalConnectionsCount: 0, connections: [] },
                incoming = { connectionsInListCount: 0, totalConnectionsCount: 0, connections: [] },
              ) => {
                const existingIds = new Set(
                  existing.connections.map((connection: { profileId: string }) => connection.profileId),
                )
                const uniqueIncomingConnections = incoming.connections.filter(
                  (connection: { profileId: string }) => !existingIds.has(connection.profileId),
                )

                return {
                  connectionsInListCount: incoming.connectionsInListCount,
                  totalConnectionsCount: incoming.totalConnectionsCount,
                  connections: [...existing.connections, ...uniqueIncomingConnections],
                }
              },
            },
            getProfileSoSVPresentations: createArrayCacheOptions([
              'profileId',
              'listId',
              'contentTypes',
              'drugSelection',
              'topicSelection',
              'meetingId',
              'timeframe',
              'startDate',
              'endDate',
            ]),
            getProfileSoSVJournalArticles: createArrayCacheOptions([
              'profileId',
              'listId',
              'contentTypes',
              'drugSelection',
              'topicSelection',
              'journalId',
              'timeframe',
              'startDate',
              'endDate',
            ]),
            getSoSVJournalArticles: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'journalId',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'geographyFilter',
              'topicSelection',
              'source',
              'savedListId',
              'smartListId',
            ]),
            getSoSVPresentations: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'meetingId',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'geographyFilter',
              'topicSelection',
              'source',
              'savedListId',
              'smartListId',
            ]),
            getSoSVJournals: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'topicSelection',
              'weighted',
              'sortBy',
              'source',
              'savedListId',
              'smartListId',
            ]),
            getSoSVHubExperts: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'contentTypes',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'geographyFilter',
              'topicSelection',
              'weighted',
              'sortBy',
              'savedListId',
              'smartListId',
            ]),
            getSoSVHubExpertLocations: createObjectCacheOptions([
              'listId',
              'productGroupId',
              'contentTypes',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'geographyFilter',
              'topicSelection',
              'weighted',
              'savedListId',
              'smartListId',
            ]),
            getSoSVSocieties: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'topicSelection',
              'weighted',
              'sortBy',
              'source',
              'savedListId',
              'smartListId',
            ]),
            getSoSVMeetings: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'societyId',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'topicSelection',
              'weighted',
              'sortBy',
              'source',
              'savedListId',
              'smartListId',
            ]),
            getSoSVGeographies: createPaginatedArrayCacheOptions([
              'listId',
              'productGroupId',
              'timeframe',
              'startDate',
              'endDate',
              'drugSelection',
              'topicSelection',
              'contentTypes',
              'weighted',
              'sortBy',
              'savedListId',
              'smartListId',
            ]),
          },
        },
        ProfileType: {
          keyFields: ['profileId'],
        },
      },
      addTypename: false,
    }),
    link: from([
      createAuthLink({
        url: config.GRAPHQL_ENDPOINT,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: getJwtTokenOrSignOut,
        },
        region: config.GRAPHQL_REGION,
      }),
      scopeTokenLink,
      new RetryLink(),
      split(
        (op) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { operation } = op.query.definitions[0] as any

          return operation !== 'subscription'
        },
        httpLink,
        createSubscriptionHandshakeLink(
          {
            auth: {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: getJwtTokenOrSignOut,
            },
            region: config.GRAPHQL_REGION,
            url: config.GRAPHQL_ENDPOINT,
          },
          httpLink,
        ),
      ),
    ]),
  })

  if (!sharedApolloClient) {
    sharedApolloClient = apolloClient
  }

  return apolloClient
}
