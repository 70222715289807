import styled from 'styled-components'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'

export const StyledProductsMentionsByTimeframeLineChart = styled(StyledChart)<{
  productColors: string[]
  series: Highcharts.SeriesOptionsType[]
}>`
  height: 11.875rem;
  .highcharts-graph {
    stroke-width: 1px;
  }
  .highcharts-point {
    stroke-width: 0;
  }
  ${({ productColors, series }) => getProductColorsCss({ productColors, series, isLineChart: true })};
  @media (min-width: ${({ theme }) => theme.pxsToEms(2315)}) {
    .highcharts-legend {
      display: none;
    }
  }
`
