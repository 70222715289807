import React from 'react'
import { CustomSVG } from '~components/ui/nucleons/custom-svg'
import { StyledDisplayedOnDesktop, StyledHiddenOnDesktop } from './styles'

export const Experts = (): JSX.Element => (
  <>
    <CustomSVG>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C16 7.76142 13.7614 10 11 10C8.23858 10 6 7.76142 6 5C6 2.23858 8.23858 0 11 0C13.7614 0 16 2.23858 16 5ZM14 5C14 6.65685 12.6569 8 11 8C9.34315 8 8 6.65685 8 5C8 3.34315 9.34315 2 11 2C12.6569 2 14 3.34315 14 5Z"
      />
      <path d="M14 14C14 13.4477 13.5523 13 13 13H9C8.44771 13 8 13.4477 8 14V16H6V14C6 12.3431 7.34315 11 9 11H13C14.6569 11 16 12.3431 16 14V16H14V14Z" />
      <path d="M5 3.12602C4.68038 3.04375 4.3453 3 4 3C1.79086 3 0 4.79086 0 7C0 9.20914 1.79086 11 4 11C4.3453 11 4.68038 10.9562 5 10.874V8.73244C4.70583 8.90261 4.36429 9 4 9C2.89543 9 2 8.10457 2 7C2 5.89543 2.89543 5 4 5C4.36429 5 4.70583 5.09739 5 5.26756V3.12602Z" />
      <path d="M2 12H5V14H2V16H0V14C0 12.8954 0.895431 12 2 12Z" />
    </CustomSVG>
    <span>Experts</span>
  </>
)

export const SoSV = (): JSX.Element => (
  <>
    <CustomSVG>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2H14V10H12V11H11.9999V12V12.9194L10.8507 12H10.8594L8.35156 10H2V2ZM7.64546 12H0V0H16V12H13.9999V16H12.5781L7.64546 12Z"
      />
    </CustomSVG>
    <span>
      <StyledHiddenOnDesktop>SoSV</StyledHiddenOnDesktop>
      <StyledDisplayedOnDesktop>Share of Scientific Voice™</StyledDisplayedOnDesktop>
    </span>
  </>
)

export const Science = (): JSX.Element => (
  <>
    <CustomSVG>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14L13 14V7H8V2L3 2L3 14ZM10 2.82843V5H12.1716L10 2.82843ZM10 0L15 5L15 16L1 16V0H10Z"
      />
    </CustomSVG>
    <span>Science</span>
  </>
)

export const PLI = (): JSX.Element => (
  <>
    <CustomSVG>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2222 14.2222H1.77778V1.77778H8V0H0V1.77778V16C0 16 0.791111 16 1.77778 16H16C16 16 16 15.2 16 14.2222V8H14.2222V14.2222ZM9.77778 0V1.77778H12.9689L4.23111 10.5156L5.48444 11.7689L14.2222 3.03111V6.22222H16V0H9.77778Z"
      />
    </CustomSVG>
    <span>PLI</span>
  </>
)

export const OKAI = (): JSX.Element => (
  <>
    <CustomSVG>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2222 14.2222H1.77778V1.77778H8V0H0V1.77778V16C0 16 0.791111 16 1.77778 16H16C16 16 16 15.2 16 14.2222V8H14.2222V14.2222ZM9.77778 0V1.77778H12.9689L4.23111 10.5156L5.48444 11.7689L14.2222 3.03111V6.22222H16V0H9.77778Z"
      />
    </CustomSVG>
    <span>Accelerated Insights</span>
  </>
)
