import React from 'react'
import { ExpertLoadingBlock } from './components/ExpertLoadingBlock'
import { PageTitleBarLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { StyledContentLoadingBlock } from './styles'
import { useExpertEcosystemContext } from '~common/providers'

export const NetworkAndSearchPageLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <>
      <PageTitleBarLoadingBlock />
      <StyledContentLoadingBlock>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
          <ExpertLoadingBlock key={item} />
        ))}
      </StyledContentLoadingBlock>
    </>
  )
}
