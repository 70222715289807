import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import crypto from 'crypto-js'
import { Checkbox } from '~components/ui/atoms/checkbox'
import { Heading } from '~components/ui/atoms/heading'
import { Input } from '~components/ui/molecules/input'
import { Button } from '~components/ui/atoms/button'
import {
  StyledAttributesContainer,
  StyledConfigurationContainer,
  StyledDashboardContainer,
  StyledLoadForm,
} from '~components/pages/widgets/dashboard/styles'
import { StyledSelectWithSystemDropdown } from '~components/ui/hubs-navigation/styles'
import { Select } from '~components/ui/molecules/select'
import { EventMap, useFrameMessageBus } from '~components/pages/widgets/use-frame-message-bus'

type QueryParams = {
  clientId: string
  timestamp: number
  key: string
}

const pad = (value: number): string => {
  if (value < 10) {
    return '0' + value
  }
  return value + ''
}

const formatDate = (date: Date): string => {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds())
  )
}

interface IFrameEvents extends EventMap {
  authComplete: (payload: {
    isSuccess: boolean
    error?: Error | null
    lists: Array<{ id: string; name: string; sources: string[] }>
  }) => void
  changeList: (payload: { listId: string; source?: string }) => void
  dataLoading: (payload: { isLoading: boolean }) => void
}

type WidgetType = 'sosv' | 'experts'

export const Dashboard: React.FC = () => {
  const [widgetType, setWidgetType] = useState<WidgetType>('sosv')
  const [profileId, setProfileId] = useState<string | undefined>(undefined)
  const [includeListIdInQueryString, setIncludeListIdInQueryString] = useState<boolean>(true)
  const [listOptions, setListOptions] = useState<Array<{ id: string; name: string; sources: string[] }>>([])
  const [sourceOptions, setSourceOptions] = useState<Array<string>>([])
  const [clientId, setClientId] = useState('')
  const [secret, setSecret] = useState('')
  const [dateTime, setDateTime] = useState(formatDate(new Date()))
  const [listId, setListId] = useState<string | undefined>(undefined)
  const [sourceId, setSourceId] = useState<string | undefined>(undefined)
  const [iframeQueryParams, setIframeQueryParams] = useState<QueryParams | null>(null)
  const [authComplete, setAuthComplete] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''
  const iframe = useRef<HTMLIFrameElement>(null)
  const frameMessageBus = useFrameMessageBus<IFrameEvents>()

  useEffect(() => {
    const unsubscribeOnAuthComplete = frameMessageBus.on('authComplete', (payload) => {
      if (payload.error) {
        setError(payload.error)
      } else {
        setError(null)
      }
      setAuthComplete(payload.isSuccess)
      setListOptions(payload.lists)
      setListId(payload.lists.length ? payload.lists[0].id : undefined)
      setSourceOptions(
        payload.lists.length && payload.lists[0].sources && payload.lists[0].sources.length
          ? payload.lists[0].sources
          : [],
      )
      setSourceId(
        payload.lists.length && payload.lists[0].sources && payload.lists[0].sources.length
          ? payload.lists[0].sources[0]
          : undefined,
      )
    })

    const unsubscribeOnDataLoading = frameMessageBus.on('dataLoading', (payload) => {
      setDataLoading(payload.isLoading)
    })

    return () => {
      unsubscribeOnAuthComplete()
      unsubscribeOnDataLoading()
    }
  }, [frameMessageBus, setListOptions])

  const handleChangeClientId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setClientId(event.target.value)
  }, [])

  const handleChangeDateTime = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTime(event.target.value)
  }, [])

  const handleSecretChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(event.target.value)
  }, [])

  const handleProfileIdChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileId(event.target.value)
  }, [])

  const handleListChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const list = listOptions.find((l) => l.id === event.target.value)
      setListId(list?.id)
      setSourceOptions(list?.sources ?? [])
      setSourceId(list?.sources?.[0])
    },
    [listOptions],
  )

  const handleSubmitClientIdForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const timestamp = new Date(dateTime).getTime()
      const key = encodeURIComponent(crypto.HmacSHA256(`${clientId};${timestamp}`, secret).toString(crypto.enc.Base64))
      setIframeQueryParams({
        clientId,
        timestamp,
        key,
      })
    },
    [clientId, dateTime, secret],
  )

  useEffect(() => {
    if (iframe.current && iframe.current.contentWindow) {
      frameMessageBus.emit(iframe.current.contentWindow, 'changeList', { listId: listId as string, source: sourceId })
    }
  }, [listId, sourceId, frameMessageBus])

  if (error) {
    throw error
  }

  const listIdParam = includeListIdInQueryString && listId ? `listId=${listId}&` : ''
  const widgetPath = widgetType === 'sosv' ? 'sosv?' : `experts/${profileId}?${listIdParam}`
  const widgetUrl = iframeQueryParams
    ? `${origin}/widgets/${widgetPath}${Object.entries(iframeQueryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    : ''

  return (
    <>
      <Head>
        <title>Widget Dashboard | Pharmaspectra</title>
      </Head>
      <StyledDashboardContainer>
        <StyledConfigurationContainer>
          <div>
            <Heading level="3">Load form</Heading>
            <StyledLoadForm onSubmit={handleSubmitClientIdForm}>
              <Input
                inputSize={'sm'}
                value={clientId}
                onChange={handleChangeClientId}
                label={'Client identifier'}
                labelPosition={'top'}
              />

              <Input
                inputSize={'sm'}
                value={dateTime}
                onChange={handleChangeDateTime}
                label={'Timestamp'}
                labelPosition={'top'}
                type="datetime-local"
              />

              <Input
                inputSize={'sm'}
                value={secret}
                placeholder={'Please provide shared secret key'}
                onChange={handleSecretChange}
                label={'Secret'}
                labelPosition={'top'}
              />

              <Checkbox
                id={'sosv'}
                isChecked={widgetType === 'sosv'}
                label={'SoSV'}
                onChange={() => setWidgetType('sosv')}
              />
              <Checkbox
                id={'experts'}
                isChecked={widgetType === 'experts'}
                label={'Experts'}
                onChange={() => setWidgetType('experts')}
              />

              {widgetType === 'experts' && (
                <>
                  <Input
                    inputSize={'sm'}
                    value={profileId}
                    placeholder={'Please provide profile id'}
                    onChange={handleProfileIdChange}
                    label={'Profile Id'}
                    labelPosition={'top'}
                  />

                  <Checkbox
                    id={'includeListIdInQueryString'}
                    isChecked={includeListIdInQueryString}
                    label={'Include listId in query string'}
                    onChange={() => setIncludeListIdInQueryString(!includeListIdInQueryString)}
                  />
                </>
              )}

              <Button size={'sm'} type={'submit'}>
                Load Widget
              </Button>
            </StyledLoadForm>
          </div>
          <div>
            <div>
              <Heading level="3">Query parameters</Heading>
              <StyledAttributesContainer>
                <div>
                  <b>Widget URL (5min valid): </b>
                </div>
                <div>
                  {iframeQueryParams ? (
                    <a title="embeddable widget url" target="_blank" rel={'noreferrer'} href={widgetUrl}>
                      {widgetUrl}
                    </a>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <b>Client identifier:</b>{' '}
                </div>{' '}
                <div>{iframeQueryParams?.clientId ?? ''}</div>
                <div>
                  <b>Timestamp:</b>
                </div>
                <div> {iframeQueryParams?.timestamp ?? ''}</div>
                <div>
                  <b>Key:</b>
                </div>
                <div> {iframeQueryParams?.key ? decodeURIComponent(iframeQueryParams.key) : ''}</div>
              </StyledAttributesContainer>
            </div>
            <div>
              <Heading level="3">Status</Heading>
              <div>
                <span style={{ marginRight: '1em' }}>
                  <b>Authenticated: </b> {authComplete + ''}
                </span>
                <span>
                  <b>Data loading: </b> {dataLoading + ''}
                </span>
              </div>
            </div>
            <div>
              <Heading level="3">List selection</Heading>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '1em' }}>
                <div>
                  <StyledSelectWithSystemDropdown
                    id="subscriptions"
                    selectDropdownSize="md"
                    isDisabled={!authComplete}
                    placeholder="Select subscription..."
                    value={listOptions.find((el) => el.id === listId)?.id}
                    onChange={handleListChange}
                  >
                    {listOptions.map(({ id, name }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </StyledSelectWithSystemDropdown>
                </div>
                {widgetType === 'sosv' && (
                  <>
                    <div>
                      <StyledSelectWithSystemDropdown
                        id="sources"
                        selectDropdownSize="md"
                        isDisabled={!authComplete}
                        placeholder="Select source..."
                        value={sourceId}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSourceId(e.target.value)}
                      >
                        {sourceOptions.map((value) => (
                          <Select.Option value={value} key={value}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                          </Select.Option>
                        ))}
                      </StyledSelectWithSystemDropdown>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </StyledConfigurationContainer>
        {iframeQueryParams && (
          <div
            style={{
              flex: 1,
            }}
          >
            <Heading level="3">Preview in iframe</Heading>
            <iframe
              ref={iframe}
              style={{ height: '95%', width: '100%' }}
              title={'external-widget'}
              src={widgetUrl}
            ></iframe>
          </div>
        )}
      </StyledDashboardContainer>
    </>
  )
}
