import styled from 'styled-components'
import { Controlled } from '~components/ui/controlled'
import { SelectWithSystemDropdown } from '~components/ui/molecules/select'

export const StyledFiltersGroup = styled.div`
  display: flex;
  justify-content: start;
  & > * {
    flex-grow: 1;
  }
  & > *:first-child {
    margin-right: 0.5rem;
  }
`

export const StyledBinarySwitch = styled(Controlled.BinarySwitch)`
  width: 100%;
`

export const StyledTopicsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  & > * {
    max-width: 7.3125rem;
    align-items: center;
    word-break: break-all;
  }
`

export const StyledProductsGrid = styled(StyledTopicsGrid)`
  margin-top: 0.5rem;
`

export const StyledListSelect = styled(SelectWithSystemDropdown)`
  select {
    max-width: none;
    width: 100%;
  }
`

export const StyledListSection = styled.section`
  margin-bottom: 1rem;
`

export const StyledDatePickerDateRange = styled(Controlled.DatePickerDateRange)`
  margin-top: 0.5rem;
`
