import React from 'react'
import { useExpertEcosystemContext } from '~common/providers'
import { ExpertLoadingBlock } from './ExpertLoadingBlock'

export const ExpertsListLoadingBlocks = ({ blocksLength = 10 }: { blocksLength?: number }): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <>
      {[...new Array(blocksLength)].map((_item, i) => (
        <ExpertLoadingBlock key={i} />
      ))}
    </>
  )
}
