import React, { useContext, useEffect, useState } from 'react'
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece'
import { useIdTokenLogin } from '~common/hooks/use-id-token-login'
import { useRouter } from 'next/router'
import { RedirectManagerContext } from '~common/hooks/use-redirect-manager'

export const authStageNames = {
  signIn: 'signIn',
  signedIn: 'signedIn',
  requireNewPassword: 'requireNewPassword',
  recoveryPassword: 'recoveryPassword',
  forgotPassword: 'forgotPassword',
}

export const Authentication = (props: IAuthPieceProps): JSX.Element => {
  useIdTokenLogin()
  const { manualLogout } = useContext(RedirectManagerContext)
  const [idToken, setIdToken] = useState('')
  const { query, isReady } = useRouter()
  const { authState } = props
  const isLocal = process?.env?.IS_LOCAL === 'true'

  const { id_token: queryIdToken, user_email: queryUserEmail } = query

  useEffect(() => {
    if (isReady && !isLocal) {
      if (queryIdToken) setIdToken(queryIdToken as string)
      if (authState === authStageNames.signIn && !idToken && !queryIdToken) {
        const redirectUrl = new URL(window.location.href)
        redirectUrl.searchParams.delete('user_email')
        window.location.href =
          (process?.env?.LOGOUT_URL || 'https://products.pharmaspectra.com') +
          (!manualLogout
            ? `?redirect_url=${encodeURIComponent(redirectUrl.href)}${
                queryUserEmail ? `&user_email=${encodeURIComponent(queryUserEmail as string)}` : ''
              }`
            : '')
      }
    }
  }, [authState, idToken, queryIdToken, queryUserEmail, isReady, isLocal, manualLogout])
  return <></>
}
