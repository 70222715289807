import styled from 'styled-components'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'
import { Flex } from '~common'

export const StyledExportWrapper = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
`

export const StyledProductsMentionsLineChart = styled(StyledChart)<{
  productColors: string[]
  series: Highcharts.SeriesOptionsType[]
}>`
  height: 100%;
  .highcharts-graph {
    stroke-width: 2px;
  }
  .highcharts-point {
    stroke-width: 0;
  }
  .custom-products-mentions-area-chart {
    height: 10.6875rem !important;
    @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
      height: 23.8125rem !important;
    }
    & > svg {
      height: 10.6875rem !important;
      @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
        height: 23.8125rem !important;
      }
    }
  }
  ${({ productColors, series }) => getProductColorsCss({ productColors, series, isLineChart: true })};
`
