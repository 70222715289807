import * as Highcharts from 'highcharts'
import {
  capitalizeFirstLetter,
  locale,
  sosvTimeframeLabels,
  toCamelCase,
  camelCaseToSentenceCase,
  formattedDate,
  generateSoSVModuleTitle,
  DateBreakdown,
  DateInterval,
  roundDecimals,
} from '~common'
import { CustomPointOptionsObject } from './sosv'
import { ExportChartConfig } from './types'

export const getProductColorsCss = ({
  productColors,
  series,
  isLineChart,
}: {
  productColors: string[]
  series: Highcharts.SeriesOptionsType[] | Highcharts.PointOptionsObject[]
  isLineChart?: boolean
}): string =>
  [...series].reduce(
    (acc: string, cur: Highcharts.SeriesOptionsType | Highcharts.PointOptionsObject, i: number): string =>
      (acc += `.${cur.className} { fill: ${productColors[i]} !important; ${
        isLineChart
          ? `path.highcharts-point {
          fill: ${productColors[i]} !important;
          stroke: ${productColors[i]} !important;
        } rect.highcharts-point {
          fill: ${productColors[i]} !important;
          stroke: ${productColors[i]} !important;
        }`
          : ''
      }}`),
    '',
  )

export const getChartCategories = (
  dateInterval: DateInterval,
  categoriesBySelectedYearsRange: number[],
): string[] | number[] => {
  if (dateInterval.breakDownBy === DateBreakdown.Quarter) {
    return getThreeYearsCategories(dateInterval.startDate, dateInterval.endDate)
  }
  if (dateInterval.breakDownBy === DateBreakdown.Month) {
    return getOneYearCategories(dateInterval.startDate, dateInterval.endDate)
  }
  if (dateInterval.breakDownBy === DateBreakdown.Week) {
    return getTwelveWeeksCategories(dateInterval.startDate, dateInterval.endDate)
  }
  return categoriesBySelectedYearsRange
}

const getTwelveWeeksCategories = (startDate: Date, endDate: Date): string[] => {
  const dates: string[] = []
  const addDays = (currentDate: Date, days: number) => {
    const date = currentDate
    date.setDate(date.getDate() + days)
    return date
  }
  let currentDate = startDate
  if (currentDate.getDay() > 1) {
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
  }
  if (currentDate.getDay() < 1) {
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 6)
  }
  while (currentDate <= endDate) {
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    const displayMonth = month < 10 ? '0' + month : month
    const displayDay = day < 10 ? '0' + day : day
    dates.push(locale() === 'en-US' ? `${displayMonth}/${displayDay}` : `${displayDay}/${displayMonth}`)
    currentDate = addDays(currentDate, 7)
  }
  return dates
}

const getOneYearCategories = (startDate: Date, endDate: Date): string[] => {
  const start = startDate.toISOString().slice(0, 10).split('-')
  const end = endDate.toISOString().slice(0, 10).split('-')
  const startYear = parseInt(start[0])
  const endYear = parseInt(end[0])
  const dates: string[] = []
  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1
      const displayMonth = month < 10 ? '0' + month : month
      if (i === endYear) {
        dates.push(`${displayMonth}/${endYear.toString().slice(-2)}`)
      } else {
        dates.push(`${displayMonth}/${startYear.toString().slice(-2)}`)
      }
    }
  }
  return dates
}

const getThreeYearsCategories = (startDate: Date, endDate: Date): string[] => {
  if (startDate > endDate) {
    const t = endDate
    endDate = startDate
    startDate = t
  }
  startDate = startDate
  startDate.setDate(2)
  let startQ = getQuarter(startDate)
  const endQ = getQuarter(endDate)
  const dates = [startQ]
  while (startQ != endQ) {
    startDate.setMonth(startDate.getMonth() + 3)
    startQ = getQuarter(startDate)
    dates.push(startQ)
  }
  return dates
}

const getQuarter = (date: Date): string =>
  `Q${Math.ceil((date.getMonth() + 1) / 3)}/${date.getFullYear().toString().slice(-2)}`

export const generateExportChartSourceText = ({
  exportChartConfig: {
    subscriptionName,
    listName,
    weighteningType,
    sosvTimeframe,
    graphType = 'share',
    contentTypes,
    cumulatingType,
    collectionName,
    startDate,
    endDate,
  },
  series,
  isTotal,
}: {
  exportChartConfig: ExportChartConfig
  series: CustomPointOptionsObject[] | Highcharts.SeriesOptionsType[]
  isTotal: boolean
}): string => {
  const isLaunchAnalogue = collectionName === 'launchAnalogue'
  const subscriptionText = `${subscriptionName}${listName !== subscriptionName ? ` - ${listName}` : ''}`
  const graphTypeText = graphType === 'counts' ? (weighteningType === 'weighted' ? 'score' : 'mentions') : 'share'
  const timeframeText =
    sosvTimeframe !== 'customDateRange'
      ? capitalizeFirstLetter(
          isLaunchAnalogue
            ? sosvTimeframeLabels[sosvTimeframe].replace('last ', '')
            : sosvTimeframeLabels[sosvTimeframe],
        )
      : `between ${formattedDate(startDate)} and ${formattedDate(endDate)}`
  const cumulatingText = cumulatingType
    ? ` ${capitalizeFirstLetter(camelCaseToSentenceCase(toCamelCase(cumulatingType)))},`
    : ''
  const weighteningText = capitalizeFirstLetter(camelCaseToSentenceCase(toCamelCase(weighteningType)))
  const contentTypesText = contentTypes
    .map((contentType) => `${capitalizeFirstLetter(camelCaseToSentenceCase(toCamelCase(contentType)))}s`)
    .join(', ')
  const productsText = series.map((el: CustomPointOptionsObject | Highcharts.SeriesOptionsType) => el.name).join(', ')
  const collectionText = !collectionName
    ? `${isTotal ? 'Total product' : 'Product'} ${graphTypeText}`
    : isLaunchAnalogue
    ? camelCaseToSentenceCase(collectionName)
    : `${capitalizeFirstLetter(collectionName)}`

  return `<strong>Source</strong>: Pharmaspectra SoSV - ${subscriptionText} - ${collectionText}, ${timeframeText},${cumulatingText} ${weighteningText}, ${contentTypesText}; ${productsText}; exported ${new Date().toLocaleString()}`
}

export const generateExportChartFileName = ({
  exportChartConfig: {
    subscriptionName,
    listName,
    sosvTimeframe,
    weighteningType,
    graphType = 'share',
    startDate,
    endDate,
    collectionName,
  },
  isTotal,
  top,
}: {
  exportChartConfig: ExportChartConfig
  isTotal: boolean
  top?: number
}): string =>
  `${subscriptionName}${listName !== subscriptionName ? ` - ${listName}` : ''}, ${generateSoSVModuleTitle({
    moduleType: collectionName || 'products',
    graphType,
    weighteningType,
    sosvTimeframe,
    isTotal,
    startDate,
    endDate,
    top,
  })}`

export const generateExportChartTitleText = ({
  exportChartConfig: {
    subscriptionName,
    listName,
    weighteningType,
    sosvTimeframe,
    graphType = 'share',
    startDate,
    endDate,
    collectionName,
  },
  isTotal,
  top,
}: {
  exportChartConfig: ExportChartConfig
  isTotal: boolean
  top?: number
}): string =>
  `<strong>Pharmaspectra Share of Scientific Voice™</strong><br/><span>${subscriptionName}${
    listName !== subscriptionName ? ` - ${listName}` : ''
  }</span><br/>${generateSoSVModuleTitle({
    moduleType: collectionName || 'products',
    graphType,
    weighteningType,
    sosvTimeframe,
    isTotal,
    startDate,
    endDate,
    top,
  })}`

export const getExportCategoryLabels = (breakDownBy = 'default'): string => {
  const labels = {
    week: 'Week commencing',
    month: 'Months',
    quarter: 'Quarters',
    year: 'Years',
    default: 'Category',
  }
  return labels[breakDownBy]
}

export const millisecondsInOneDay = 24 * 3600 * 1000

export const utcMidnight = (): number => {
  const now = new Date()
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
}

type MentionWithShare = {
  counts: number[]
  countsWeighted: number[]
  productName: string
  share: number[]
  shareWeighted: number[]
}

export const calculateMentionsShare = <T extends { counts: number[]; countsWeighted: number[]; productName: string }>(
  numberOfExperts: number,
  mentions: T[],
): MentionWithShare[] => {
  const result: MentionWithShare[] = mentions.map((m) => ({ ...m, share: [], shareWeighted: [] }))

  for (let i = 0; i < numberOfExperts; i++) {
    const { totalCount, totalCountWeighted } = mentions.reduce(
      (acc, next) => {
        return {
          totalCount: acc.totalCount + (next.counts[i] ?? 0),
          totalCountWeighted: acc.totalCountWeighted + (next.countsWeighted[i] ?? 0),
        }
      },
      { totalCount: 0, totalCountWeighted: 0 },
    )

    result.forEach((m) => {
      m.share[i] = totalCount > 0 ? roundDecimals((m.counts[i] / totalCount) * 100, 1) : 0
      m.shareWeighted[i] =
        totalCountWeighted > 0 ? roundDecimals((m.countsWeighted[i] / totalCountWeighted) * 100, 1) : 0
    })
  }
  return result
}
