import styled from 'styled-components'
import { Flex } from '~common'

export const StyledSortBy = styled(Flex)`
  flex-wrap: wrap;
  margin: -0.5rem;
  & > * {
    flex: 0 1 auto;
    margin: 0.5rem;
  }
`
