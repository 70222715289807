import styled from 'styled-components'

export const StyledTabs = styled.ul`
  display: flex;
  gap: 2px;
  margin-bottom: 0.5rem;
  overflow-x: scroll;
  & > * {
    flex-shrink: 0;
  }
`
