import { TooltipFormatterContextObject } from 'highcharts'
import { getCommonChartOptions } from '../../common-charts-options'
import { getProductColors } from '~common'

export const useProductsMentionsByTimeframeLineChartOptions = ({
  data,
  categories,
  themeBrand,
  toolTipFormatter,
}: {
  data: Highcharts.SeriesOptionsType[]
  categories: number[] | string[]
  themeBrand: 'pharmaspectra' | 'iqvia'
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): [Highcharts.Options] => {
  const commonChartOptions = getCommonChartOptions(categories)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      type: 'area',
      height: 180,
      marginLeft: 35,
      className: 'custom-products-mentions-area-chart',
    },
    colors: getProductColors(data.length, themeBrand),
    tooltip: {
      ...commonChartOptions.tooltip,
      distance: 19,
      style: {
        width: 300,
      },
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    legend: {
      verticalAlign: 'middle',
      enabled: true,
      y: 15,
      x: 10,
      reversed: true,
      itemMarginBottom: 5,
      itemDistance: 8,
      itemWidth: 80,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolRadius: 0,
      borderColor: 'none',
      width: 255,
      itemStyle: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: '400',
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      gridLineWidth: 0,
      labels: {
        x: -4,
        style: {
          width: 25,
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return `${this.value}`
          }
          return ''
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: {
              enabled: false,
              size: 0,
            },
          },
        },
      },
      area: {
        opacity: 1,
        fillOpacity: 0,
        marker: {
          enabled: false,
        },
      },
    },
    series: data.reverse(),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }

  return [options]
}
