import styled from 'styled-components'

export const StyledChart = styled.figure`
  font-family: ${({ theme }) => theme.fontFamily} !important;
  width: 100%;
  height: 7rem;
`

export const StyledProductMentionsBarChartEmptyStatе = styled.div`
  font-family: ${({ theme }) => theme.fontFamily} !important;
  ${({ theme }) => theme.textSizes.desktop.labelBold};
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

export const StyledHighchartsTooltipDiv = styled.div`
  .highcharts-tooltip,
  .highcharts-tooltip-box,
  .highcharts-tooltip .highcharts-label-box {
    font-family: ${({ theme }) => theme.fontFamily} !important;
    font-size: 0.75rem;
    line-height: 1rem;
    stroke-width: 0;
    margin: 0;
    stroke: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
    fill: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
    background: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
    padding: 4px;
    fill-opacity: 1;
  }

  .highcharts-tooltip .key-value-wrapper {
    font-family: ${({ theme }) => theme.fontFamily} !important;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 0.75rem !important;
      line-height: 1rem;
    }

    .key {
      margin-right: 20px;
      color: ${({ theme }) => theme.colors.textOn.textOnInverseSecondary};
    }

    .value {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.textOn.textOnInversePrimary};
      flex-grow: 1;
      text-align: right;
    }
  }
`
