import { gql } from '@apollo/client'
import {
  GetSoSVDocumentsCountsQuery,
  GetSoSVDocumentsCountsQueryVariables,
} from '~apollo/queries/sosv/documents-counts/__generated__/GetSoSVDocumentsCountsQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'

export const GET_SOSV_DOCUMENTS_COUNTS = gql`
  query GetSoSVDocumentsCountsQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $contentTypes: [String!]!
    $geographyFilter: GeographyFilter
    $journalId: String
    $meetingId: String
    $source: String
    $savedListId: String
    $smartListId: String
  ) {
    getSoSVDocumentsCounts(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: $contentTypes
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      geographyFilter: $geographyFilter
      journalId: $journalId
      meetingId: $meetingId
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      totalCountsByContentType {
        contentType
        count
      }
      matchingContentByContentType {
        contentType
        count
      }
    }
  }
`

export const soSVDocumentsCountsMock: GetSoSVDocumentsCountsQuery = {
  getSoSVDocumentsCounts: {
    totalCountsByContentType: [
      { contentType: 'journal-article', count: 21 },
      { contentType: 'presentation', count: 107 },
    ],
    matchingContentByContentType: [
      { contentType: 'journal-article', count: 21 },
      { contentType: 'presentation', count: 107 },
    ],
  },
}

export const sosVDocumentsCountsMockWithSavedList: GetSoSVDocumentsCountsQuery = {
  ...soSVDocumentsCountsMock,
  getSoSVDocumentsCounts: {
    ...soSVDocumentsCountsMock.getSoSVDocumentsCounts,
    matchingContentByContentType: [
      { contentType: 'journal-article', count: 11 },
      { contentType: 'presentation', count: 55 },
    ],
  },
}

export const getSoSVDocumentsCountsQueryHandler: GraphqlHandler<
  GetSoSVDocumentsCountsQueryVariables,
  GetSoSVDocumentsCountsQuery
> = (req, res, ctx) => {
  const isSavedListId = Boolean(req.variables?.savedListId)

  if (!req.variables.contentTypes.includes('journal-article') && !req.variables.contentTypes.includes('presentation')) {
    return res(
      ctx.data({
        ...soSVDocumentsCountsMock,
        getSoSVDocumentsCounts: {
          ...soSVDocumentsCountsMock.getSoSVDocumentsCounts,
          matchingContentByContentType: [
            { contentType: 'journal-article', count: 0 },
            { contentType: 'presentation', count: 0 },
          ],
        },
      }),
    )
  }
  if (req.variables.timeframe === 'oneYear') {
    return res(
      ctx.data({
        ...soSVDocumentsCountsMock,
        getSoSVDocumentsCounts: {
          ...soSVDocumentsCountsMock.getSoSVDocumentsCounts,
          matchingContentByContentType: [
            { contentType: 'journal-article', count: isSavedListId ? 3 : 10 },
            { contentType: 'presentation', count: isSavedListId ? 2 : 5 },
          ],
        },
      }),
    )
  }
  return res(ctx.data(isSavedListId ? sosVDocumentsCountsMockWithSavedList : soSVDocumentsCountsMock))
}
