import styled from 'styled-components'

export const StyledLatestLayout = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background: ${({ theme }) => theme.colors.brand.backgroundPrimary};
`

export const StyledLatestMain = styled.main`
  width: 100vw;
  min-height: calc(100vh - 4.7375rem);
  min-width: 20rem;
`
