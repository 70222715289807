import React from 'react'
import { FeatureIconProps } from '~components/ui/organisms/cluster-map/types'
import { Cluster } from '~components/ui/organisms/cluster-map/components/markers/components'

export const SocietyEventMarkerIcon: React.FC<FeatureIconProps> = ({ properties, scale, isIqviaBrand, mode }) => {
  return (
    <Cluster
      size={25}
      count={properties.meetingCount as number}
      isIqviaBrand={isIqviaBrand}
      scale={scale}
      mode={mode}
    />
  )
}
