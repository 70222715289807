import styled, { css } from 'styled-components'
import { Flex } from '~common'
import { Heading } from '~components/ui/atoms/heading'

interface StyledHeadingProps {
  isSticky: boolean
  isTitleAsSubtitle?: boolean
  isSelectSticky?: boolean
}

export const StyledHeading = styled(Heading)<StyledHeadingProps>`
  transition: all 0.32s ease-in-out;
  ${({ isSticky, isTitleAsSubtitle, isSelectSticky, theme, level }) => {
    if (!isSticky && level === '1') {
      return {
        ...theme.textSizes.mobile.h1,
      }
    }
    if (!isSticky && level === '2') {
      return {
        ...theme.textSizes.mobile.h2,
      }
    }
    if (isTitleAsSubtitle) {
      return css`
        ${({ theme }) => theme.textSizes.desktop.buttonL}
        margin-right: ${isSelectSticky && '1.5rem'};
      `
    }
    return {
      ...theme.textSizes.desktop.buttonL,
      ...(isSelectSticky && { ['margin-right']: `${isSelectSticky && '1.5rem'}` }),
    }
  }}
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${({ isSticky, theme, level }) => {
      if (!isSticky && level === '1') {
        return {
          ...theme.textSizes.desktop.h1,
        }
      }
      if (!isSticky && level === '2') {
        return {
          ...theme.textSizes.desktop.h2,
        }
      }
    }}
  }
`

interface StyledPageTitleBarOuterProps {
  isSticky: boolean
  isTitleAsSubtitle?: boolean
}

export const StyledPageTitleBarOuter = styled.section<StyledPageTitleBarOuterProps>`
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  width: 100%;
  z-index: 998;
  overflow: hidden;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  transition: all 0.32s ease-in-out;
  top: 0;
  ${({ isSticky, isTitleAsSubtitle }) => {
    if (!isSticky)
      return css`
        border-bottom: 0px solid transparent;
      `
    if (isTitleAsSubtitle)
      return css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral10};
      `
    return css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
    `
  }}
`

export const StyledInnerWrapper = styled(Flex)`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  justify-content: space-between;
  align-items: center;
  transition: all 0.32s ease-in-out;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    max-width: ${({ theme }) => theme.pxsToEms(2040)};
  }
`

export const StyledOptionalElementsOuter = styled.section<{ isSticky: boolean }>`
  transition: all 0.32s ease-in-out;
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
`

interface StyledPageTitleBarInner {
  isSticky?: boolean
  isOptionalElementsPassed?: boolean
}

export const StyledPageTitleBarInner = styled(StyledInnerWrapper)<StyledPageTitleBarInner>`
  padding: ${({ isSticky, isOptionalElementsPassed }) =>
    isSticky ? '0.5rem 1rem' : `${isOptionalElementsPassed ? '1rem 1rem 0' : '1rem'}`};
  & > *:last-child {
    width: auto;
  }
  select {
    max-width: 8rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    select {
      max-width: 16rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    padding: ${({ isSticky, isOptionalElementsPassed }) =>
      isSticky ? '0.5rem 1.5rem' : `${isOptionalElementsPassed ? '1.5rem 1.5rem 0' : '1.5rem'}`};
  }
`

export const StyledOptionalElementsInner = styled(StyledInnerWrapper)<{ isFullContentView: boolean }>`
  padding: 0 1rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  & > *:last-child {
    width: auto;
  }
  & > * {
    margin-top: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${({ isFullContentView }) =>
      isFullContentView &&
      css`
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      `}
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    padding: 0 1.5rem 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    & > * {
      margin-top: 1.5rem;
    }
  }
`

export const StyledStatsWithChildren = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    flex-direction: row;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`
