import React from 'react'
import { GetSoSVExpertsQuery } from '~apollo/queries/sosv/experts/__generated__/GetSoSVExpertsQuery'
import { formatUrl, generateSoSVModuleTitle, Mode, SoSVTimeframe } from '~common'
import { ChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { ChartLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { CollectionTeaser } from '~components/ui/molecules/collection-teaser'
import { Module } from '~components/ui/molecules/module'
import { CollectionsProductsMentionsStackedBarChart } from '~components/ui/organisms/charts'
import { LinkThemeProvider } from '~common/theme'
import { LockedExpert } from '~components/ui/molecules/locked-expert'
import { OptStatus } from '~apollo/generated-global-types'
import { getMentions } from '~components/pages/sosv-hub/helpers'
import { useFeatures } from '~common/hooks/use-features'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'

export const TopExperts: React.FC<{
  isLoading: boolean
  styleMode: Mode
  data?: GetSoSVExpertsQuery
  topAmount?: number
}> = ({ data, isLoading, styleMode, topAmount = 5 }) => {
  const { isIqviaBrand, showSosvChartDataExport } = useFeatures()
  const themeBrand = isIqviaBrand ? 'iqvia' : 'pharmaspectra'

  const {
    listId,
    rootListId,
    productGroupId,
    source,
    weighteningType,
    contentTypes,
    timeframe,
    cumulatingType,
    graphType,
    drugSelection,
    startDate,
    endDate,
    rootListName: subscriptionName,
    listName,
  } = useSoSVState()

  const hasExperts = (data?.getSoSVHubExpertsCount ?? 0) > 0
  const fullVersionUrl = (profileId?: string): string => {
    const subpage = profileId ? `/experts/${profileId}/sosv` : '/sosv/experts'
    return formatUrl(getPlatformUrl() + subpage, {
      listId,
      rootSubscriptionId: rootListId,
      weighteningType: weighteningType,
      productGroupId: productGroupId,
      sosvTimeframe: timeframe,
      sosvContentTypes: contentTypes,
      startDate,
      endDate,
      drugSelection,
      expertsSortBy: 'profileRank',
      cumulatingType,
      graphType,
      dataSet: source,
    })
  }

  if (isLoading) return <ChartLoadingBlock />

  return (
    <Module.Container>
      <Module.Header linkText={hasExperts ? 'View all' : undefined} linkTo={hasExperts ? fullVersionUrl() : undefined}>
        {generateSoSVModuleTitle({
          moduleType: 'experts',
          sosvTimeframe: SoSVTimeframe.ThreeYears,
          weighteningType,
          top: topAmount,
        })}
      </Module.Header>

      {!!data?.getSoSVHubExpertsCount ? (
        <CollectionsProductsMentionsStackedBarChart
          barHeightSize="lg"
          weighteningType={weighteningType}
          collections={data.getSoSVHubExperts
            .slice(0, topAmount)
            .map(
              ({
                profileId,
                speciality,
                country,
                city,
                institution,
                gdprExcluded,
                firstName,
                lastName,
                fullName,
                optStatus,
                profileRank,
                profileStatus,
              }) => ({
                id: profileId,
                title: `${fullName} <br/> ${
                  gdprExcluded || optStatus === OptStatus.out
                    ? '<strong>Profile unavailable due to privacy</strong>'
                    : `${city}, ${country}`
                }`,
                collectionUI: (
                  <LinkThemeProvider mode={styleMode} brand={themeBrand}>
                    {gdprExcluded || optStatus === OptStatus.out ? (
                      <LockedExpert
                        fullName={fullName}
                        firstName={firstName}
                        lastName={lastName || ''}
                        privacyMessage="Profile unavailable due to a privacy request"
                      />
                    ) : (
                      <CollectionTeaser
                        heading={fullName}
                        status={profileStatus || ''}
                        subheading={`${city}, ${country}`}
                        metadataOptions={{
                          primary: speciality,
                          secondary: institution,
                        }}
                        rank={profileRank}
                        imageSrc={`https://pharmaspectra-link-medmeme.s3.amazonaws.com/processed/${profileId}.jpg`}
                        linkTo={fullVersionUrl(profileId)}
                      />
                    )}
                  </LinkThemeProvider>
                ),
              }),
            )}
          showLegend={true}
          categories={data.getSoSVHubExperts.slice(0, topAmount).map(({ profileId }) => profileId)}
          mentions={getMentions(drugSelection ?? [], data.getSoSVHubExperts.slice(0, topAmount))}
          exportChartConfig={
            showSosvChartDataExport
              ? {
                  collectionName: 'experts',
                  subscriptionName: subscriptionName as string,
                  listName: listName as string,
                  sosvTimeframe: SoSVTimeframe.ThreeYears,
                  contentTypes,
                  weighteningType,
                }
              : undefined
          }
          themeMode={styleMode}
          themeBrand={themeBrand}
        />
      ) : (
        <ChartEmptyState isStackedBar />
      )}

      {hasExperts ? (
        <Module.Footer
          links={[
            {
              name: `View all ${data?.getSoSVHubExpertsCount} experts`,
              id: 'experts',
              href: fullVersionUrl(),
            },
          ]}
        />
      ) : null}
    </Module.Container>
  )
}
