import React from 'react'
import { Divider } from '~components/ui/atoms/divider'
import { EHeadingLevels } from '~components/ui/atoms/heading'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { OnwardLinks } from '~components/ui/molecules/onward-links'
import { StyledHeaderWrapper, StyledHeader, StyledFlex, StyledHeading, StyledFooter, StyledInfoTooltip } from './styles'
import { ExternalLinkSvg } from '~common/svgs'
import { TooltipBehavior, TooltipContentWidth } from '../info-tooltip'

export interface HeaderProps {
  linkTo?: string
  headingLevel?: 2 | 3
  isSubTitle?: boolean
  asTag?: string
  linkText?: string
  infoHelp?: string | JSX.Element
  isLinkExternal?: boolean
  tooltipWidth?: number | TooltipContentWidth
  tooltipBehavior?: TooltipBehavior
  tooltipContainerRef?: React.RefObject<HTMLElement>
}

export const Header: React.FC<HeaderProps> = ({
  linkTo,
  children,
  isSubTitle = false,
  headingLevel = 2,
  asTag = 'header',
  linkText = 'View all',
  infoHelp,
  isLinkExternal = false,
  tooltipWidth,
  tooltipBehavior,
  tooltipContainerRef,
}) => (
  <StyledHeaderWrapper as={asTag as never}>
    <StyledHeader>
      <StyledFlex>
        <StyledHeading
          isSubTitle={isSubTitle}
          textColor="secondary"
          level={headingLevel === 2 ? EHeadingLevels.h2 : EHeadingLevels.h3}
        >
          {children}
        </StyledHeading>
        {infoHelp && (
          <StyledInfoTooltip
            content={infoHelp}
            tooltipWidth={tooltipWidth}
            containerRef={tooltipContainerRef}
            behavior={tooltipBehavior}
          />
        )}
      </StyledFlex>
      {linkTo && (
        <CustomLink
          size="sm"
          href={linkTo}
          icon={isLinkExternal ? <ExternalLinkSvg className={'external-link-icon'} /> : undefined}
        >
          {linkText}
        </CustomLink>
      )}
    </StyledHeader>
    <Divider borderColor="warning" />
  </StyledHeaderWrapper>
)

export interface FooterLink {
  name: string
  href: string
  id?: string
}

export interface FooterProps {
  links: FooterLink[]
}

const Footer: React.FC<FooterProps> = ({ links }: FooterProps) => (
  <StyledFooter>
    <OnwardLinks links={links} />
  </StyledFooter>
)

export const Container: React.FC = ({ children }) => {
  return <>{children}</>
}

export const Module = {
  Container,
  Header,
  Footer,
}
