import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { useConfig } from '~common/hooks'

interface CognitoTokens {
  idToken: string
  accessToken: string
  refreshToken: string
}

const loadCognito = async (tokens: CognitoTokens, poolId: string, webClientId: string) => {
  try {
    await Auth.signOut()
  } catch (error) {
    // ignored
  }

  const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: tokens.accessToken,
  })

  const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: tokens.idToken,
  })

  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: tokens.refreshToken,
  })

  const session = new AmazonCognitoIdentity.CognitoUserSession({
    IdToken,
    AccessToken,
    RefreshToken,
  })

  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: poolId,
    ClientId: webClientId,
  })

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: AccessToken.payload.username,
    Pool: userPool,
  })

  cognitoUser.setSignInUserSession(session)
}

export const useWidgetAuth = (): {
  isAuthenticated: boolean
  error: Error | null
  currentUser: string | null
  isLoading: boolean
} => {
  const router = useRouter()
  const [tokens, setTokens] = useState<CognitoTokens | null>(null)
  const [tokensError, setTokensError] = useState<Error | null>(null)
  const [isTokenLoaded, setIsTokenLoaded] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<string | null>(null)
  const { clientId, timestamp, key } = router.query
  const {
    ENV,
    USER_POOL_WEB_CLIENT_ID,
    USER_POOL_ID,
    VERIFY_EMBEDDED_WIDGET_API_URL = 'http://VERIFY_EMBEDDED_WIDGET_API_URL',
  } = useConfig()

  const isLocal = !ENV || ENV === 'local'

  useEffect(() => {
    setIsTokenLoaded(false)
    setTokens(null)
    setTokensError(null)
    setCurrentUser(null)
    if (!clientId) {
      return
    }
    if (isLocal) {
      setTokens({
        accessToken: 'local_access_token',
        refreshToken: 'local_refresh_token',
        idToken: 'local_id_token',
      })
      setIsTokenLoaded(true)
      return
    }
    fetch(VERIFY_EMBEDDED_WIDGET_API_URL, {
      method: 'POST',
      body: JSON.stringify({
        clientId,
        timestamp,
        key,
      }),
    })
      .then((res) => {
        if (res.status === 403) {
          throw new Error(`Signed key: ${key} is not valid or expired`)
        }
        return res.json()
      })
      .then(
        (result) => {
          setIsTokenLoaded(true)
          setTokens({
            idToken: result.idToken,
            accessToken: result.accessToken,
            refreshToken: result.refreshToken,
          })
        },
        (error) => {
          setIsTokenLoaded(true)
          setTokensError(error)
        },
      )
  }, [clientId, timestamp, key, isLocal, VERIFY_EMBEDDED_WIDGET_API_URL])

  useEffect(() => {
    if (tokens) {
      if (isLocal) {
        setCurrentUser('localWidgetUser@pharmaspectra.com')
        return
      }
      loadCognito(tokens, USER_POOL_ID, USER_POOL_WEB_CLIENT_ID)
        .then(() => {
          Auth.currentAuthenticatedUser()
            .then((u) => setCurrentUser(u.attributes['email']))
            .catch((error) => setTokensError(new Error(error)))
        })
        .catch((error) => setTokensError(new Error(error)))
    }
  }, [tokens, USER_POOL_ID, isLocal, USER_POOL_WEB_CLIENT_ID])

  return {
    isLoading: !isTokenLoaded,
    error: tokensError,
    currentUser,
    isAuthenticated: Boolean(isTokenLoaded && currentUser && !tokensError),
  }
}
