import React from 'react'
import { Mode, Scale } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { StyledCluster } from './styles'

export const Cluster = ({
  size,
  count,
  isIqviaBrand,
  scale,
  mode,
}: {
  size: number
  count: number
  isIqviaBrand: boolean
  scale: Scale
  mode: Mode
}): JSX.Element => (
  <LinkThemeProvider mode={mode} brand={isIqviaBrand ? 'iqvia' : 'pharmaspectra'}>
    <StyledCluster size={size} scale={scale}>
      {count}
    </StyledCluster>
  </LinkThemeProvider>
)
