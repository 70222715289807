import React from 'react'
import styled from 'styled-components'

const StyledInitialsWrapper = styled.div`
  width: 5.5rem;
  height: 5.5rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.brand.purpleSecondary};
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    width: 8rem;
    height: 8rem;
  }
`

const StyledInitials = styled.span`
  ${({ theme }) => theme.textSizes.desktop.bodyL}
  margin: 0 auto;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.brand.purplePrimary};
`

export interface InitialsPlaceholderProps extends React.HTMLAttributes<HTMLDivElement> {
  firstName: string
  lastName: string
}

export const InitialsPlaceholder: React.FC<InitialsPlaceholderProps> = ({ firstName, lastName, ...rest }) => (
  <StyledInitialsWrapper {...rest}>
    <StyledInitials>{getInitials(firstName, lastName)}</StyledInitials>
  </StyledInitialsWrapper>
)

const getInitials = (firstName: string, lastName: string) => {
  const sanitasiedFirstName = firstName.replace(/[^a-zA-Z]/g, '')
  const sanitasiedLastName = lastName.replace(/[^a-zA-Z]/g, '')
  return `${sanitasiedFirstName.charAt(0)}${sanitasiedLastName.charAt(0)}`
}
