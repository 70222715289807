import React from 'react'
import { LinkThemeProvider } from '~common/theme'
import { CustomImage } from '~components/ui/atoms/custom-image'
import { StyledMarkerPicture } from './styles'
import { FeatureIconProps } from '~components/ui/organisms/cluster-map/types'

export const MarkerImage = ({ properties, scale, isIqviaBrand, mode }: FeatureIconProps): JSX.Element => (
  <LinkThemeProvider mode={mode} brand={isIqviaBrand ? 'iqvia' : 'pharmaspectra'}>
    <figure>
      <StyledMarkerPicture scale={scale}>
        <CustomImage
          src={`https://pharmaspectra-link-medmeme.s3.amazonaws.com/processed/${properties.id}.jpg`}
          alt=""
        />
      </StyledMarkerPicture>
    </figure>
  </LinkThemeProvider>
)
