import { Subscription, SubscriptionPermission } from '~common/types/common-link-types'
import { DataSet, DataSetType, DefaultSoSVTimeFrame, formatUrl, Hub } from '~common'
import { SelectOption } from '~components/ui/controlled/select/types'
import { SoSVFiltersUserSettings } from '~common/hooks/use-user-filters-settings'
import { isExpertEcosystemRouter, Router } from '~common/hooks'
import React from 'react'

export const findRootSubscriptionId = (listId: string, subscriptions: Subscription[]): string => {
  for (const subscription of subscriptions) {
    if (subscription.defaultSubscriptionId === listId) return subscription.defaultSubscriptionId
    for (const list of subscription.children) {
      if (list.id === listId) return subscription.defaultSubscriptionId
      for (const subList of list.children) {
        if (subList.id === listId) return subscription.defaultSubscriptionId
      }
    }
  }
  return listId
}

export const getModifiedSubscriptionsWithRootAndListIds = ({
  subscriptions,
  rootSubscriptionId,
  listId,
}: {
  subscriptions?: Subscription[]
  rootSubscriptionId?: string
  listId?: string
}): {
  modifiedSubscriptions: Subscription[]
  selectedListId: string
  selectedRootSubscriptionId: string
} => {
  const modifiedSubscriptions = (subscriptions || []).map((subscription) => ({
    ...subscription,
    defaultSubscriptionId:
      subscription.parentId === null && subscription.children.length > 0
        ? subscription.children.find((el) => el.name === 'All')?.children[0]?.id ||
          subscription.children.find((el) => el.name === 'All')?.id ||
          subscription.children[0].id
        : subscription.id,
  }))
  const selectedListId = listId || modifiedSubscriptions[0]?.defaultSubscriptionId
  const selectedRootSubscriptionId = rootSubscriptionId || findRootSubscriptionId(selectedListId, modifiedSubscriptions)
  return { modifiedSubscriptions, selectedListId, selectedRootSubscriptionId }
}

export const redirectToProperHub = ({
  productGroupId,
  rootSubscriptionId,
  listId,
  sosvProducts,
  permissions,
  router,
  excludeSoSVCheck = false,
}: {
  rootSubscriptionId: string
  listId: string
  permissions: SubscriptionPermission[]
  router?: Router
  productGroupId?: string
  sosvProducts?: string[]
  excludeSoSVCheck?: boolean
}): void | Error => {
  if (!router || isExpertEcosystemRouter(router)) return

  if (permissions.includes(SubscriptionPermission.Experts)) {
    void router.push(
      formatUrl('/experts', {
        rootSubscriptionId,
        listId,
      }),
      undefined,
      {
        shallow: true,
      },
    )
  } else if (
    !excludeSoSVCheck &&
    permissions.includes(SubscriptionPermission.SoSV) &&
    permissions.includes(SubscriptionPermission.SoSVScience)
  ) {
    void router.push(
      formatUrl(`/sosv`, {
        rootSubscriptionId,
        listId,
        dataSet: DataSet.SCIENCE,
        sosvContentTypes: ['presentation', 'journal-article'],
        sosvTimeframe: DefaultSoSVTimeFrame,
        graphType: 'counts',
        weighteningType: 'weighted',
        cumulatingType: 'non-cumulative',
        productGroupId,
        drugSelection: sosvProducts,
      }),
      undefined,
      {
        shallow: true,
      },
    )
  } else if (permissions.includes(SubscriptionPermission.Science)) {
    void router.push(
      formatUrl(`/science`, {
        rootSubscriptionId,
        listId,
      }),
      undefined,
      {
        shallow: true,
      },
    )
  } else {
    void router.push('/not-authorized', undefined, {
      shallow: true,
    })
  }
}

export const getHubsForNavigation = ({
  permissionsForSelectedSubscription,
  isLoading,
  hasSoSVExpertsForSelectedList,
  smartListId,
  savedListId,
  productGroupId,
  sosvProducts,
  rootSubscriptionId,
  listId,
  dataSet,
  ExpertsHubIcon,
  SoSVHubIcon,
  ScienceHubIcon,
  PLIHubIcon,
  OKAIListIcon,
  isInternalTestingGroup,
  userSoSVFiltersSettings,
  userEmail,
  pliUrl,
  okaiListUrl,
}: {
  permissionsForSelectedSubscription: SubscriptionPermission[]
  isLoading: boolean
  hasSoSVExpertsForSelectedList: boolean
  dataSet?: DataSetType
  smartListId?: string
  savedListId?: string
  productGroupId?: string
  sosvProducts?: string[]
  rootSubscriptionId: string
  listId: string
  ExpertsHubIcon: React.ReactElement | string
  SoSVHubIcon: React.ReactElement | string
  ScienceHubIcon: React.ReactElement | string
  PLIHubIcon: React.ReactElement | string
  OKAIListIcon: React.ReactElement | string
  isInternalTestingGroup: boolean
  userSoSVFiltersSettings?: SoSVFiltersUserSettings
  userEmail: string
  pliUrl: string
  okaiListUrl?: string | undefined | null
}): Hub[] => [
  {
    id: 'experts',
    isLoading: isLoading,
    link: formatUrl(`/experts`, {
      listId,
      rootSubscriptionId,
      savedListId,
      smartListId,
    }),
    content: ExpertsHubIcon,
    isDisabled: !permissionsForSelectedSubscription?.includes(SubscriptionPermission.Experts),
  },
  {
    id: 'sosv',
    isDisabled: !permissionsForSelectedSubscription?.includes(SubscriptionPermission.SoSV),
    isLoading: isLoading,
    link: formatUrl(`/sosv`, {
      rootSubscriptionId,
      listId,
      savedListId,
      smartListId,
      dataSet: !!dataSet
        ? dataSet
        : hasSoSVExpertsForSelectedList || smartListId || savedListId
        ? DataSet.EXPERTS
        : DataSet.SCIENCE,
      sosvContentTypes: ['presentation', 'journal-article'],
      sosvTimeframe: DefaultSoSVTimeFrame,
      graphType: 'counts',
      weighteningType: 'weighted',
      cumulatingType: 'non-cumulative',
      productGroupId,
      drugSelection: sosvProducts,
      ...(userSoSVFiltersSettings ?? {}),
    }),
    content: SoSVHubIcon,
  },
  ...((permissionsForSelectedSubscription ?? []).includes(SubscriptionPermission.PLI) && userEmail
    ? [
        {
          id: 'pli',
          link: formatUrl(pliUrl, { user_email: userEmail, subscription_id: listId }),
          content: PLIHubIcon,
        },
      ]
    : []),
  ...((permissionsForSelectedSubscription ?? []).includes(SubscriptionPermission.AcceleratedInsights) &&
  userEmail &&
  okaiListUrl
    ? [
        {
          id: 'okai',
          isLoading: isLoading,
          link: formatUrl(okaiListUrl, { user_email: userEmail, subscription_id: listId }),
          content: OKAIListIcon,
        },
      ]
    : []),
  ...(isInternalTestingGroup
    ? [
        {
          id: 'science',
          link: `/science`,
          isDisabled: !permissionsForSelectedSubscription?.includes(SubscriptionPermission.Science),
          content: ScienceHubIcon,
        },
      ]
    : []),
]

export const mapToSubscriptionsOptions = (subscriptions: Subscription[], showClientNames: boolean): SelectOption[] => {
  return (
    subscriptions.map((subscription) => {
      const maybeClientPrefix =
        showClientNames && subscription.clientName !== null ? `${subscription.clientName} - ` : ''
      return {
        value: subscription.defaultSubscriptionId,
        label: `${maybeClientPrefix}${subscription.name}`,
      }
    }) || []
  )
}
