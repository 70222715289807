import addYears from 'date-fns/addYears'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInYears from 'date-fns/differenceInYears'
import parseISO from 'date-fns/parseISO'

import { SearchTimeframe } from '~apollo/generated-global-types'

import {
  convertPillsToUrlFiltersParam,
  DateBreakdown,
  DateInterval,
  getIntervalForTimeframe,
  SearchedQueryValues,
  SoSVTimeframe,
} from '~common'
import { formatStartAndEndDatesParams } from '~components/helpers'

export const buildSearchParamsString = ({
  searchQueryValues,
  contentTypes,
  timeframe,
  listId,
  savedListId,
  smartListId,
  startDate,
  endDate,
  showGrants = true,
}: {
  searchQueryValues: SearchedQueryValues[]
  contentTypes: Record<string, boolean>
  timeframe: SearchTimeframe
  startDate?: Date
  endDate?: Date
  listId?: string
  savedListId?: string | null
  smartListId?: string | null
  showGrants?: boolean
}): string => {
  const pillParams = searchQueryValues.reduce((acc, cur, i) => {
    const result = convertPillsToUrlFiltersParam(cur, i)
    return acc + result + `&`
  }, '')
  const contentTypesConfiguration = Object.entries(contentTypes || {}).filter(([_, selected]) => selected)

  let contentTypesParams

  if (!showGrants && !contentTypesConfiguration.length) {
    contentTypesParams = Object.entries(contentTypes)
      .filter(([type, _]) => type !== 'grants' && type !== 'nih-grants')
      .map(([type, _]) => `contentTypes=${type}`)
      .join('&')
  } else {
    contentTypesParams = contentTypesConfiguration.map(([type, _]) => `contentTypes=${type}`).join('&')
  }

  let startAndEndDatesParams = ''

  const withStartAndEndDates = !!startDate && !!endDate
  const isCustomDateRangeWithStartAndEndDates = timeframe === 'customDateRange' && withStartAndEndDates

  const timeframeParam = `&timeframe=${
    timeframe === 'customDateRange' && !withStartAndEndDates ? 'allTime' : timeframe
  }`

  if (isCustomDateRangeWithStartAndEndDates) {
    startAndEndDatesParams = formatStartAndEndDatesParams(startDate, endDate)
  }

  return `${pillParams}${listId ? `listId=${listId}` : ''}${timeframeParam}${
    isCustomDateRangeWithStartAndEndDates ? startAndEndDatesParams : ''
  }${contentTypesParams ? `&${contentTypesParams}` : ''}${savedListId ? `&savedListId=${savedListId}` : ''}${
    smartListId ? `&smartListId=${encodeURIComponent(smartListId)}` : ''
  }`
}

export const getDateInterval = ({
  timeframe,
  startDate,
  endDate,
}: {
  timeframe: SoSVTimeframe
  startDate?: string
  endDate?: string
}): DateInterval => {
  const now = new Date()
  if (!timeframe)
    return {
      breakDownBy: DateBreakdown.Year,
      startDate: addYears(now, -10),
      endDate: now,
    }

  if (timeframe === 'customDateRange' && startDate && endDate) {
    const end = parseISO(endDate as string)
    const start = parseISO(startDate as string)

    const weeks = differenceInWeeks(end, start)
    if (weeks < 12) {
      return {
        breakDownBy: DateBreakdown.Week,
        startDate: start,
        endDate: end,
      }
    }

    const years = differenceInYears(end, start)
    if (years < 1)
      return {
        breakDownBy: DateBreakdown.Month,
        startDate: start,
        endDate: end,
      }

    if (years < 3)
      return {
        breakDownBy: DateBreakdown.Quarter,
        startDate: start,
        endDate: end,
      }

    return {
      breakDownBy: DateBreakdown.Year,
      startDate: start,
      endDate: end,
    }
  } else {
    return getIntervalForTimeframe(timeframe)
  }
}

export interface LatLng {
  longitude: number
  latitude: number
}

export const isPointInBoundingBox = (point: LatLng, maxSouthWestPoint: LatLng, maxNorthEastPoint: LatLng): boolean => {
  const isLongitudeInRange = () => {
    if (maxNorthEastPoint.longitude < maxSouthWestPoint.longitude) {
      return point.longitude >= maxSouthWestPoint.longitude || point.longitude <= maxNorthEastPoint.longitude
    }
    return point.longitude >= maxSouthWestPoint.longitude && point.longitude <= maxNorthEastPoint.longitude
  }

  return (
    point.latitude >= maxSouthWestPoint.latitude && point.latitude <= maxNorthEastPoint.latitude && isLongitudeInRange()
  )
}

export const paginateItems = <T>(items: T[], currentPage: number, pageSize: number): T[] => {
  const offset = (currentPage - 1) * pageSize
  return items.slice(offset).slice(0, pageSize)
}
