import React, { RefObject } from 'react'
import * as Highcharts from 'highcharts'
import { DropdownOption } from '~components/ui/molecules/dropdown'
import { HoverMenuButton } from '~components/ui/molecules/hover-menu-button'
import { ExportType } from '~common'

export interface ExportChartTriggerProps extends React.HTMLAttributes<HTMLElement> {
  exportTypes?: ExportType[]
  chartRef?: React.RefObject<{
    chart: Highcharts.Chart & { downloadXLS?: () => void }
    container: RefObject<HTMLDivElement>
  }>
}

export const ExportChartTrigger = ({
  chartRef,
  exportTypes = ['jpeg', 'png'],
  ...rest
}: ExportChartTriggerProps): JSX.Element => {
  const onExportHandler = (option: DropdownOption) => {
    const types: Record<string, Highcharts.ExportingMimeTypeValue> = {
      jpeg: 'image/jpeg',
      png: 'image/png',
    }
    if (option.id === 'xls') {
      chartRef?.current?.chart?.downloadXLS?.()
    } else {
      chartRef?.current?.chart.exportChart(
        {
          type: types[option.id],
        },
        {
          chart: {
            events: undefined,
            backgroundColor: '#ffffff',
          },
        },
      )
    }
  }
  const exportMenuOptions: { id: ExportType; label: string }[] = [
    {
      id: 'png',
      label: 'Download PNG image',
    },
    {
      id: 'jpeg',
      label: 'Download JPEG image',
    },
    {
      id: 'xls',
      label: 'Download XLS',
    },
  ]
  const menuOptions = exportMenuOptions.filter((el) => exportTypes.includes(el.id))
  return (
    <HoverMenuButton
      size="sm"
      title="Export"
      onSelectMenuOption={onExportHandler}
      menuOptions={menuOptions}
      {...rest}
    />
  )
}
