import styled from 'styled-components'
import { Flex } from '~common'
import { Heading } from '~components/ui/atoms/heading'
import { InitialsPlaceholder } from '~components/ui/atoms/initials-placeholder'

export const StyledLockedExpert = styled(Flex)``

export const StyledInitialsPlaceholder = styled(InitialsPlaceholder)`
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
`

export const StyledPrivacyMessage = styled.p`
  margin-top: 0.25rem;
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.text.textPrimary};
  ${({ theme }) => theme.textSizes.desktop.subscription};
`

export const StyledHeadingAndPrivacyMesageWrapper = styled.div``
