import styled from 'styled-components'
import { Flex } from '~common'
import { Heading } from '~components/ui/atoms/heading'
import { InfoTooltip } from '~components/ui/molecules/info-tooltip'

export const StyledHeaderWrapper = styled.div``

export const StyledHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

export const StyledFlex = styled(Flex)`
  align-items: center;
`

export const StyledHeading = styled(Heading)<{ isSubTitle?: boolean; level: '2' | '3' }>`
  ${({ isSubTitle, level, theme }) => {
    if (isSubTitle || level === '3') return theme.textSizes.desktop.h4
    if (level === '2') return theme.textSizes.desktop.h3
  }}
`

export const StyledFooter = styled(Flex).attrs({
  as: 'footer',
})`
  margin-top: 0.5rem;
`

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 0.25rem;
`

export const StyledInfoTooltipContentWrapper = styled.div`
  line-height: 1.4;
  font-size: 0.75rem;
  & > ul {
    list-style-type: disc;
    padding-left: 1rem;

    & > li {
      margin: 0.3rem 0;
    }
  }

  & ol {
    list-style-type: circle;
    padding-left: 1rem;
  }
`
