import { gql } from '@apollo/client'
import {
  GetOnTopicContributionCountQuery,
  GetOnTopicContributionCountQueryVariables,
} from '~apollo/queries/sosv/on-topic-counts/__generated__/GetOnTopicContributionCountQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'

export const GET_ON_TOPIC_CONTRIBUTION_COUNT = gql`
  query GetOnTopicContributionCountQuery(
    $listId: String!
    $savedListId: String
    $smartListId: String
    $source: String
  ) {
    getOnTopicContributionCount(listId: $listId, savedListId: $savedListId, smartListId: $smartListId, source: $source)
  }
`

export const getOnTopicContributionCountQueryHandler: GraphqlHandler<
  GetOnTopicContributionCountQueryVariables,
  GetOnTopicContributionCountQuery
> = (req, res, ctx) => {
  return res(
    ctx.data({
      getOnTopicContributionCount: req.variables.source === 'science' ? 198765 : 1001,
    }),
  )
}
