import React from 'react'
import { useExpertEcosystemContext } from '~common/providers'
import {
  StyledExpertSection,
  StyledLoadingImageBlock,
  StyledLoadingTitleBlock,
  StyledLoadingSubtitleBlock,
  StyledFlexColumnBlock,
} from '../styles'

export const ExpertLoadingBlock = ({
  isTransparent = false,
  isImageBlock = true,
}: {
  isTransparent?: boolean
  isImageBlock?: boolean
}): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <StyledExpertSection isTransparent={isTransparent}>
      {isImageBlock && <StyledLoadingImageBlock />}
      <StyledFlexColumnBlock>
        <StyledLoadingTitleBlock />
        <StyledLoadingSubtitleBlock />
      </StyledFlexColumnBlock>
    </StyledExpertSection>
  )
}
