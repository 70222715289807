import styled from 'styled-components'
import { StyledChart } from '../../styles'

export const StyledPhaseInvolvementChart = styled(StyledChart)`
  .highcharts-tooltip > span {
    left: 10px !important;
    .key {
      margin-right: 4px;
    }
  }
  .highcharts-container,
  .highcharts-root,
  .highcharts-background,
  .highcharts-container svg {
    width: 100% !important;
  }
  .phase-0 > rect {
    fill: ${({ theme }) => theme.colors.dataVisualisation.clinicalPhase0};
  }
  .phase-I > rect {
    fill: ${({ theme }) => theme.colors.dataVisualisation.clinicalPhase1};
  }
  .phase-II > rect {
    fill: ${({ theme }) => theme.colors.dataVisualisation.clinicalPhase2};
  }
  .phase-III > rect {
    fill: ${({ theme }) => theme.colors.dataVisualisation.clinicalPhase3};
  }
  .phase-IV > rect {
    fill: ${({ theme }) => theme.colors.dataVisualisation.clinicalPhase4};
  }

  .phase-NA > rect {
    fill: ${({ theme }) => theme.colors.dataVisualisation.clinicalPhaseNa};
  }
`
