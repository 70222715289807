import { TooltipFormatterContextObject } from 'highcharts'
import { getTickInterval } from './helpers'
import { getCommonChartOptions, getCommonExportChartOptions } from '../../common-charts-options'
import { roundDecimals, SoSVTimeframe, WeighteningType } from '~common'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import { generateExportChartFileName, generateExportChartSourceText, generateExportChartTitleText } from '../../helpers'
import { ExportChartConfig } from '../..'

export const useLaunchAnalogueMultipleSeriesStockChartOptions = ({
  data,
  productColors,
  tZeroDate,
  minDate,
  maxDate,
  range,
  rangeChanged,
  weighteningType,
  exportChartConfig,
  toolTipFormatter,
}: {
  data: Highcharts.SeriesOptionsType[]
  productColors: string[]
  tZeroDate: number
  minDate: number
  maxDate: number
  range: SoSVTimeframe
  rangeChanged?: (range: SoSVTimeframe) => void
  weighteningType: WeighteningType
  exportChartConfig?: ExportChartConfig
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): Highcharts.Options => {
  const commonChartOptions = getCommonChartOptions()
  const commonExportOptions = getCommonExportChartOptions(data)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    exporting: {
      ...(exportChartConfig
        ? {
            ...commonExportOptions,
            tableCaption: generateExportChartTitleText({ exportChartConfig, isTotal: false }),
            chartOptions: {
              ...commonExportOptions.chartOptions,
              plotOptions: {
                series: {
                  dataLabels: {
                    ...commonExportOptions.chartOptions?.plotOptions?.series?.dataLabels,
                    formatter: function (): string {
                      return `${
                        !this.y
                          ? 0
                          : weighteningType === 'weighted'
                          ? roundDecimals(this.y, 1)
                          : roundDecimals(this.y, 0)
                      }`
                    },
                  },
                },
              },
              rangeSelector: { enabled: false },
              title: {
                ...commonExportOptions.chartOptions?.title,
                text: generateExportChartTitleText({ exportChartConfig, isTotal: false }),
              },
              subtitle: {
                ...commonExportOptions.chartOptions?.subtitle,
                text: generateExportChartSourceText({
                  exportChartConfig,
                  series: data.filter((el) => el.showInLegend),
                  isTotal: false,
                }),
              },
              chart: {
                ...commonExportOptions.chartOptions?.chart,
                marginLeft: 70,
                marginRight: 50,
                plotBackgroundImage: 'https://app.pharmaspectra.com/line-graph-pharmaspectra-watermark.png',
              },
              yAxis: {
                gridLineWidth: 1,
                labels: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
              xAxis: {
                labels: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
            },
            filename: generateExportChartFileName({ exportChartConfig, isTotal: false }),
          }
        : { enabled: false }),
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: true,
      inputEnabled: false,
      selected: mapRangeToSelectedIndex(range),
      buttonSpacing: 8,
      buttonTheme: {
        width: 60,
        height: 20,
        r: 2,
        style: {
          fill: '#F9F8F7',
          color: '#67625D',
          stroke: 'rgba(0,0,0,0.1)',
          strokeWidth: '1px',
          transition: 'fill 250ms',
        },
        states: {
          select: {
            style: {
              color: 'white',
              fill: '#5A6BAD',
              fontWeight: 400,
            },
          },
          hover: {
            style: {
              color: 'white',
              fill: '#5A6BAD',
              fontWeight: 400,
            },
          },
        },
      },
      buttons: [
        {
          type: 'month',
          count: 3,
          text: '3 months',
          title: 'View 3 months',
          dataGrouping: {
            forced: true,
            groupAll: true,
            approximation: 'sum',
            units: [['week', [1]]],
          },
          events: {
            click: function () {
              if (rangeChanged) rangeChanged(SoSVTimeframe.TwelveWeeks)
            },
          },
        },
        {
          type: 'year',
          count: 1,
          text: '1 year',
          title: 'View 1 year',
          dataGrouping: {
            forced: true,
            groupAll: true,
            approximation: 'sum',
            units: [['month', [1]]],
          },
          events: {
            click: function () {
              if (rangeChanged) rangeChanged(SoSVTimeframe.OneYear)
            },
          },
        },
        {
          type: 'year',
          count: 3,
          text: '3 years',
          title: 'View 3 years',
          dataGrouping: {
            forced: true,
            groupAll: true,
            approximation: 'sum',
            units: [['month', [3]]],
          },
          events: {
            click: function () {
              if (rangeChanged) rangeChanged(SoSVTimeframe.ThreeYears)
            },
          },
        },
        {
          type: 'year',
          count: 10,
          title: 'View 10 years',
          text: '10 years',
          dataGrouping: {
            forced: true,
            groupAll: true,
            approximation: 'sum',
            units: [['year', [1]]],
          },
          events: {
            click: function () {
              if (rangeChanged) rangeChanged(SoSVTimeframe.TenYears)
            },
          },
        },
      ],
    },
    chart: {
      ...commonChartOptions.chart,
      animation: false,
      events: {
        load() {
          setTimeout(() => {
            this.reflow.bind(this)
            if (rangeChanged) rangeChanged(range)
          }, 0)
        },
      },
      marginLeft: 30,
      className: 'launch-analogue-multiple-series-stock-chart',
    },
    colors: productColors,
    tooltip: {
      ...commonChartOptions.tooltip,
      distance: 19,
      padding: 4,
      style: {
        width: 300,
      },
      outside: false,
      shared: true,
      split: false,
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    legend: {
      verticalAlign: 'bottom',
      enabled: true,
      y: 0,
      x: 0,
      reversed: true,
      itemMarginBottom: 5,
      itemDistance: 8,
      itemWidth: 120,
      symbolHeight: 8,
      symbolWidth: 8,
      symbolRadius: 0,
      borderColor: 'none',
      width: 400,
      itemStyle: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: '400',
      },
    },
    xAxis: {
      ...commonChartOptions.xAxis,
      ordinal: false,
      type: 'datetime',
      startOfWeek: 1,
      min: minDate,
      max: maxDate,
      tickInterval: getTickInterval(range),
      labels: {
        skew3d: true,
        rotation: 0,
        style: {
          textOverflow: 'none',
        },
        formatter: function () {
          if (!this.chart.options.rangeSelector) return ''
          const range = mapSelectedIndexToRange(this.chart.options.rangeSelector.selected)
          const value = this.value as number
          return formatXAxisLabel(range, value, tZeroDate)
        },
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      opposite: false,
      tickAmount: 11,
      gridLineWidth: 0,
      showLastLabel: true,
      labels: {
        x: -2,
        y: 4,
        style: {
          width: 27,
        },
        formatter: function () {
          if (this.isLast || this.isFirst || this.pos === (this.axis.max || 0) / 2) {
            return `${this.value}`
          }
          return ''
        },
      },
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        states: {
          hover: {
            halo: {
              enabled: false,
              size: 0,
            },
          },
        },
      },
      area: {
        opacity: 1,
        fillOpacity: 0,
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick: function () {
            return false
          },
        },
      },
    },
    series: data.reverse(),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
        },
      ],
    },
  }

  return options
}

const formatXAxisLabel = (range: SoSVTimeframe, value: number, tZeroDate: number): string => {
  let difference: number
  let unit: string

  switch (range) {
    case SoSVTimeframe.ThreeYears:
      difference = Math.ceil(differenceInCalendarMonths(value, tZeroDate) / 3)
      unit = 'Q'
      break
    case SoSVTimeframe.OneYear:
      difference = differenceInCalendarMonths(value, tZeroDate)
      unit = 'M'
      break
    case SoSVTimeframe.TwelveWeeks:
      difference = Math.ceil(differenceInCalendarDays(value, tZeroDate) / 7)
      unit = 'W'
      break
    case SoSVTimeframe.TenYears:
    default:
      difference = Math.ceil(differenceInCalendarMonths(value, tZeroDate) / 12)
      unit = 'Y'
      break
  }

  return difference === 0 ? 'T0' : difference < 0 ? `${difference}${unit}` : `+${difference}${unit}`
}

const mapSelectedIndexToRange = (selected: number | undefined): SoSVTimeframe => {
  return selected === 0
    ? SoSVTimeframe.TwelveWeeks
    : selected === 1
    ? SoSVTimeframe.OneYear
    : selected === 2
    ? SoSVTimeframe.ThreeYears
    : SoSVTimeframe.TenYears
}

const mapRangeToSelectedIndex = (range: SoSVTimeframe): number => {
  switch (range) {
    case SoSVTimeframe.TwelveWeeks:
      return 0
    case SoSVTimeframe.OneYear:
      return 1
    case SoSVTimeframe.ThreeYears:
      return 2
    case SoSVTimeframe.TenYears:
    default:
      return 3
  }
}
