import styled, { css } from 'styled-components'
import { TGridItemWidth, TGridItemBackgroundVariation } from './InBoxGrid'
import { Button } from '~components/ui/atoms/button'
import { flexMixin } from '~common'

export const StyledContainer = styled.section`
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    grid-template-columns: repeat(12, 1fr);
  }

  #exposed-styled-wrapper > & {
    height: inherit;
  }
`

interface StyledItemWrapperProps {
  itemWidth: TGridItemWidth
  backgroundVariation: TGridItemBackgroundVariation
  isOutOfFlow: boolean
  viewportHeight: number
  containerOffsetTop: number
  isExpanded?: boolean
  isScrollable?: boolean
}

export const StyledItemWrapper = styled.section<StyledItemWrapperProps>`
  height: ${({ viewportHeight, containerOffsetTop }) => `${viewportHeight - containerOffsetTop}px`};
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'scroll' : 'hidden')};
  position: ${({ isOutOfFlow }) => (isOutOfFlow ? 'absolute' : 'static')};
  left: 0;
  z-index: ${({ isOutOfFlow }) => (isOutOfFlow ? '2' : '1')};
  background-color: ${({ backgroundVariation, theme }) => {
    if (backgroundVariation === 'transparent') return 'transparent'
    if (backgroundVariation === 'primary') return theme.colors.brand.backgroundPrimary
    if (backgroundVariation === 'secondary') return theme.colors.brand.backgroundSecondary
  }};
  transition: margin-left 0.24s ease-in-out 0.24s;
  margin-left: ${({ isExpanded }) => !isExpanded && '-100%'};
  width: 100%;
  ${({ itemWidth }) =>
    itemWidth &&
    css`
      grid-column: span 4;
    `}
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: ${({ isOutOfFlow }) => isOutOfFlow && '50%'};
    ${({ itemWidth }) => {
      if (['narrow', 'wide'].includes(itemWidth)) {
        return css`
          grid-column: span 3;
        `
      }
      if (['wider', 'widest'].includes(itemWidth)) {
        return css`
          grid-column: span 6;
        `
      }
      return
    }}
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    position: static;
    overflow-y: scroll;
    margin-left: 0;
    max-width: none;
    ${({ itemWidth }) => {
      if (itemWidth === 'narrow') {
        return css`
          grid-column: span 4;
        `
      }
      if (['wide', 'wider'].includes(itemWidth)) {
        return css`
          grid-column: span 8;
        `
      }
      if (itemWidth === 'widest') {
        return css`
          grid-column: span 12;
        `
      }
      return
    }}
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    ${({ itemWidth }) => {
      if (itemWidth === 'narrow') {
        return css`
          grid-column: span 3;
          max-width: ${({ theme }) => theme.pxsToEms(528)};
        `
      }
      if (itemWidth === 'wide') {
        return css`
          grid-column: span 6;
        `
      }
      if (itemWidth === 'wider') {
        return css`
          grid-column: span 9;
        `
      }
      return
    }}
  }
`

export const StyledMinimiseButton = styled(Button)`
  display: none;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    ${flexMixin}
  }
`
