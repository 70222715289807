import React from 'react'
import { removeAnyHTMLTags, smartTrim } from '~common'
import { CollectionRank } from '~components/ui/atoms/collection-rank'
import { CustomImage } from '~components/ui/atoms/custom-image'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { getProfileStatusDisplay } from '~components/ui/atoms/expert-image/helper'
import {
  StyledHeading,
  StyledContentWrapper,
  StyledCollectionText,
  StyledContentImage,
  StyledContentInfo,
} from './styles'

export interface CollectionTeaserProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  heading: string
  linkTo: string
  rank?: number | string
  status?: string
  subheading?: string
  imageSrc?: string
  metadataOptions?: {
    primary: string
    secondary: string
  }
  isWithPaddingAroundContent?: boolean
}

export const CollectionTeaser: React.FC<CollectionTeaserProps> = ({
  rank,
  status,
  linkTo,
  heading,
  subheading,
  metadataOptions,
  imageSrc,
  isWithPaddingAroundContent = false,
  children,
  ...rest
}) => {
  const imageTag = getProfileStatusDisplay(status)
  return (
    <div {...rest}>
      {rank && <CollectionRank rank={rank} />}
      <StyledContentWrapper isWithPaddingAroundContent={isWithPaddingAroundContent}>
        {!!imageSrc && (
          <StyledContentImage>
            <CustomImage src={imageSrc} alt="" tag={imageTag} />
          </StyledContentImage>
        )}
        <StyledContentInfo isWithImg={!!imageSrc} isWithChildren={!!children}>
          <StyledHeading level="3">
            <CustomLink
              title={removeAnyHTMLTags(heading)}
              href={linkTo}
              dangerouslySetInnerHTML={{ __html: `${smartTrim(heading, 120)}` }}
            />
          </StyledHeading>
          {subheading && <StyledCollectionText dangerouslySetInnerHTML={{ __html: `${subheading}` }} />}
          {metadataOptions && (
            <>
              <StyledCollectionText>{metadataOptions.primary}</StyledCollectionText>
              <StyledCollectionText title={metadataOptions.secondary}>{metadataOptions.secondary}</StyledCollectionText>
            </>
          )}
          {children}
        </StyledContentInfo>
      </StyledContentWrapper>
    </div>
  )
}
