import styled from 'styled-components'
import { Button, ButtonProps } from '~components/ui/atoms/button'
import { StatisticsBar } from '~components/ui/molecules/statistics-bar'
import { browserTruncateMixin, flexMixin } from '~common'
import { KeyValuePairs } from '~components/ui/atoms/key-value-pairs'
import { CollectionTeaser } from '~components/ui/molecules/collection-teaser'

export const StyledExportButton = styled(Button)<ButtonProps>`
  & svg path {
    transform: translateY(0rem);
  }
  &:hover svg path {
    transform: translateY(0.046875rem);
  }
  &:focus-visible svg path {
    transform: translateY(0.046875rem);
  }
  &:active svg path {
    transform: translateY(0.09375rem);
  }
  margin-top: 1rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    margin-left: 1rem;
    margin-top: 0;
  }
`

export const StyledStatisticsBarItems = styled(StatisticsBar.Items)`
  display: none;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    display: flex;
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
    margin-right: 0.5rem;
    padding-right: 0.5rem;
  }
`

export const StyledStatsWithExportButtonSection = styled.section`
  ${flexMixin};
  margin-top: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    flex-direction: row;
    align-items: center;
  }
`

export const StyledRecentMeetingKeyValuePair = styled(KeyValuePairs)`
  em {
    font-weight: 400;
  }
  color: ${({ theme }) => theme.colors.text.textPrimary};
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${browserTruncateMixin}
    max-width: 100%;
  }
`
export const StyledJournalsCollectionTeaser = styled(CollectionTeaser)`
  height: 100%;
  *:last-child {
    ${flexMixin};
    align-items: center;
    height: 60px;
  }
`
