import styled from 'styled-components'
import { Scale, scaleColor } from '~common'

export const StyledMarkerPicture = styled.picture<{ scale: Scale }>`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.neutrals.neutral5};
  border-radius: 50%;
  border: 2px solid ${scaleColor};
  float: left;
  position: relative;
  > img {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto;
    max-height: 100%;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`
