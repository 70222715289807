import styled from 'styled-components'
import { HeadingTabProps } from './HeadingTab'

export const StyledHeadingTab = styled.div<HeadingTabProps>`
  ${({ theme }) => theme.textSizes.desktop.label}
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.textOn.textOnPill : theme.colors.text.textSecondary)};
  border-radius: 2px 2px 0px 0px;
  padding: 0.25rem 0.5rem 0.125rem;
  width: max-content;
  transition: all 0.24s ease-in-out;
  & label {
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.textOn.textOnPill : theme.colors.text.textSecondary};
  }
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.pill.pillBackgroundPrimary : theme.colors.brand.backgroundTertiary};
  &:hover,
  &:focus-visible {
    background: ${({ theme }) => theme.colors.pill.pillBackgroundHover};
    color: ${({ theme }) => theme.colors.textOn.textOnPill};
    cursor: pointer;
  }
`
