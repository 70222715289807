import React from 'react'
import styled from 'styled-components'
import { useExpertEcosystemContext } from '~common/providers'
import { PageTitleBarLoadingBlock } from './components/PageTitleBarLoadingBlock'
import { StyledLoadingListContentBlock, StyledLoadingTitleBlock } from './styles'

const TweetsPageContentLoadingBlock = styled.div`
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: 1rem auto 0;
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: ${({ theme }) => theme.pxsToEms(608)};
    padding: 0;
  }
`

export const TweetsPageLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <>
      <PageTitleBarLoadingBlock />
      <TweetsPageContentLoadingBlock>
        <StyledLoadingTitleBlock />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item) => (
          <StyledLoadingListContentBlock key={item} />
        ))}
      </TweetsPageContentLoadingBlock>
    </>
  )
}
