import { NextRouter, useRouter as useNextRouter } from 'next/router'
import { formatUrl, isNilOrEmpty } from '~common'
import { useExpertEcosystemContext, IExpertEcosystemContextRouter } from '~common/providers/ExpertEcosystemProvider'
import { getCurrentShallowQuery, pushShallowHistoryEntry } from '~common/providers/shallow-routing-state-provider'

export type Router = NextRouter | IExpertEcosystemContextRouter

export const useRouter = (): Router | undefined => {
  const nextRouter = useNextRouter()
  const { router } = useExpertEcosystemContext() ?? {}

  return isNilOrEmpty(router) ? nextRouter : router
}

export const isExpertEcosystemRouter = (router: Router): router is IExpertEcosystemContextRouter => {
  return 'location' in router
}

export const useContributionIdFromUrlHash = (): string | undefined => {
  const router = useRouter()
  const urlHash = global.location?.hash.replace('#', '') || undefined
  const contributionId = router?.query?.['contributionId'] as string | undefined

  if (router && isExpertEcosystemRouter(router)) {
    if (!urlHash && !!contributionId) {
      addUrlHashItem(contributionId, router)
    }

    if (!contributionId && !!urlHash) {
      addUrlHashItem(urlHash, router)
    }
  }

  return urlHash ?? contributionId
}

export const addUrlHashItem = (
  hashValue: string,
  router?: Router,
  other: Record<string, string | string[] | undefined> = {},
): void => {
  if (router && isExpertEcosystemRouter(router)) {
    const { pathname } = router.location
    const mergedQueryParams = { ...(router?.query ?? {}), ...getCurrentShallowQuery() }
    const filtersInEEFormat = Object.keys(mergedQueryParams).filter((key) => key.startsWith('filters '))
    const filtersInPSFormat = Object.keys(mergedQueryParams).filter((key) => key.startsWith('filters['))

    if (filtersInPSFormat.length && filtersInEEFormat.length) {
      filtersInEEFormat.forEach((key) => delete mergedQueryParams[key])
    }

    const { id, ...rest } = mergedQueryParams

    pushShallowHistoryEntry(
      formatUrl(
        pathname,
        {
          ...rest,
          ...other,
          contributionId: hashValue,
        },
        hashValue,
      ),
    )
  } else {
    void router?.push(`#${hashValue}`)
  }
}
