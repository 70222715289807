import { TooltipFormatterContextObject } from 'highcharts'
import React, { RefObject, useRef } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import eachYearOfInterval from 'date-fns/eachYearOfInterval'
import { renderToStaticMarkup } from 'react-dom/server'
import { getProductColors, StyledScreenReadersOnly, CumulatingType, WeighteningType, DateInterval, Mode } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { ExportChartTrigger } from '../../components/export-chart-trigger'
import { ExportChartConfig } from '../../types'
import { getChartCategories } from '../../helpers'
import { StyledProductsMentionsColumnChart, StyledExportWrapper } from './styles'
import { useProductsMentionsColumnChartOptions } from './use-products-mentions-column-chart'

interface MentionsPercentages {
  productName: string
  percentages: number[]
  percentagesWeighted: number[]
  cumulativePercentages: number[]
  cumulativePercentagesWeighted: number[]
}

export interface ProductsMentionsColumnChartProps {
  sosvStats: MentionsPercentages[]
  dateInterval: DateInterval
  exportChartConfig?: ExportChartConfig
  cumulatingType?: CumulatingType
  weighteningType?: WeighteningType
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const ProductsMentionsColumnChart = ({
  sosvStats,
  dateInterval,
  cumulatingType = 'non-cumulative',
  weighteningType = 'unweighted',
  exportChartConfig,
  themeMode,
  themeBrand,
}: ProductsMentionsColumnChartProps): JSX.Element => {
  const chartRef = useRef<{ chart: Highcharts.Chart; container: RefObject<HTMLDivElement> }>(null)
  const yearsRange = eachYearOfInterval({ start: dateInterval.startDate, end: dateInterval.endDate }).map((date) =>
    date.getFullYear(),
  )
  const categories = getChartCategories(dateInterval, yearsRange)
  const productMentionsSeries: Highcharts.SeriesOptionsType[] =
    sosvStats.map((el, i) => {
      const data = [
        ...(cumulatingType === 'non-cumulative'
          ? weighteningType === 'unweighted'
            ? el.percentages
            : el.percentagesWeighted
          : weighteningType === 'unweighted'
          ? el.cumulativePercentages
          : el.cumulativePercentagesWeighted),
      ]

      return {
        name: el.productName,
        data,
        className: `product-${i}`,
        type: 'column',
        showInLegend: !!data.length,
      }
    }) || []

  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">Product</span> <span className="value">{context.series.name}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Timeframe</span> <span className="value">{context.key}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Percentage</span> <span className="value">{context.y}%</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const [options] = useProductsMentionsColumnChartOptions({
    data: productMentionsSeries,
    categories,
    exportChartConfig,
    dateInterval,
    toolTipFormatter,
  })

  return (
    <StyledProductsMentionsColumnChart
      productColors={getProductColors(productMentionsSeries.length, themeBrand)}
      series={productMentionsSeries}
    >
      {!!exportChartConfig && (
        <StyledExportWrapper>
          <ExportChartTrigger exportTypes={exportChartConfig.exportFormatTypes} chartRef={chartRef} />
        </StyledExportWrapper>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      <StyledScreenReadersOnly>Products mentions percentages column chart</StyledScreenReadersOnly>
    </StyledProductsMentionsColumnChart>
  )
}
