import React from 'react'
import styled from 'styled-components'
import { Flex } from '~common'
import { InfoTooltip } from '~components/ui/molecules/info-tooltip'

const StyledFiltersHeading = styled.h3`
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

const StyledFiltersHeadingWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral10};
  padding-bottom: 1px;
  margin-bottom: 0.5rem;
  align-items: center;
`

export interface FiltersHeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  asTag?: string
  infoHelp?: string | JSX.Element
}

export const FiltersHeading: React.FC<FiltersHeadingProps> = ({ children, asTag = 'h3', infoHelp, ...restProps }) => (
  <StyledFiltersHeadingWrapper as="div">
    <StyledFiltersHeading as={asTag as never} {...restProps}>
      {children}
    </StyledFiltersHeading>
    {infoHelp && <InfoTooltip content={infoHelp} />}
  </StyledFiltersHeadingWrapper>
)
