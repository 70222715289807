import React from 'react'
import { useRouter } from 'next/router'
import { ErrorPageBlock } from '~components/ui/molecules/error-page-block'
import { AmbulanceSvg } from '~common/svgs'
import Head from 'next/head'
import { LinkThemeProvider } from '~common/theme'
import { useMode } from '../../../../../shared/common/hooks/use-mode'
import { SimpleHeader } from '../simple-header'
import { ApolloProvider } from '@apollo/client'
import { createClient } from '~apollo'
import { getConfig } from '~config'

export function withRedirectErrorHandler<P>(WrappedComponent: React.FC<P>): React.FC<P> {
  return ({ ...childProps }) => {
    const router = useRouter()
    const [styleMode] = useMode()
    const config = getConfig()

    const { redirectError } = router.query
    if (redirectError) {
      return (
        <>
          <Head>
            <title>Not Found | Pharmaspectra</title>
          </Head>
          <ApolloProvider client={createClient(config)}>
            <LinkThemeProvider mode={styleMode}>
              <SimpleHeader></SimpleHeader>
              <ErrorPageBlock
                errorMessage={
                  'It seems there was a problem processing your request. This issue has been reported and will be looked into.'
                }
                icon={<AmbulanceSvg />}
              />
            </LinkThemeProvider>
          </ApolloProvider>
        </>
      )
    }
    return <WrappedComponent {...childProps} />
  }
}
