import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { Auth } from 'aws-amplify'

export const useScopeToken = (): void => {
  const router = useRouter()
  const scopeToken = (router.query.scope_token as string) || undefined
  const listOrSubId = (router.query.listId as string) || (router.query.subscriptionId as string) || undefined
  const profileId = (router.query.profileId as string) || (router.query.id as string) || undefined
  const [currentAuthenticatedEmail, setCurrentAuthenticatedEmail] = useState<string>('')

  Auth.currentSession()
    .then((session) => {
      const currentIdToken = session.getIdToken()
      setCurrentAuthenticatedEmail(currentIdToken?.payload?.email)
    })
    .catch((_) => {
      setCurrentAuthenticatedEmail('')
    })

  useEffect(() => {
    if (!scopeToken || !currentAuthenticatedEmail) {
      return
    }
    const decodedScopeToken = jwtDecode(scopeToken) as Record<string, string>
    if (decodedScopeToken && currentAuthenticatedEmail === decodedScopeToken.sub) {
      window.localStorage.setItem('scopeToken', scopeToken)
    }

    //Remove scope_token from the URL
    delete router.query.scope_token
    void router.replace(router).then(() => {
      router.reload()
    })
  }, [scopeToken, router, currentAuthenticatedEmail])

  useEffect(() => {
    const scopeToken = window.localStorage.getItem('scopeToken')
    if (!scopeToken || !currentAuthenticatedEmail || !router.isReady) {
      return
    }
    const decoded = jwtDecode(scopeToken) as Record<string, string>
    if (decoded && currentAuthenticatedEmail === decoded['sub']) {
      if (decoded['listId'] !== listOrSubId || decoded['profileId'] !== profileId) {
        //if we're looking at a list or profile that isn't in the token then sign out
        window.localStorage.removeItem('scopeToken')
        void Auth.signOut()
      }
    }
  }, [listOrSubId, profileId, currentAuthenticatedEmail, router])
}

//A simple react component so that this hook can be added to the app structure underneath the
//session provider, as it uses the session information
export const WithUseScopeToken: React.FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  useScopeToken()
  return children
}
