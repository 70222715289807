import { gql } from '@apollo/client'
import { calculateMentionsShare, MentionsBuilder } from '~apollo'
import {
  GetSoSVJournalInfoQuery,
  GetSoSVJournalInfoQueryVariables,
} from '~apollo/queries/sosv/journals/__generated__/GetSoSVJournalInfoQuery'
import {
  GetSoSVJournalsQuery,
  GetSoSVJournalsQueryVariables,
} from '~apollo/queries/sosv/journals/__generated__/GetSoSVJournalsQuery'

import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'

export const GET_SOSV_JOURNALS = gql`
  query GetSoSVJournalsQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $weighted: Boolean
    $sortBy: String
    $pageSize: Int!
    $currentPage: Int!
    $source: String
    $savedListId: String
    $smartListId: String
  ) {
    getSoSVJournalsCount(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    )
    getSoSVJournals(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      weighted: $weighted
      sortBy: $sortBy
      pageSize: $pageSize
      currentPage: $currentPage
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      id
      title
      impactScore
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
    }
    getSoSVSortByDrugs(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: ["journal-article"]
      weighted: $weighted
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      topicSelection: $topicSelection
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
    )
  }
`

export const GET_SOSV_JOURNAL_INFO = gql`
  query GetSoSVJournalInfoQuery($listId: String!, $productGroupId: String, $journalId: String!, $source: String) {
    getSoSVJournalInfo(listId: $listId, productGroupId: $productGroupId, journalId: $journalId, source: $source) {
      id
      title
      impactScore
      country
    }
  }
`

export const soSVJournalsResponseMock: GetSoSVJournalsQuery = {
  getSoSVJournalsCount: 2,
  getSoSVJournals: [
    {
      id: 'journal1',
      title: 'Journal 1',
      impactScore: 34.2234,
      mentions: calculateMentionsShare([
        MentionsBuilder.random({ productName: 'Lantus' }),
        MentionsBuilder.random({ productName: 'Glooko' }),
      ]),
    },
    {
      id: 'journal2',
      title: 'Journal 2',
      impactScore: 12.634234,
      mentions: calculateMentionsShare([MentionsBuilder.random({ productName: 'Glooko' })]),
    },
  ],
  getSoSVSortByDrugs: ['Lantus', 'Glooko', 'Soliqua', 'Toujeo', 'Tresiba', 'Victoza', 'Xultophy'],
}

export const getSoSVJournalInfoMock = (id: string): GetSoSVJournalInfoQuery => ({
  getSoSVJournalInfo: soSVJournalsResponseMock.getSoSVJournals
    .filter(({ id: journalId }) => journalId === id)
    .map(({ id, title, impactScore }) => ({ id, title, country: '', impactScore }))[0],
})

export const getSoSVJournalsQueryHandler: GraphqlHandler<GetSoSVJournalsQueryVariables, GetSoSVJournalsQuery> = (
  req,
  res,
  ctx,
) => {
  if (req.variables.timeframe === 'oneYear') {
    const { id, title, impactScore } = soSVJournalsResponseMock.getSoSVJournals[0]
    return res(
      ctx.data({
        getSoSVJournalsCount: 1,
        getSoSVJournals: [
          {
            id,
            title,
            impactScore,
            mentions: calculateMentionsShare([
              MentionsBuilder.random({ productName: 'Lantus' }),
              MentionsBuilder.random({ productName: 'Glooko' }),
            ]),
          },
        ],
        getSoSVSortByDrugs: ['Lantus', 'Glooko', 'Soliqua', 'Toujeo', 'Tresiba', 'Victoza', 'Xultophy'],
      }),
    )
  }

  if (req.variables.savedListId) {
    return res(
      ctx.data({
        ...soSVJournalsResponseMock,
        getSoSVJournalsCount: 1,
        getSoSVJournals: soSVJournalsResponseMock.getSoSVJournals.slice(0, 1),
      }),
    )
  }

  return res(ctx.data(soSVJournalsResponseMock))
}

export const getSoSVJournalInfoQueryHandler: GraphqlHandler<
  GetSoSVJournalInfoQueryVariables,
  GetSoSVJournalInfoQuery
> = (req, res, ctx) => {
  return res(ctx.data(getSoSVJournalInfoMock(req.variables.journalId)))
}
