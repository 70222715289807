import React from 'react'
import { GetSoSVGeographiesQuery } from '~apollo/queries/sosv/geographies/__generated__/GetSoSVGeographiesQuery'
import { formatUrl, generateSoSVModuleTitle, GeographyFilter, Mode, SoSVTimeframe } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { convertGeographyToName, getMentions } from '~components/pages/sosv-hub/helpers'
import { ChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { ChartLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { CollectionTeaser } from '~components/ui/molecules/collection-teaser'
import { Module } from '~components/ui/molecules/module'
import { CollectionsProductsMentionsStackedBarChart } from '~components/ui/organisms/charts'
import { useFeatures } from '~common/hooks/use-features'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'

export const TopGeographies: React.FC<{
  styleMode: Mode
  data?: GetSoSVGeographiesQuery
  isLoading?: boolean
}> = ({ data, isLoading, styleMode }) => {
  const { isIqviaBrand, showSosvChartDataExport } = useFeatures()
  const themeBrand = isIqviaBrand ? 'iqvia' : 'pharmaspectra'

  const {
    listId,
    rootListId,
    productGroupId,
    source,
    weighteningType,
    contentTypes,
    timeframe,
    cumulatingType,
    graphType,
    drugSelection = [],
    rootListName: subscriptionName = '',
    listName = '',
    startDate,
    endDate,
  } = useSoSVState()

  const hasLocations = (data?.getSoSVGeographiesCount?.count ?? 0) > 0
  const fullVersionUrl = (geography?: GeographyFilter): string => {
    const subpage = geography ? `/sosv/locations/details` : '/sosv/locations'
    return formatUrl(getPlatformUrl() + subpage, {
      listId,
      rootSubscriptionId: rootListId,
      weighteningType,
      productGroupId: productGroupId,
      sosvTimeframe: timeframe,
      sosvContentTypes: contentTypes,
      drugSelection,
      expertsSortBy: 'profileRank',
      cumulatingType,
      graphType,
      startDate,
      endDate,
      dataSet: source,
      city: geography?.city,
      state: geography?.state,
      country: geography?.country,
    })
  }

  if (isLoading) return <ChartLoadingBlock />

  const geographies: 'countries' | 'cities' | 'locations' = data?.getSoSVGeographiesCount?.type
    ? data.getSoSVGeographiesCount.type === 'countries'
      ? 'countries'
      : 'cities'
    : 'locations'

  return (
    <Module.Container>
      <Module.Header
        linkText={data?.getSoSVGeographiesCount?.count ? 'View all' : undefined}
        linkTo={data?.getSoSVGeographiesCount?.count ? fullVersionUrl() : undefined}
      >
        {generateSoSVModuleTitle({
          moduleType: geographies,
          sosvTimeframe: SoSVTimeframe.ThreeYears,
          weighteningType,
          top: 5,
        })}
      </Module.Header>

      {!!data?.getSoSVGeographiesCount?.count ? (
        <CollectionsProductsMentionsStackedBarChart
          weighteningType={weighteningType}
          exportChartConfig={
            showSosvChartDataExport
              ? {
                  collectionName: data.getSoSVGeographiesCount.type === 'countries' ? 'countries' : 'cities',
                  subscriptionName,
                  listName,
                  sosvTimeframe: SoSVTimeframe.ThreeYears,
                  contentTypes: contentTypes,
                  weighteningType: weighteningType,
                }
              : undefined
          }
          collections={data.getSoSVGeographies.map((geography) => ({
            id: convertGeographyToName(geography),
            title: convertGeographyToName(geography),
            collectionUI: (
              <LinkThemeProvider mode={styleMode} brand={themeBrand}>
                <CollectionTeaser heading={convertGeographyToName(geography)} linkTo={fullVersionUrl(geography)} />
              </LinkThemeProvider>
            ),
          }))}
          showLegend={true}
          categories={data.getSoSVGeographies.map((geography) => convertGeographyToName(geography))}
          mentions={getMentions(drugSelection, data.getSoSVGeographies)}
          themeMode={styleMode}
          themeBrand={themeBrand}
        />
      ) : (
        <ChartEmptyState isStackedBar />
      )}
      {hasLocations ? (
        <Module.Footer
          links={[
            {
              name: `View all ${data?.getSoSVGeographiesCount?.count} ${
                data?.getSoSVGeographiesCount?.type === 'countries' ? 'countries' : 'cities'
              }`,
              id: 'geographies',
              href: fullVersionUrl(),
            },
          ]}
        />
      ) : null}
    </Module.Container>
  )
}
