import { TooltipFormatterContextObject } from 'highcharts'
import React from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import { LinkThemeProvider } from '~common/theme'
import { StyledProductsMentionsBarChart } from './styles'
import { useProductsMentionsBarChartOptions } from './use-products-mentions-bar-chart-options'
import { getProductColors, Mode, StyledScreenReadersOnly } from '~common'
import { StyledHighchartsTooltipDiv, StyledProductMentionsBarChartEmptyStatе } from '../../styles'
export interface ProductsMentionsChartProps {
  series: Highcharts.SeriesOptionsType[]
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const ProductsMentionsBarChart = ({
  series,
  themeMode,
  themeBrand,
}: ProductsMentionsChartProps): JSX.Element => {
  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">{context.series.name}</span> <span className="value">{context.y}%</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const [options] = useProductsMentionsBarChartOptions(
    series as Array<Highcharts.SeriesOptionsType>,
    themeBrand,
    toolTipFormatter,
  )

  const productColors = getProductColors(series.length, themeBrand)

  return (
    <>
      {!!series.length ? (
        <StyledProductsMentionsBarChart productColors={productColors} series={series}>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <StyledScreenReadersOnly>Products mentions percentage bar chart</StyledScreenReadersOnly>
        </StyledProductsMentionsBarChart>
      ) : (
        <StyledProductMentionsBarChartEmptyStatе>No mentions</StyledProductMentionsBarChartEmptyStatе>
      )}
    </>
  )
}
