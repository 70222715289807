import React from 'react'
import styled from 'styled-components'
import { ButtonWithNoStyle, Flex } from '~common'

export interface TimeframeRange {
  value: number
  key: string
  isDisabled?: boolean
  timeframe?: string
}

export const StyledYear = styled.li<{ isActive: boolean }>`
  ${({ theme, isActive }) => (isActive ? theme.textSizes.desktop.labelBold : theme.textSizes.desktop.label)};
`

export const StyledYearButton = styled(ButtonWithNoStyle)<{ isActive: boolean; isDisabled?: boolean }>`
  color: ${({ theme, isActive }) => (!isActive ? theme.colors.buttons.link : theme.colors.buttons.textLinkActive)};
  cursor: ${({ isActive, isDisabled }) => (isActive || isDisabled ? 'default' : 'pointer')} !important;
  &:hover,
  &:focus {
    color: ${({ theme, isActive, isDisabled }) => !isDisabled && !isActive && theme.colors.text.textHoverPrimary};
  }
  opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : '1')};
`

export const StyledTimeRangeList = styled(Flex).attrs({ as: 'ul' })`
  margin-top: 0.5rem;
  justify-content: flex-end;
  & li:not(:last-child) {
    margin-right: 1rem;
  }
`

export const Timeframe = ({
  timeRanges,
  selectedTimeRange,
  selectTimeRange,
}: {
  timeRanges: TimeframeRange[]
  selectedTimeRange: TimeframeRange
  selectTimeRange: (range: TimeframeRange) => void
}): JSX.Element => {
  const isActive = (timeRange: TimeframeRange) =>
    timeRange.key === selectedTimeRange.key && timeRange.value === selectedTimeRange.value
  return (
    <StyledTimeRangeList>
      {timeRanges.map((timeRange: TimeframeRange, i: number) => (
        <StyledYear key={i} isActive={isActive(timeRange)}>
          <StyledYearButton
            onClick={() => (timeRange.isDisabled ? null : selectTimeRange(timeRange))}
            isActive={!timeRange.isDisabled && isActive(timeRange)}
            isDisabled={timeRange.isDisabled}
          >
            {timeRange.value}
            {timeRange.key}
          </StyledYearButton>
        </StyledYear>
      ))}
    </StyledTimeRangeList>
  )
}
