import { SoSVSource } from '~components/pages/widgets/sosv/types'
import { CumulatingType, GraphType, ProductGroup, SoSVTimeframe, WeighteningType } from '~common'
import { GetSubscriptionsForUserQuery_getSubscriptionsForUser } from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import React, { createContext, Dispatch, ReactElement, ReactNode, useContext, useReducer } from 'react'

export interface ISoSVState {
  listId?: string
  listName?: string
  expertsCount: number
  rootListId?: string
  rootListName?: string
  productGroupId?: string
  productGroups: ProductGroup[]
  drugSelection: string[]
  topics: string[]
  topicSelection: string[]
  source: SoSVSource
  weighteningType: WeighteningType
  timeframe: SoSVTimeframe
  startDate?: string
  endDate?: string
  contentTypes: string[]
  cumulatingType: CumulatingType
  graphType: GraphType
  subscriptions?: GetSubscriptionsForUserQuery_getSubscriptionsForUser[]
}

export const initialSoSVState: ISoSVState = {
  expertsCount: 0,
  source: SoSVSource.EXPERTS,
  weighteningType: 'weighted',
  timeframe: SoSVTimeframe.ThreeYears,
  contentTypes: ['presentation', 'journal-article'],
  cumulatingType: 'cumulative',
  graphType: 'counts',
  productGroups: [],
  drugSelection: [],
  topics: [],
  topicSelection: [],
}

const reducer = (state: ISoSVState, newState: ISoSVState): ISoSVState => {
  return { ...state, ...newState }
}

const SoSVStateContext = createContext<ISoSVState>(initialSoSVState)
const SoSVStateDispatchContext = createContext<Dispatch<ISoSVState>>(() => null)

const SoSVSettingsProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialSoSVState)

  return (
    <SoSVStateContext.Provider value={state}>
      <SoSVStateDispatchContext.Provider value={dispatch}>{children}</SoSVStateDispatchContext.Provider>
    </SoSVStateContext.Provider>
  )
}

const useSoSVState = (): ISoSVState => useContext(SoSVStateContext)

const useSoSVStateDispatch = (): Dispatch<ISoSVState> => useContext(SoSVStateDispatchContext)

export { SoSVStateContext, SoSVSettingsProvider, useSoSVState, useSoSVStateDispatch }
