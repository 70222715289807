import React from 'react'
import {
  StyledCustomProductsMentionsBarChart,
  StyledCustomProductMention,
  StyledCustomProductMentionContent,
} from './styles'
import { getPercentage, getProductColors, WeighteningType } from '~common'
import { StyledProductMentionsBarChartEmptyStatе } from '../../styles'

export interface ProductsMentionsChartProps {
  sosvSummary: {
    productName: string
    count: number
    countWeighted: number
  }[]
  weighteningType: WeighteningType
  isIqviaBrand: boolean
}

export const CustomProductsMentionsBarChart = ({
  sosvSummary,
  weighteningType,
  isIqviaBrand,
}: ProductsMentionsChartProps): JSX.Element => {
  const productColors = getProductColors(sosvSummary.length, isIqviaBrand ? 'iqvia' : 'pharmaspectra').reverse()
  const total = sosvSummary.reduce(
    (acc, cur) => (acc += weighteningType === 'weighted' ? cur.countWeighted : cur.count),
    0,
  )
  const series = sosvSummary.map((item, i) => ({
    name: item.productName,
    data: [
      {
        y: +(weighteningType === 'weighted'
          ? getPercentage({ value: item.countWeighted, whole: total })
          : getPercentage({ value: item.count, whole: total })),
      },
    ],
    color: productColors[i],
  }))
  return (
    <>
      {!!series.length ? (
        <StyledCustomProductsMentionsBarChart>
          {series.map((el) => (
            <StyledCustomProductMention
              key={el.color}
              elementWidth={el.data[0].y}
              title={`${el.name}: ${parseFloat(el.data[0].y.toFixed(1))}%`}
            >
              <StyledCustomProductMentionContent color={el.color}></StyledCustomProductMentionContent>
            </StyledCustomProductMention>
          ))}
        </StyledCustomProductsMentionsBarChart>
      ) : (
        <StyledProductMentionsBarChartEmptyStatе>No mentions</StyledProductMentionsBarChartEmptyStatе>
      )}
    </>
  )
}
