import styled from 'styled-components'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'
import { SortBy } from '~components/ui/molecules/sort-by'
import { Flex } from '~common'

export const StyledSortBy = styled(SortBy)`
  align-self: center;
`

export const StyledSortAndExportWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`

export const StyledCollectionsProductMentionsStackedBarChart = styled(StyledChart)<{
  productColors: string[]
  series: Highcharts.SeriesOptionsType[]
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  .highcharts-point {
    fill: unset;
    stroke-width: 0px;
  }

  .highcharts-axis-labels > span {
    font-family: ${({ theme }) => theme.fontFamily} !important;
    width: 180px !important;
    overflow-x: scroll !important;
    @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
      width: 320px !important;
      overflow-x: hidden !important;
    }
  }

  ${({ productColors, series }) => getProductColorsCss({ productColors, series })};
`
