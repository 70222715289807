import { gql } from '@apollo/client'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { GetSoSVGeographiesQuery, GetSoSVGeographiesQueryVariables } from './__generated__/GetSoSVGeographiesQuery'
import { calculateMentionsShare } from '~apollo'

export const GET_SOSV_GEOGRAPHIES = gql`
  query GetSoSVGeographiesQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $contentTypes: [String!]!
    $weighted: Boolean
    $savedListId: String
    $smartListId: String
    $sortBy: String
    $pageSize: Int!
    $currentPage: Int!
  ) {
    getSoSVGeographies(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      contentTypes: $contentTypes
      weighted: $weighted
      sortBy: $sortBy
      pageSize: $pageSize
      currentPage: $currentPage
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      country
      state
      city
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
    }
    getSoSVGeographiesCount(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      contentTypes: $contentTypes
      savedListId: $savedListId
      smartListId: $smartListId
    ) {
      count
      type
    }
    getSoSVSortByDrugs(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: $contentTypes
      weighted: $weighted
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      topicSelection: $topicSelection
      savedListId: $savedListId
      smartListId: $smartListId
    )
  }
`

export const SoSVGeographiesMock: GetSoSVGeographiesQuery = {
  getSoSVGeographies: [
    {
      country: 'USA',
      state: null,
      city: null,
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 12, countWeighted: 12 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
    },
    {
      country: 'UK',
      state: null,
      city: null,
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 9, countWeighted: 9 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
    },
    {
      country: 'Japan',
      state: null,
      city: null,
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 6, countWeighted: 6 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
    },
    {
      country: 'France',
      state: null,
      city: null,
      mentions: calculateMentionsShare([
        { productName: 'Lantus', count: 0, countWeighted: 0 },
        { productName: 'Toujeo', count: 0, countWeighted: 0 },
        { productName: 'Tresiba', count: 0, countWeighted: 0 },
        { productName: 'Victoza', count: 4, countWeighted: 4 },
        { productName: 'Xultophy', count: 0, countWeighted: 0 },
        { productName: 'Soliqua', count: 0, countWeighted: 0 },
      ]),
    },
  ],
  getSoSVGeographiesCount: {
    type: 'countries',
    count: 4,
  },
  getSoSVSortByDrugs: ['Lantus', 'Glooko', 'Soliqua', 'Toujeo', 'Tresiba', 'Victoza', 'Xultophy'],
}

export const getSoSVGeographiesQueryHandler: GraphqlHandler<
  GetSoSVGeographiesQueryVariables,
  GetSoSVGeographiesQuery
> = (req, res, ctx) => {
  const result: GetSoSVGeographiesQuery = JSON.parse(JSON.stringify(SoSVGeographiesMock))
  const sortBy = req.variables.sortBy || 'all'
  const weighted = req.variables.weighted ?? false
  const drugSelection = req.variables.drugSelection || []
  result.getSoSVGeographies.forEach((e) =>
    e.mentions.forEach((m) => {
      if (!drugSelection.includes(m.productName)) {
        m.count = 0
      }
    }),
  )
  result.getSoSVGeographies.sort((a, b) => {
    const countA = a.mentions.reduce(
      (p, c) => p + (sortBy === 'all' || sortBy === c.productName ? (weighted ? c.countWeighted : c.count) : 0),
      0,
    )
    const countB = b.mentions.reduce(
      (p, c) => p + (sortBy === 'all' || sortBy === c.productName ? (weighted ? c.countWeighted : c.count) : 0),
      0,
    )
    return countB - countA
  })

  if (req.variables.savedListId) {
    return res(
      ctx.data({ ...result, getSoSVGeographiesCount: 2, getSoSVGeographies: result.getSoSVGeographies.slice(0, 2) }),
    )
  }

  return res(ctx.data(result))
}
