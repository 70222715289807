import React from 'react'
import { NorthBlackSvg, SouthBlackSvg } from '~common/svgs'
import { Badge } from '~components/ui/atoms/badge'
import { StyledSortBy } from './styles'

interface Option {
  id: string
  name: string
  isDisabled?: boolean
  direction?: 'desc' | 'asc'
}

export interface SortByProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option[]
  onSort: (id: string) => void
  selectedOptionId?: string
}

export const SortBy = ({ options, selectedOptionId, onSort, ...rest }: SortByProps): JSX.Element => (
  <StyledSortBy {...rest}>
    {options.map((option: Option) => {
      const isSelected = option.id === selectedOptionId
      const SortIcon = option?.direction === 'asc' ? NorthBlackSvg : SouthBlackSvg
      return (
        <Badge
          key={option.id}
          iconPosition="right"
          icon={isSelected && <SortIcon ariaLabel="selected sort item" />}
          isSelected={isSelected}
          isDisabled={option.isDisabled}
          onClick={() => (!option.isDisabled ? onSort(option.id) : undefined)}
        >
          {option.name}
        </Badge>
      )
    })}
  </StyledSortBy>
)
