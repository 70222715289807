import { TooltipFormatterContextObject } from 'highcharts'
import { TTrendDirection } from '~common'
import { getCommonChartOptions } from '../../common-charts-options'

export const useOnTopicChartOptions = ({
  data,
  categories,
  regressionLineData,
  onTopicRegressionLineDirection,
  toolTipFormatter,
}: {
  data: { y: number }[]
  categories: (number | string)[]
  regressionLineData: number[]
  onTopicRegressionLineDirection: TTrendDirection
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): [Highcharts.Options] => {
  const commonChartOptions = getCommonChartOptions(categories)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      height: 93,
      type: 'column',
      className: 'on-topic',
    },
    tooltip: {
      ...commonChartOptions.tooltip,
      distance: 11,
      style: {
        width: 96,
      },
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      gridLineWidth: 0,
      labels: {
        x: -4,
        style: {
          width: 25,
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return `${this.value}`
          }
          return ''
        },
      },
    },
    xAxis: {
      ...commonChartOptions.xAxis,
      labels: {
        x: -2,
        formatter: function () {
          if (this.isLast) {
            return 'Present'
          }
          if (this.isFirst) {
            return '10 yrs ago'
          }
          return ''
        },
        skew3d: true,
        rotation: 0,
        style: {
          textOverflow: 'none',
        },
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
      },
    },
    series: [
      {
        data,
        type: 'column',
      },
      {
        type: 'line',
        name: 'Regression Line',
        data: regressionLineData,
        pointStart: categories.length - 5,
        marker: {
          enabled: false,
        },
        visible: categories.length >= 5,
        enableMouseTracking: false,
        className: `regression-line-${onTopicRegressionLineDirection}`,
      },
    ],
  }
  return [options]
}
