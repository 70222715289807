import React from 'react'
import { capitalizeFirstLetter } from '~common'
import { useCrumbtrail } from '~common/hooks/use-crumbtrail'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { StyledCrumbtrailNav, StyledCrumbtrailList, StyledItem, StyledChevronIcon } from './styles'

interface Crumb {
  name: string
  href: string
}

export interface CrumbtrailProps {
  listId?: string
  useCrumbtrailHook?: (id?: string) => { crumbs: Crumb[]; isLoading: boolean; isError: boolean }
}

export const Crumbtrail = ({ listId, useCrumbtrailHook = useCrumbtrail }: CrumbtrailProps): JSX.Element => {
  const { crumbs, isLoading, isError } = useCrumbtrailHook(listId)
  const isLast = (index: number) => index === crumbs.length - 1
  return (
    <StyledCrumbtrailNav role="navigation" aria-label="Breadcrumb" isLoading={isLoading}>
      {!isLoading && !isError && (
        <StyledCrumbtrailList>
          {crumbs.map((crumb: Crumb, i: number) =>
            isLast(i) ? (
              <StyledItem title={getCrumbtrail(crumb.name)} key={i}>
                {getCrumbtrail(crumb.name)}
              </StyledItem>
            ) : (
              <StyledItem key={`${crumb.href}${crumb.name}`} title={getCrumbtrail(crumb.name)}>
                <CustomLink size="sm" href={crumb.href}>
                  {getCrumbtrail(crumb.name)}
                </CustomLink>
                <StyledChevronIcon width="12" height="12" position="middle" />
              </StyledItem>
            ),
          )}
        </StyledCrumbtrailList>
      )}
    </StyledCrumbtrailNav>
  )
}

const getCrumbtrail = (name: string) => {
  if (!name) return
  const sanitisedName = name.replace(/([^\s])-([^\s])/g, '$1 $2') //replace hyphens that are surrounded with not whitespace
  return capitalizeFirstLetter(sanitisedName)
}
