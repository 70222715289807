import React from 'react'
import { Module } from '~components/ui/molecules/module'
import { formatUrl, StyledSection } from '~common'
import { StatisticsBar } from '~components/ui/molecules/statistics-bar'
import { Divider } from '~components/ui/atoms/divider'
import { ContentTeaser } from '~components/ui/molecules/content-teaser'
import { GetSoSVPresentationsQuery_getSoSVPresentations } from '~apollo/queries/sosv/presentations/__generated__/GetSoSVPresentationsQuery'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'

export interface PresentationsProps {
  presentationMatchingCount: number
  recentPresentations?: GetSoSVPresentationsQuery_getSoSVPresentations[]
  productColors: string[]
}

export const Presentations: React.FC<PresentationsProps> = ({
  presentationMatchingCount,
  recentPresentations,
  productColors,
}) => {
  const {
    listId,
    rootListId,
    productGroupId,
    source,
    weighteningType,
    contentTypes,
    timeframe,
    cumulatingType,
    graphType,
    drugSelection = [],
    startDate,
    endDate,
  } = useSoSVState()

  const fullVersionUrl = (presentationId?: string): string => {
    const subpage = '/sosv/presentations'

    return formatUrl(
      getPlatformUrl() + subpage,
      {
        listId,
        rootSubscriptionId: rootListId,
        weighteningType: weighteningType,
        productGroupId: productGroupId,
        sosvTimeframe: timeframe,
        sosvContentTypes: contentTypes,
        startDate,
        endDate,
        drugSelection,
        journalsSortBy: 'all',
        cumulatingType,
        graphType,
        dataSet: source,
      },
      presentationId,
    )
  }

  return (
    <Module.Container>
      <Module.Header linkTo={presentationMatchingCount ? fullVersionUrl() : undefined}>Presentations</Module.Header>
      <StyledSection data-testid="presentations-stats">
        <StatisticsBar.Items
          statistics={[
            {
              name: 'Matched',
              value: presentationMatchingCount.toString(),
            },
          ]}
        />
      </StyledSection>
      {!!presentationMatchingCount && (
        <>
          <Divider borderType="dotted" />
          <ul>
            {recentPresentations?.map((item: GetSoSVPresentationsQuery_getSoSVPresentations) => (
              <ContentTeaser
                key={item.id}
                asTag="li"
                heading={item.title}
                subheading={item.conferenceName}
                startDate={item.date}
                teaserPhaseOrType={item.type}
                linkTo={fullVersionUrl(item.id)}
                mentionedProducts={item.mentions
                  .map((mention, i) => ({
                    name: mention.productName,
                    count: mention.count,
                    denominationColor: productColors[i],
                  }))
                  .filter((mention) => mention.count)}
              />
            ))}
          </ul>
          <Module.Footer
            links={[
              {
                name: `View all ${presentationMatchingCount} presentations`,
                id: 'presentations',
                href: fullVersionUrl(),
              },
            ]}
          />
        </>
      )}
    </Module.Container>
  )
}
