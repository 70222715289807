import React from 'react'
import { InBoxGrid } from '~components/ui/templates/inbox-grid'
import { ListLoadingBlocks } from './components/ListLoadingBlocks'
import { StyledLoadingListContentBlock, StyledLoadingTitleBlock } from './styles'
import styled from 'styled-components'
import { useExpertEcosystemContext } from '~common/providers'

const StyleAbstarctLoadingBlock = styled.article`
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: 1rem auto 2rem;
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: ${({ theme }) => theme.pxsToEms(608)};
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 1.5rem auto 3rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    max-width: ${({ theme }) => theme.pxsToEms(760)};
  }
`

export const InboxAbstractLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <StyleAbstarctLoadingBlock data-testid="inbox-abstract-loading-blocks">
      <StyledLoadingTitleBlock marginBottomSize="sm" />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((el) => (
        <StyledLoadingListContentBlock key={el} />
      ))}
    </StyleAbstarctLoadingBlock>
  )
}

export const InboxPageLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <InBoxGrid.Container>
      <InBoxGrid.Item itemWidth="narrow" isOutOfFlow={true}>
        <ListLoadingBlocks />
      </InBoxGrid.Item>
      <InBoxGrid.Item itemWidth="wider" backgroundVariation="transparent">
        <InboxAbstractLoadingBlocks />
      </InBoxGrid.Item>
    </InBoxGrid.Container>
  )
}
