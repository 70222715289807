import React from 'react'
import {
  StyledLockedExpert,
  StyledInitialsPlaceholder,
  StyledPrivacyMessage,
  StyledHeading,
  StyledHeadingAndPrivacyMesageWrapper,
} from './styles'

export interface LockedExpertProps extends React.HTMLAttributes<HTMLDivElement> {
  firstName: string
  lastName: string
  privacyMessage: string
  fullName: string
}

export const LockedExpert = ({
  firstName,
  lastName,
  privacyMessage,
  fullName,
  ...rest
}: LockedExpertProps): JSX.Element => {
  return (
    <StyledLockedExpert {...rest}>
      <StyledInitialsPlaceholder firstName={firstName} lastName={lastName} />
      <StyledHeadingAndPrivacyMesageWrapper>
        <StyledHeading level="3">{fullName}</StyledHeading>
        <StyledPrivacyMessage as="p">{privacyMessage}</StyledPrivacyMessage>
      </StyledHeadingAndPrivacyMesageWrapper>
    </StyledLockedExpert>
  )
}
