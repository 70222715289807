import React, { RefObject, useRef } from 'react'
import Highcharts, { TooltipFormatterContextObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import { getProductColors, GraphType, Mode, roundDecimals, StyledScreenReadersOnly, WeighteningType } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { StyledProductsMentionsTreemapChart, StyledExportWrapper } from './styles'
import { useProductsMentionsTreemapChartOptions } from './use-products-mentions-treemap-chart-options'
import { ExportChartConfig } from '../../types'
import { ExportChartTrigger } from '../../components/export-chart-trigger'

export interface CustomPointOptionsObject extends Highcharts.PointOptionsObject {
  value: number
  shapeArgs?: {
    width: number
    height: number
  }
  dataLabel?: {
    width: number
    height: number
  }
}

interface MentionsCount {
  productName: string
  counts: number[]
  countsWeighted: number[]
}

export interface ProductsMentionsTreemapChartProps {
  sosvStats: MentionsCount[]
  exportChartConfig?: ExportChartConfig
  graphType: GraphType
  weighteningType?: WeighteningType
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const ProductsMentionsTreemapChart = ({
  sosvStats,
  weighteningType = 'unweighted',
  exportChartConfig,
  graphType = 'counts',
  themeMode,
  themeBrand,
}: ProductsMentionsTreemapChartProps): JSX.Element => {
  const chartRef = useRef<{ chart: Highcharts.Chart; container: RefObject<HTMLDivElement> }>(null)
  const productMentionsSeries: CustomPointOptionsObject[] =
    sosvStats.map((el, i, arr) => {
      const value = el[weighteningType === 'unweighted' ? 'counts' : 'countsWeighted'].reduce(
        (acc, cur) => acc + cur,
        0,
      )
      return {
        name: el.productName,
        value,
        className: `product-${i}`,
        color: getProductColors(arr.length, themeBrand).reverse()[i],
      }
    }) || []
  const totalMentions = productMentionsSeries.reduce(
    (acc: number, cur: CustomPointOptionsObject) => acc + cur.value || 0,
    0,
  )

  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const count = context.point.options.value || 0
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">Product</span> <span className="value">{context.key}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Count</span>{' '}
              <span className="value">{count != 0 ? roundDecimals(count, 1) : ''}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Share</span>{' '}
              <span className="value">{Number(((count / totalMentions) * 100).toFixed(1))}%</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }
  const [options] = useProductsMentionsTreemapChartOptions({
    data: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        data: productMentionsSeries.reverse(),
        showInLegend: true,
        legendType: 'point',
      },
    ],
    categories: [],
    totalMentions,
    exportChartConfig,
    graphType,
    series: productMentionsSeries.filter((el: CustomPointOptionsObject) => !!el.value),
    themeBrand,
    toolTipFormatter,
  })

  return (
    <>
      {!!exportChartConfig && (
        <StyledExportWrapper>
          <ExportChartTrigger chartRef={chartRef} />
        </StyledExportWrapper>
      )}
      <StyledProductsMentionsTreemapChart
        productColors={getProductColors(productMentionsSeries.length, themeBrand)}
        series={productMentionsSeries}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
          ref={chartRef}
          containerProps={{ style: { height: '100%' } }}
        />
        <StyledScreenReadersOnly>Product mentions count line chart</StyledScreenReadersOnly>
      </StyledProductsMentionsTreemapChart>
    </>
  )
}
