import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { AmbulanceSvg, MissingFileSvg } from '~common/svgs'
import { HidingErrorBoundary } from '~components/error-boundary/error-boundary'
import { Crumbtrail } from '~components/ui/crumbtrail'
import { ErrorPageBlock } from '~components/ui/molecules/error-page-block'

export const PERMISSION_ERROR = 'The user is not authorized to access this data'
export class PermissionError extends Error {
  constructor(errorMessage: string) {
    super(`${PERMISSION_ERROR}; ${errorMessage}`)
  }
}

export const ServerError = ({
  resetErrorBoundary,
  error,
}: {
  resetErrorBoundary: () => void
  error: Error
}): JSX.Element => {
  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeComplete', resetErrorBoundary)
    return () => {
      router.events.off('routeChangeComplete', resetErrorBoundary)
    }
  }, [router, resetErrorBoundary])
  return (
    <>
      <Head>
        <title>Error | Pharmaspectra</title>
      </Head>
      {error.message.includes(PERMISSION_ERROR) ? (
        <ErrorPageBlock
          errorMessage="Either the page doesn’t exist or you do not have access to it"
          icon={<MissingFileSvg />}
        />
      ) : (
        <>
          <HidingErrorBoundary>
            <Crumbtrail />
          </HidingErrorBoundary>
          <ErrorPageBlock
            errorMessage="We're having trouble loading this content right now, please try refreshing in a few moments"
            icon={<AmbulanceSvg />}
          />
        </>
      )}
    </>
  )
}
