import { gql } from '@apollo/client'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { GenerateZendeskSsoTokenQuery } from './__generated__/GenerateZendeskSsoTokenQuery'

export const GENERATE_ZENDESK_SSO_TOKEN = gql`
  query GenerateZendeskSsoTokenQuery {
    generateZendeskSsoToken
  }
`

export const GenerateZendeskSsoTokenQueryHandler: GraphqlHandler<never, GenerateZendeskSsoTokenQuery> = (
  req,
  res,
  ctx,
) => {
  const response: GenerateZendeskSsoTokenQuery = { generateZendeskSsoToken: 'dummy-jwt-token' }
  return res(ctx.data(response))
}
