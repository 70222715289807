import React from 'react'
import { Controlled } from '~components/ui/controlled'
import { ControlledProps } from '~components/ui/controlled/types'
import { StyledDropdownWithCheckboxesItem } from '../styles'
import { Option } from '../types'

export interface DropdownCheckboxProps<TFormValues> extends ControlledProps<TFormValues> {
  item: Option
  tabIndex: number
  name: string
}

export const DropdownCheckbox = <TFormValues extends Record<string, unknown>>({
  item,
  tabIndex,
  name,
  ...restProps
}: DropdownCheckboxProps<TFormValues>): JSX.Element => (
  <StyledDropdownWithCheckboxesItem>
    <Controlled.Checkbox
      id={item.id}
      checkboxSize="sm"
      label={item.label}
      tabIndex={tabIndex}
      name={`${name}[${item.id}]`}
      {...restProps}
    />
  </StyledDropdownWithCheckboxesItem>
)
