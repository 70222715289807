import { gql } from '@apollo/client'
import {
  ContactBuilder,
  DigitalAffinityInSubscriptionBuilder,
  SocialProfileBuilder,
  SoSVSummaryBuilder,
  StatsInSubscriptionBuilder,
  SubProfileBuilder,
  SubscriptionBuilder,
} from '~apollo'
import { OptStatus } from '~apollo/generated-global-types'
import {
  GetProfilesInListLocationsQuery,
  GetProfilesInListLocationsQueryVariables,
} from '~apollo/queries/subscriptions/__generated__/GetProfilesInListLocationsQuery'
import {
  GetProfilesInListQuery,
  GetProfilesInListQueryVariables,
} from '~apollo/queries/subscriptions/__generated__/GetProfilesInListQuery'
import {
  GetSubscriptionsForUserQuery,
  GetSubscriptionsForUserQuery_getSubscriptionsForUser,
} from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { Features, SmartListType } from '~common'
import { GetFeaturesForUserQuery } from '~common/hooks/use-features/__generated__/GetFeaturesForUserQuery'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { fakePagination } from '~mocks/handlers/helpers'
import { ANNA, ANNA_ID, JOHN, JOHN_ID, TAMARA, TAMARA_ID, WOJCIECH, WOJCIECH_ID } from '~mocks/handlers/ids'
import { arrayOf, randomString } from '~testing/test-helpers'
import { GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables } from './__generated__/GetSubscriptionByIdQuery'
import {
  GetSubscriptionInfoForProfileQuery,
  GetSubscriptionInfoForProfileQueryVariables,
} from './__generated__/GetSubscriptionInfoForProfileQuery'

export const GET_SUBSCRIPTION_INFO_FOR_PROFILE = gql`
  query GetSubscriptionInfoForProfileQuery($profileId: String!, $listId: String!) {
    getSubscriptionById(listId: $listId) {
      name
      id
      parentId
      rootId
      fullName
      subsTree {
        name
        id
      }
    }

    getSubscriptionStatsForProfile(profileId: $profileId, listId: $listId) {
      onTopicOverallPercentage
      onTopicCountsTimeline
      onTopicCountRegressionSlope
      onTopicCountRegressionYIntercept
      onTopicClinicalTrialsPercentage
      onTopicJournalsPercentage
      onTopicPresentationsPercentage
      profileRank
      profileRankByYearsAgo
      profileRankRegressionSlope
      profileRankRegressionYIntercept
      tlScore
      rankNormalization
      rank
      rankScoreByYearsAgo
      rankScoreRegressionSlope
      rankScoreRegressionYIntercept
      presentationScore
      journalScore
      treatmentGuidelineScore
      clinicalTrialScore
      grantScore
      institutionalScore
      editorialScore
      societyScore
    }
  }
`

export const GET_SUBSCRIPTIONS_FOR_USER = gql`
  query GetSubscriptionsForUserQuery {
    getSubscriptionsForUser {
      id
      name
      clientName
      profileCount
      parentId
      permissions
      children {
        id
        name
        clientName
        profileCount
        parentId
        permissions
        children {
          id
          name
          clientName
          profileCount
          parentId
          permissions
        }
      }
    }
  }
`

export const GET_PROFILES_IN_LIST = gql`
  query GetProfilesInListQuery(
    $listId: String!
    $pageSize: Int!
    $currentPage: Int!
    $sortBy: [SortBy!]
    $savedListId: String
    $disclosureCategories: [String!]
    $dataType: String
    $smartListId: String
  ) {
    getProfilesInList(
      listId: $listId
      pageSize: $pageSize
      currentPage: $currentPage
      sortBy: $sortBy
      savedListId: $savedListId
      disclosureCategories: $disclosureCategories
      dataType: $dataType
      smartListId: $smartListId
    ) {
      totalProfiles
      profiles {
        ... on SubscriptionProfileType {
          profileId
          subscriptionStats {
            profileRank
            profileRankByYearsAgo
            profileRankRegressionSlope
            onTopicOverallPercentage
            onTopicCountsTimeline
            onTopicCountRegressionSlope
            tlScore
            rank
            rankScoreByYearsAgo
            rankScoreRegressionSlope
            rankScoreRegressionYIntercept
          }
          createdOn
          updatedOn
          firstName
          middleName
          lastName
          suffix
          fullName
          profileStatus
          hcpIdentifier {
            type
            value
          }
          socialProfile {
            linkedInUrl
            twitterUrl
            twitterUsername
            twitterFollowersCount
          }
          degrees
          specialities
          contacts {
            institutionName
            address1
            address2
            city
            state
            country
            zip
            phoneNumber
            faxNumber
            firstEmail
            secondEmail
            location {
              lon
              lat
            }
          }
          totalJournals
          totalPresentations
          totalClinicalTrials
          totalGrants
          totalPatents
          totalTreatmentGuidelines
          doesWorkWithIndustry
          isPrimaryInvestigator
          areasOfInterest
          currentResearch {
            title
          }
          latestContribution {
            contributionType
            id
            sourceName
            title
            date
            type
          }
          networkConnectionsTop3 {
            connectionsInListCount
            totalConnectionsCount
            connections {
              ... on NetworkConnectionType {
                profileId
                fullName
                renderAsProfiled
                __typename
              }
              ... on NetworkProfileLockedConnectionType {
                profileId
                fullName
                renderAsProfiled
                __typename
              }
            }
          }
          gdprExcluded
          alsoInLists
          optStatus
          sosvSummaries {
            productGroupId
            sosvStats {
              productName
              percentage
            }
          }
          digitalAffinity {
            subscriptionId
            totalOwnedContent
            totalEarnedContent
            digitalRank
            digitalOnTopicPercentage
          }
          influenceScores {
            subscriptionId
            overall
            localityInternational
            localityNational
          }
          __typename
        }
        ... on SubscriptionProfileStubType {
          profileId
          fullName
          firstName
          lastName
          subscriptionStats {
            profileRank
            profileRankRegressionSlope
            rank
            rankScoreRegressionSlope
          }
          gdprExcluded
          optStatus
          __typename
        }
      }
    }
  }
`

export const GET_PROFILES_IN_LIST_LOCATIONS = gql`
  query GetProfilesInListLocationsQuery(
    $listId: String!
    $pageSize: Int!
    $currentPage: Int!
    $sortBy: [SortBy!]
    $savedListId: String
    $disclosureCategories: [String!]
    $dataType: String
    $smartListId: String
  ) {
    getProfilesInList(
      listId: $listId
      pageSize: $pageSize
      currentPage: $currentPage
      sortBy: $sortBy
      savedListId: $savedListId
      disclosureCategories: $disclosureCategories
      dataType: $dataType
      smartListId: $smartListId
    ) {
      profiles {
        ... on SubscriptionProfileType {
          profileId
          subscriptionStats {
            profileRank
            rank
          }
          createdOn
          updatedOn
          firstName
          middleName
          lastName
          suffix
          fullName
          profileStatus
          specialities
          contacts {
            institutionName
            address1
            address2
            city
            state
            country
            location {
              lat
              lon
            }
          }
          gdprExcluded
          optStatus
          sosvSummaries {
            productGroupId
            sosvStats {
              productName
              percentage
            }
          }
          __typename
        }
        ... on SubscriptionProfileStubType {
          profileId
          fullName
          firstName
          lastName
          subscriptionStats {
            profileRank
            profileRankRegressionSlope
            rank
            rankScoreRegressionSlope
          }
          gdprExcluded
          optStatus
          __typename
        }
      }
    }
  }
`

export const GET_SUBSCRIPTION_BY_ID = gql`
  query GetSubscriptionByIdQuery($listId: String!) {
    getSubscriptionById(listId: $listId) {
      name
      id
      parentId
      rootId
      profileCount
      contributionCount
      clientName
      permissions
    }
  }
`

export const getSubscriptionByIdHandler: GraphqlHandler<GetSubscriptionByIdQueryVariables, GetSubscriptionByIdQuery> = (
  req,
  res,
  ctx,
) => {
  if (
    req.variables.listId !== 'top-level-sub-1' &&
    req.variables.listId !== 'child-sub-1' &&
    req.variables.listId !== 'top-level-sub-2' &&
    req.variables.listId !== 'grandchild-sub' &&
    req.variables.listId !== 'grandchild-sub-3'
  )
    throw new Error(`No mocked response for list with id ${req.variables.listId}`)
  const response = {
    'top-level-sub-1': {
      getSubscriptionById: SubscriptionBuilder.random({
        id: 'top-level-sub-1',
        name: 'Top level Sub',
        parentId: 'top-level-sub-1',
        profileCount: 9,
        contributionCount: 109,
      }),
    },
    'child-sub-1': {
      getSubscriptionById: SubscriptionBuilder.random({
        id: 'child-sub-1',
        name: 'Child Subscription 1',
        parentId: 'top-level-sub-1',
        rootId: 'child-sub-1',
        profileCount: 9,
        contributionCount: 110,
      }),
    },
    'top-level-sub-2': {
      getSubscriptionById: SubscriptionBuilder.random({
        id: 'top-level-sub-2',
        name: 'Top level Sub 2',
        parentId: 'top-level-sub-2',
        profileCount: 9,
        contributionCount: 111,
      }),
    },
    'grandchild-sub': {
      getSubscriptionById: SubscriptionBuilder.random({
        id: 'grandchild-sub',
        name: 'Grandchild 1 Subscription 1',
        parentId: 'child-sub-2',
        rootId: 'child-sub-1',
        profileCount: 2,
        contributionCount: 51,
      }),
    },
    'grandchild-sub-3': {
      getSubscriptionById: SubscriptionBuilder.random({
        id: 'grandchild-sub-3',
        name: 'Grandchild 1 Subscription 3',
        parentId: 'child-sub-3',
        rootId: 'child-sub-1',
        profileCount: 2,
        contributionCount: 52,
      }),
    },
  }

  return res(ctx.data(response[req.variables.listId]))
}

export const getProfilesInListLocationsHandler: GraphqlHandler<
  GetProfilesInListLocationsQueryVariables,
  GetProfilesInListLocationsQuery
> = (req, res, ctx) => {
  return res(
    ctx.data({
      getProfilesInList: {
        profiles: [
          SubProfileBuilder.random({
            fullName: TAMARA,
            profileId: TAMARA_ID,
            gdprExcluded: false,
            optStatus: OptStatus.in,
            sosvSummaries: [
              {
                productGroupId: randomString('productGroupId'),
                sosvStats: [
                  SoSVSummaryBuilder.random({ productName: 'Lantus', percentage: 15 }),
                  SoSVSummaryBuilder.random({ productName: 'Glooko', percentage: 15 }),
                  SoSVSummaryBuilder.random({ productName: 'Tresiba', percentage: 170 }),
                ],
              },
            ],
            contacts: [ContactBuilder.random({ location: { lat: 48.2083537, lon: 16.3725042 } })],
          }),
          SubProfileBuilder.random({
            fullName: WOJCIECH,
            profileId: WOJCIECH_ID,
            gdprExcluded: false,
            optStatus: OptStatus.in,
            contacts: [ContactBuilder.random({ location: { lat: 52.51604, lon: 13.37691 } })],
          }),
          SubProfileBuilder.random({
            fullName: ANNA,
            profileId: ANNA_ID,
            gdprExcluded: false,
            optStatus: OptStatus.in,
            sosvSummaries: [
              {
                productGroupId: randomString('productGroupId'),
                sosvStats: [
                  SoSVSummaryBuilder.random({ productName: 'Lantus', percentage: 15 }),
                  SoSVSummaryBuilder.random({ productName: 'Glooko', percentage: 15 }),
                  SoSVSummaryBuilder.random({ productName: 'Tresiba', percentage: 170 }),
                ],
              },
            ],
            contacts: [ContactBuilder.random({ location: { lat: 37.77712, lon: -122.41964 } })],
          }),
        ].map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
      },
    }),
  )
}

export const getProfilesInListHandler: GraphqlHandler<GetProfilesInListQueryVariables, GetProfilesInListQuery> = (
  req,
  res,
  ctx,
) => {
  if (
    req.variables.listId !== 'top-level-sub-1' &&
    req.variables.listId !== 'child-sub-1' &&
    req.variables.listId !== 'top-level-sub-2' &&
    req.variables.listId !== 'grandchild-sub'
  )
    throw new Error(`No mocked response for list with id ${req.variables.listId}`)
  const response = {
    'top-level-sub-1': {
      getProfilesInList: {
        totalProfiles: 12,
        profiles: fakePagination(
          [
            SubProfileBuilder.random({
              fullName: TAMARA,
              profileId: TAMARA_ID,
              socialProfile: SocialProfileBuilder.random({
                twitterUsername: 'test@twitter.com',
                linkedInUrl: 'some linkedin url',
              }),
            }),
            ...arrayOf((i) => SubProfileBuilder.random({ profileId: `profile-${i}`, fullName: `profile-${i}` }), 10),
            SubProfileBuilder.random({ fullName: ANNA, profileId: ANNA_ID }),
          ].map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
          req.variables,
        ),
      },
    },
    'child-sub-1': {
      getProfilesInList: {
        totalProfiles: 8,
        profiles: fakePagination(
          [
            SubProfileBuilder.random({
              fullName: TAMARA,
              profileId: TAMARA_ID,
              gdprExcluded: false,
              socialProfile: SocialProfileBuilder.random({
                twitterUsername: 'test@twitter.com',
                linkedInUrl: 'some linkedin url',
              }),
              sosvSummaries: [
                {
                  productGroupId: randomString('productGroupId'),
                  sosvStats: [
                    SoSVSummaryBuilder.random({ productName: 'Lantus', percentage: 15 }),
                    SoSVSummaryBuilder.random({ productName: 'Glooko', percentage: 15 }),
                    SoSVSummaryBuilder.random({ productName: 'Tresiba', percentage: 170 }),
                  ],
                },
              ],
              digitalAffinity: DigitalAffinityInSubscriptionBuilder.random({
                digitalRank: 2,
                digitalOnTopicPercentage: 90.54,
                totalOwnedContent: 200,
                totalEarnedContent: 300,
              }),
            }),
            SubProfileBuilder.random({
              fullName: WOJCIECH,
              profileId: WOJCIECH_ID,
              gdprExcluded: false,
              optStatus: OptStatus.out,
            }),
            ...arrayOf((i) => SubProfileBuilder.random({ profileId: `profile-${i}`, fullName: `profile-${i}` }), 5),
            SubProfileBuilder.random({
              fullName: ANNA,
              profileId: ANNA_ID,
              gdprExcluded: false,
              sosvSummaries: [
                {
                  productGroupId: randomString('productGroupId'),
                  sosvStats: [
                    SoSVSummaryBuilder.random({ productName: 'Lantus', percentage: 15 }),
                    SoSVSummaryBuilder.random({ productName: 'Glooko', percentage: 15 }),
                    SoSVSummaryBuilder.random({ productName: 'Tresiba', percentage: 170 }),
                  ],
                },
              ],
              digitalAffinity: DigitalAffinityInSubscriptionBuilder.random({
                digitalRank: 10,
                digitalOnTopicPercentage: 52.73,
                totalOwnedContent: 123,
                totalEarnedContent: 9999,
              }),
            }),
          ].map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
          req.variables,
        ),
      },
    },
    'top-level-sub-2': {
      getProfilesInList: {
        totalProfiles: 12,
        profiles: fakePagination(
          [
            SubProfileBuilder.random({
              fullName: TAMARA,
              profileId: TAMARA_ID,
              socialProfile: SocialProfileBuilder.random({
                twitterUsername: 'test@twitter.com',
                linkedInUrl: 'some linkedin url',
              }),
            }),
            ...arrayOf((i) => SubProfileBuilder.random({ profileId: `profile-${i}`, fullName: `profile-${i}` }), 10),
            SubProfileBuilder.random({ fullName: ANNA, profileId: ANNA_ID }),
          ].map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
          req.variables,
        ),
      },
    },
    'grandchild-sub': {
      getProfilesInList: {
        totalProfiles: 2,
        profiles: fakePagination(
          [
            SubProfileBuilder.random({
              fullName: TAMARA,
              profileId: TAMARA_ID,
              socialProfile: SocialProfileBuilder.random({
                twitterUsername: 'test@twitter.com',
                linkedInUrl: 'some linkedin url',
              }),
            }),
            SubProfileBuilder.random({ fullName: ANNA, profileId: ANNA_ID }),
          ].map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
          req.variables,
        ),
      },
    },
  }
  if (req.variables.savedListId && req.variables.savedListId !== 'wanted-saved-list-id-1')
    return res(ctx.data({ getProfilesInList: { totalProfiles: 0, profiles: [] } }))
  else if (req.variables.smartListId) {
    if (req.variables.listId === 'child-sub-1') {
      if (req.variables.smartListId === `${SmartListType.Geographic};germany`) {
        return res(
          ctx.data({
            getProfilesInList: {
              totalProfiles: 2,
              profiles: fakePagination(
                [
                  SubProfileBuilder.random({ fullName: ANNA, profileId: ANNA_ID, gdprExcluded: false }),
                  SubProfileBuilder.random({
                    fullName: JOHN,
                    profileId: JOHN_ID,
                    gdprExcluded: false,
                  }),
                ].map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
                req.variables,
              ),
            },
          }),
        )
      }
      if (req.variables.smartListId.includes(';usa;')) {
        return res(
          ctx.data({
            getProfilesInList: {
              totalProfiles: 4,
              profiles: fakePagination(
                arrayOf(
                  (i) => SubProfileBuilder.random({ profileId: `profile-${i}`, fullName: `profile-${i}` }),
                  4,
                ).map((e) => ({ ...e, __typename: 'SubscriptionProfileType' })),
                req.variables,
              ),
            },
          }),
        )
      }
      return res(ctx.data({ getProfilesInList: { totalProfiles: 0, profiles: [] } }))
    } else {
      return res(ctx.data({ getProfilesInList: { totalProfiles: 0, profiles: [] } }))
    }
  } else return res(ctx.data(response[req.variables.listId]))
}

export const dummySubscriptions: GetSubscriptionsForUserQuery_getSubscriptionsForUser[] = [
  {
    id: 'top-level-sub-1',
    name: 'Top Level Subscription 1',
    clientName: 'Client1',
    profileCount: 0,
    parentId: null,
    permissions: [
      SubscriptionPermission.Experts,
      SubscriptionPermission.SoSVExperts,
      SubscriptionPermission.SoSV,
      SubscriptionPermission.Digital,
      SubscriptionPermission.AcceleratedInsights,
    ],
    children: [
      {
        id: 'child-sub-1',
        name: 'Child Subscription 1',
        clientName: 'Client1',
        profileCount: 9,
        children: [],
        parentId: 'top-level-sub-1',
        permissions: [
          SubscriptionPermission.Experts,
          SubscriptionPermission.Digital,
          SubscriptionPermission.AcceleratedInsights,
        ],
      },
      {
        id: 'child-sub-2',
        name: 'Child Subscription 2',
        clientName: 'Client1',
        profileCount: 0,
        parentId: 'top-level-sub-1',
        permissions: [
          SubscriptionPermission.Experts,
          SubscriptionPermission.Science,
          SubscriptionPermission.SoSVExperts,
          SubscriptionPermission.SoSVScience,
          SubscriptionPermission.SoSV,
          SubscriptionPermission.Digital,
        ],
        children: [
          {
            id: 'grandchild-sub',
            name: 'Grandchild 1 Subscription 1',
            clientName: 'Client1',
            profileCount: 2,
            parentId: 'child-sub-2',
            permissions: [
              SubscriptionPermission.Experts,
              SubscriptionPermission.Science,
              SubscriptionPermission.SoSVExperts,
              SubscriptionPermission.SoSVScience,
              SubscriptionPermission.SoSV,
              SubscriptionPermission.Digital,
            ],
          },
          {
            id: 'grandchild-sub-2',
            name: 'Grandchild 2 Subscription 1',
            clientName: 'Client1',
            profileCount: 60,
            parentId: 'child-sub-2',
            permissions: [SubscriptionPermission.Experts, SubscriptionPermission.Digital],
          },
          {
            id: 'grandchild-sub-3',
            name: 'Grandchild 3 Subscription 1',
            clientName: 'Client1',
            profileCount: 60,
            parentId: 'child-sub-2',
            permissions: [
              SubscriptionPermission.Experts,
              SubscriptionPermission.SoSV,
              SubscriptionPermission.Digital,
              SubscriptionPermission.SoSV,
              SubscriptionPermission.SoSVExperts,
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'top-level-sub-2',
    name: 'Top Level Subscription 2',
    clientName: 'Client1',
    profileCount: 199,
    children: [],
    parentId: null,
    permissions: [SubscriptionPermission.Experts, SubscriptionPermission.Digital],
  },
  {
    id: 'top-level-sub-3',
    name: 'Top Level Subscription 3',
    clientName: 'Client2',
    profileCount: 20,
    children: [],
    parentId: null,
    permissions: [
      SubscriptionPermission.Experts,
      SubscriptionPermission.Science,
      SubscriptionPermission.SoSVExperts,
      SubscriptionPermission.SoSVScience,
      SubscriptionPermission.SoSV,
      SubscriptionPermission.Digital,
    ],
  },
]

export const getSubscriptionsForUserHandler: GraphqlHandler<never, GetSubscriptionsForUserQuery> = (req, res, ctx) => {
  const response: GetSubscriptionsForUserQuery = { getSubscriptionsForUser: dummySubscriptions }
  return res(ctx.data(response))
}
export const getFeaturesForUserHandler: GraphqlHandler<never, GetFeaturesForUserQuery> = (req, res, ctx) => {
  const response: GetFeaturesForUserQuery = {
    getFeaturesForUser: {
      experts: [
        Features.Experts,
        Features.Disclosures,
        Features.NetworkInformation,
        Features.ShowClientNames,
        Features.SocialInformation,
        Features.PDFSummaryExport,
        Features.DisplayGrants,
        Features.InternalTestingGroup,
        Features.AcceleratedInsights,
      ],
      sosv: ['sosv'],
      general: [],
      science: [],
    },
  }
  return res(ctx.data(response))
}

export const getSubscriptionInfoForProfileHandler: GraphqlHandler<
  GetSubscriptionInfoForProfileQueryVariables,
  GetSubscriptionInfoForProfileQuery
> = (req, res, ctx) => {
  const response: GetSubscriptionInfoForProfileQuery = {
    getSubscriptionById: SubscriptionBuilder.random({
      rootId: 'test-root-id',
      id: 'child-sub-1',
      name: 'Child subscription 1',
    }),
    getSubscriptionStatsForProfile: StatsInSubscriptionBuilder.random({
      onTopicOverallPercentage: 99,
      profileRank: 4321,
    }),
  }

  return res(ctx.data(response))
}
