import addYears from 'date-fns/addYears'
import addWeeks from 'date-fns/addWeeks'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import { getProductColors, SoSVTimeframe, WeighteningType } from '~common'
import { millisecondsInOneDay } from '~components/ui/organisms/charts/helpers'

export interface LaunchAnalogueMentionsCount {
  productName: string
  counts: number[]
  countsWeighted: number[]
  launchDate?: string | null
}

const missingValue = -1

const addWeeksToDate = (date: number, count = 1): number => {
  return addWeeks(date, count).getTime()
}

const centerLaunchDate = (stats: LaunchAnalogueMentionsCount, tZeroDate: number): LaunchAnalogueMentionsCount => {
  if (!stats.launchDate || stats.counts.length === 0) return stats

  const tenYearsAgo = addYears(tZeroDate, -10).getTime()

  const launchDate = new Date(stats.launchDate as string).getTime()
  let start = addYears(launchDate, -10).getTime()
  let stop = addYears(launchDate, 10).getTime()

  if (start > tZeroDate) {
    start = tenYearsAgo
    stop = addWeeksToDate(launchDate, differenceInWeeks(launchDate, start))
  }
  if (stop < tZeroDate) {
    stop = tZeroDate
    start = addWeeksToDate(launchDate, -1 * differenceInWeeks(stop, launchDate))
  }

  const counts: number[] = []
  const countsWeighted: number[] = []

  for (let date = start; date < tenYearsAgo; date = addWeeksToDate(date, 1)) {
    counts.push(missingValue)
    countsWeighted.push(missingValue)
  }

  counts.push(...stats.counts)
  countsWeighted.push(...stats.countsWeighted)

  for (let date = tZeroDate; date < stop; date = addWeeksToDate(date, 1)) {
    counts.push(missingValue)
    countsWeighted.push(missingValue)
  }

  return {
    productName: stats.productName,
    launchDate: stats.launchDate,
    counts,
    countsWeighted,
  }
}

const buildEmptyValues = (count: number): number[] => {
  const values: number[] = []
  for (let i = 0; i < count; i++) {
    values.push(missingValue)
  }
  return values
}

const alignProductSets = (productSets: LaunchAnalogueMentionsCount[]): LaunchAnalogueMentionsCount[] => {
  const setLengths = productSets.map((set) => set.counts.length)
  const maxLength = Math.max(...setLengths)

  for (const set of productSets) {
    if (set.counts.length === 0) continue

    const difference = maxLength - set.counts.length
    if (difference > 0) {
      const padding = buildEmptyValues(Math.round(difference / 2))

      set.counts.unshift(...padding)
      set.counts.push(...padding)

      set.countsWeighted.unshift(...padding)
      set.countsWeighted.push(...padding)
    }

    if (set.counts.length > maxLength) {
      set.counts.splice(maxLength)
      set.countsWeighted.splice(maxLength)
    }
  }

  return productSets
}

const getStatsValueBoundaries = (
  stats: LaunchAnalogueMentionsCount,
): { firstValueIndex: number; lastValueIndex: number } => {
  const firstValueIndex = stats.counts.findIndex((value) => value !== missingValue)
  let lastValueIndex = stats.counts
    .slice()
    .reverse()
    .findIndex((value) => value !== missingValue)
  if (lastValueIndex !== -1) lastValueIndex = stats.counts.length - lastValueIndex

  return { firstValueIndex, lastValueIndex }
}

const getDataPointValue = (
  index: number,
  value: number,
  firstValueIndex: number,
  lastValueIndex: number,
): number | null => {
  if (value !== missingValue) return value

  if (firstValueIndex === -1 || lastValueIndex === -1) return value === missingValue ? null : value

  return index < firstValueIndex || index > lastValueIndex ? null : 0
}

export const buildLaunchAnalogueDataSets = (
  sosvStats: LaunchAnalogueMentionsCount[],
  tZeroDate: number,
): LaunchAnalogueMentionsCount[] => {
  const centeredSets = sosvStats.map((stats) => centerLaunchDate(stats, tZeroDate))

  return alignProductSets(centeredSets)
}

export const buildLaunchAnalogueSeries = (
  productSets: LaunchAnalogueMentionsCount[],
  minDate: number,
  weighteningType: WeighteningType,
  themeBrand: 'pharmaspectra' | 'iqvia',
): Highcharts.SeriesOptionsType[] => {
  return (
    productSets.map((stats, i, arr) => {
      const { firstValueIndex, lastValueIndex } = getStatsValueBoundaries(stats)

      const dataPoints = stats[weighteningType === 'weighted' ? 'countsWeighted' : 'counts'].map((count, index) => [
        addWeeks(minDate, index).getTime(),
        getDataPointValue(index, count, firstValueIndex, lastValueIndex),
      ])

      return {
        name: stats.productName,
        data: dataPoints,
        className: `product-${i}`,
        type: 'area',
        showInLegend: !!dataPoints.length,
        tooltip: {
          valueDecimals: 1,
        },
        lineColor: getProductColors(arr.length, themeBrand).reverse()[i],
      }
    }) || []
  )
}

export const getTickInterval = (range: SoSVTimeframe): number => {
  switch (range) {
    case SoSVTimeframe.ThreeYears:
      return 3 * 30 * millisecondsInOneDay

    case SoSVTimeframe.OneYear:
      return 30 * millisecondsInOneDay

    case SoSVTimeframe.TwelveWeeks:
      return 7 * millisecondsInOneDay

    case SoSVTimeframe.TenYears:
    default:
      return 12 * 30 * millisecondsInOneDay
  }
}
