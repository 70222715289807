import { TooltipFormatterContextObject } from 'highcharts'
import { useState } from 'react'
import { TTrendDirection } from '~common'
import { getThemes } from '~common/theme'
import { getCommonChartOptions } from '../../common-charts-options'

export const useRankChartOptions = ({
  data,
  regressionLineData,
  rankRegressionLineDirection,
  categories,
  themeBrand,
  toolTipFormatter,
}: {
  data: { y: number }[]
  categories: number[]
  regressionLineData: number[][]
  rankRegressionLineDirection: TTrendDirection
  themeBrand: 'pharmaspectra' | 'iqvia'
  toolTipFormatter: (context: TooltipFormatterContextObject) => string
}): [Highcharts.Options] => {
  const [threshold, setThreshold] = useState(0)
  const lightModeColors = getThemes('light', themeBrand).colors
  const commonChartOptions = getCommonChartOptions(categories)
  const options: Highcharts.Options = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      type: 'area',
      height: 93,
    },
    tooltip: {
      ...commonChartOptions.tooltip,
      distance: 19,
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return toolTipFormatter(this)
      },
    },
    legend: {
      ...commonChartOptions.legend,
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      x: 100,
      y: 70,
      floating: true,
      borderWidth: 1,
    },
    yAxis: {
      ...commonChartOptions.yAxis,
      gridLineWidth: 0,
      reversed: true,
      labels: {
        x: -4,
        style: {
          width: 25,
        },
        formatter: function () {
          if (this.isFirst) {
            return '1'
          }
          if (this.isLast) {
            setThreshold(Number(this.value))
            return `${this.value}`
          }
          return ''
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: {
              size: 6,
            },
          },
        },
      },
      area: {
        stacking: 'overlap',
        lineColor: lightModeColors.dataVisualisation.lineGraphAccent,
        className: 'custom-rank-area',
        opacity: 1,
        threshold,
        fillOpacity: 0.48,
        marker: {
          enabled: false,
          lineWidth: 0.5,
          lineColor: lightModeColors.brand.bluePrimary,
        },
      },
    },
    series: [
      {
        data,
        pointStart: categories[0],
        type: 'area',
        color: lightModeColors.dataVisualisation.lineGraphFill,
      },
      {
        type: 'line',
        name: 'Regression Line',
        data: regressionLineData,
        marker: {
          enabled: false,
        },
        borderWidth: 50,
        enableMouseTracking: false,
        className: `regression-line-${rankRegressionLineDirection}`,
      },
    ],
  }

  return [options]
}
