import { SmartList } from '~components/pages/experts-hub/types'
import { camelCaseToSentenceCase, SmartListType, roundDecimals } from '~common'
import { GetSubscriptionsForUserQuery_getSubscriptionsForUser } from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { GetProfileQuery_getProfileByIdFromDDB_ProfileType_influenceScores } from './expert/__generated__/GetProfileQuery'

interface StatsKeyMappings {
  [key: string]: string
}

export const networkStatsKeyMappings: StatsKeyMappings = {
  overall: 'Score',
  localityNational: 'Nat l',
  localityInternational: 'Int l',
  estimatedTotal: 'Est. network',
  subscriptionTotal: 'Subscription total',
}

export const getStatistics = (
  statistics: { [key: string]: number },
  allowZeroValues = false,
  keyMappings: StatsKeyMappings = {},
): {
  name: string
  value: string
}[] =>
  Object.keys(statistics).reduce((acc: { name: string; value: string }[], cur) => {
    const value = statistics[cur]
    const isValid = allowZeroValues ? value !== undefined && value !== null : value
    if (isValid) {
      const name = (keyMappings && keyMappings[cur]) ?? camelCaseToSentenceCase(cur)
      acc = [...acc, { name, value: statistics[cur].toString() }]
    }
    return acc
  }, [])

export const getFormattedInfluenceScores = (
  influenceScores: GetProfileQuery_getProfileByIdFromDDB_ProfileType_influenceScores,
): {
  overall: number
  localityNational: number
  localityInternational: number
} => {
  const { overall, localityNational, localityInternational } = influenceScores

  return {
    overall: overall ? roundDecimals(overall, 2) : 0,
    localityNational: localityNational ? roundDecimals(localityNational, 2) : 0,
    localityInternational: localityInternational ? roundDecimals(localityInternational, 2) : 0,
  }
}

export const getListIdsForAlsoInSubscriptions = (
  nestedLists: GetSubscriptionsForUserQuery_getSubscriptionsForUser[],
): string[] => {
  const results: Pick<GetSubscriptionsForUserQuery_getSubscriptionsForUser, 'id' | 'name'>[] = []
  for (const list of nestedLists) {
    if (list.children.length === 0) {
      results.push({
        id: list.id,
        name: list.name,
      })
    }
    for (const child of list.children) {
      if (child.children.length === 0) {
        results.push({
          id: child.id,
          name: child.name,
        })
      }
      for (const childChild of child.children) {
        results.push({
          id: childChild.id,
          name: childChild.name,
        })
      }
    }
  }
  return results.filter((a) => a.name !== 'All').map((l) => l.id)
}

export const getRootListNameOfSmartList = (lists: SmartList[], id: string): string | undefined => {
  return (
    lists
      .reduce<SmartList[]>((acc, curr) => {
        return [...acc, curr, ...('children' in curr && curr.children.length ? curr.children : [])]
      }, [])
      .find((gsm) => gsm.id === id)?.listName ?? undefined
  )
}

export const getFullSmartListName = (lists: SmartList[], id: string): string | undefined => {
  const [type, parentKey, childKey] = id.split(';')
  const smartList = lists.find((l) => l.id === `${type};${parentKey}`)
  if (smartList) {
    let name = smartList.name
    if (childKey) {
      const childSmartList =
        'children' in smartList && smartList.children.length ? smartList.children.find((l) => l.id === id) : undefined
      if (childSmartList) {
        name += ` - ${childSmartList.name}`
      }
    }
    return name
  }
  return undefined
}

export const getSmartListType = (smartListId?: string): SmartListType | undefined => {
  if (smartListId) {
    const decoded = decodeURIComponent(smartListId)
    if (decoded.startsWith(SmartListType.Geographic)) {
      return SmartListType.Geographic
    } else if (decoded.startsWith(SmartListType.Speciality)) {
      return SmartListType.Speciality
    }
  }
  return undefined
}
