import React from 'react'
import { StyledTableSection } from '~common'
import { useExpertEcosystemContext } from '~common/providers'
import { PageTitleBarLoadingBlock } from './components/PageTitleBarLoadingBlock'
import { StyledLoadingListContentBlock, StyledLoadingTitleBlock } from './styles'

export const StandardPageLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <>
      <PageTitleBarLoadingBlock />
      <StyledTableSection>
        <StyledLoadingTitleBlock marginBottomSize="sm" />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item) => (
          <StyledLoadingListContentBlock key={item} />
        ))}
      </StyledTableSection>
    </>
  )
}
