import React from 'react'
import { LinkThemeProvider } from '~common/theme'
import { convertGeographyToFullName } from '~components/pages/sosv-hub/helpers'
import { Flex, Mode } from '~common'
import { buildViewSoSVSocietyEventsLocationDetailsUrl } from '~components/pages/sosv-hub/url-helpers'
import { ParsedUrlQuery } from 'querystring'
import styled from 'styled-components'
import { CustomLink } from '~components/ui/atoms/custom-link'

export const StyledTeaser = styled.div`
  background-color: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  width: 16.25rem;
`

export const StyledTeaserContent = styled(Flex)`
  padding: 1.375rem 0.3125rem 0.875rem;
  justify-content: space-between;
`

const StyledTabularLocation = styled.dt`
  ${({ theme }) => ({ ...theme.textSizes.desktop.bodyS, color: theme.colors.buttons.link })};
`

const StyledTabularCount = styled.dd`
  ${({ theme }) => ({ ...theme.textSizes.desktop.bodyS, color: theme.colors.text.textSecondary })};
`

interface SocietyEventsLocationTeaserProps {
  query?: ParsedUrlQuery
  city: string
  state?: string | null
  country: string
  meetingCount: number
  styleMode: Mode
  isIqviaBrand: boolean
  href?: string
  rootPath?: string
}

export const SocietyEventsLocationTeaser: React.FC<SocietyEventsLocationTeaserProps> = ({
  query,
  city,
  state,
  country,
  meetingCount,
  styleMode,
  isIqviaBrand,
  href,
  rootPath,
}) => {
  return (
    <LinkThemeProvider mode={styleMode} brand={isIqviaBrand ? 'iqvia' : 'pharmaspectra'}>
      <StyledTeaser>
        <StyledTeaserContent>
          <StyledTabularLocation>
            {' '}
            <CustomLink
              size="sm"
              href={
                href ??
                buildViewSoSVSocietyEventsLocationDetailsUrl(
                  { city, state, country },
                  query as ParsedUrlQuery,
                  rootPath,
                )
              }
              id="society-events-location"
            >
              {convertGeographyToFullName({ city, state, country })}
            </CustomLink>
          </StyledTabularLocation>
          <StyledTabularCount>{meetingCount}</StyledTabularCount>
        </StyledTeaserContent>
      </StyledTeaser>
    </LinkThemeProvider>
  )
}
