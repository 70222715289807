import React from 'react'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { StyledDropdownWithLinksItem, StyledDropdown } from '../styles'

interface DropdownLinkItem {
  name: string
  linkTo: string
}

export interface DropdownWithLinksProps extends React.HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  dropdownLinks: DropdownLinkItem[]
  ariaLabel: string
  dropdownTitle?: string
}

export const DropdownWithLinks = ({
  dropdownTitle,
  dropdownLinks,
  ariaLabel,
  isOpen,
  ...rest
}: DropdownWithLinksProps): JSX.Element => (
  <StyledDropdown
    tabIndex={-1}
    aria-label={ariaLabel}
    isFixedMaxWidth={true}
    aria-hidden={!isOpen}
    isOpen={isOpen}
    {...rest}
  >
    {dropdownTitle && <StyledDropdownWithLinksItem hasNoAction={true}>{dropdownTitle}</StyledDropdownWithLinksItem>}
    {dropdownLinks.map((link: DropdownLinkItem) => (
      <StyledDropdownWithLinksItem key={link.linkTo}>
        <CustomLink size="sm" href={link.linkTo} tabIndex={isOpen ? 0 : -1}>
          {link.name}
        </CustomLink>
      </StyledDropdownWithLinksItem>
    ))}
  </StyledDropdown>
)
