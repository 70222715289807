import React from 'react'
import { useExpertEcosystemContext } from '~common/providers'
import { StyledLoadingListSection, StyledLoadingListTitleBlock, StyledLoadingListContentBlock } from '../styles'

export const ListLoadingBlocks = ({ id }: { id?: string }): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <StyledLoadingListSection data-testid={id}>
      <StyledLoadingListTitleBlock />
      <StyledLoadingListContentBlock size="md" />
      <StyledLoadingListContentBlock size="md" />
      <StyledLoadingListContentBlock size="md" />
    </StyledLoadingListSection>
  )
}
