import styled from 'styled-components'

export const StyledDashboardContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  & > div {
    padding: 1em 1em;

    & > div:first-child {
      padding: 0.5em 0.5em;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.colors.brand.backgroundTertiary};
    }
  }
`

export const StyledConfigurationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1em;

  & > div:first-child {
    width: 20%;
  }

  & > div:nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;

    & > div {
      padding: 0.5em 0.5em;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.colors.brand.backgroundTertiary};
    }
  }
`

export const StyledAttributesContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  & > div {
    font-size: 0.75em;
    margin-top: 1em;
    padding-left: 0.5em;
    word-wrap: break-word;
    &:nth-child(odd) {
      width: 15%;
      border: 1px solid ${({ theme }) => theme.colors.brand.purplePrimary};
    }
    &:nth-child(even) {
      width: 85%;
      border-right: 1px solid ${({ theme }) => theme.colors.brand.purplePrimary};
      border-bottom: 1px solid ${({ theme }) => theme.colors.brand.purplePrimary};
      border-top: 1px solid ${({ theme }) => theme.colors.brand.purplePrimary};
    }
  }
`

export const StyledLoadForm = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
  padding-top: 0.5em;
`
