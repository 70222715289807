interface Item {
  item_id: string
  item_name: string
}

interface TrackItemSelectedOpts {
  listName: string
  items: Item[]
}

export const trackItemSelected = (opts: TrackItemSelectedOpts): void => {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'select_item', {
      items: opts.items,
      item_list_name: opts.listName,
    })
  }
}

export const trackItemViewed = (items: Item[]): void => {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'view_item', { items })
  }
}
