import { EventMap } from '~components/pages/widgets/use-frame-message-bus'

export enum SoSVSource {
  EXPERTS = 'experts',
  SCIENCE = 'science',
}

export interface FrameEvents extends EventMap {
  authComplete: (payload: {
    isSuccess: boolean
    error?: Error | null
    lists: Array<{ id: string; name: string; sources: Array<SoSVSource> }>
  }) => void
  changeList: (payload: { listId: string; source?: SoSVSource }) => void
  dataLoading: (payload: { isLoading: boolean }) => void
}
