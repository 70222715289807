import React from 'react'
import { ListLoadingBlocks } from './components/ListLoadingBlocks'
import { SoSVGrid } from '~components/ui/templates/sosv-grid'
import { ChartLoadingBlock } from './components/ChartLoadingBlock'
import { PageTitleBarLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { StyledLoadingListContentBlock, StyledLoadingTitleBlock } from './styles'
import { useExpertEcosystemContext } from '~common/providers'

export const SoSVHubPageLoadingBlocks = (): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <article data-testid="sosv-hub-abstract-loading-blocks">
      <SoSVGrid.Container>
        <SoSVGrid.Item itemWidth="widest" isTransparent={true}>
          <PageTitleBarLoadingBlock isTransparent={true} />
        </SoSVGrid.Item>
        <SoSVGrid.Item itemWidth="widest">
          <StyledLoadingTitleBlock />
          <StyledLoadingListContentBlock height="180px" />
        </SoSVGrid.Item>
        <SoSVGrid.Item itemWidth="widest" isTransparent={true}>
          <ChartLoadingBlock id="page-loading-blocks" />
        </SoSVGrid.Item>
        {[1, 2].map((item) => (
          <SoSVGrid.Item key={item} itemWidth="narrow">
            <ListLoadingBlocks id="page-loading-blocks" />
          </SoSVGrid.Item>
        ))}
        <SoSVGrid.Item itemWidth="widest" isTransparent={true}>
          <ChartLoadingBlock id="page-loading-blocks" />
        </SoSVGrid.Item>
      </SoSVGrid.Container>
    </article>
  )
}
