import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledInfoTooltip, StyledTooltipTip, StyledInfoSvg } from './styles'

export type TooltipBehavior = 'hover' | 'click'
export type Placement = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight'
export type TooltipBreakpointWidth = {
  bp: number
  width: number
}
export type TooltipContentWidth = {
  breakpoints: TooltipBreakpointWidth[]
  width: number
}

export interface InfoTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string | JSX.Element
  delay?: number
  tooltipWidth?: number | TooltipContentWidth
  containerRef?: React.RefObject<HTMLElement>
  behavior?: TooltipBehavior
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  containerRef,
  content,
  delay,
  tooltipWidth,
  behavior,
  ...rest
}) => {
  let timeout: NodeJS.Timeout

  const [tooltipHeight, setTooltipHeight] = useState(0)
  const [placement, setPlacement] = useState<Placement>('topCenter')
  const [active, setActive] = useState(false)
  const [infoIconRect, setInfoIconRect] = useState<undefined | DOMRect>()
  const [tooltipTipRect, setTooltipTipRect] = useState<undefined | DOMRect>()

  const infoIconRef = useRef<SVGSVGElement>(null)
  const tooltipTipRef = useRef<HTMLDivElement>(null)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay || 200)
  }

  const hideTip = useCallback(() => {
    clearInterval(timeout)
    setActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setTooltipHeight(tooltipTipRef?.current?.clientHeight || 0), [active])

  const handleTooltipHeightCalculation = () => {
    setTooltipTipRect(tooltipTipRef.current?.getBoundingClientRect())
    setInfoIconRect(infoIconRef.current?.getBoundingClientRect())
  }

  useEffect(() => {
    if (active && infoIconRect && tooltipTipRect) {
      const containerRect = containerRef?.current?.getBoundingClientRect()
      const placement = calcPlacement(infoIconRect, tooltipTipRect, containerRect)
      setPlacement(placement)
    }
  }, [infoIconRect, tooltipTipRect, active, containerRef])

  useEffect(() => {
    handleTooltipHeightCalculation()
  }, [active])

  useEffect(() => {
    window.addEventListener('resize', handleTooltipHeightCalculation, { passive: true })
    return () => window.removeEventListener('resize', handleTooltipHeightCalculation)
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipTipRef.current && !tooltipTipRef.current.contains(event.target)) {
        hideTip()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hideTip])

  const handleBehaviorEvents = (behavior: TooltipBehavior | undefined) => {
    if (behavior === 'click') {
      return { onClick: showTip, onScroll: hideTip }
    }

    return { onMouseEnter: showTip, onMouseLeave: hideTip }
  }

  return (
    <StyledInfoTooltip {...handleBehaviorEvents(behavior)} {...rest}>
      <StyledInfoSvg elementRef={infoIconRef} ariaLabel="Info" />
      {active && (
        <StyledTooltipTip
          ref={tooltipTipRef}
          placement={placement}
          tooltipHeight={tooltipHeight}
          tooltipWidth={typeof tooltipWidth === 'number' ? tooltipWidth : tooltipWidth?.width ?? 200}
          bpWidths={typeof tooltipWidth === 'number' ? [] : tooltipWidth?.breakpoints}
          animation={behavior !== 'click'}
        >
          {content}
        </StyledTooltipTip>
      )}
    </StyledInfoTooltip>
  )
}

const calcPlacement = (infoIconRect: DOMRect, tooltipTipRect: DOMRect, containerRect?: DOMRect): Placement => {
  const documentWidth = containerRect ? containerRect.width : document.documentElement.clientWidth
  const containerLeft = containerRect ? containerRect.left : 0
  const containerTop = containerRect ? containerRect.top : 0

  const enouphSpaceForRight = infoIconRect.right + tooltipTipRect.width < documentWidth + containerLeft
  const enouphSpaceForLeft = tooltipTipRect.width < infoIconRect.left - containerLeft
  const enouphSpaceForTop = tooltipTipRect.height < infoIconRect.top - containerTop
  const isEnouphSpaceForCenter = enouphSpaceForLeft && enouphSpaceForRight

  if (isEnouphSpaceForCenter && enouphSpaceForTop) return 'topCenter'
  if (enouphSpaceForLeft && enouphSpaceForTop) return 'topLeft'
  if (enouphSpaceForRight && enouphSpaceForTop) return 'topRight'
  if (isEnouphSpaceForCenter && !enouphSpaceForTop) return 'bottomCenter'
  if (enouphSpaceForLeft && !enouphSpaceForTop) return 'bottomLeft'
  if (enouphSpaceForRight && !enouphSpaceForTop) return 'bottomRight'
  if (enouphSpaceForTop) return 'topCenter'
  return 'bottomCenter'
}
