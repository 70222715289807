import * as Highcharts from 'highcharts'
import { TooltipFormatterContextObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, {
  JSXElementConstructor,
  memo,
  ReactElement,
  RefObject,
  useCallback,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { getProductColors, GraphType, Mode, roundDecimals, StyledScreenReadersOnly, WeighteningType } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { StyledCollectionsProductMentionsStackedBarChart, StyledSortAndExportWrapper, StyledSortBy } from './styles'
import { useCollectionsProductsMentionsStackedBarChart } from './use-collections-products-mentions-stacked-bar-chart'
import { ExportChartConfig } from '../../types'
import { ExportChartTrigger } from '../../components/export-chart-trigger'
import { createPortal } from 'react-dom'

export interface Collection {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collectionUI: ReactElement<any, string | JSXElementConstructor<any>>
  collectionUIPlaceholder?: string
  title: string
}

export type BarHeightSize = 'sm' | 'md' | 'lg'

export interface MentionsCount {
  productName: string
  counts: number[]
  countsWeighted: number[]
  share: number[]
  shareWeighted: number[]
}

export interface CollectionsProductsMentionsStackedBarChartProps {
  mentions: MentionsCount[]
  categories: string[]
  weighteningType: WeighteningType
  onSort?: (element: string) => void
  sortBy?: string
  sortOptions?: { id: string; name: string; isDisabled?: boolean }[]
  showLegend: boolean
  collections: Collection[]
  exportChartConfig?: ExportChartConfig
  barHeightSize?: BarHeightSize
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
  placeholderInfixId?: string
  graphType?: GraphType
}

const containerProps = { style: { height: '100%' } }

export const CollectionsProductsMentionsStackedBarChart = ({
  mentions,
  categories,
  onSort,
  sortBy,
  sortOptions,
  showLegend,
  collections,
  exportChartConfig,
  barHeightSize = 'sm',
  weighteningType,
  themeMode,
  themeBrand,
  placeholderInfixId = 'custom-label',
  graphType = 'counts',
}: CollectionsProductsMentionsStackedBarChartProps): JSX.Element => {
  const [chart, setChart] = useState<Highcharts.Chart | null>(null)
  const chartRef = useRef<{ chart: Highcharts.Chart; container: RefObject<HTMLDivElement> }>(null)

  const chartCallback = useCallback((chart: Highcharts.Chart) => {
    setChart(chart)
  }, [])

  const productMentionsSeries: Highcharts.SeriesOptionsType[] =
    mentions.map((el, i, arr) => {
      let seriesData: { y: number; originalCount: number; originalShare: number }[] = []
      if (weighteningType === 'unweighted') {
        if (graphType === 'counts') {
          seriesData = el.counts.map((c, idx) => ({
            y: c,
            originalCount: c,
            originalShare: el.share[idx],
          }))
        } else {
          seriesData = el.share.map((s, idx) => ({
            y: s,
            originalCount: el.counts[idx],
            originalShare: s,
          }))
        }
      } else {
        if (graphType === 'counts') {
          seriesData = el.countsWeighted.map((c, idx) => ({
            y: c,
            originalCount: c,
            originalShare: el.shareWeighted[idx],
          }))
        } else {
          seriesData = el.shareWeighted.map((s, idx) => ({
            y: s,
            originalCount: el.countsWeighted[idx],
            originalShare: s,
          }))
        }
      }
      return {
        name: el.productName,
        data: seriesData,
        className: `product-${i}`,
        type: 'column',
        stacking: graphType === 'counts' ? 'normal' : 'percent',
        lineColor: getProductColors(arr.length, themeBrand).reverse()[i],
        showInLegend: !!(weighteningType === 'unweighted' ? el.counts : el.countsWeighted).length,
      }
    }) || []

  const toolTipFormatter = useCallback(
    (context: TooltipFormatterContextObject) => {
      const tooltipElement = (
        <LinkThemeProvider mode={themeMode} brand={themeBrand}>
          <StyledHighchartsTooltipDiv>
            <div className="highcharts-tooltip">
              <div className="key-value-wrapper">
                <span className="key">Product</span> <span className="value">{context.series.name}</span>
              </div>
              <div className="key-value-wrapper">
                <span className="key">Count</span>{' '}
                <span className="value">
                  {context.point['originalCount'] ? roundDecimals(context.point['originalCount'], 1) : ''}
                </span>
              </div>
              <div className="key-value-wrapper">
                <span className="key">Share</span>{' '}
                <span className="value">
                  {context.point['originalShare'] ? `${context.point['originalShare']}%` : ''}
                </span>
              </div>
            </div>
          </StyledHighchartsTooltipDiv>
        </LinkThemeProvider>
      )

      return renderToStaticMarkup(tooltipElement)
    },
    [themeBrand, themeMode],
  )

  const [options] = useCollectionsProductsMentionsStackedBarChart({
    data: productMentionsSeries,
    categories,
    showLegend,
    collections,
    exportChartConfig,
    barHeightSize,
    graphType,
    toolTipFormatter,
  })

  return (
    <StyledCollectionsProductMentionsStackedBarChart
      productColors={getProductColors(productMentionsSeries.length, themeBrand)}
      series={productMentionsSeries}
    >
      <StyledSortAndExportWrapper>
        {sortOptions && sortBy && onSort ? (
          <StyledSortBy options={sortOptions} selectedOptionId={sortBy} onSort={onSort} />
        ) : null}
        {!!exportChartConfig && <ExportChartTrigger chartRef={chartRef} />}
      </StyledSortAndExportWrapper>
      {collections.map((col) =>
        col.collectionUIPlaceholder ? (
          <CollectionItem
            key={col.id}
            chart={chart}
            item={col.collectionUI}
            placeholderId={generateCollectionItemId(col, placeholderInfixId)}
          />
        ) : null,
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate
        oneToOne
        ref={chartRef}
        containerProps={containerProps}
        callback={chartCallback}
      />
      <StyledScreenReadersOnly>Collections products mentions stacked bar chart</StyledScreenReadersOnly>
    </StyledCollectionsProductMentionsStackedBarChart>
  )
}

const generateCollectionItemId = (item: Collection, portalId: string) => `highcharts-${portalId}-${item.id}`

const CollectionItem = memo(
  ({ chart, item, placeholderId }: { chart: Highcharts.Chart | null; item: JSX.Element; placeholderId: string }) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const portalNodeRef = useRef<HTMLDivElement | null>(document.getElementById(placeholderId) as HTMLDivElement)

    useLayoutEffect(() => {
      const observer = new MutationObserver(() => {
        const placeholderContainer = document.getElementById(placeholderId)
        if (placeholderContainer && placeholderContainer !== portalNodeRef.current) {
          forceUpdate()
        }
      })
      observer.observe(document.body, {
        subtree: true,
        childList: true,
      })

      return () => {
        observer.disconnect()
      }
    }, [placeholderId])

    portalNodeRef.current = document.getElementById(placeholderId) as HTMLDivElement
    return chart && portalNodeRef.current ? createPortal(item, portalNodeRef.current, placeholderId) : null
  },
)
