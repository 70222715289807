import React, { Dispatch } from 'react'
import { ExpertsHubAction, ExpertsHubState } from './types'

const ExpertsHubContext = React.createContext<(ExpertsHubState | Dispatch<ExpertsHubAction>)[] | null>(null)

export const ExpertsHubStateProvider: React.FC<{
  expertsHubState: ExpertsHubState
  expertsHubDispatch: Dispatch<ExpertsHubAction>
  children: React.ReactNode
}> = ({ expertsHubState, expertsHubDispatch, children }) => {
  const value = React.useMemo(() => [expertsHubState, expertsHubDispatch], [expertsHubState, expertsHubDispatch])
  return <ExpertsHubContext.Provider value={value}>{children}</ExpertsHubContext.Provider>
}

interface ExpertsHubStateReturn extends ExpertsHubState {
  searchDispatch: Dispatch<ExpertsHubAction>
}

export const useExpertsHubState = (): ExpertsHubStateReturn => {
  const expertsHubContext = React.useContext(ExpertsHubContext)
  if (!expertsHubContext) {
    throw new Error(`useExpertsHubState must be used within a ExpertsHubStateProvider`)
  }
  const [expertsHubState, expertsHubDispatch] = expertsHubContext
  return {
    ...(expertsHubState as ExpertsHubState),
    searchDispatch: expertsHubDispatch as Dispatch<ExpertsHubAction>,
  }
}
