import { ParsedUrlQuery } from 'querystring'
import { formatUrl, GeographyFilter, MapBounds } from '~common'

const cleanPartialUrl = (sosvPage: string): string => {
  if (sosvPage === '') return ''

  if (sosvPage.startsWith('/')) {
    sosvPage = sosvPage.slice(1)
  }
  if (!sosvPage.endsWith('/')) {
    return `${sosvPage}/`
  }
  return sosvPage
}

export const buildViewAllSoSVExpertsUrl = (query: ParsedUrlQuery, sosvPage = '', rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    expertsSortBy = 'all',
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}experts`, {
    rootSubscriptionId,
    listId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    cumulatingType,
    weighteningType,
    expertsSortBy,
    city,
    state,
    country,
    savedListId,
    smartListId,
  })
}

export const buildViewAllSoSVMapUrl = (
  query: ParsedUrlQuery,
  sosvPage = '',
  mapBounds?: MapBounds,
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    expertsSortBy = 'all',
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}map`, {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    drugSelection,
    topicSelection,
    cumulatingType,
    weighteningType,
    expertsSortBy,
    city,
    state,
    country,
    savedListId,
    smartListId,
    southWestLng: mapBounds ? mapBounds.southWest.lng : undefined,
    southWestLat: mapBounds ? mapBounds.southWest.lat : undefined,
    northEastLng: mapBounds ? mapBounds.northEast.lng : undefined,
    northEastLat: mapBounds ? mapBounds.northEast.lat : undefined,
  })
}

export const buildViewSoSVEventLocationMeetingDetailsUrl = (
  meetingId: string,
  query: ParsedUrlQuery,
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    meetingsSortBy = 'all',
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
  } = query

  return formatUrl(rootPath ?? `/sosv/society-events-by-location/details/meeting`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    meetingsSortBy,
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    meetingId,
  })
}

export const buildViewSoSVSocietyEventsLocationDetailsUrl = (
  geography: GeographyFilter,
  query: ParsedUrlQuery,
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    meetingsSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/society-events-by-location/details`, {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    drugSelection,
    topicSelection,
    cumulatingType,
    weighteningType,
    meetingsSortBy,
    city: geography.city,
    state: geography.state,
    country: geography.country,
    savedListId,
    smartListId,
  })
}

export const buildViewAllSoSVSocietyEventsLocationMapUrl = (
  query: ParsedUrlQuery,
  sosvPage = '',
  mapBounds?: MapBounds,
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    expertsSortBy = 'all',
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}society-events-by-location`, {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    meetingId,
    societyId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    drugSelection,
    topicSelection,
    cumulatingType,
    weighteningType,
    expertsSortBy,
    city,
    state,
    country,
    savedListId,
    smartListId,
    southWestLng: mapBounds ? mapBounds.southWest.lng : undefined,
    southWestLat: mapBounds ? mapBounds.southWest.lat : undefined,
    northEastLng: mapBounds ? mapBounds.northEast.lng : undefined,
    northEastLat: mapBounds ? mapBounds.northEast.lat : undefined,
  })
}

export const buildViewExpertSoSVUrl = (expertId: string, query: ParsedUrlQuery, rootPath?: string): string => {
  const {
    listId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    productGroupId,
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
    topicSelection,
    journalId,
    meetingId,
  } = query

  return formatUrl(rootPath ?? `/experts/${expertId}/sosv`, {
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    weighteningType,
    cumulatingType,
    productGroupId,
    drugSelection,
    savedListId,
    smartListId,
    topicSelection,
    journalId,
    meetingId,
  })
}

export const buildViewAllSoSVJournalsUrl = (query: ParsedUrlQuery, rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    journalsSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/journals`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    journalsSortBy,
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  })
}

export const buildViewSoSVJournalDetailsUrl = (journalId: string, query: ParsedUrlQuery, rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    journalsSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/journals/details`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    journalsSortBy,
    cumulatingType,
    weighteningType,
    journalId,
    savedListId,
    smartListId,
  })
}

export const buildViewAllSoSVJournalArticlesUrl = (query: ParsedUrlQuery, sosvPage = '', rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    city,
    state,
    country,
    journalsSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}journal-articles`, {
    rootSubscriptionId,
    listId,
    journalId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    city,
    state,
    country,
    journalsSortBy,
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  })
}

export const buildVSoSVJournalArticleUrl = (
  journalArticleId: string,
  query: ParsedUrlQuery,
  sosvPage = '',
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    journalId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    journalsSortBy = 'all',
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  } = query

  return formatUrl(
    rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}journal-articles`,
    {
      rootSubscriptionId,
      listId,
      journalId,
      dataSet,
      sosvContentTypes,
      sosvTimeframe,
      startDate,
      endDate,
      graphType,
      productGroupId,
      drugSelection,
      topicSelection,
      journalsSortBy,
      cumulatingType,
      weighteningType,
      city,
      state,
      country,
      savedListId,
      smartListId,
    },
    journalArticleId,
  )
}

export const buildViewAllSoSVPresentationsUrl = (query: ParsedUrlQuery, sosvPage = '', rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    meetingId,
    societyId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    journalsSortBy = 'all',
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}presentations`, {
    meetingId,
    societyId,
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    journalsSortBy,
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  })
}

export const buildViewSoSVPresentationUrl = (
  presentationId: string,
  query: ParsedUrlQuery,
  sosvPage = '',
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    meetingId,
    societyId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    cumulatingType,
    weighteningType,
    city,
    state,
    country,
    savedListId,
    smartListId,
  } = query

  return formatUrl(
    rootPath ?? `/sosv/${cleanPartialUrl(sosvPage)}presentations`,
    {
      rootSubscriptionId,
      listId,
      meetingId,
      societyId,
      dataSet,
      sosvContentTypes,
      sosvTimeframe,
      startDate,
      endDate,
      graphType,
      productGroupId,
      drugSelection,
      topicSelection,
      cumulatingType,
      weighteningType,
      city,
      state,
      country,
      savedListId,
      smartListId,
    },
    presentationId,
  )
}

export const buildViewAllSoSVSocietiesUrl = (query: ParsedUrlQuery, rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    societiesSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/societies`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    societiesSortBy,
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  })
}

export const buildViewSoSVSocietyDetailsUrl = (societyId: string, query: ParsedUrlQuery, rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    societiesSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/societies/details`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    societiesSortBy,
    cumulatingType,
    weighteningType,
    societyId,
    savedListId,
    smartListId,
  })
}

export const buildViewSoSVMeetingDetailsUrl = (
  societyId: string,
  meetingId: string,
  query: ParsedUrlQuery,
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    meetingsSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/societies/details/meeting`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    meetingsSortBy,
    cumulatingType,
    weighteningType,
    meetingId,
    societyId,
    savedListId,
    smartListId,
  })
}

export const buildViewAllSoSVGeographiesUrl = (query: ParsedUrlQuery, rootPath?: string): string => {
  const {
    rootSubscriptionId,
    listId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    geographiesSortBy = 'all',
    cumulatingType,
    weighteningType,
    productGroupId,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/locations`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    geographiesSortBy,
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  })
}

export const buildViewSoSVGeographyDetailsUrl = (
  geography: GeographyFilter,
  query: ParsedUrlQuery,
  rootPath?: string,
): string => {
  const {
    rootSubscriptionId,
    listId,
    productGroupId,
    dataSet,
    graphType,
    sosvTimeframe,
    startDate,
    endDate,
    sosvContentTypes = [],
    drugSelection = [],
    topicSelection = [],
    meetingsSortBy = 'all',
    cumulatingType,
    weighteningType,
    savedListId,
    smartListId,
  } = query

  return formatUrl(rootPath ?? `/sosv/locations/details`, {
    rootSubscriptionId,
    listId,
    dataSet,
    sosvContentTypes,
    sosvTimeframe,
    startDate,
    endDate,
    graphType,
    productGroupId,
    drugSelection,
    topicSelection,
    meetingsSortBy,
    cumulatingType,
    weighteningType,
    city: geography.city,
    state: geography.state,
    country: geography.country,
    savedListId,
    smartListId,
  })
}
