import { getConfig } from '~common/config'
import {
  GetSubscriptionsForUserQuery_getSubscriptionsForUser,
  GetSubscriptionsForUserQuery_getSubscriptionsForUser_children,
  GetSubscriptionsForUserQuery_getSubscriptionsForUser_children_children,
} from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { SoSVSource } from '~components/pages/widgets/sosv/types'

export const getPlatformUrl = (): string => {
  const { ENV } = getConfig()
  if (ENV === 'local') {
    return 'http://localhost:3000'
  }
  return `https://${ENV === 'prod' ? 'app' : ENV}.pharmaspectra.com`
}

type List =
  | GetSubscriptionsForUserQuery_getSubscriptionsForUser
  | GetSubscriptionsForUserQuery_getSubscriptionsForUser_children
  | GetSubscriptionsForUserQuery_getSubscriptionsForUser_children_children

export const findAllSoSVLists = (lists: List[], accumulator: List[] = []): List[] => {
  for (const list of lists) {
    const isSoSV = (list.permissions ?? []).includes(SubscriptionPermission.SoSV)
    const isSubscription = !list.parentId
    if ('children' in list && list.children.length) {
      findAllSoSVLists(list.children, accumulator)
    } else if (!isSubscription && isSoSV) {
      accumulator.push(list)
    } else if (
      isSubscription &&
      (('children' in list && list.children.length === 0) || !('children' in list)) &&
      isSoSV
    ) {
      accumulator.push(list)
    }
  }
  return accumulator
}

export const findFirstSoSVList = (lists: List[], source?: SoSVSource): List | undefined => {
  for (const list of lists) {
    const isSoSV = source
      ? (list.permissions ?? []).includes(
          source === SoSVSource.SCIENCE ? SubscriptionPermission.SoSVScience : SubscriptionPermission.SoSVExperts,
        )
      : (list.permissions ?? []).includes(SubscriptionPermission.SoSV)
    const isSubscription = !list.parentId
    if ('children' in list && list.children.length) {
      const result = findFirstSoSVList(list.children, source)
      if (result) {
        return result
      }
    } else if (!isSubscription && isSoSV) {
      return list
    } else if (
      isSubscription &&
      (('children' in list && list.children.length === 0) || !('children' in list)) &&
      isSoSV
    ) {
      return list
    }
  }
  return undefined
}

export const findSoSVListById = (lists: List[], listId: string, source?: SoSVSource): List | undefined => {
  for (const list of lists) {
    if (list.id === listId) {
      if (source) {
        if (source === SoSVSource.EXPERTS && (list.permissions ?? []).includes(SubscriptionPermission.SoSVExperts)) {
          return list
        } else if (
          source === SoSVSource.SCIENCE &&
          (list.permissions ?? []).includes(SubscriptionPermission.SoSVScience)
        )
          return list
      } else {
        return list
      }
    } else if ('children' in list && list.children.length) {
      const result = findSoSVListById(list.children, listId, source)
      if (result) {
        return result
      }
    }
  }
  return undefined
}
