import React, { RefObject, useRef, useState } from 'react'
import { StyledRelativeDiv } from '~common'
import { useViewportHeight } from '~common/hooks'
import { BackArrowSvg } from '~common/svgs'
import { Transition } from 'react-transition-group'
import {
  StyledContainer,
  StyledItemWrapper,
  StyledBackdrop,
  StyledMinimiseButton,
  StyledChildren,
  StyledIconButton,
  StyledMinimiseBtnText,
} from './styles'

export type TGridItemWidth = 'narrow' | 'wide' | 'widest'
export type TGridItemBackgroundVariation = 'transparent' | 'primary' | 'secondary'

const Container = ({
  children,
  showBackdrop,
  onClickBackdrop,
  childrenLength,
}: {
  children: React.ReactNode
  showBackdrop: boolean
  onClickBackdrop: () => void
  childrenLength: number
}): JSX.Element => (
  <StyledContainer onTouchEnd={() => window.scrollTo(0, 0)}>
    {children}
    {showBackdrop && <StyledBackdrop onClick={onClickBackdrop} childrenLength={childrenLength} />}
  </StyledContainer>
)

type ItemProps = React.HTMLAttributes<HTMLElement> & {
  itemWidth: TGridItemWidth
  asTag?: string
  isOutOfFlow?: boolean
  isExpanded?: boolean
  gridItemRef?: RefObject<HTMLElement>
  onScroll?: React.UIEventHandler<Element>
  isScrollable?: boolean
  backgroundVariation?: TGridItemBackgroundVariation
  withMinimise?: boolean
  onMinimise?: () => void
  minimisedPaneIcon?: React.ReactChild
}

const Item: React.FC<ItemProps> = ({
  children,
  gridItemRef,
  itemWidth,
  onScroll,
  asTag = 'section',
  backgroundVariation = 'secondary' as TGridItemBackgroundVariation,
  isOutOfFlow = false,
  isExpanded = true,
  isScrollable = true,
  withMinimise = false,
  onMinimise,
  minimisedPaneIcon,
  ...rest
}) => {
  const [isMinimised, setIsMinimised] = useState(false)
  const itemRef = useRef<HTMLElement | null>(null)
  const viewportHeight = useViewportHeight()

  const onMinimiseHandler = () => {
    setIsMinimised(true)
    onMinimise?.()
  }

  const onUnMinimiseHandler = () => {
    setIsMinimised(false)
    onMinimise?.()
  }

  return (
    <StyledItemWrapper
      as={asTag as never}
      itemWidth={itemWidth}
      backgroundVariation={backgroundVariation}
      isOutOfFlow={isOutOfFlow}
      isScrollable={isScrollable}
      isExpanded={isExpanded}
      isMinimised={isMinimised}
      viewportHeight={viewportHeight}
      containerOffsetTop={gridItemRef?.current?.offsetTop || itemRef.current?.offsetTop || 0}
      ref={gridItemRef || itemRef}
      onScroll={onScroll}
      {...rest}
    >
      {withMinimise && (
        <StyledRelativeDiv>
          <Transition
            in={!isMinimised || isExpanded}
            timeout={{
              enter: 240,
              exit: 240,
            }}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            {(state) => (
              <StyledMinimiseButton
                icon={<BackArrowSvg />}
                size="lg"
                variant="link"
                onClick={onMinimiseHandler}
                animationState={state}
                itemWidth={itemWidth}
              >
                <StyledMinimiseBtnText itemWidth={itemWidth}>hide</StyledMinimiseBtnText>
              </StyledMinimiseButton>
            )}
          </Transition>
          <Transition
            in={isMinimised}
            timeout={{
              enter: 240,
              exit: 240,
            }}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            {(state) => (
              <StyledIconButton
                a11yTitle="controls"
                icon={minimisedPaneIcon}
                onClick={onUnMinimiseHandler}
                animationState={state}
                itemWidth={itemWidth}
              />
            )}
          </Transition>
        </StyledRelativeDiv>
      )}
      <Transition
        in={!isMinimised}
        timeout={{
          enter: 240,
          exit: 240,
        }}
      >
        {(state) => (
          <StyledChildren animationState={state} itemWidth={itemWidth}>
            {children}
          </StyledChildren>
        )}
      </Transition>
    </StyledItemWrapper>
  )
}

export const MultiItemsInBoxGrid = {
  Container,
  Item,
}
