import styled from 'styled-components'
import { flexMixin, loadingStateBackgroundMixin } from '~common'
import { PharmaspectraBrandLogo, IqviaBrandLogo } from '~components/ui/molecules/brand-logo'

export const StyledHeader = styled.header`
  ${flexMixin}
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100vw;
  background: ${({ theme }) => theme.colors.navigation.navPrimary};
  &:focus {
    outline: none;
  }
`

export const StyledHeaderLoadingBlock = styled.header`
  height: 4.7375rem;
  width: 100%;
  ${loadingStateBackgroundMixin}
`

export const StyledPharmaspectraBrandLogo = styled(PharmaspectraBrandLogo)`
  padding-left: 1rem;
`

export const StyledIqviaBrandLogo = styled(IqviaBrandLogo)`
  padding-left: 1rem;
  ${flexMixin}
  align-items: center;
`
