import { ExpertsHubAction as Action, ExpertsHubState } from './types'
import * as actionTypes from './action-types'
import { initLoading } from './actions'

export const reducer = (state: ExpertsHubState, action: Action): ExpertsHubState => {
  switch (action.type) {
    case actionTypes.INITIALISE: {
      return initLoading(action.payload)
    }
    default:
      throw new Error('Action type not recognised')
  }
}
