import styled, { css } from 'styled-components'
import { TGridItemWidth, TGridItemBackgroundVariation } from './MultiItemsInBoxGrid'
import { Backdrop } from '~components/ui/atoms/backdrop'
import { Button } from '~components/ui/atoms/button'
import { IconButton } from '~components/ui/atoms/icon-button'
import { Flex, flexMixin } from '~common'

export const StyledContainer = styled(Flex).attrs({ as: 'section' })`
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    flex-direction: row;
    justify-content: flex-start;
  }

  #exposed-styled-wrapper > & {
    height: inherit;
  }
`

interface StyledItemWrapperProps {
  itemWidth: TGridItemWidth
  backgroundVariation: TGridItemBackgroundVariation
  isOutOfFlow: boolean
  viewportHeight: number
  containerOffsetTop: number
  isExpanded?: boolean
  isScrollable?: boolean
  isMinimised?: boolean
}

export const StyledItemWrapper = styled.section<StyledItemWrapperProps>`
  width: 100%;
  height: ${({ viewportHeight, containerOffsetTop }) => `${viewportHeight - containerOffsetTop}px`};
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'scroll' : 'hidden')};
  position: ${({ isOutOfFlow }) => (isOutOfFlow ? 'absolute' : 'static')};
  left: 0;
  z-index: ${({ isOutOfFlow }) => (isOutOfFlow ? '999' : '1')};
  transition: margin-left 0.24s ease-in-out 0.24s, max-width ease-in-out 0.24s;
  margin-left: ${({ isExpanded }) => !isExpanded && '-100%'};
  background-color: ${({ backgroundVariation, theme }) => {
    if (backgroundVariation === 'transparent') return 'transparent'
    if (backgroundVariation === 'primary') return theme.colors.brand.backgroundPrimary
    if (backgroundVariation === 'secondary') return theme.colors.brand.backgroundSecondary
  }};
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: ${({ isOutOfFlow }) => isOutOfFlow && '50%'};
    ${({ itemWidth }) => {
      if (['narrow', 'wide'].includes(itemWidth)) {
        return css`
          width: 50%;
          flex-shrink: 0;
        `
      }

      if (['widest'].includes(itemWidth)) {
        return css`
          width: 100%;
        `
      }

      return
    }}
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    ${({ itemWidth, isMinimised, theme }) => {
      if ('wide' === itemWidth) {
        return css`
          position: static;
          overflow-y: ${isMinimised ? 'hidden' : 'scroll'};
          margin-left: 0;
          width: calc(100% / 3);
          max-width: ${isMinimised ? '48px' : theme.pxsToEms(320)};
        `
      }
      return
    }}
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    ${({ itemWidth, isMinimised, theme }) => {
      if ('narrow' === itemWidth) {
        return css`
          position: static;
          overflow-y: ${isMinimised ? 'hidden' : 'scroll'};
          margin-left: 0;
          width: calc(100% / 3);
          max-width: ${isMinimised ? '48px' : theme.pxsToEms(320)};
        `
      }
      return
    }}
  }
`

export const StyledBackdrop = styled(Backdrop)<{ childrenLength: number }>`
  z-index: 998;
  @media (min-width: ${({ theme, childrenLength }) =>
      theme.pxsToEms(childrenLength <= 2 ? theme.breakPoints.tabletLarge : theme.breakPoints.desktopSmall)}) {
    display: none;
  }
`

export const StyledMinimiseButton = styled(Button)<{ animationState: string; itemWidth: TGridItemWidth }>`
  transition: opacity 0.1s ease-in-out;
  ${flexMixin}
  position: absolute;
  top: 20px;
  right: 16px;
  display: ${({ animationState }) => (animationState === 'exiting' ? 'none' : 'flex')};
  z-index: 999;
  opacity: ${({ animationState }) => (animationState === 'entered' ? 1 : 0)};
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    top: 30px;
  }
  @media (min-width: ${({ theme, itemWidth }) =>
      theme.pxsToEms(itemWidth === 'narrow' ? theme.breakPoints.desktopSmall : theme.breakPoints.tabletLarge)}) {
    &:after {
      content: 'minimize';
      visibility: visible;
    }
  }
`

export const StyledMinimiseBtnText = styled.span<{ itemWidth: TGridItemWidth }>`
  @media (min-width: ${({ theme, itemWidth }) =>
      theme.pxsToEms(itemWidth === 'narrow' ? theme.breakPoints.desktopSmall : theme.breakPoints.tabletLarge)}) {
    display: none;
  }
`

export const StyledIconButton = styled(IconButton)<{ animationState: string; itemWidth: TGridItemWidth }>`
  display: none;
  svg {
    fill: ${({ theme }) => theme.colors.buttons.link};
  }
  @media (min-width: ${({ theme, itemWidth }) =>
      theme.pxsToEms(itemWidth === 'narrow' ? theme.breakPoints.desktopSmall : theme.breakPoints.tabletLarge)}) {
    ${flexMixin}
    position: absolute;
    top: 16px;
    right: 16px;
    display: ${({ animationState }) => (animationState === 'exiting' ? 'none' : 'flex')};
  }
`

export const StyledChildren = styled.div<{ animationState: string; itemWidth: TGridItemWidth }>`
  display: block;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  width: 100%;
  height: 100%;
  @media (min-width: ${({ theme, itemWidth }) =>
      theme.pxsToEms(itemWidth === 'narrow' ? theme.breakPoints.desktopSmall : theme.breakPoints.tabletLarge)}) {
    display: ${({ animationState }) => (animationState === 'exited' ? 'none' : 'block')};
    opacity: ${({ animationState }) => (animationState === 'entered' ? 1 : 0)};
  }
`
