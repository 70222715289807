import React, { ReactNode } from 'react'
import { StyledLatestMain } from './styles'
import { GlobalStyles, LinkThemeProvider } from '~common/theme'
import { useMode } from '~common/hooks/use-mode'
import { useRouter } from 'next/router'

export const Layout = ({ children }: { children: ReactNode }): JSX.Element => {
  const [mode] = useMode()
  const router = useRouter()
  const isExpertWidget = router.pathname.includes('experts')
  const brand = isExpertWidget ? 'iqvia' : 'pharmaspectra'
  const brandMode = isExpertWidget ? 'light' : mode

  return (
    <LinkThemeProvider mode={brandMode} brand={brand}>
      <GlobalStyles />
      <StyledLatestMain>{children}</StyledLatestMain>
    </LinkThemeProvider>
  )
}
