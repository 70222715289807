import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { GENERATE_ZENDESK_SSO_TOKEN } from '~common/hooks/use-zendesk/query'
import { GenerateZendeskSsoTokenQuery } from './__generated__/GenerateZendeskSsoTokenQuery'
import { datadogRum } from '@datadog/browser-rum'

export interface UseZendeskReturn {
  zendeskUrl: string
  refetch?: () => void
}

/**
 * Used in Layout (outside ErrorBoundary), so we don't rely on our usual error handling
 * The fallback (if we can't get the JWT for Zendesk SSO to work) is the plain URL
 */
export const useZendeskUrl = (): UseZendeskReturn => {
  const [zendeskUrl, setZendeskUrl] = useState('')

  const zendeskSsoTokenResponse = useQuery<GenerateZendeskSsoTokenQuery>(GENERATE_ZENDESK_SSO_TOKEN)

  useEffect(() => {
    if (zendeskSsoTokenResponse.error) {
      setZendeskUrl('https://medmeme.zendesk.com')
      datadogRum.addError(zendeskSsoTokenResponse.error, undefined)
    } else if (zendeskSsoTokenResponse.data?.generateZendeskSsoToken) {
      const zendeskToken = zendeskSsoTokenResponse.data?.generateZendeskSsoToken
      setZendeskUrl(`https://medmeme.zendesk.com/access/jwt?jwt=${zendeskToken}`)
    }
  }, [zendeskSsoTokenResponse])

  return {
    zendeskUrl,
    refetch: zendeskSsoTokenResponse.refetch,
  }
}
