import React from 'react'
import { Controlled } from '~components/ui/controlled'
import { ControlledProps } from '~components/ui/controlled/types'
import { StyledDropdownWithCheckboxesItem, StyledDropdown } from '../styles'
import { Option } from '../types'

export interface DropdownWithControlledCheckboxesProps<TFormValues>
  extends React.HTMLAttributes<HTMLUListElement>,
    ControlledProps<TFormValues> {
  isOpen: boolean
  options: Option[]
  ariaLabel: string
  dropdownTitle?: string
  onCheckOption?: (id: string) => void
  name: string
}

export const DropdownWithControlledCheckboxes = <TFormValues extends Record<string, unknown>>({
  dropdownTitle,
  options,
  ariaLabel,
  isOpen,
  name,
  control,
  errors,
  rules,
  ...rest
}: DropdownWithControlledCheckboxesProps<TFormValues>): JSX.Element => (
  <StyledDropdown
    tabIndex={-1}
    aria-label={ariaLabel}
    aria-hidden={!isOpen}
    isOpen={isOpen}
    isFixedMaxWidth={true}
    {...rest}
  >
    {dropdownTitle && (
      <StyledDropdownWithCheckboxesItem hasNoAction={true}>{dropdownTitle}</StyledDropdownWithCheckboxesItem>
    )}
    {options.map((item: Option) => (
      <StyledDropdownWithCheckboxesItem key={item.id}>
        <Controlled.Checkbox
          id={item.id}
          checkboxSize="sm"
          label={item.label}
          tabIndex={isOpen ? 0 : -1}
          name={`${name}[${item.id}]`}
          defaultChecked={item.isChecked}
          control={control}
          errors={errors}
          rules={rules}
        />
      </StyledDropdownWithCheckboxesItem>
    ))}
  </StyledDropdown>
)
