import React from 'react'
import { HeadingTab } from '~components/ui/atoms/heading-tab'
import { StyledTabs } from './styles'

interface Tab {
  name: string | React.ReactNode
  id: string
}

export interface TabsProps {
  tabs: Tab[]
  onSelect: (id: string) => void
  selectedTabId: string
  className?: string
}

export const Tabs: React.FC<TabsProps> = ({ tabs, selectedTabId, onSelect, className, children }): JSX.Element => (
  <>
    <StyledTabs className={className}>
      {tabs.map((el: Tab) => (
        <HeadingTab key={el.id} isSelected={el.id === selectedTabId} onClick={() => onSelect(el.id)} asTag="li">
          {el.name}
        </HeadingTab>
      ))}
    </StyledTabs>
    {children}
  </>
)
