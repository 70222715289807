import { ExpertsHubState, Initialise } from './types'
import { getModifiedSubscriptionsWithRootAndListIds } from '~common/helpers/common-link-helpers'
import { SubscriptionPermission } from '~common/types/common-link-types'
import { findSelectedList } from '~components/ui/sosv-hub-layout/helpers'

export const initLoading = (payload: Initialise['payload']): ExpertsHubState => {
  const {
    rootSubscriptionId,
    listId,
    showClientNames,
    subscriptions,
    sosvProductGroups,
    sosvProducts,
    savedLists,
    smartLists,
    savedListId,
    productGroupId,
    smartListId,
    userEmail,
  } = payload

  const { selectedRootSubscriptionId, selectedListId, modifiedSubscriptions } =
    getModifiedSubscriptionsWithRootAndListIds({ subscriptions, rootSubscriptionId, listId })

  const selectedSubscription = modifiedSubscriptions.filter(
    (subscription) => subscription.defaultSubscriptionId === selectedRootSubscriptionId,
  )[0]

  const selectedLists = !!selectedSubscription?.children.length
    ? selectedSubscription.children
    : selectedSubscription
    ? [selectedSubscription]
    : []

  const selectedSavedLists = (savedLists || []).filter(
    (savedList) =>
      (savedList.subscriptionId === selectedRootSubscriptionId || savedList.listId === selectedRootSubscriptionId) &&
      savedList.searchType != 'ME',
  )
  const selectedMyExpertsSavedLists = (savedLists || []).filter(
    (savedList) =>
      (savedList.subscriptionId === selectedRootSubscriptionId || savedList.listId === selectedRootSubscriptionId) &&
      savedList.searchType === 'ME',
  )

  const selectedList = findSelectedList(selectedLists, selectedListId)

  const hasSoSVExpertsForSelectedList = selectedList?.permissions.includes(SubscriptionPermission.SoSVExperts) || false

  return {
    hasInitialised: true,
    showClientNames,
    listId: selectedListId,
    rootSubscriptionId: selectedRootSubscriptionId,
    subscriptions: modifiedSubscriptions,
    lists: selectedLists,
    savedListId,
    savedLists: selectedSavedLists,
    myExpertsLists: selectedMyExpertsSavedLists,
    smartLists: smartLists ?? {},
    smartListId,
    sosvProducts,
    productGroupId,
    sosvProductGroups,
    permissionsForSelectedSubscription: (selectedSubscription?.permissions || []) as SubscriptionPermission[],
    hasSoSVExpertsForSelectedList,
    userEmail,
  }
}
