import { MapBounds } from '~common'
import { formatUrl } from '~common/helpers'
import { actionTypes } from '.'
import { isExpertEcosystemRouter, Router } from '~common/hooks'
import { MapAction as Action } from './types'
import React from 'react'
import { getCurrentShallowQuery, pushShallowHistoryEntry } from '~common/providers/shallow-routing-state-provider'

export const initialiseMap = ({
  mapDispatch,
  router,
  visiblePointsTotal,
  mapBounds,
  shallowRedirect,
}: {
  mapDispatch: React.Dispatch<Action>
  visiblePointsTotal: number
  mapBounds: MapBounds
  router: Router | undefined
  shallowRedirect?: (url: string) => void
}): void => {
  mapDispatch({
    type: actionTypes.INITIALISE_MAP,
    payload: {
      visiblePointsTotal,
      mapBounds,
    },
  })
  if (router) {
    if (isExpertEcosystemRouter(router)) {
      const merged = { ...router.query, ...getCurrentShallowQuery() }
      const shallowPush = shallowRedirect ?? pushShallowHistoryEntry
      shallowPush(
        formatUrl(router.location.pathname, {
          ...merged,
          southWestLng: mapBounds.southWest.lng,
          southWestLat: mapBounds.southWest.lat,
          northEastLng: mapBounds.northEast.lng,
          northEastLat: mapBounds.northEast.lat,
        }),
      )
    } else {
      void router.replace(
        formatUrl(router.route, {
          ...router.query,
          southWestLng: mapBounds.southWest.lng,
          southWestLat: mapBounds.southWest.lat,
          northEastLng: mapBounds.northEast.lng,
          northEastLat: mapBounds.northEast.lat,
        }),
        undefined,
        {
          shallow: true,
        },
      )
    }
  }
}

export const setMapBounds = ({
  mapDispatch,
  mapBounds,
}: {
  mapDispatch: React.Dispatch<Action>
  mapBounds: MapBounds
}): void => {
  mapDispatch({
    type: actionTypes.SET_MAP_BOUNDS,
    payload: {
      mapBounds,
    },
  })
}
