import styled from 'styled-components'
import { CenteredFlex, Scale, scaleColor } from '~common'

export const StyledCluster = styled(CenteredFlex)<{ size: number; scale: Scale }>`
  font-family: ${({ theme }) => theme.fontFamily};
  ${({ theme }) => theme.textSizes.desktop.labelBold};
  color: ${({ theme }) => theme.colors.always.alwaysLight};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background: ${scaleColor};
  border-radius: 50%;
  padding: 0.9375rem;
`
