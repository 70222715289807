import React from 'react'
import { CollectionTeaser } from '../collection-teaser'
import { StyledMapExpertTeaser, StyledChildren } from './styles'

export interface MapExpertTeaserProps {
  rank: number
  name: string
  status?: string
  location: string
  linkToExpert: string
  imageSrc?: string
  metadataOptions?: {
    primary: string
    secondary: string
  }
}
export const MapExpertTeaser: React.FC<MapExpertTeaserProps> = ({
  rank,
  name,
  status,
  location,
  linkToExpert,
  imageSrc,
  metadataOptions,
  children,
}): JSX.Element => (
  <StyledMapExpertTeaser>
    <CollectionTeaser
      heading={name}
      rank={rank}
      status={status}
      subheading={location}
      imageSrc={imageSrc}
      linkTo={linkToExpert}
      metadataOptions={metadataOptions}
      isWithPaddingAroundContent={true}
    />
    {children && <StyledChildren>{children}</StyledChildren>}
  </StyledMapExpertTeaser>
)
