import styled from 'styled-components'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'

export const StyledProductsMentionsBarChart = styled(StyledChart)<{
  productColors: string[]
  series: Highcharts.SeriesOptionsType[]
}>`
  height: 24px;
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-plot-background {
    height: 100%;
  }
  .highcharts-tooltip > span {
    .key {
      margin-right: 4px;
    }
  }
  .highcharts-point {
    fill: unset;
    stroke-width: 0px;
  }
  .highcharts-container,
  .highcharts-root,
  .highcharts-background,
  .highcharts-container svg {
    width: 100% !important;
  }
  ${({ productColors, series }) => getProductColorsCss({ productColors, series })};
`
