import {
  GetSoSVMentionsBreakdownQuery,
  GetSoSVMentionsBreakdownQueryVariables,
} from '~apollo/queries/sosv/mentions-breakdown/__generated__/GetSoSVMentionsBreakdownQuery'
import {
  GetSoSVDocumentsCountsQuery,
  GetSoSVDocumentsCountsQueryVariables,
} from '~apollo/queries/sosv/documents-counts/__generated__/GetSoSVDocumentsCountsQuery'
import { RefetchQueriesInclude, useApolloClient, useQuery } from '@apollo/client'
import {
  GetOnTopicContributionCountQuery,
  GetOnTopicContributionCountQueryVariables,
} from '~apollo/queries/sosv/on-topic-counts/__generated__/GetOnTopicContributionCountQuery'
import { GET_ON_TOPIC_CONTRIBUTION_COUNT } from '~apollo/queries/sosv/on-topic-counts/query'
import { GET_SOSV_MENTIONS_BREAKDOWN } from '~apollo/queries/sosv/mentions-breakdown/query'
import { GET_SOSV_DOCUMENTS_COUNTS } from '~apollo/queries/sosv/documents-counts/query'
import {
  GetSoSVExpertsQuery,
  GetSoSVExpertsQueryVariables,
} from '~apollo/queries/sosv/experts/__generated__/GetSoSVExpertsQuery'
import { GET_SOSV_EXPERTS } from '~apollo/queries/sosv/experts/query'
import {
  GetSoSVJournalsQuery,
  GetSoSVJournalsQueryVariables,
} from '~apollo/queries/sosv/journals/__generated__/GetSoSVJournalsQuery'
import { GET_SOSV_JOURNALS } from '~apollo/queries/sosv/journals/query'
import {
  GetSoSVSocietiesQuery,
  GetSoSVSocietiesQueryVariables,
} from '~apollo/queries/sosv/societies/__generated__/GetSoSVSocietiesQuery'
import { GET_SOSV_SOCIETIES } from '~apollo/queries/sosv/societies/query'
import {
  GetSoSVGeographiesQuery,
  GetSoSVGeographiesQueryVariables,
} from '~apollo/queries/sosv/geographies/__generated__/GetSoSVGeographiesQuery'
import { GET_SOSV_GEOGRAPHIES } from '~apollo/queries/sosv/geographies/query'
import { MapAction as Action, MapState } from '~common/hooks/use-map/map-state-managment/types'
import { actionCreators, mapReducer } from '~common/hooks/use-map/map-state-managment'
import { Dispatch, useEffect, useReducer } from 'react'
import { DefaultCoordinates } from '~common'
import { ISoSVState } from '~components/pages/widgets/sosv/settings/settings'
import { GET_SOSV_GEOGRAPHY_MEETINGS_COUNT } from '~apollo/queries/sosv/meetings/query'
import {
  GetSoSVGeographyMeetingsCountQuery,
  GetSoSVGeographyMeetingsCountQueryVariables,
} from '~apollo/queries/sosv/meetings/__generated__/GetSoSVGeographyMeetingsCountQuery'
import { SoSVSource } from '~components/pages/widgets/sosv/types'
import {
  GetSoSVJournalArticlesQuery,
  GetSoSVJournalArticlesQueryVariables,
} from '~apollo/queries/sosv/journal-articles/__generated__/GetSoSVJournalArticlesQuery'
import { GET_SOSV_JOURNAL_ARTICLES } from '~apollo/queries/sosv/journal-articles/query'
import {
  GetSoSVPresentationsQuery,
  GetSoSVPresentationsQueryVariables,
} from '~apollo/queries/sosv/presentations/__generated__/GetSoSVPresentationsQuery'
import { GET_SOSV_PRESENTATIONS } from '~apollo/queries/sosv/presentations/query'

export interface UseSoSVDetailsReturn {
  isLoading: boolean
  mapState: MapState
  mapDispatch: Dispatch<Action>
  topJournalsData?: GetSoSVJournalsQuery
  topSocietiesData?: GetSoSVSocietiesQuery
  topExpertsData?: GetSoSVExpertsQuery
  topGeographiesData?: GetSoSVGeographiesQuery
  topMeetingsLocationsData?: GetSoSVGeographyMeetingsCountQuery
  mentionsBreakdownData?: GetSoSVMentionsBreakdownQuery
  documentsCountsData?: GetSoSVDocumentsCountsQuery
  topPresentationsData?: GetSoSVPresentationsQuery
  topJournalArticlesData?: GetSoSVJournalArticlesQuery
  totalContributionsCount?: number
}

export const useSoSVDetails = (state: ISoSVState): UseSoSVDetailsReturn => {
  const {
    weighteningType,
    timeframe,
    startDate,
    endDate,
    listId,
    contentTypes,
    drugSelection = [],
    topicSelection,
    productGroupId = '',
    source,
  } = state

  const [mapState, mapDispatch]: [MapState, Dispatch<Action>] = useReducer(mapReducer, {
    visiblePointsTotal: 0,
    mapBounds: undefined,
    isInitialised: false,
  })

  useEffect(() => {
    actionCreators.setMapBounds({
      mapDispatch,
      mapBounds: {
        southWest: {
          lng: DefaultCoordinates.SouthWestLng,
          lat: DefaultCoordinates.SouthWestLat,
        },
        northEast: {
          lng: DefaultCoordinates.NorthEastLng,
          lat: DefaultCoordinates.NorthEastLat,
        },
      },
    })
  }, [])

  const {
    data: expertsContributionCountData = { getOnTopicContributionCount: 0 },
    loading: isLoadingExpertsContributionCount,
    error: errorExpertsContributionCount,
  } = useQuery<GetOnTopicContributionCountQuery, GetOnTopicContributionCountQueryVariables>(
    GET_ON_TOPIC_CONTRIBUTION_COUNT,
    {
      variables: { listId: listId as string, source: source },
      skip: !listId,
    },
  )

  const {
    data: mentionsBreakdownData = { getSoSVMentionsBreakdown: [] },
    loading: isLoadingMentionsBreakdown,
    error: errorMentionsBreakdown,
  } = useQuery<GetSoSVMentionsBreakdownQuery, GetSoSVMentionsBreakdownQueryVariables>(GET_SOSV_MENTIONS_BREAKDOWN, {
    variables: {
      listId: listId as string,
      productGroupId,
      contentTypes,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      source,
    },
    skip: !listId,
  })

  const {
    data: documentsCountsData,
    loading: isLoadingDocumentsCounts,
    error: errorDocumentsCounts,
  } = useQuery<GetSoSVDocumentsCountsQuery, GetSoSVDocumentsCountsQueryVariables>(GET_SOSV_DOCUMENTS_COUNTS, {
    variables: {
      listId: listId as string,
      productGroupId,
      contentTypes,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      source: source,
    },
    skip: !listId,
  })

  const {
    data: topExpertsData,
    error: errorTopExperts,
    loading: isLoadingTopExperts,
  } = useQuery<GetSoSVExpertsQuery, GetSoSVExpertsQueryVariables>(GET_SOSV_EXPERTS, {
    variables: {
      listId: listId as string,
      productGroupId,
      contentTypes,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      weighted: weighteningType === 'weighted',
      sortBy: 'profileRank',
      pageSize: 5,
      currentPage: 0,
    },
    skip: !listId || source === SoSVSource.SCIENCE,
  })

  const {
    data: topJournalsData,
    error: errorTopJournals,
    loading: isLoadingTopJournals,
  } = useQuery<GetSoSVJournalsQuery, GetSoSVJournalsQueryVariables>(GET_SOSV_JOURNALS, {
    variables: {
      listId: listId as string,
      productGroupId,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      weighted: weighteningType === 'weighted',
      sortBy: 'impactScore',
      pageSize: 5,
      currentPage: 0,
      source,
    },
    skip: !listId,
  })

  const {
    data: topJournalArticlesData,
    error: errorTopJournalArticles,
    loading: isLoadingTopJournalArticles,
  } = useQuery<GetSoSVJournalArticlesQuery, GetSoSVJournalArticlesQueryVariables>(GET_SOSV_JOURNAL_ARTICLES, {
    variables: {
      listId: listId as string,
      productGroupId,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      pageSize: 5,
      currentPage: 0,
      source,
    },
    skip: !listId,
  })

  const {
    data: topSocietiesData,
    error: errorTopSocieties,
    loading: isLoadingTopSocieties,
  } = useQuery<GetSoSVSocietiesQuery, GetSoSVSocietiesQueryVariables>(GET_SOSV_SOCIETIES, {
    variables: {
      listId: listId as string,
      productGroupId,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      weighted: weighteningType === 'weighted',
      sortBy: 'all',
      pageSize: 5,
      currentPage: 0,
      source: source,
    },
    skip: !listId,
  })

  const {
    data: topGeographiesData,
    error: errorTopGeographies,
    loading: isLoadingTopGeographies,
  } = useQuery<GetSoSVGeographiesQuery, GetSoSVGeographiesQueryVariables>(GET_SOSV_GEOGRAPHIES, {
    variables: {
      listId: listId as string,
      productGroupId,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      contentTypes,
      topicSelection,
      weighted: weighteningType === 'weighted',
      sortBy: 'all',
      pageSize: 5,
      currentPage: 0,
    },
    skip: !listId || source === SoSVSource.SCIENCE,
  })

  const {
    data: topGeographyMeetingsCount,
    loading: isLoadingTopGeographyMeetingsCount,
    error: errorTopGeographyMeetingsCount,
  } = useQuery<GetSoSVGeographyMeetingsCountQuery, GetSoSVGeographyMeetingsCountQueryVariables>(
    GET_SOSV_GEOGRAPHY_MEETINGS_COUNT,
    {
      variables: {
        listId: listId as string,
        productGroupId,
        contentTypes,
        timeframe,
        startDate,
        endDate,
        drugSelection,
        topicSelection,
        pageSize: 5,
        currentPage: 0,
      },
      skip: !listId || source === SoSVSource.EXPERTS,
    },
  )

  const {
    data: topPresentationsData,
    error: errorTopPresentations,
    loading: isLoadingTopPresentations,
  } = useQuery<GetSoSVPresentationsQuery, GetSoSVPresentationsQueryVariables>(GET_SOSV_PRESENTATIONS, {
    variables: {
      listId: listId as string,
      productGroupId,
      timeframe,
      startDate,
      endDate,
      drugSelection,
      topicSelection,
      pageSize: 5,
      currentPage: 0,
      source,
    },
    skip: !listId,
  })

  const apolloClient = useApolloClient()
  useEffect(() => {
    const sourceQueriesMap: Record<SoSVSource, RefetchQueriesInclude> = {
      [SoSVSource.EXPERTS]: [
        GET_SOSV_GEOGRAPHIES,
        GET_SOSV_SOCIETIES,
        GET_SOSV_JOURNALS,
        GET_SOSV_JOURNAL_ARTICLES,
        GET_SOSV_EXPERTS,
        GET_SOSV_PRESENTATIONS,
        GET_SOSV_DOCUMENTS_COUNTS,
        GET_SOSV_MENTIONS_BREAKDOWN,
        GET_ON_TOPIC_CONTRIBUTION_COUNT,
      ],
      [SoSVSource.SCIENCE]: [
        GET_SOSV_SOCIETIES,
        GET_SOSV_JOURNALS,
        GET_SOSV_JOURNAL_ARTICLES,
        GET_SOSV_DOCUMENTS_COUNTS,
        GET_SOSV_MENTIONS_BREAKDOWN,
        GET_SOSV_PRESENTATIONS,
        GET_ON_TOPIC_CONTRIBUTION_COUNT,
        GET_SOSV_GEOGRAPHY_MEETINGS_COUNT,
      ],
    }

    const intervalId = setInterval(async () => {
      if (source) {
        await apolloClient.refetchQueries({
          include: sourceQueriesMap[source],
        })
      }
    }, 5 * 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [apolloClient, source])

  const error =
    errorMentionsBreakdown ||
    errorExpertsContributionCount ||
    errorDocumentsCounts ||
    errorTopExperts ||
    errorTopJournals ||
    errorTopSocieties ||
    errorTopGeographies ||
    errorTopGeographyMeetingsCount ||
    errorTopJournalArticles ||
    errorTopPresentations

  if (error) throw error

  const isLoading =
    isLoadingMentionsBreakdown ||
    isLoadingExpertsContributionCount ||
    isLoadingDocumentsCounts ||
    isLoadingTopExperts ||
    isLoadingTopJournals ||
    isLoadingTopSocieties ||
    isLoadingTopGeographies ||
    isLoadingTopGeographyMeetingsCount ||
    isLoadingTopJournalArticles ||
    isLoadingTopPresentations

  return {
    isLoading,
    mentionsBreakdownData,
    documentsCountsData,
    totalContributionsCount: expertsContributionCountData?.getOnTopicContributionCount,
    topExpertsData,
    topJournalsData,
    topSocietiesData,
    topGeographiesData,
    topJournalArticlesData,
    topPresentationsData,
    mapState,
    mapDispatch,
    topMeetingsLocationsData: topGeographyMeetingsCount,
  }
}
