import React, { ChangeEvent } from 'react'
import { Hub } from '~common'
import { useIsTouchableDevice } from '~common/hooks'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { Select } from '~components/ui/molecules/select'
import {
  StyledHubsNavigationWrapper,
  StyledSubscriptionsWrapper,
  StyledNavigation,
  StyledNavigationList,
  StyledNavigationItem,
  StyledSelectWithSystemDropdown,
  StyedSubscription,
} from './styles'

export interface HubsNavigationProps extends React.HTMLAttributes<HTMLDivElement> {
  hubs: Hub[]
  subscriptionsOptions: {
    value: string
    label: string
  }[]
  selectedHubId: string
  selectedSubscriptionId: string
  onChangeSubscription?: (id: string) => void
}

export const HubsNavigation = ({
  subscriptionsOptions,
  hubs,
  selectedHubId,
  selectedSubscriptionId,
  onChangeSubscription,
  ...rest
}: HubsNavigationProps): JSX.Element => {
  const { isTouchable, handleTouchStart, handleMouseMove } = useIsTouchableDevice()
  return (
    <StyledHubsNavigationWrapper as="section" onTouchStart={handleTouchStart} onMouseMove={handleMouseMove} {...rest}>
      <StyledSubscriptionsWrapper>
        {subscriptionsOptions.length > 1 ? (
          <StyledSelectWithSystemDropdown
            id="subscriptions"
            selectDropdownSize="md"
            placeholder="Select subscription..."
            value={subscriptionsOptions.find((el) => el.value === selectedSubscriptionId)?.value}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => onChangeSubscription?.(e.target.value)}
          >
            {subscriptionsOptions.map((option) => (
              <Select.Option value={option.value} key={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </StyledSelectWithSystemDropdown>
        ) : (
          <StyedSubscription>{subscriptionsOptions[0]?.label}</StyedSubscription>
        )}
      </StyledSubscriptionsWrapper>
      <StyledNavigation aria-label="hubs navigation list" role="navigation">
        <StyledNavigationList>
          {hubs.map((navigationItem) => (
            <StyledNavigationItem
              key={navigationItem.id}
              isSelected={navigationItem.id === selectedHubId}
              isFullWidth={hubs.length === 1}
              isTouchable={isTouchable}
              isDisabled={navigationItem.isDisabled}
              isLoading={navigationItem.isLoading}
            >
              {navigationItem.isDisabled ? (
                <span>{navigationItem.content}</span>
              ) : (
                <CustomLink href={navigationItem.link} type="custom">
                  {navigationItem.content}
                </CustomLink>
              )}
            </StyledNavigationItem>
          ))}
        </StyledNavigationList>
      </StyledNavigation>
    </StyledHubsNavigationWrapper>
  )
}
