import { SortBy, SortDirection } from '~apollo/generated-global-types'
import { NextRouter, useRouter } from 'next/router'
import { getPillsFromQuery, SearchCategories } from '~common'

export type SortType =
  | 'rank'
  | 'rankTrend'
  | 'workWithIndustry'
  | 'mostWorkWithIndustry'
  | 'onTopicPercent'
  | 'onTopicTrend'
  | 'twitter'
  | 'searchRank'
  | 'searchPercentage'
  | 'searchTrend'
  | 'networkSize'
  | 'fullName'
  | 'digitalAffinityRank'
  | 'digitalAffinityOnTopicPercentage'
  | 'digitalAffinityOwnedContent'
  | 'digitalAffinityEarnedContent'
  | 'digitalAffinityTotalEarnedAndOwnedContent'
  | 'influenceScoresOverall'
  | 'influenceScoresLocalityInternational'
  | 'influenceScoresLocalityNational'

export const DEFAULT_SORT_TYPE: SortType = 'rank'

const contentSearchPillCategories = [
  SearchCategories.booleanQuery,
  SearchCategories.medicalTerm,
  SearchCategories.freeText,
  SearchCategories.journal,
  SearchCategories.societyEvent,
  SearchCategories.societyEventCustomTerm,
].map((c) => c.toLowerCase())

type SortDefinition = {
  label: string
  sortBy: SortBy[]
}

export type SortOptions = {
  rank?: SortDefinition
  rankTrend?: SortDefinition
  onTopicPercent?: SortDefinition
  onTopicTrend?: SortDefinition
  twitter?: SortDefinition
  tlScore?: SortDefinition
  workWithIndustry?: SortDefinition
  mostWorkWithIndustry?: SortDefinition
  searchRank?: SortDefinition
  searchPercentage?: SortDefinition
  searchTrend?: SortDefinition
  networkSize?: SortDefinition
  fullName?: SortDefinition
  digitalAffinityRank?: SortDefinition
  digitalAffinityOnTopicPercentage?: SortDefinition
  digitalAffinityOwnedContent?: SortDefinition
  digitalAffinityEarnedContent?: SortDefinition
  digitalAffinityTotalEarnedAndOwnedContent?: SortDefinition
  influenceScoresOverall?: SortDefinition
  influenceScoresLocalityInternational?: SortDefinition
  influenceScoresLocalityNational?: SortDefinition
}

interface SortOptionParams {
  isForSearchResults?: boolean
  includeTwitter?: boolean
  includeTlScore?: boolean
  includeSearchPercentage?: boolean
  includeSearchTrend?: boolean
  includeNetworkSize?: boolean
  includeRank?: boolean
  includeRankTrend?: boolean
  includeFullName?: boolean
  includeDigitalAffinityRank?: boolean
  includeDigitalAffinityOnTopicPercentage?: boolean
  includeDigitalAffinityOwnedContent?: boolean
  includeDigitalAffinityEarnedContent?: boolean
  includeDigitalAffinityTotalEarnedAndOwnedContent?: boolean
  includeInfluenceScoresOverall?: boolean
  includeInfluenceScoresLocalityInternational?: boolean
  includeInfluenceScoresLocalityNational?: boolean
}

const defaultSortOptionParams: SortOptionParams = {
  isForSearchResults: false,
  includeTwitter: true,
  includeRank: true,
  includeRankTrend: true,
  includeTlScore: false,
  includeSearchPercentage: false,
  includeSearchTrend: false,
  includeNetworkSize: false,
  includeFullName: false,
  includeDigitalAffinityRank: false,
  includeDigitalAffinityOnTopicPercentage: false,
  includeDigitalAffinityOwnedContent: false,
  includeDigitalAffinityEarnedContent: false,
  includeDigitalAffinityTotalEarnedAndOwnedContent: false,
  includeInfluenceScoresOverall: false,
  includeInfluenceScoresLocalityInternational: false,
  includeInfluenceScoresLocalityNational: false,
}

const getSearchSortOptions = (params?: SortOptionParams): SortOptions => {
  const {
    includeTwitter,
    includeSearchPercentage,
    includeSearchTrend,
    includeNetworkSize,
    includeRank,
    includeRankTrend,
    includeFullName,
    includeDigitalAffinityRank,
    includeDigitalAffinityOnTopicPercentage,
    includeDigitalAffinityOwnedContent,
    includeDigitalAffinityEarnedContent,
    includeDigitalAffinityTotalEarnedAndOwnedContent,
    includeInfluenceScoresOverall,
    includeInfluenceScoresLocalityInternational,
    includeInfluenceScoresLocalityNational,
  } = params || defaultSortOptionParams

  const searchSortOptions: SortOptions = {
    searchRank: {
      label: 'Search Rank',
      sortBy: [{ field: 'searchRank', direction: SortDirection.desc }],
    },
    mostWorkWithIndustry: {
      label: 'Most work with industry',
      sortBy: [{ field: 'mostWorkWithIndustry', direction: SortDirection.desc }],
    },
  }

  if (includeFullName) {
    searchSortOptions.fullName = {
      label: 'Full Name',
      sortBy: [{ field: 'fullName', direction: SortDirection.asc }],
    }
  }

  if (includeRank) {
    searchSortOptions.rank = {
      label: 'Rank',
      sortBy: [{ field: 'rank', direction: SortDirection.asc }],
    }
  }

  if (includeRankTrend) {
    searchSortOptions.rankTrend = {
      label: 'Rank trend',
      sortBy: [{ field: 'rankTrend', direction: SortDirection.asc }],
    }
  }

  if (includeSearchPercentage) {
    searchSortOptions.searchPercentage = {
      label: 'Search percentage',
      sortBy: [{ field: 'searchPercentage', direction: SortDirection.desc }],
    }
  }

  if (includeSearchTrend) {
    searchSortOptions.searchTrend = {
      label: 'Search trend',
      sortBy: [{ field: 'searchTrend', direction: SortDirection.desc }],
    }
  }

  if (includeTwitter) {
    searchSortOptions.twitter = {
      label: 'Twitter followers',
      sortBy: [{ field: 'twitter', direction: SortDirection.desc }],
    }
  }

  if (includeNetworkSize) {
    searchSortOptions.networkSize = {
      label: 'Est. Network size',
      sortBy: [{ field: 'networkSize', direction: SortDirection.desc }],
    }
  }

  if (includeDigitalAffinityRank) {
    searchSortOptions.digitalAffinityRank = {
      label: 'Digital rank',
      sortBy: [{ field: 'digitalAffinityRank', direction: SortDirection.asc }],
    }
  }

  if (includeDigitalAffinityOnTopicPercentage) {
    searchSortOptions.digitalAffinityOnTopicPercentage = {
      label: 'Digital On topic',
      sortBy: [{ field: 'digitalAffinityOnTopicPercentage', direction: SortDirection.desc }],
    }
  }

  if (includeDigitalAffinityOwnedContent) {
    searchSortOptions.digitalAffinityOwnedContent = {
      label: 'Digital Owned',
      sortBy: [{ field: 'digitalAffinityTotalOwnedContent', direction: SortDirection.desc }],
    }
  }

  if (includeDigitalAffinityEarnedContent) {
    searchSortOptions.digitalAffinityEarnedContent = {
      label: 'Digital Earned',
      sortBy: [{ field: 'digitalAffinityTotalEarnedContent', direction: SortDirection.desc }],
    }
  }

  if (includeDigitalAffinityTotalEarnedAndOwnedContent) {
    searchSortOptions.digitalAffinityTotalEarnedAndOwnedContent = {
      label: 'Digital Earned + Owned',
      sortBy: [{ field: 'digitalAffinityTotalEarnedAndOwnedContent', direction: SortDirection.desc }],
    }
  }
  if (includeInfluenceScoresOverall) {
    searchSortOptions.influenceScoresOverall = {
      label: 'Overall Network score',
      sortBy: [{ field: 'influenceScoresOverall', direction: SortDirection.desc }],
    }
  }
  if (includeInfluenceScoresLocalityInternational) {
    searchSortOptions.influenceScoresLocalityInternational = {
      label: 'International network score',
      sortBy: [{ field: 'influenceScoresLocalityInternational', direction: SortDirection.desc }],
    }
  }
  if (includeInfluenceScoresLocalityNational) {
    searchSortOptions.influenceScoresLocalityNational = {
      label: 'National network score',
      sortBy: [{ field: 'influenceScoresLocalityNational', direction: SortDirection.desc }],
    }
  }

  return searchSortOptions
}

export const getSortOptions = (params?: SortOptionParams): SortOptions => {
  const {
    isForSearchResults,
    includeTwitter,
    includeTlScore,
    includeNetworkSize,
    includeDigitalAffinityRank,
    includeDigitalAffinityOnTopicPercentage,
    includeDigitalAffinityOwnedContent,
    includeDigitalAffinityEarnedContent,
    includeDigitalAffinityTotalEarnedAndOwnedContent,
    includeInfluenceScoresOverall,
    includeInfluenceScoresLocalityInternational,
    includeInfluenceScoresLocalityNational,
  } = params || defaultSortOptionParams

  if (isForSearchResults) {
    return getSearchSortOptions(params)
  } else {
    const sortOptions: SortOptions = {
      rank: {
        label: 'Rank',
        sortBy: [{ field: 'subscriptionStats.profileRank', direction: SortDirection.asc }],
      },
      rankTrend: {
        label: 'Rank trend',
        sortBy: [{ field: 'subscriptionStats.profileRankRegressionSlope', direction: SortDirection.asc }],
      },
      mostWorkWithIndustry: {
        label: 'Most work with industry',
        sortBy: [{ field: 'disclosures.totalCount', direction: SortDirection.desc }],
      },
      onTopicPercent: {
        label: 'On topic percentage',
        sortBy: [{ field: 'subscriptionStats.onTopicOverallPercentage', direction: SortDirection.desc }],
      },
      onTopicTrend: {
        label: 'On topic trend',
        sortBy: [{ field: 'subscriptionStats.onTopicCountRegressionSlope', direction: SortDirection.desc }],
      },
    }

    if (includeTwitter) {
      sortOptions.twitter = {
        label: 'Twitter followers',
        sortBy: [{ field: 'socialProfile.twitterFollowersCount', direction: SortDirection.desc }],
      }
    }

    if (includeTlScore) {
      sortOptions.tlScore = {
        label: 'TL score',
        sortBy: [{ field: 'subscriptionStats.tlScore', direction: SortDirection.desc }],
      }
    }

    if (includeNetworkSize) {
      sortOptions.networkSize = {
        label: 'Est. Network size',
        sortBy: [{ field: 'network.totalSize', direction: SortDirection.desc }],
      }
    }

    if (includeDigitalAffinityRank) {
      sortOptions.digitalAffinityRank = {
        label: 'Digital rank',
        sortBy: [{ field: 'digitalAffinity.digitalRank', direction: SortDirection.asc }],
      }
    }

    if (includeDigitalAffinityOnTopicPercentage) {
      sortOptions.digitalAffinityOnTopicPercentage = {
        label: 'Digital On topic',
        sortBy: [{ field: 'digitalAffinity.digitalOnTopicPercentage', direction: SortDirection.desc }],
      }
    }

    if (includeDigitalAffinityOwnedContent) {
      sortOptions.digitalAffinityOwnedContent = {
        label: 'Digital Owned',
        sortBy: [{ field: 'digitalAffinity.totalOwnedContent', direction: SortDirection.desc }],
      }
    }

    if (includeDigitalAffinityEarnedContent) {
      sortOptions.digitalAffinityEarnedContent = {
        label: 'Digital Earned',
        sortBy: [{ field: 'digitalAffinity.totalEarnedContent', direction: SortDirection.desc }],
      }
    }

    if (includeDigitalAffinityTotalEarnedAndOwnedContent) {
      sortOptions.digitalAffinityTotalEarnedAndOwnedContent = {
        label: 'Digital Earned + Owned',
        sortBy: [{ field: 'digitalAffinity.totalEarnedAndOwnedContent', direction: SortDirection.desc }],
      }
    }

    if (includeInfluenceScoresOverall) {
      sortOptions.influenceScoresOverall = {
        label: 'Overall Network score',
        sortBy: [{ field: 'influenceScores.overall', direction: SortDirection.desc }],
      }
    }
    if (includeInfluenceScoresLocalityInternational) {
      sortOptions.influenceScoresLocalityInternational = {
        label: 'International network score',
        sortBy: [{ field: 'influenceScores.localityInternational', direction: SortDirection.desc }],
      }
    }
    if (includeInfluenceScoresLocalityNational) {
      sortOptions.influenceScoresLocalityNational = {
        label: 'National network score',
        sortBy: [{ field: 'influenceScores.localityNational', direction: SortDirection.desc }],
      }
    }

    return sortOptions
  }
}

const getDefaultSearchSortType = (router: NextRouter): SortType => {
  const pillsCategories = getPillsFromQuery(router.query).map((pill) => pill.category.toLowerCase())
  return contentSearchPillCategories.some((c) => pillsCategories.includes(c)) ? 'searchRank' : 'rank'
}

export const useSort = (isForSearchResults?: boolean): [SortType, (sortType: SortType) => void] => {
  const router = useRouter()
  const currentSort: SortType =
    (router.query.sort as SortType) || (isForSearchResults ? getDefaultSearchSortType(router) : DEFAULT_SORT_TYPE)
  const setSort = (sort: SortType) => {
    router.query.sort = sort
    void router.push(router, undefined, {
      shallow: true,
    })
  }

  return [currentSort, setSort]
}
