import { gql } from '@apollo/client'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import {
  GetSubscriptionsNamesQuery,
  GetSubscriptionsNamesQueryVariables,
} from './__generated__/GetSubscriptionsNamesQuery'

export const GET_SUBSCRIPTIONS_NAMES = gql`
  query GetSubscriptionsNamesQuery($listId: String!, $savedListId: String) {
    getSubscriptionById(listId: $listId, savedListId: $savedListId) {
      subsTree {
        id
        name
        subId
      }
    }
  }
`

export const getSubscriptionsNamesHandler: GraphqlHandler<
  GetSubscriptionsNamesQueryVariables,
  GetSubscriptionsNamesQuery
> = (req, res, ctx) => {
  if (
    req.variables.listId !== 'child-sub-1' &&
    req.variables.listId !== 'top-level-sub-1' &&
    req.variables.listId !== 'grandchild-sub-3' &&
    req.variables.listId !== 'grandchild-sub'
  )
    throw new Error(`No mocked response for listId ${req.variables.listId}`)

  const response: GetSubscriptionsNamesQuery = {
    getSubscriptionById: {
      subsTree: [
        { id: '123-123-123', name: 'Subscription name', subId: null },
        { id: '234-234-234', name: 'Parent name', subId: null },
        { id: '345-345-345', name: 'List name', subId: null },
      ],
    },
  }

  if (req.variables.listId === 'top-level-sub-1') {
    response.getSubscriptionById.subsTree = [{ id: 'top-level-sub-1', name: 'Top Level Sub', subId: null }]
  }

  return res(ctx.data(response))
}
