import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

export const HomePage: React.FC = () => {
  const router = useRouter()

  useEffect(() => {
    void router.push('/widgets')
  })

  return (
    <>
      <Head>
        <title>Pharmaspectra</title>
      </Head>
    </>
  )
}
