import React from 'react'
import styled from 'styled-components'

const StyledBackdrop = styled.div<{ isClickable: boolean }>`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: ${({ theme }) => theme.colors.always.alwaysOverlay};
  z-index: 1000;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`

export interface BackdropProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: () => void
}

export const Backdrop: React.FC<BackdropProps> = ({ onClick, children, ...rest }): JSX.Element => (
  <StyledBackdrop onClick={onClick} isClickable={!!onClick} data-testid="backdrop" {...rest}>
    {children}
  </StyledBackdrop>
)
