import 'core-js'
import axios from 'axios'
import React, { useEffect, useMemo, useRef } from 'react'
import { AppProps } from 'next/app'
import { getConfig } from '~config'
import Amplify from 'aws-amplify'
import { Config } from '~common'
import { Layout } from '~components/ui/layout'
import { Layout as WidgetLayout } from '~components/pages/widgets'
import * as Highcharts from 'highcharts'
import { ErrorBoundary } from '~components/error-boundary/error-boundary'
import { ApolloProvider } from '@apollo/client'
import { createClient } from '~apollo'
import { withAuthenticator } from 'aws-amplify-react'
import { Authentication } from '~components/ui/authentication'
import Head from 'next/head'
import { datadogRum, RumErrorEvent } from '@datadog/browser-rum'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ConfigProvider, PageRestrictionOpts, SessionProvider, UserSession } from '~common/hooks'
import { withFakeAuthentication } from '~components/ui/authentication/FakeAuthentication'
import { WithUseScopeToken } from '~common/hooks/use-scope-token'
import HighchartsTreeChart from 'highcharts/modules/treemap'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportingData from 'highcharts/modules/export-data'
import HighchartsStock from 'highcharts/modules/stock'
import '../index.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Logger } from '~utils/logger'
import { CustomCookieStorage } from '~common/auth/custom-cookie-storage'
import { withRedirectManager } from '~common/hooks/use-redirect-manager'
import { withRedirectErrorHandler } from '~components/ui/redirect-error-handler'

const shouldUseMocks = process.env.USE_MOCKS === 'true'
const checkVersionDuration = 1000 * 60

if (shouldUseMocks) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('../mocks')
}

const isDeployed = process.env.NODE_ENV === 'production' && process.env.ENV && process.env.IS_LOCAL !== 'true'

if (isDeployed) {
  const config = getConfig()

  datadogRum.init({
    site: 'datadoghq.com',
    applicationId: config.RUM_APP_ID,
    clientToken: config.RUM_CLIENT_TOKEN,
    service: `link-frontend-${process.env.ENV}`,
    version: `1.0.${config.GIT_HASH}`,
    sampleRate: 100,
    replaySampleRate: 100,
    env: process.env.ENV,
    trackInteractions: ['dev', 'prod'].includes(process.env.ENV as string),
    beforeSend: (event) => {
      if (event.type === 'error') {
        const err = (event as RumErrorEvent).error
        const isTestPageError = err.message === 'Throwing an error for testing purposes'
        if (isTestPageError) return false
      }

      return
    },
  })

  datadogRum.startSessionReplayRecording()
}

if (!shouldUseMocks) {
  const config = getConfig()

  const pdfUrl: Record<string, string> = {
    prod: `https://pdf.pharmaspectra.com`,
    dev: `https://pdf-dev.pharmaspectra.com`,
    sandbox: `https://pdf.sandbox.pharmaspectra.systems`,
  }

  const pptUrl: Record<string, string> = {
    prod: `https://ppt.pharmaspectra.com`,
    dev: `https://ppt-dev.pharmaspectra.com`,
    sandbox: `https://ppt.sandbox.pharmaspectra.systems`,
  }

  const excelUrl: Record<string, string> = {
    prod: `https://excel.pharmaspectra.com`,
    dev: `https://excel-dev.pharmaspectra.com`,
    sandbox: `https://excel.sandbox.pharmaspectra.systems`,
  }

  const docxUrl: Record<string, string> = {
    prod: `https://docx.pharmaspectra.com`,
    dev: `https://docx-dev.pharmaspectra.com`,
    sandbox: `https://docx.sandbox.pharmaspectra.systems`,
  }

  const amplifyConfig = {
    Auth: {
      region: config.GRAPHQL_REGION,
      userPoolId: config.USER_POOL_ID,
      userPoolWebClientId: config.USER_POOL_WEB_CLIENT_ID,
      ...(config.IS_EXTERNAL === 'true' ? {} : { storage: CustomCookieStorage }),
    },
    API: {
      endpoints: [
        {
          name: 'link-pdf-generator',
          endpoint: pdfUrl[config.ENV] ?? pdfUrl['dev'],
          region: 'us-east-1',
          paths: ['/'],
        },
        {
          name: 'link-ppt-generator',
          endpoint: pptUrl[config.ENV] ?? pptUrl['dev'],
          region: 'us-east-1',
          paths: ['/'],
        },
        {
          name: 'link-excel-generator',
          endpoint: excelUrl[config.ENV] ?? excelUrl['dev'],
          region: 'us-east-1',
          paths: ['/'],
        },
        {
          name: 'link-docx-generator',
          endpoint: docxUrl[config.ENV] ?? docxUrl['dev'],
          region: 'us-east-1',
          paths: ['/'],
        },
      ],
    },
  }

  Amplify.configure(amplifyConfig)
}

if (typeof Highcharts === 'object') {
  HighchartsTreeChart(Highcharts)
  HighchartsStock(Highcharts)
  HighchartsExporting(Highcharts)
  HighchartsExportingData(Highcharts)
  Highcharts.AST.allowedAttributes.push('title')
  Highcharts.AST.allowedAttributes.push('alt')
  Highcharts.AST.allowedAttributes.push('figure')
}

export interface CustomAppProps {
  props: { config: Config }
  pageProps: PageRestrictionOpts
  authData?: {
    username: string
    signInUserSession: UserSession
  }
}

let fetchCacheTimeout: NodeJS.Timeout

const CustomApp: React.FC<CustomAppProps & AppProps> = ({ Component, pageProps, authData }) => {
  const config = getConfig()
  const defaultTitle = 'Loading | Pharmaspectra'
  const trackers = useMemo(
    () => [config.GOOGLE_ANALYTICS_UA_ID, config.GOOGLE_ANALYTICS_GA4_ID],
    [config.GOOGLE_ANALYTICS_GA4_ID, config.GOOGLE_ANALYTICS_UA_ID],
  )

  useEffect(() => {
    fetchCacheTimeout = setInterval(() => checkVersionAndReload(config), checkVersionDuration)
    return () => {
      clearInterval(fetchCacheTimeout)
    }
  }, [config])

  const startVersionCheck = useRef(() => {
    /* noop */
  })
  const stopVersionCheck = useRef(() => {
    /* noop */
  })

  startVersionCheck.current = () => {
    if (window.navigator.onLine) {
      fetchCacheTimeout = setInterval(() => checkVersionAndReload(config), checkVersionDuration)
    }
  }

  stopVersionCheck.current = () => {
    clearInterval(fetchCacheTimeout)
  }

  useEffect(() => {
    window.addEventListener('focus', startVersionCheck.current)
    window.addEventListener('blur', stopVersionCheck.current)
  }, [])

  useEffect(() => {
    checkVersionAndReload(config)
  }, [config])

  const { sub, given_name, family_name, email } = authData?.signInUserSession?.idToken?.payload ?? {}
  useEffect(() => {
    if ([sub, given_name, family_name, email].every((v) => v == null)) {
      return
    }

    datadogRum.setUser({
      id: sub,
      name: `${given_name} ${family_name}`,
      email: email,
    })

    return () => {
      datadogRum.removeUser()
    }
  }, [sub, given_name, family_name, email])

  useEffect(() => {
    const titleElement = document.querySelector('head > title')
    if (!titleElement) return
    if (titleElement?.textContent !== defaultTitle) {
      trackers.forEach((tracker) =>
        gtag('config', tracker, {
          // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#default_behavior
          page_title: titleElement.textContent,
          page_path: global.location.pathname,
          page_location: global.location.href,
          user_id: authData?.username,
        }),
      )
    }

    const observer = new MutationObserver((mutations) => {
      const hasTitleChanged = mutations.some(
        (m) =>
          m.target === titleElement &&
          Array.from(m.addedNodes).some((n) => n.nodeName === '#text') &&
          titleElement.textContent !== defaultTitle,
      )
      if (!hasTitleChanged) return

      trackers.forEach((tracker) =>
        gtag('config', tracker, {
          // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#default_behavior
          page_title: titleElement.textContent,
          page_path: global.location.pathname,
          page_location: global.location.href,
          user_id: authData?.username,
        }),
      )
    })

    observer.observe(titleElement, { subtree: true, characterData: true, childList: true })
    return () => observer.disconnect()
  }, [trackers, authData?.username])

  return (
    <ConfigProvider config={config}>
      <Head>
        <title>{defaultTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0 maximum-scale=1" />
      </Head>
      <div id="modal"></div>

      <ApolloProvider client={createClient(config)}>
        <QueryClientProvider client={new QueryClient()}>
          {config.IS_EXTERNAL === 'true' ? (
            <WidgetLayout>
              <ErrorBoundary>
                <Component {...pageProps} />{' '}
              </ErrorBoundary>
            </WidgetLayout>
          ) : (
            <SessionProvider isPrototypePage={pageProps.isPrototypePage} session={authData?.signInUserSession}>
              <WithUseScopeToken>
                <Layout>
                  <ErrorBoundary>
                    <Component {...pageProps} />{' '}
                  </ErrorBoundary>
                </Layout>
              </WithUseScopeToken>
            </SessionProvider>
          )}
        </QueryClientProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}

const isExternal = getConfig().IS_EXTERNAL === 'true'
// NOTE: React.memo might be a cause for bugs in the future. We did this to fix dynamic
// pages rendering twice: https://github.com/vercel/next.js/issues/12010
export default withRedirectErrorHandler(
  shouldUseMocks
    ? React.memo(isExternal ? CustomApp : withFakeAuthentication(CustomApp))
    : React.memo(
        isExternal
          ? CustomApp
          : withRedirectManager(withAuthenticator(CustomApp, false, [<Authentication key="authentication" />])),
      ),
)

function checkVersionAndReload(config: Config): void {
  if (process.env.IS_LOCAL === 'true') {
    return
  }

  const { log } = new Logger('_app', config.LOG_LEVEL)
  axios
    .get(`${window.location.origin}/version.json`)
    .then(async (version) => {
      const gitHashFromFile = version.data.gitHash
      const gitHashFromConfig = config.GIT_HASH
      if (gitHashFromFile !== gitHashFromConfig) {
        log.warn('Git hash did not match!')
        log.debug({ gitHashFromFile })
        log.debug({ gitHashFromConfig })
        if ('caches' in window) {
          const cacheKeys = await window.caches.keys()
          await Promise.all(
            cacheKeys.map((key) => {
              void window.caches.delete(key)
            }),
          )
        }
        const params = new URLSearchParams(window.location.search)
        const refresh = params.get('refresh')
        if (!refresh) {
          const url = new URL(window.location.href)
          url.searchParams.set('refresh', 'true')
          window.location.replace(url)
        }
      }
    })
    .catch((err) => {
      log.error(err)
    })
}
