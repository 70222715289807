import * as Highcharts from 'highcharts'
import { DateBreakdown, getProductColors } from '~common'
import { lightModeColors } from '~common/theme'
import { getExportCategoryLabels } from './helpers'

export const getCommonChartOptions = (categories?: (number | string)[]): Highcharts.Options => ({
  title: {
    text: undefined,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  chart: {
    marginRight: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    style: {
      fontFamily: 'Inter var, sans-serif',
    },
  },
  tooltip: {
    style: {
      width: 88,
    },
    distance: 11,
    shadow: false,
    useHTML: true,
    outside: true,
    borderWidth: 0,
    padding: 0,
    className: 'custom-tooltip',
  },
  xAxis: {
    tickWidth: 1,
    tickLength: 4,
    lineColor: lightModeColors.neutrals.neutral20,
    lineWidth: 1,
    tickmarkPlacement: 'on',
    tickColor: lightModeColors.neutrals.neutral20,
    categories: categories?.map((i: number | string) => i.toString()),
    labels: {
      x: -2,
      formatter: function () {
        if (this.isLast || this.isFirst) {
          return `${this.value}`
        }
        return ''
      },
      skew3d: true,
      rotation: 0,
      style: {
        textOverflow: 'none',
      },
    },
    showLastLabel: true,
  },
  yAxis: {
    min: 0,
    title: {
      style: {
        display: 'none',
      },
    },
    tickAmount: 6,
  },
})

export const getCommonExportChartOptions = (
  data: (Highcharts.SeriesTreemapOptions | Highcharts.SeriesOptionsType)[],
  breakDownBy?: DateBreakdown,
): Highcharts.ExportingOptions => ({
  enabled: true,
  allowHTML: true,
  sourceWidth: 1920,
  sourceHeight: 1080,

  csv: {
    columnHeaderFormatter: function (_: Highcharts.SeriesOptionsType[], key: string) {
      if (key) return false
      return getExportCategoryLabels(breakDownBy)
    },
  },
  chartOptions: {
    title: {
      useHTML: true,
      style: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#000',
        fontWeight: '400',
      },
    },

    subtitle: {
      useHTML: true,
      verticalAlign: 'bottom',
      y: 35,
      style: {
        fontSize: '16px',
        color: '#000',
        fontWeight: '400',
        textAlign: 'center',
        width: 1600,
      },
    },

    legend: {
      squareSymbol: true,
      verticalAlign: 'bottom',
      enabled: true,
      width: 1200,
      itemWidth: 300,
      itemMarginBottom: 5,
      itemDistance: 10,
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      borderColor: 'none',
      align: 'center',
      y: 150,
      reversed: true,
      itemStyle: {
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: '400',
        textAlign: 'center',
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none',
            fontSize: '16px',
            fontWeight: '400',
          },
        },
      },
    },
    chart: {
      marginTop: 220,
      spacingTop: 65,
      spacingBottom: 170,
      style: {
        fontFamily: 'Inter var, sans-serif',
      },
    },
    colors: getProductColors(data.length),
  },
  buttons: {
    contextButton: {
      enabled: false,
    },
  },
})
