import { useEffect, useState } from 'react'

export const useViewportHeight = (): number => {
  const [viewPortHeight, setViewPortHeight] = useState<number>(0)

  useEffect(() => {
    const handler = () => {
      const viewPortHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      setViewPortHeight(viewPortHeight)
    }

    handler()

    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return viewPortHeight
}
