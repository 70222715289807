import { TooltipFormatterContextObject } from 'highcharts'
import React from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import { Mode, StyledScreenReadersOnly, TTrendDirection } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { Timeframe, TimeframeRange } from '../../components/timeframe'
import { StyledRankChart } from './styles'
import { useRankChartOptions } from './use-rank-chart-options'

export interface RankChartProps {
  setYearsRange: (timeframeRange: TimeframeRange) => void
  selectedYearsRange: TimeframeRange
  yearRanges: TimeframeRange[]
  categories: number[]
  regressionLineData: number[][]
  rankRegressionLineDirection: TTrendDirection
  rankData: {
    y: number
  }[]
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const RankChart = ({
  setYearsRange,
  selectedYearsRange,
  yearRanges,
  rankData,
  categories,
  regressionLineData,
  rankRegressionLineDirection,
  themeMode,
  themeBrand,
}: RankChartProps): JSX.Element => {
  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">Year</span> <span className="value">{context.key}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Rank</span> <span className="value">{context.y}</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const [options] = useRankChartOptions({
    data: rankData,
    categories,
    regressionLineData,
    rankRegressionLineDirection,
    themeBrand,
    toolTipFormatter,
  })

  return (
    <StyledRankChart>
      <Timeframe timeRanges={yearRanges} selectTimeRange={setYearsRange} selectedTimeRange={selectedYearsRange} />
      <HighchartsReact highcharts={Highcharts} options={options} />
      <StyledScreenReadersOnly>Rank line chart</StyledScreenReadersOnly>
    </StyledRankChart>
  )
}
