import { gql } from '@apollo/client'
import { calculateMentionsShare, MentionsBuilder } from '~apollo'
import {
  GetSoSVMeetingsQuery,
  GetSoSVMeetingsQueryVariables,
} from '~apollo/queries/sosv/meetings/__generated__/GetSoSVMeetingsQuery'
import { GraphqlHandler } from '~mocks/handlers/appsync-graphql'
import { GetSoSVMeetingInfoQuery, GetSoSVMeetingInfoQueryVariables } from './__generated__/GetSoSVMeetingInfoQuery'
import {
  GetSoSVGeographyMeetingsCountQuery,
  GetSoSVGeographyMeetingsCountQueryVariables,
} from '~apollo/queries/sosv/meetings/__generated__/GetSoSVGeographyMeetingsCountQuery'
import {
  GetSoSVMeetingsLocationsQuery,
  GetSoSVMeetingsLocationsQueryVariables,
} from '~apollo/queries/sosv/meetings/__generated__/GetSoSVMeetingsLocationsQuery'

export const GET_SOSV_MEETINGS = gql`
  query GetSoSVMeetingsQuery(
    $listId: String!
    $productGroupId: String
    $societyId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $weighted: Boolean
    $sortBy: String
    $pageSize: Int!
    $currentPage: Int!
    $source: String
    $savedListId: String
    $smartListId: String
    $geographyFilter: GeographyFilter
  ) {
    getSoSVMeetingsCount(
      listId: $listId
      productGroupId: $productGroupId
      societyId: $societyId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
      geographyFilter: $geographyFilter
    )
    getSoSVMeetings(
      listId: $listId
      productGroupId: $productGroupId
      societyId: $societyId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      weighted: $weighted
      sortBy: $sortBy
      pageSize: $pageSize
      currentPage: $currentPage
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
      geographyFilter: $geographyFilter
    ) {
      id
      name
      mentions {
        productName
        count
        countWeighted
        share
        shareWeighted
      }
    }
    getSoSVSortByDrugs(
      listId: $listId
      productGroupId: $productGroupId
      contentTypes: ["presentation"]
      weighted: $weighted
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      topicSelection: $topicSelection
      societyId: $societyId
      source: $source
      savedListId: $savedListId
      smartListId: $smartListId
      geographyFilter: $geographyFilter
    )
  }
`

export const GET_SOSV_MEETING_INFO = gql`
  query GetSoSVMeetingInfoQuery($listId: String!, $productGroupId: String, $meetingId: String!) {
    getSoSVMeetingInfo(listId: $listId, productGroupId: $productGroupId, meetingId: $meetingId) {
      id
      name
      date
      society {
        id
        name
      }
    }
  }
`

export const GET_SOSV_GEOGRAPHY_MEETINGS_COUNT = gql`
  query GetSoSVGeographyMeetingsCountQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $contentTypes: [String!]!
    $source: String
    $geographyFilter: GeographyFilter
    $pageSize: Int!
    $currentPage: Int!
  ) {
    getSoSVGeographyMeetingsCount(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      source: $source
      geographyFilter: $geographyFilter
      contentTypes: $contentTypes
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      city
      country
      state
      location {
        lon
        lat
      }
      meetingCount
    }
    getSoSVMeetingsLocationsCount(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      source: $source
      geographyFilter: $geographyFilter
      contentTypes: $contentTypes
    )
  }
`

export const GET_SOSV_MEETINGS_LOCATIONS = gql`
  query GetSoSVMeetingsLocationsQuery(
    $listId: String!
    $productGroupId: String
    $timeframe: String
    $startDate: String
    $endDate: String
    $drugSelection: [String!]
    $topicSelection: [String!]
    $contentTypes: [String!]!
    $source: String
    $geographyFilter: GeographyFilter
  ) {
    getSoSVMeetingsLocations(
      listId: $listId
      productGroupId: $productGroupId
      timeframe: $timeframe
      startDate: $startDate
      endDate: $endDate
      drugSelection: $drugSelection
      topicSelection: $topicSelection
      source: $source
      geographyFilter: $geographyFilter
      contentTypes: $contentTypes
    ) {
      type
      features {
        type
        geometry {
          type
          coordinates
        }
        properties {
          city
          country
          state
          meetingCount
        }
      }
    }
  }
`

export const soSVMeetingsMock: GetSoSVMeetingsQuery = {
  getSoSVMeetingsCount: 2,
  getSoSVMeetings: [
    {
      id: 'meeting1',
      name: 'Meeting 1',
      mentions: calculateMentionsShare([
        MentionsBuilder.random({ productName: 'Lantus' }),
        MentionsBuilder.random({ productName: 'Glooko' }),
      ]),
    },
    {
      id: 'meeting2',
      name: 'Meeting 2',
      mentions: calculateMentionsShare([MentionsBuilder.random({ productName: 'Glooko' })]),
    },
  ],
  getSoSVSortByDrugs: ['Lantus', 'Glooko', 'Soliqua', 'Toujeo', 'Tresiba', 'Victoza', 'Xultophy'],
}

export const soSVMeetingInfoMock: GetSoSVMeetingInfoQuery = {
  getSoSVMeetingInfo: {
    id: 'meeting1',
    name: 'Meeting 1',
    date: '2022-01-01 12:00:00',
    society: {
      id: 'society1',
      name: 'Society 1',
    },
  },
}

export const sosSVGeographyMeetingsCountMock: GetSoSVGeographyMeetingsCountQuery = {
  getSoSVGeographyMeetingsCount: [
    { city: 'San Diego', country: 'USA', state: 'CA', meetingCount: 53, location: { lat: 32.71568, lon: -117.16171 } },
    { city: 'Boston', country: 'USA', state: 'MA', meetingCount: 48, location: { lat: 42.35843, lon: -71.05977 } },
    {
      city: 'Vienna',
      country: 'Austria',
      state: null,
      meetingCount: 12,
      location: { lat: 48.2083537, lon: 16.3725042 },
    },
    { city: 'Berlin', country: 'Germany', state: null, meetingCount: 41, location: { lat: 52.51604, lon: 13.37691 } },
    {
      city: 'San Francisco',
      country: 'USA',
      state: 'CA',
      meetingCount: 40,
      location: { lat: 37.77712, lon: -122.41964 },
    },
  ],
  getSoSVMeetingsLocationsCount: 5,
}

export const sosvMeetingsLocationsMock: GetSoSVMeetingsLocationsQuery = {
  getSoSVMeetingsLocations: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-117.16171, 32.71568],
        },
        properties: {
          city: 'San Diego',
          country: 'USA',
          state: 'CA',
          meetingCount: 53,
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-71.05977, 42.35843],
        },
        properties: {
          city: 'Boston',
          country: 'USA',
          state: 'CA',
          meetingCount: 48,
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [16.3725042, 48.2083537],
        },
        properties: {
          city: 'Vienna',
          country: 'Austria',
          state: null,
          meetingCount: 12,
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [13.37691, 52.51604],
        },
        properties: {
          city: 'Berlin',
          country: 'Germany',
          state: null,
          meetingCount: 41,
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-122.41964, 37.77712],
        },
        properties: {
          city: 'San Francisco',
          country: 'USA',
          state: 'CA',
          meetingCount: 40,
        },
      },
    ],
  },
}

export const getSoSVMeetingsQueryHandler: GraphqlHandler<GetSoSVMeetingsQueryVariables, GetSoSVMeetingsQuery> = (
  req,
  res,
  ctx,
) => {
  return res(ctx.data(soSVMeetingsMock))
}

export const getSoSVMeetingInfoQueryHandler: GraphqlHandler<
  GetSoSVMeetingInfoQueryVariables,
  GetSoSVMeetingInfoQuery
> = (req, res, ctx) => {
  return res(ctx.data(soSVMeetingInfoMock))
}

export const getSoSVGeographyMeetingsCountQueryHandler: GraphqlHandler<
  GetSoSVGeographyMeetingsCountQueryVariables,
  GetSoSVGeographyMeetingsCountQuery
> = (req, res, ctx) => {
  return res(ctx.data(sosSVGeographyMeetingsCountMock))
}

export const getSoSVMeetingsLocationsHandler: GraphqlHandler<
  GetSoSVMeetingsLocationsQueryVariables,
  GetSoSVMeetingsLocationsQuery
> = (req, res, ctx) => {
  return res(ctx.data(sosvMeetingsLocationsMock))
}
