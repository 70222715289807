import React, { RefObject } from 'react'
import {
  StyledPageTitleBarInner,
  StyledPageTitleBarOuter,
  StyledHeading,
  StyledOptionalElementsOuter,
  StyledOptionalElementsInner,
  StyledStatsWithChildren,
} from './styles'

export interface PageTitleBarProps {
  isFullContentView: boolean
  titleBarHeadingText: string
  isSticky: boolean
  isTitleAsSubtitle?: boolean
  asTag?: string
  statistics?: React.ReactChild
  pageTitleSelect?: {
    element: React.ReactChild
    isSticky: boolean
  }
  pageTitleRef?: RefObject<HTMLElement>
}

export const PageTitleBar: React.FC<PageTitleBarProps> = ({
  titleBarHeadingText,
  statistics,
  pageTitleSelect,
  isFullContentView,
  isSticky,
  isTitleAsSubtitle,
  pageTitleRef,
  children,
  asTag = 'header',
}): JSX.Element => {
  const isOptionalElementsPassed = statistics || pageTitleSelect || children
  return (
    <>
      <StyledPageTitleBarOuter
        ref={pageTitleRef}
        as={asTag as never}
        isSticky={isSticky}
        isTitleAsSubtitle={isTitleAsSubtitle}
      >
        <StyledPageTitleBarInner isSticky={isSticky} isOptionalElementsPassed={!!isOptionalElementsPassed}>
          <StyledHeading
            isSticky={isSticky}
            isSelectSticky={pageTitleSelect?.isSticky}
            isTitleAsSubtitle={isTitleAsSubtitle}
            level={isTitleAsSubtitle ? '2' : '1'}
          >
            {titleBarHeadingText}
          </StyledHeading>
          {pageTitleSelect?.isSticky && isSticky && pageTitleSelect.element}
        </StyledPageTitleBarInner>
      </StyledPageTitleBarOuter>
      {(statistics || pageTitleSelect || children) && (
        <StyledOptionalElementsOuter isSticky={isSticky}>
          <StyledOptionalElementsInner isFullContentView={isFullContentView}>
            {children ? (
              <StyledStatsWithChildren>
                {statistics && statistics}
                {children}
              </StyledStatsWithChildren>
            ) : (
              statistics && statistics
            )}
            {pageTitleSelect && !pageTitleSelect?.isSticky && pageTitleSelect.element}
          </StyledOptionalElementsInner>
        </StyledOptionalElementsOuter>
      )}
    </>
  )
}
