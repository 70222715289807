import React from 'react'
import styled from 'styled-components'
import { Flex } from '~common'

const StyledList = styled(Flex).attrs({ as: 'ul' })`
  ${({ theme }) => theme.textSizes.desktop.label};
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.text.textSecondary};
  & > li:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.neutral40};
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }
  & > li:last-child {
    flex-grow: 1;
  }
`

export interface MetadataProps extends React.HTMLAttributes<HTMLUListElement> {
  primary?: React.ReactChild
  secondary?: React.ReactChild | null
}

export const Metadata = ({ primary, secondary, className }: MetadataProps): JSX.Element => (
  <StyledList className={className}>
    {primary && <li>{primary}</li>}
    {secondary && <li>{secondary}</li>}
  </StyledList>
)
