import React, { useEffect, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect } from '~common/hooks'
import { Button, ButtonProps } from '~components/ui/atoms/button'
import { ChevronIcon } from '~components/ui/atoms/chevron-icon'
import { DropdownOption } from '../dropdown'
import { StyledHoverMenuButton, StyledMenuDropdown } from './styles'

type Coords = Record<string, string | number>

export type MenuDropdownSize = 'sm' | 'md' | 'lg'

export interface HoverMenuButtonProps extends ButtonProps {
  title: string
  onSelectMenuOption: (option: DropdownOption) => void
  menuOptions: DropdownOption[]
  className?: string
  menuDropdownSize?: MenuDropdownSize
  menuDropdownTitle?: string
  menuOptionsActionButton?: {
    text: string
    onClick: () => void
  }
}

export const HoverMenuButton = ({
  title,
  menuOptions,
  onSelectMenuOption,
  menuDropdownTitle,
  menuOptionsActionButton,
  menuDropdownSize = 'sm',
  ...rest
}: HoverMenuButtonProps): JSX.Element => {
  const [buttonRect, setButtonRect] = useState<undefined | DOMRect>()
  const [dropdownWithOptionsRect, setDropdownWithOptionsRect] = useState<undefined | DOMRect>()
  const [isOverButton, setIsOverButton] = useState<boolean>(false)
  const [isOverList, setIsOverList] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isTouchInput, setIsTouchInput] = useState<boolean>()
  const [hasClicked, setHasClicked] = useState<boolean>()
  const [coords, setCoords] = useState<Coords>({})

  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownWithOptionsRef = useRef<HTMLUListElement>(null)

  const handleDropdownHeightCalculation = () => {
    const dropdownWithOptionsRect = dropdownWithOptionsRef.current?.getBoundingClientRect()
    const buttonRect = buttonRef.current?.getBoundingClientRect()
    setDropdownWithOptionsRect(dropdownWithOptionsRect)
    setButtonRect(buttonRect)
  }

  useEffect(() => {
    if (isOverButton && dropdownWithOptionsRect && buttonRect) {
      const coords = calcCoords(buttonRect, dropdownWithOptionsRect)
      setCoords(coords)
    }
  }, [dropdownWithOptionsRect, buttonRect, isOverButton])

  useEffect(() => handleDropdownHeightCalculation(), [isOverButton])

  useEffect(() => {
    window.addEventListener('resize', handleDropdownHeightCalculation, { passive: true })
    return () => window.removeEventListener('resize', handleDropdownHeightCalculation)
  })

  useIsomorphicLayoutEffect(() => {
    if (isOpen && !isOverButton && !isOverList && !isTouchInput) {
      buttonRef?.current?.click()
      setIsOpen(false)
    } else if (!isOpen && (isOverButton || isOverList) && !isTouchInput) {
      buttonRef?.current?.click()
      setIsOpen(true)
    }
  }, [isOpen, isOverButton, isOverList, isTouchInput])

  useEffect(() => {
    setIsTouchInput(false)
    setHasClicked(false)
  }, [hasClicked])

  const onSelectMenuOptionHandler = (option: DropdownOption) => {
    setIsOverList(false)
    onSelectMenuOption(option)
  }

  return (
    <StyledHoverMenuButton className={rest.className}>
      <Button
        {...rest}
        aria-haspopup={true}
        data-toggle="dropdown"
        aria-label="trigger menu options dropdown"
        icon={<ChevronIcon isOpened={true} position="right" />}
        iconPosition="right"
        buttonRef={buttonRef}
        onTouchStart={() => setIsTouchInput(true)}
        onMouseEnter={() => setIsOverButton(true)}
        onMouseLeave={() => setIsOverButton(false)}
        onKeyDown={() => setIsOpen(!isOpen)}
        onFocus={() => setIsOverButton(true)}
        onBlur={() => setIsOverButton(false)}
        onClick={(e) => {
          e.stopPropagation()
          setHasClicked(true)
          setIsOpen(!isOpen)
        }}
      >
        {title}
      </Button>
      <StyledMenuDropdown
        dropdownWithOptionsRef={dropdownWithOptionsRef}
        dropdownTitle={menuDropdownTitle}
        isOpen={isOpen}
        actionButton={menuOptionsActionButton}
        dropdownOptions={menuOptions}
        ariaLabel="menu options"
        size={rest.size}
        onMouseEnter={() => setIsOverList(true)}
        onMouseLeave={() => setIsOverList(false)}
        onBlur={() => setIsOverList(false)}
        onFocus={() => setIsOverList(true)}
        onSelectOption={onSelectMenuOptionHandler}
        menuDropdownSize={menuDropdownSize}
        style={{
          [`${coords.type === 'left' ? 'right' : 'left'}`]: 0,
        }}
      />
    </StyledHoverMenuButton>
  )
}

const calcCoords = (buttonRect, dropdownWithOptionsRect): Coords => {
  const docWidth = document.documentElement.clientWidth
  const isLeft =
    buttonRect.right + dropdownWithOptionsRect.width > docWidth &&
    buttonRect.left + dropdownWithOptionsRect.width > docWidth
  const coords: Coords = {}
  if (!isLeft) {
    coords.type = 'right'
  } else {
    coords.type = 'left'
  }
  return coords
}
