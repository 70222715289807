import styled from 'styled-components'
import { Flex, loadingStateBackgroundMixin } from '~common'

export const StyledLoadingListSection = styled(Flex)`
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: 1rem;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  width: 100%;
`

export const StyledLoadingListTitleBlock = styled.div`
  width: 8.125rem;
  height: 2rem;
  margin-bottom: 1rem;
  ${loadingStateBackgroundMixin}
`

export const StyledLoadingListContentBlock = styled.div<{ size?: 'sm' | 'md' | 'lg'; height?: string }>`
  width: 100%;
  height: ${({ size = 'sm', height }) => {
    if (height) return height
    if (size === 'sm') return '2.5rem'
    if (size === 'md') return '6.25rem'
    if (size === 'lg') return '100%'
  }};

  margin: 0 0 1rem 0;
  ${loadingStateBackgroundMixin}
`

export const StyledExpertSection = styled(Flex)<{ isTransparent: boolean }>`
  background-color: ${({ isTransparent, theme }) => !isTransparent && theme.colors.brand.backgroundSecondary};
  width: 100%;
  padding: ${({ isTransparent }) => !isTransparent && '1.5rem'};
  margin: ${({ isTransparent }) => !isTransparent && '1.5rem 0'};
`

export const StyledLoadingImageBlock = styled.div`
  width: 6.75rem;
  height: 6.75rem;
  flex-shrink: 0;
  margin-right: 1rem;
  ${loadingStateBackgroundMixin}
`

export const StyledLoadingTitleBlock = styled.div<{ marginBottomSize?: 'sm' | 'md' }>`
  width: 6.125rem;
  height: 1.5rem;
  margin-bottom: ${({ marginBottomSize }) => (marginBottomSize === 'sm' ? '1rem' : '1.5rem')};
  ${loadingStateBackgroundMixin}
`

export const StyledFlexColumnBlock = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

export const StyledLoadingSubtitleBlock = styled.div`
  max-width: 30.5rem;
  width: 100%;
  height: 3rem;
  ${loadingStateBackgroundMixin}
`

export const StyledContentLoadingBlock = styled.section`
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: 1rem auto 0;
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    padding: 0 1.5rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    max-width: ${({ theme }) => theme.pxsToEms(1236 + 24 + 24)};
  }
`

export const StyledChartLoadingBlock = styled.div<{ withNoPadding?: boolean }>`
  background-color: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  height: 14.25rem;
  padding: ${({ withNoPadding }) => (withNoPadding ? '0' : '0.5rem')};
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    height: 28.375rem;
    padding: ${({ withNoPadding }) => (withNoPadding ? '0' : '1rem')};
  }
`
