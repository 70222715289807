import styled from 'styled-components'
import { Theme } from '~common/theme'
import { TTrendDirection } from '~common/types'

const getColorForTrendDirection = (
  theme: Theme,
  trendDirection: TTrendDirection,
  mode: 'dafault' | 'reversable',
): string => {
  const trendColors: Record<TTrendDirection, Record<string, string>> = {
    up: {
      dafault: theme.colors.warnings.success,
      reversable: theme.colors.responsive.successOnRankHeader,
    },
    down: {
      dafault: theme.colors.warnings.alert,
      reversable: theme.colors.responsive.regressionOnRankHeader,
    },
    straight: {
      dafault: theme.colors.neutrals.neutral60,
      reversable: theme.colors.responsive.neutralOnRankHeader,
    },
  }
  return trendColors[trendDirection][mode]
}

export const StyledTrendDirectionSVG = styled.svg<{
  trendDirection: TTrendDirection
  isColorReversedOnMobile?: boolean
}>`
  margin-left: 0.25rem;
  fill: ${({ trendDirection, theme, isColorReversedOnMobile }) =>
    isColorReversedOnMobile
      ? getColorForTrendDirection(theme, trendDirection, 'reversable')
      : getColorForTrendDirection(theme, trendDirection, 'dafault')};

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    fill: ${({ trendDirection, theme }) => getColorForTrendDirection(theme, trendDirection, 'dafault')};
  }
`
