import React, { RefObject, useRef } from 'react'
import Highcharts, { TooltipFormatterContextObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  getProductColors,
  StyledScreenReadersOnly,
  CumulatingType,
  WeighteningType,
  DateInterval,
  Mode,
  roundDecimals,
} from '~common'
import { LinkThemeProvider } from '~common/theme'
import { StyledHighchartsTooltipDiv } from '~components/ui/organisms/charts/styles'
import { StyledProductsMentionsLineChart, StyledExportWrapper } from './styles'
import { useProductsMentionsLineChartOptions } from './use-products-mentions-line-chart-options'
import { getChartCategories } from '../../helpers'
import { ExportChartTrigger } from '../../components/export-chart-trigger'
import { ExportChartConfig } from '../../types'
import eachYearOfInterval from 'date-fns/eachYearOfInterval'

interface MentionsCount {
  productName: string
  counts: number[]
  countsWeighted: number[]
  cumulativeCounts: number[]
  cumulativeCountsWeighted: number[]
}

export interface ProductsMentionsLineChartProps {
  exportChartConfig?: ExportChartConfig
  sosvStats: MentionsCount[]
  dateInterval: DateInterval
  cumulatingType?: CumulatingType
  weighteningType?: WeighteningType
  themeMode: Mode
  themeBrand: 'pharmaspectra' | 'iqvia'
}

export const ProductsMentionsLineChart = ({
  sosvStats,
  dateInterval,
  cumulatingType = 'non-cumulative',
  weighteningType = 'unweighted',
  exportChartConfig,
  themeMode,
  themeBrand,
}: ProductsMentionsLineChartProps): JSX.Element => {
  const chartRef = useRef<{ chart: Highcharts.Chart; container: RefObject<HTMLDivElement> }>(null)
  const yearsRange = eachYearOfInterval({ start: dateInterval.startDate, end: dateInterval.endDate }).map((date) =>
    date.getFullYear(),
  )
  const categories = getChartCategories(dateInterval, yearsRange)
  const productMentionsSeries: Highcharts.SeriesOptionsType[] =
    sosvStats.map((el, i, arr) => {
      const data = [
        ...(cumulatingType === 'non-cumulative'
          ? weighteningType === 'unweighted'
            ? el.counts
            : el.countsWeighted
          : weighteningType === 'unweighted'
          ? el.cumulativeCounts
          : el.cumulativeCountsWeighted),
      ]
      return {
        name: el.productName,
        data,
        className: `product-${i}`,
        type: 'area',
        showInLegend: !!data.length,
        lineColor: getProductColors(arr.length, themeBrand).reverse()[i],
      }
    }) || []

  const toolTipFormatter = (context: TooltipFormatterContextObject) => {
    const tooltipElement = (
      <LinkThemeProvider mode={themeMode} brand={themeBrand}>
        <StyledHighchartsTooltipDiv>
          <div className="highcharts-tooltip">
            <div className="key-value-wrapper">
              <span className="key">Product</span> <span className="value">{context.series.name}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Timeframe</span> <span className="value">{context.key}</span>
            </div>
            <div className="key-value-wrapper">
              <span className="key">Count</span>{' '}
              <span className="value">{context.y && context.y != 0 ? roundDecimals(context.y, 1) : ''}</span>
            </div>
          </div>
        </StyledHighchartsTooltipDiv>
      </LinkThemeProvider>
    )

    return renderToStaticMarkup(tooltipElement)
  }

  const [options] = useProductsMentionsLineChartOptions({
    data: productMentionsSeries,
    categories,
    exportChartConfig,
    dateInterval,
    themeBrand,
    toolTipFormatter,
  })

  return (
    <StyledProductsMentionsLineChart
      productColors={getProductColors(productMentionsSeries.length, themeBrand)}
      series={productMentionsSeries}
    >
      {!!exportChartConfig && (
        <StyledExportWrapper>
          <ExportChartTrigger exportTypes={exportChartConfig.exportFormatTypes} chartRef={chartRef} />
        </StyledExportWrapper>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        ref={chartRef}
        containerProps={{ style: { height: '100%' } }}
      />
      <StyledScreenReadersOnly>Product mentions count line chart</StyledScreenReadersOnly>
    </StyledProductsMentionsLineChart>
  )
}
