import React from 'react'
import { GetSoSVSocietiesQuery } from '~apollo/queries/sosv/societies/__generated__/GetSoSVSocietiesQuery'
import { formatUrl, generateSoSVModuleTitle, Mode, SoSVTimeframe } from '~common'
import { LinkThemeProvider } from '~common/theme'
import { getMentions } from '~components/pages/sosv-hub/helpers'
import { StyledRecentMeetingKeyValuePair } from '~components/pages/sosv-hub/styles'
import { ChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { ChartLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { CollectionTeaser } from '~components/ui/molecules/collection-teaser'
import { Module } from '~components/ui/molecules/module'
import { CollectionsProductsMentionsStackedBarChart } from '~components/ui/organisms/charts'
import { useFeatures } from '~common/hooks/use-features'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'

export const TopSocieties: React.FC<{
  styleMode: Mode
  data?: GetSoSVSocietiesQuery
  isLoading?: boolean
  subscriptionName?: string
  listName?: string
}> = ({ data, isLoading, styleMode }) => {
  const { isIqviaBrand, showSosvChartDataExport } = useFeatures()
  const themeBrand = isIqviaBrand ? 'iqvia' : 'pharmaspectra'

  const {
    listId,
    rootListId,
    productGroupId,
    source,
    weighteningType,
    contentTypes,
    timeframe,
    cumulatingType,
    graphType,
    drugSelection = [],
    rootListName: subscriptionName = '',
    listName = '',
    startDate,
    endDate,
  } = useSoSVState()

  const fullVersionUrl = (societyId?: string): string => {
    const subpage = societyId ? '/sosv/societies/details' : '/sosv/societies'

    return formatUrl(getPlatformUrl() + subpage, {
      listId,
      rootSubscriptionId: rootListId,
      weighteningType,
      productGroupId: productGroupId,
      sosvTimeframe: timeframe,
      sosvContentTypes: contentTypes,
      drugSelection,
      expertsSortBy: 'impactScore',
      cumulatingType,
      graphType,
      dataSet: source,
      societyId,
      startDate,
      endDate,
    })
  }

  const hasSoSVSocieties = (data?.getSoSVSocietiesCount ?? 0) > 0

  if (isLoading) return <ChartLoadingBlock />

  return (
    <Module.Container>
      <Module.Header
        linkText={hasSoSVSocieties ? 'View all' : undefined}
        linkTo={hasSoSVSocieties ? fullVersionUrl() : undefined}
      >
        {generateSoSVModuleTitle({
          moduleType: 'societies',
          sosvTimeframe: SoSVTimeframe.ThreeYears,
          weighteningType,
          top: 5,
        })}
      </Module.Header>

      {!!data?.getSoSVSocietiesCount ? (
        <CollectionsProductsMentionsStackedBarChart
          weighteningType={'weighted'}
          exportChartConfig={
            showSosvChartDataExport
              ? {
                  collectionName: 'societies',
                  subscriptionName,
                  listName,
                  sosvTimeframe: SoSVTimeframe.ThreeYears,
                  contentTypes: ['presentation'],
                  weighteningType,
                }
              : undefined
          }
          collections={data.getSoSVSocieties.map(({ id, name, mostRecentMeeting }) => ({
            id,
            title: name,
            collectionUI: (
              <LinkThemeProvider mode={styleMode} brand={themeBrand}>
                <CollectionTeaser heading={name} linkTo={fullVersionUrl(id)}>
                  <StyledRecentMeetingKeyValuePair
                    keyValueDivider=":"
                    pairs={[
                      {
                        key: 'Recent meeting',
                        value: (
                          <CustomLink href={fullVersionUrl(id)} title={mostRecentMeeting.name}>
                            {mostRecentMeeting.name}
                          </CustomLink>
                        ),
                      },
                    ]}
                  />
                </CollectionTeaser>
              </LinkThemeProvider>
            ),
          }))}
          showLegend={true}
          categories={data.getSoSVSocieties.map(({ id }) => id)}
          mentions={getMentions(drugSelection, data.getSoSVSocieties)}
          themeMode={styleMode}
          themeBrand={themeBrand}
        />
      ) : (
        <ChartEmptyState isStackedBar />
      )}
      {hasSoSVSocieties ? (
        <Module.Footer
          links={[
            {
              name: `View all ${data?.getSoSVSocietiesCount} societies`,
              id: 'societies',
              href: fullVersionUrl(),
            },
          ]}
        />
      ) : null}
    </Module.Container>
  )
}
