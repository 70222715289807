/* eslint-disable @typescript-eslint/ban-types */

/** Only for use in spec.ts files */
export const randomString = (prefix: string): string => {
  return `${prefix}-${Math.random().toString(36).substring(7)}`
}

export const randomStrings = (prefix: string, length: number): string[] => {
  return new Array(length).fill(0).map((_, index) => randomString(`${prefix}-${index + 1}`))
}

export const randomUrl = (domain: string): string => {
  return `http://${randomString(domain)}.pharma.com`
}

export const randomHCPIdentifier = (): string => {
  const hcpTypes = ['NPI', 'GMC', 'MCR', 'HPI-I', 'unknown']
  return hcpTypes[Math.floor(Math.random() * hcpTypes.length)]
}

export const randomStringCodingBoolean = (): string | undefined => {
  const values = [undefined, ' ', 'Y', 'N']
  return values[randomNumber(0, values.length)]
}

/**
 * @param min is inclusive
 * @param max is exclusive */
export const randomNumber = (min = 0, max = 1000): number => {
  return Math.floor(Math.random() * (max - min) + min)
}

export const roundTo = (value: number, decimalPlaces = 2): number => {
  const factor = 10 ** decimalPlaces
  return Math.round((value + Number.EPSILON) * factor) / factor
}

export const randomFloat = (min = 0.0, max = 1000.0, decimalPlaces?: number): number => {
  const value = Math.random() * (max - min) + min
  return decimalPlaces ? roundTo(value, decimalPlaces) : value
}

export const randomISODate = (): string => {
  return randomDate().toISOString()
}

export const randomLatitude = (): number => {
  return parseFloat(randomFloat(-90, 90).toFixed(4))
}

export const randomLongitude = (): number => {
  return parseFloat(randomFloat(-180, 180).toFixed(4))
}

interface RandomDateArgs {
  minYear?: number
  maxYear?: number
  year?: number
  /** beware, this is 0 indexed just like normal JS Date object! */
  month?: number
  day?: number
}
export const randomDate = (args?: RandomDateArgs): Date => {
  const year = args?.year ?? randomNumber(args?.minYear ?? 1980, args?.maxYear ?? 2050)
  const month = args?.month ?? randomNumber(0, 11)
  const day = args?.day ?? randomNumber(0, 31)
  return new Date(Date.UTC(year, month, day))
}

export const randomDateOnly = (args?: RandomDateArgs): string => {
  return randomDate(args).toLocaleDateString()
}

export const randomLengthArray = <TOut>(length = randomNumber(0, 10), map?: (index: number) => TOut): TOut[] => {
  const array = Array(length).fill(null)
  return map ? array.map((_, i) => map(i)) : array
}

export const randomArrayOf = <TOut>(f: (i: number) => TOut, length = randomNumber(0, 10)): TOut[] =>
  Array.from({ length }, (_, i) => f(i))

export const arrayOf = <TOut>(f: (i: number) => TOut, length: number): TOut[] => randomArrayOf(f, length)

export const randomNumberArray = (length: number, min?: number, max?: number): number[] => {
  return Array(length)
    .fill(null)
    .map(() => randomNumber(min, max))
}

export const randomStringArray = (prefix: string): string[] =>
  randomLengthArray().map((_, i) => randomString(`${prefix}-${i}`))

export const randomBool = (): boolean => !!randomNumber(0, 2)

export const randomError = (prefix = 'error'): Error => new Error(randomString(prefix ?? ''))

export const randomItemFrom = <T>(items: ReadonlyArray<T>, predicate?: (item: T) => boolean): T => {
  for (let attempt = 0; attempt < 1000; attempt++) {
    const item = items[randomNumber(0, items.length)]
    if (!predicate || predicate(item)) return item
  }
  throw new Error('Have you excluded everything by accident?')
}

export const randomItemFromEnum = <T extends Record<string, string | number>>(theEnum: T): T[keyof T] => {
  const enumValues = Object.values(theEnum) as T[keyof T][]
  return randomItemFrom(enumValues)
}

export const randomItemFromEnumExcluding = <T extends Record<string, string | number>>(
  theEnum: T,
  itemToExclude: T[keyof T],
): T[keyof T] => {
  const enumValues = Object.values(theEnum) as T[keyof T][]
  return randomItemFromListExcluding(enumValues, itemToExclude)
}

export const uniqueEnumArray = <T extends Record<string, string | number>>(theEnum: T): T[keyof T][] => {
  return Array.from(new Set(randomArrayOf(() => randomItemFromEnum(theEnum))))
}

export const randomItemFromListExcluding = <T>(items: ReadonlyArray<T>, itemToExclude: T): T => {
  return randomItemFrom(items, (item) => item !== itemToExclude)
}

export const randomItemFromListExcludingList = <T>(items: ReadonlyArray<T>, itemsToExclude: Array<T>): T => {
  return randomItemFrom(items.filter((item) => itemsToExclude.forEach((toExclude) => toExclude !== item)))
}

export const eitherOr = <A, B>(a: A, b: B): A | B => {
  return randomItemFrom([a, b])
}

export const thingOrUndefined = <T>(thing: T): T | undefined => eitherOr(thing, undefined)
export const thingOrNull = <T>(thing: T): T | null => eitherOr(thing, null)
export const thingOrEmptyString = <T>(thing: T): T | '' => eitherOr(thing, '')

export const randomToYear = randomNumber(1980, 2021)
export const randomFromYear = randomToYear - randomNumber(1, 10)
export const maybeYearOrRange = randomItemFrom([undefined, String(randomToYear), randomFromYear + '-' + randomToYear])

/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
}

export const mockObj = <T>(thing: Partial<T>): jest.Mocked<T> => thing as jest.Mocked<T>

export const mockCtor = (target: jest.Constructable) => target as jest.MockedClass<typeof target>

export const mocked = <T extends (...args: any[]) => ReturnType<T>>(fn: T) => fn as jest.MockedFunction<T>

type MockExtensions<T extends (...args: any[]) => ReturnType<T>> = {
  mockReturnValueTimes(value: ReturnType<T>, times: number): MockFn<T>
  mockResolvedValueTimes(value: jest.ResolvedValue<ReturnType<T>>, times: number): MockFn<T>
}

export type MockFn<T extends (...args: any[]) => ReturnType<T>> = (
  | jest.Mock<ReturnType<T>, Parameters<T>>
  | jest.MockedFunction<T>
) &
  MockExtensions<T>

export function mockFn<T extends (...args: any[]) => ReturnType<T>>(): jest.Mock<ReturnType<T>, Parameters<T>> &
  MockExtensions<T>
export function mockFn<T extends (...args: any[]) => ReturnType<T>>(fn: T): jest.MockedFunction<T> & MockExtensions<T>
export function mockFn<T extends (...args: any[]) => ReturnType<T>>(fn?: T): MockFn<T> {
  const mockedFn = fn ? (fn as jest.MockedFunction<T>) : jest.fn<ReturnType<T>, Parameters<T>>()
  // @ts-expect-error no idea hot to fix properly
  mockedFn.mockReturnValueTimes = (value: ReturnType<T>, times: number) => {
    for (let i = 0; i < times; i++) {
      mockedFn.mockReturnValueOnce(value)
    }
    return mockedFn
  }

  // @ts-expect-error no idea hot to fix properly
  mockedFn.mockResolvedValueTimes = (value: jest.ResolvedValue<ReturnType<T>>, times: number) => {
    for (let i = 0; i < times; i++) {
      mockedFn.mockResolvedValueOnce(value)
    }
    return mockedFn
  }

  // @ts-expect-error no idea hot to fix properly
  return mockedFn
}

export const to2d = <T>(thing: T): [T] => [thing]

interface RetryOpts<T> {
  assertion?: (item: T) => void | never | Promise<void | never>
  maxTries?: number
  delayMs?: number
}

export const delay = (delayMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, delayMs)
  })
}

export const retry = async <T>(
  fn: () => Promise<T> | T,
  { assertion, maxTries = 20, delayMs = 250 }: RetryOpts<T> = {},
): Promise<T> => {
  let attemptNo = 0
  return (async function makeAttempt(): Promise<T> {
    attemptNo += 1

    try {
      const result = await fn()
      await assertion?.(result)
      return result
    } catch (err) {
      if (attemptNo === maxTries) throw err
      await new Promise((resolve) => setTimeout(resolve, delayMs))
      return makeAttempt()
    }
  })()
}

export const cases = <T extends { map: Function; [index: number]: any }>(items: T): [T[number]][] => {
  return items.map(to2d)
}
