import { Config } from '~common'

export const getConfig = (): Config => {
  const config: Config = {
    // these are coming from next.config.js at build time
    GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT as string,
    EE_GRAPHQL_ENDPOINT: (process.env.EE_GRAPHQL_ENDPOINT || process.env.GRAPHQL_ENDPOINT) as string,
    GRAPHQL_REGION: process.env.GRAPHQL_REGION as string,
    USER_POOL_ID: process.env.USER_POOL_ID as string,
    USER_POOL_WEB_CLIENT_ID: process.env.USER_POOL_WEB_CLIENT_ID as string,
    CONTRIBUTIONS_API_URL: process.env.CONTRIBUTIONS_API_URL as string,
    CONTRIBUTIONS_API_KEY: process.env.CONTRIBUTIONS_API_KEY as string,
    RUM_APP_ID: process.env.RUM_APP_ID as string,
    RUM_CLIENT_TOKEN: process.env.RUM_CLIENT_TOKEN as string,
    GOOGLE_ANALYTICS_UA_ID: process.env.GOOGLE_ANALYTICS_UA_ID as string,
    GOOGLE_ANALYTICS_GA4_ID: process.env.GOOGLE_ANALYTICS_GA4_ID as string,
    GIT_HASH: process.env.GIT_HASH as string,
    ENV: process.env.ENV as string,
    LOG_LEVEL: process.env.LOG_LEVEL ?? 'INFO',
    IS_EXTERNAL: (process.env.IS_EXTERNAL as string) ?? 'false',
    VERIFY_EMBEDDED_WIDGET_API_URL:
      (process.env.VERIFY_EMBEDDED_WIDGET_API_URL as string) ?? 'http://VERIFY_EMBEDDED_WIDGET_API_URL',
    PLI_URL: process.env.PLI_URL ?? 'https://tl360.rivermark.biz',
  }

  Object.entries(config).forEach(([key, value]) => {
    if (!value || value === 'null') {
      throw new Error(
        `getConfig failed - environment variable ${key} is missing. Make sure you have added the environment variable to config.ts and next.config.js`,
      )
    }
  })

  return { ...config }
}
