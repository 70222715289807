import { useRouter } from 'next/router'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import React, { Dispatch, useEffect, useReducer } from 'react'
import {
  GetSoSVConfigsQuery,
  GetSoSVConfigsQueryVariables,
} from '~apollo/queries/sosv/configs/__generated__/GetSoSVConfigsQuery'
import { GET_SOSV_CONFIGS } from '~apollo/queries/sosv/configs/query'
import { GET_SUBSCRIPTIONS_FOR_USER } from '~apollo/queries/subscriptions/query'
import { GetSubscriptionsForUserQuery } from '~apollo/queries/subscriptions/__generated__/GetSubscriptionsForUserQuery'
import { getModifiedSubscriptionsWithRootAndListIds } from '~common/helpers/common-link-helpers'
import { Subscription } from '~common/types/common-link-types'
import { useFeatures } from '~common/hooks/use-features'
import { useListId } from '~common/hooks/use-list-id'
import { PERMISSION_ERROR } from '~components/pages/error/ServerError'
import {
  ExpertsHubAction as Action,
  ExpertsHubState,
} from '../../../components/pages/experts-hub/experts-hub-state-managment/types'
import { actionCreators, expertsHubReducer } from '../../../components/pages/experts-hub/experts-hub-state-managment'
import { useSession } from '~common/hooks'

export interface UseExpertsHubReturn {
  expertsHubState: ExpertsHubState
}

export const useHubs = (setError?: Dispatch<Error | undefined>): UseExpertsHubReturn => {
  const router = useRouter()
  const listId = useListId() as string
  const { rootSubscriptionId, productGroupId, smartListId, savedListId } = router.query as {
    rootSubscriptionId: string
    savedListId: string
    smartListId: string
    productGroupId: string
  }

  const userEmail = useSession()?.idToken.payload.email ?? ''

  const { featuresForUserResponse, sosvEnabledForUser, showClientNames } = useFeatures()

  const [expertsHubState, expertsHubDispatch]: [ExpertsHubState, React.Dispatch<Action>] = useReducer(
    expertsHubReducer,
    {
      hasInitialised: false,
      rootSubscriptionId: '',
      listId: '',
      showClientNames: false,
      subscriptions: [],
      lists: [],
      savedLists: [],
      smartLists: {},
      myExpertsLists: [],
      sosvProductGroups: [],
      productGroupId: '',
      sosvProducts: [],
      permissionsForSelectedSubscription: [],
      hasSoSVExpertsForSelectedList: false,
      userEmail,
    },
  )

  const [getSoSVConfig, { data: sosvConfigs, error: sosvConfigsError }] = useLazyQuery<
    GetSoSVConfigsQuery,
    GetSoSVConfigsQueryVariables
  >(GET_SOSV_CONFIGS)

  const { data: subscriptionsData, error: subscriptionsDataError } =
    useQuery<GetSubscriptionsForUserQuery>(GET_SUBSCRIPTIONS_FOR_USER)

  useEffect(() => {
    if (!subscriptionsData?.getSubscriptionsForUser || featuresForUserResponse.loading) return

    const subscriptions = subscriptionsData?.getSubscriptionsForUser as Subscription[]

    if (sosvEnabledForUser) {
      const { selectedListId } = getModifiedSubscriptionsWithRootAndListIds({
        subscriptions,
        rootSubscriptionId,
        listId,
      })

      void getSoSVConfig({
        variables: {
          listId: selectedListId,
        },
      })
    } else {
      actionCreators.initialise({
        expertsHubDispatch,
        listId,
        rootSubscriptionId,
        showClientNames,
        subscriptions,
        savedListId,
        smartListId,
        userEmail,
      })
    }
  }, [
    rootSubscriptionId,
    listId,
    savedListId,
    smartListId,
    featuresForUserResponse.loading,
    sosvEnabledForUser,
    showClientNames,
    subscriptionsData?.getSubscriptionsForUser,
    getSoSVConfig,
    userEmail,
  ])

  const hasSoSVConfigsPermissionError = (error: ApolloError | undefined): boolean => {
    return error?.message?.includes(PERMISSION_ERROR) ?? false
  }

  useEffect(() => {
    const hasPermissionError = hasSoSVConfigsPermissionError(sosvConfigsError)
    if (!sosvConfigs?.getSoSVConfigs && !hasPermissionError) return

    if (hasPermissionError) {
      actionCreators.initialise({
        expertsHubDispatch,
        listId,
        rootSubscriptionId,
        showClientNames,
        subscriptions: subscriptionsData?.getSubscriptionsForUser as Subscription[],
        savedListId,
        smartListId,
        userEmail,
      })
    } else {
      const sosvProductGroups = sosvConfigs?.getSoSVConfigs?.productGroups
      const currentProductGroupId = productGroupId ?? sosvProductGroups?.[0]?.id

      actionCreators.initialise({
        expertsHubDispatch,
        listId,
        rootSubscriptionId,
        showClientNames,
        sosvProductGroups: sosvProductGroups
          ? sosvProductGroups.map((pg) => ({ id: pg.id, name: pg.name, hidden: pg.hidden ?? false }))
          : [],
        sosvProducts:
          sosvProductGroups?.length && currentProductGroupId
            ? sosvProductGroups?.find((pg) => pg.id === currentProductGroupId)?.products.map((product) => product.label)
            : [],
        productGroupId: currentProductGroupId,
        subscriptions: subscriptionsData?.getSubscriptionsForUser as Subscription[],
        savedListId,
        smartListId,
        userEmail,
      })
    }
  }, [
    rootSubscriptionId,
    listId,
    savedListId,
    smartListId,
    productGroupId,
    showClientNames,
    sosvConfigs?.getSoSVConfigs,
    sosvConfigsError,
    subscriptionsData?.getSubscriptionsForUser,
    userEmail,
  ])

  let error = subscriptionsDataError || featuresForUserResponse.error
  if (!error && hasSoSVConfigsPermissionError(sosvConfigsError)) error = sosvConfigsError

  useEffect(() => {
    if (setError) {
      setError(error)
    }
  }, [error, setError])

  return {
    expertsHubState,
  }
}
