import React from 'react'
import { TTrendDirection } from '~common'
import { StyledTrendDirectionSVG } from './styles'

export interface TrendDirectionProps {
  trendDirection: TTrendDirection
  isColorReversedOnMobile?: boolean
}

export const TrendDirection = ({ trendDirection, isColorReversedOnMobile }: TrendDirectionProps): JSX.Element => {
  const paths = {
    up: <path d="M0 2L0 0L12 0L12 12H10L10 3.41406L2.10042 11.3137L0.686203 9.89954L8.58571 2L0 2Z" />,
    down: (
      <path d="M10 -8.74228e-08L12 0L12 12L-5.24537e-07 12L-4.37114e-07 10L8.58594 10L0.686279 2.10042L2.10046 0.686203L10 8.58571L10 -8.74228e-08Z" />
    ),
    straight: <rect y="5" width="12" height="2" />,
  }

  return (
    <StyledTrendDirectionSVG
      isColorReversedOnMobile={isColorReversedOnMobile}
      aria-label={`trend direction - ${trendDirection}`}
      trendDirection={trendDirection}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {paths[trendDirection]}
    </StyledTrendDirectionSVG>
  )
}
