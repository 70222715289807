import React, { useEffect } from 'react'
import { useFeatures } from '~common/hooks'
import { useSoSVDetails } from '~components/pages/widgets/sosv/hooks/use-sosv-details'
import { SoSVGrid } from '~components/ui/templates/sosv-grid'
import {
  generateSoSVModuleTitle,
  getProductColors,
  SoSVContentType,
  SoSVTimeframe,
  Statistic,
  StyledReadingPaneTitle,
  StyledSection,
} from '~common'
import { StyledStatisticsBarItems, StyledStatsWithExportButtonSection } from '~components/pages/sosv-hub/styles'
import { StyledLoadingSubtitleBlock } from '~components/ui/atoms/loading-blocks/pages/styles'
import { Module } from '~components/ui/molecules/module'
import {
  ProductsMentionsColumnChart,
  ProductsMentionsLineChart,
  ProductsMentionsTreemapChart,
} from '~components/ui/organisms/charts'
import { ChartEmptyState, TreemapChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { EventBus } from '~components/pages/widgets/use-frame-message-bus'
import { FrameEvents } from '~components/pages/widgets/sosv/types'
import { StatisticsBar } from '~components/ui/molecules/statistics-bar'
import { getDateInterval } from '~components/pages/helpers'
import { useMode } from '~common/hooks/use-mode'
import { TopExperts } from '~components/pages/widgets/sosv/content/TopExperts'
import { TopJournals } from '~components/pages/widgets/sosv/content/TopJournals'
import { TopSocieties } from '~components/pages/widgets/sosv/content/TopSocieties'
import { TopGeographies } from '~components/pages/widgets/sosv/content/TopGeographies'
import { TopExpertsByGeography } from '~components/pages/widgets/sosv/content/TopExpertsByGeography'
import { TopSocietyEventsByLocation } from '~components/pages/widgets/sosv/content/TopSocietyEventsByLocation'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'
import { JournalArticles } from '~components/pages/widgets/sosv/content/JournalArticles'
import { Presentations } from '~components/pages/widgets/sosv/content/Presentations'

export const Content: React.FC<{ frameMessageBus: EventBus<FrameEvents> }> = ({ frameMessageBus }) => {
  const [styleMode] = useMode()
  const { isIqviaBrand, showSosvChartDataExport } = useFeatures()
  const themeBrand = isIqviaBrand ? 'iqvia' : 'pharmaspectra'

  const state = useSoSVState()
  const {
    productGroupId,
    timeframe,
    rootListName: subscriptionName,
    weighteningType,
    contentTypes,
    source,
    graphType,
    cumulatingType,
    expertsCount: totalExpertsCount,
    listName,
    productGroups,
  } = state

  const {
    isLoading,
    mentionsBreakdownData,
    totalContributionsCount,
    documentsCountsData,
    topExpertsData,
    topSocietiesData,
    topJournalsData,
    topGeographiesData,
    mapState,
    mapDispatch,
    topMeetingsLocationsData,
    topJournalArticlesData,
    topPresentationsData,
  } = useSoSVDetails(state)

  useEffect(() => {
    if (window && window.parent) {
      frameMessageBus.emit(window.parent, 'dataLoading', {
        isLoading,
      })
    }
  }, [frameMessageBus, isLoading])

  const mentionsBreakdown = mentionsBreakdownData?.getSoSVMentionsBreakdown ?? []
  const matchingContentByContentType = documentsCountsData?.getSoSVDocumentsCounts.matchingContentByContentType
  const presentationMatchingCount =
    matchingContentByContentType?.find((c) => c.contentType === SoSVContentType.presentation)?.count || 0
  const journalArticleMatchingCount =
    matchingContentByContentType?.find((c) => c.contentType === SoSVContentType.journalArticle)?.count || 0

  const productGroup = productGroups.find((group) => group.id === productGroupId)
  const productColors = getProductColors(productGroup?.products?.length || 0, themeBrand).reverse()

  const isSoSVForExperts = source === 'experts'

  return (
    <SoSVGrid.Container>
      <SoSVGrid.Item itemWidth="widest" isTransparent={true} asTag="header">
        <StyledReadingPaneTitle level="1">{listName ?? ''}</StyledReadingPaneTitle>
        {!isLoading ? (
          <StyledStatsWithExportButtonSection>
            <StyledStatisticsBarItems
              statistics={
                [
                  isSoSVForExperts
                    ? {
                        name: 'Total experts',
                        value: (totalExpertsCount ?? 0).toString(),
                      }
                    : undefined,
                  totalContributionsCount
                    ? {
                        name: 'Total contributions',
                        value: totalContributionsCount.toString(),
                      }
                    : undefined,
                ].filter(Boolean) as Statistic[]
              }
            />
            <StatisticsBar.Items
              statistics={
                [
                  isSoSVForExperts
                    ? {
                        name: 'Experts with mentions',
                        value: (topExpertsData?.getSoSVHubExpertsCount ?? 0).toString(),
                      }
                    : undefined,
                  {
                    name: 'Contributions with mentions',
                    value: (presentationMatchingCount + journalArticleMatchingCount).toString(),
                  },
                ].filter(Boolean) as Statistic[]
              }
            />
          </StyledStatsWithExportButtonSection>
        ) : (
          <StyledSection>
            <StyledLoadingSubtitleBlock />
          </StyledSection>
        )}
      </SoSVGrid.Item>
      <SoSVGrid.Item itemWidth="widest">
        <Module.Container>
          <Module.Header>
            {generateSoSVModuleTitle({
              moduleType: 'products',
              weighteningType: weighteningType,
              sosvTimeframe: timeframe,
              graphType: graphType,
              isTotal: true,
            })}
          </Module.Header>
          <section>
            {mentionsBreakdown.length && !isLoading ? (
              <ProductsMentionsTreemapChart
                sosvStats={mentionsBreakdown}
                weighteningType={weighteningType}
                exportChartConfig={
                  showSosvChartDataExport
                    ? {
                        sosvTimeframe: timeframe,
                        contentTypes: contentTypes,
                        subscriptionName: subscriptionName || '',
                        listName: listName || '',
                        weighteningType: weighteningType,
                        graphType: graphType,
                      }
                    : undefined
                }
                graphType={graphType}
                themeMode={styleMode}
                themeBrand={themeBrand}
              />
            ) : (
              <TreemapChartEmptyState />
            )}
          </section>
        </Module.Container>
      </SoSVGrid.Item>
      <SoSVGrid.Item itemWidth="narrow">
        <Module.Container>
          <Module.Header>
            {generateSoSVModuleTitle({
              moduleType: 'products',
              sosvTimeframe: timeframe,
              graphType: graphType,
              weighteningType: weighteningType,
              isTotal: false,
            })}
          </Module.Header>
        </Module.Container>
        <section data-testid="mentions-breakdown-chart">
          {mentionsBreakdown.length ? (
            <ProductsMentionsLineChart
              sosvStats={mentionsBreakdown}
              cumulatingType={cumulatingType}
              weighteningType={weighteningType}
              dateInterval={getDateInterval({
                timeframe: SoSVTimeframe.ThreeYears,
              })}
              exportChartConfig={
                showSosvChartDataExport
                  ? {
                      exportFormatTypes: ['jpeg', 'png'],
                      sosvTimeframe: SoSVTimeframe.ThreeYears,
                      contentTypes: contentTypes,
                      cumulatingType: cumulatingType,
                      subscriptionName: subscriptionName || '',
                      listName: listName || '',
                      weighteningType: weighteningType,
                      graphType,
                    }
                  : undefined
              }
              themeMode={styleMode}
              themeBrand={themeBrand}
            />
          ) : (
            <ChartEmptyState
              yAxisLabels={{
                top: '1',
                bottom: '0',
              }}
            />
          )}
        </section>
      </SoSVGrid.Item>
      <SoSVGrid.Item itemWidth="narrow">
        <Module.Container>
          <Module.Header>
            {generateSoSVModuleTitle({
              moduleType: 'products',
              sosvTimeframe: timeframe,
              graphType: 'share',
              weighteningType: weighteningType,
              isTotal: false,
            })}
          </Module.Header>
        </Module.Container>
        <section data-testid="mentions-breakdown-chart">
          {mentionsBreakdown.length ? (
            <ProductsMentionsColumnChart
              sosvStats={mentionsBreakdown}
              cumulatingType={cumulatingType}
              weighteningType={weighteningType}
              dateInterval={getDateInterval({
                timeframe: timeframe,
              })}
              exportChartConfig={
                showSosvChartDataExport
                  ? {
                      exportFormatTypes: ['jpeg', 'png'],
                      sosvTimeframe: SoSVTimeframe.ThreeYears,
                      contentTypes: contentTypes,
                      cumulatingType: cumulatingType,
                      subscriptionName: subscriptionName || '',
                      listName: listName || '',
                      weighteningType: weighteningType,
                      graphType: 'share',
                    }
                  : undefined
              }
              themeMode={styleMode}
              themeBrand={themeBrand}
            />
          ) : (
            <ChartEmptyState
              yAxisLabels={{
                top: '1',
                bottom: '0',
              }}
            />
          )}
        </section>
      </SoSVGrid.Item>
      {isSoSVForExperts ? (
        <SoSVGrid.Item itemWidth="widest" data-testid="experts">
          <TopExperts data={topExpertsData} isLoading={isLoading} styleMode={styleMode} />
        </SoSVGrid.Item>
      ) : null}
      <SoSVGrid.Item itemWidth="widest" data-testid="journals">
        <TopJournals data={topJournalsData} isLoading={isLoading} styleMode={styleMode} />
      </SoSVGrid.Item>
      <SoSVGrid.Item itemWidth="widest" data-testid="societies">
        <TopSocieties data={topSocietiesData} isLoading={isLoading} styleMode={styleMode} />
      </SoSVGrid.Item>
      {isSoSVForExperts ? (
        <SoSVGrid.Item itemWidth="widest" data-testid="experts-by-geography">
          <TopExpertsByGeography
            data={topExpertsData}
            isLoading={isLoading}
            styleMode={styleMode}
            mapState={mapState}
            mapDispatch={mapDispatch}
          />
        </SoSVGrid.Item>
      ) : (
        <SoSVGrid.Item itemWidth="widest" data-testid="society-events-by-locations">
          <TopSocietyEventsByLocation
            data={topMeetingsLocationsData}
            isLoading={isLoading}
            mapState={mapState}
            mapDispatch={mapDispatch}
          />
        </SoSVGrid.Item>
      )}
      {isSoSVForExperts ? (
        <SoSVGrid.Item itemWidth="widest" data-testid="geographies">
          <TopGeographies data={topGeographiesData} isLoading={isLoading} styleMode={styleMode} />
        </SoSVGrid.Item>
      ) : null}

      {contentTypes.includes(SoSVContentType.presentation) && (
        <SoSVGrid.Item itemWidth={contentTypes.includes(SoSVContentType.journalArticle) ? 'narrow' : 'widest'}>
          <Presentations
            productColors={productColors}
            recentPresentations={topPresentationsData?.getSoSVPresentations}
            presentationMatchingCount={presentationMatchingCount}
          />
        </SoSVGrid.Item>
      )}

      {contentTypes.includes(SoSVContentType.journalArticle) && (
        <SoSVGrid.Item itemWidth={contentTypes.includes(SoSVContentType.presentation) ? 'narrow' : 'widest'}>
          <JournalArticles
            productColors={productColors}
            recentJournalArticles={topJournalArticlesData?.getSoSVJournalArticles}
            journalArticleMatchingCount={journalArticleMatchingCount}
          />
        </SoSVGrid.Item>
      )}
    </SoSVGrid.Container>
  )
}
