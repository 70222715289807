import styled from 'styled-components'
import { StyledChart } from '../../styles'
import { getProductColorsCss } from '../../helpers'
import { ExportChartTrigger } from '../../components/export-chart-trigger'
import { Flex } from '~common'

export const StyledExportWrapper = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
`

export const StyledExportChartTrigger = styled(ExportChartTrigger)`
  position: absolute;
  right: 0;
  z-index: 999;
`

export const StyledLaunchAnalogueMultipleSeriesStockChart = styled(StyledChart)<{
  productColors: string[]
  series: Highcharts.SeriesOptionsType[]
}>`
  height: 100%;
  margin-top: 1rem;
  position: relative;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  .highcharts-graph {
    stroke-width: 2px;
  }
  .highcharts-point {
    stroke-width: 0;
  }
  .launch-analogue-multiple-series-stock-chart {
    height: 13.1875rem !important;
    @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
      height: 28.1875rem !important;
    }
    & > svg {
      height: 13.1875rem !important;
      @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
        height: 28.1875rem !important;
      }
    }
  }
  .highcharts-range-selector-group {
    transform: translate(-30px, -10px);
    @media (min-width: ${({ theme }) => theme.pxsToEms(428)}) {
      transform: translate(-66px, -10px);
    }
    .highcharts-range-selector-buttons > *:first-child {
      display: none;
    }
  }

  .highcharts-button-pressed,
  .highcharts-button-hover {
    fill: #5a6bad !important;
    rect {
      fill: #5a6bad !important;
    }
  }

  ${({ productColors, series }) => getProductColorsCss({ productColors, series, isLineChart: true })};
`
