import { useRouter } from 'next/router'
import React, { useCallback, useMemo } from 'react'
import {
  formatUrl,
  MapBounds,
  Mode,
  SoSVTimeframe,
  sosvTimeframeLabels,
  StyledSoSVStatsDL,
  StyledStatsDD,
  StyledStatsDT,
} from '~common'
import { ChartEmptyState } from '~components/ui/atoms/chart-empty-state'
import { ChartLoadingBlock } from '~components/ui/atoms/loading-blocks'
import { Module } from '~components/ui/molecules/module'
import { MapExpertTeaser } from '~components/ui/molecules/map-expert-teaser'
import { MapAction as Action, MapState } from '~common/hooks/use-map/map-state-managment/types'
import { CustomProductsMentionsBarChart } from '~components/ui/organisms/charts/sosv/custom-product-mentions-bar-chart'
import { useFeatures } from '~common/hooks/use-features'
import dynamic from 'next/dynamic'
import { defaultBoundsEntireWorld, Feature, FeatureProperties } from '~components/ui/organisms/cluster-map/types'
import { LinkThemeProvider } from '~common/theme'
import { initialiseMap } from '~common/hooks/use-map/map-state-managment/action-creators'
import { useDebouncedCallback } from 'use-debounce'
import {
  GetSoSVExpertsQuery,
  GetSoSVExpertsQuery_getSoSVHubExperts,
  GetSoSVExpertsQuery_getSoSVHubExperts_mentions,
} from '~apollo/queries/sosv/experts/__generated__/GetSoSVExpertsQuery'
import { useSoSVState } from '~components/pages/widgets/sosv/settings/settings'
import { getPlatformUrl } from '~components/pages/widgets/sosv/utils'

const ClusterMap = dynamic(() => import('~components/ui/organisms/cluster-map/ClusterMap'), { ssr: false })

export interface TopExpertsByGeographyProps {
  isLoading: boolean
  data?: GetSoSVExpertsQuery
  mapState: MapState
  mapDispatch: React.Dispatch<Action>
  styleMode: Mode
}

interface ExpertFeatureProperties extends FeatureProperties {
  sosvMentionsTotal: number
  sosvEnabled: boolean
}

type ExpertFeature = Feature<ExpertFeatureProperties>

const toExpertsFeatures = (
  getFullProfilePageUrl: (profileId: string) => string,
  styleMode: Mode,
  isIqviaBrand: boolean,
  data?: GetSoSVExpertsQuery_getSoSVHubExperts[] | null,
): ExpertFeature[] => {
  const features = data ?? []
  const expertFeatures: ExpertFeature[] = []
  for (const feature of features) {
    if (feature.location && feature.location.lat && feature.location.lon) {
      const { profileId, fullName, profileStatus, profileRank, city, country, speciality, institution, mentions } =
        feature
      const sosvMentionsTotal = mentions.reduce((acc: number, cur: GetSoSVExpertsQuery_getSoSVHubExperts_mentions) => {
        acc += cur.countWeighted
        return acc
      }, 0)
      expertFeatures.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [feature.location.lon, feature.location.lat],
        },
        properties: {
          id: profileId,
          sosvMentionsTotal,
          sosvEnabled: true,
          popupUI: (
            <LinkThemeProvider mode={styleMode} brand={isIqviaBrand ? 'iqvia' : 'pharmaspectra'}>
              <MapExpertTeaser
                name={fullName}
                status={profileStatus || ''}
                location={`${city}, ${country}`}
                rank={profileRank}
                imageSrc={`https://pharmaspectra-link-medmeme.s3.amazonaws.com/processed/${profileId}.jpg`}
                linkToExpert={getFullProfilePageUrl(profileId)}
                metadataOptions={{
                  primary: speciality,
                  secondary: institution,
                }}
              >
                <StyledSoSVStatsDL>
                  <StyledStatsDT>Share of Scientific Voice™</StyledStatsDT>
                  <StyledStatsDD>
                    <CustomProductsMentionsBarChart
                      sosvSummary={mentions}
                      weighteningType={'weighted'}
                      isIqviaBrand={isIqviaBrand}
                    />
                  </StyledStatsDD>
                </StyledSoSVStatsDL>
              </MapExpertTeaser>
            </LinkThemeProvider>
          ),
        },
      })
    }
  }

  return expertFeatures
}

export const TopExpertsByGeography: React.FC<TopExpertsByGeographyProps> = ({
  isLoading,
  data,
  mapState,
  mapDispatch,
  styleMode,
}) => {
  const router = useRouter()
  const { isIqviaBrand } = useFeatures()
  const {
    listId,
    rootListId,
    productGroupId,
    source,
    weighteningType,
    contentTypes,
    timeframe,
    drugSelection,
    cumulatingType,
    graphType,
    startDate,
    endDate,
  } = useSoSVState()

  const uniqueIdByFilters = `${styleMode}-${listId}-${productGroupId}-${source}`

  const getFullProfilePageUrl = useCallback(
    (profileId: string) => {
      return formatUrl(getPlatformUrl() + `/experts/${profileId}/sosv`, {
        listId,
        rootSubscriptionId: rootListId,
        weighteningType: weighteningType,
        productGroupId: productGroupId,
        sosvTimeframe: timeframe,
        sosvContentTypes: contentTypes,
        drugSelection,
        expertsSortBy: 'profileRank',
        cumulatingType,
        graphType,
        source,
        startDate,
        endDate,
      })
    },
    [
      weighteningType,
      productGroupId,
      listId,
      rootListId,
      timeframe,
      contentTypes,
      drugSelection,
      cumulatingType,
      graphType,
      source,
      startDate,
      endDate,
    ],
  )

  const expertFeatures: ExpertFeature[] = useMemo(() => {
    return toExpertsFeatures(getFullProfilePageUrl, styleMode, isIqviaBrand, data?.getSoSVHubExperts)
  }, [data, styleMode, isIqviaBrand, getFullProfilePageUrl])

  const onMapChanges = useDebouncedCallback((visiblePointsTotal: number, bounds: MapBounds) => {
    initialiseMap({
      mapDispatch,
      router,
      visiblePointsTotal,
      mapBounds: bounds,
    })
  }, 200)

  const expertsByGeographyFullPageUrl = formatUrl(getPlatformUrl() + '/sosv/map', {
    listId,
    rootSubscriptionId: rootListId,
    weighteningType: weighteningType,
    productGroupId: productGroupId,
    sosvTimeframe: timeframe,
    sosvContentTypes: contentTypes,
    drugSelection,
    expertsSortBy: 'profileRank',
    cumulatingType,
    graphType,
    dataSet: source,
    southWestLng: mapState?.mapBounds ? mapState.mapBounds.southWest.lng : undefined,
    southWestLat: mapState?.mapBounds ? mapState.mapBounds.southWest.lat : undefined,
    northEastLng: mapState?.mapBounds ? mapState.mapBounds.northEast.lng : undefined,
    northEastLat: mapState?.mapBounds ? mapState.mapBounds.northEast.lat : undefined,
  })

  if (isLoading) return <ChartLoadingBlock withNoPadding={true} />

  return (
    <Module.Container>
      <Module.Header
        linkText={!!mapState.visiblePointsTotal ? 'View all found' : undefined}
        linkTo={mapState.visiblePointsTotal ? expertsByGeographyFullPageUrl : undefined}
      >{`Top 5 experts by geography over the ${sosvTimeframeLabels[SoSVTimeframe.ThreeYears]}`}</Module.Header>
      {!!expertFeatures.length ? (
        <>
          <ClusterMap
            key={uniqueIdByFilters}
            features={expertFeatures}
            scalingProperty="sosvMentionsTotal"
            onMapChanges={onMapChanges}
            fitBounds
            bounds={
              mapState.mapBounds
                ? [
                    [mapState.mapBounds.southWest.lat, mapState.mapBounds.southWest.lng],
                    [mapState.mapBounds.northEast.lat, mapState.mapBounds.northEast.lng],
                  ]
                : defaultBoundsEntireWorld
            }
          />
          {Boolean(data?.getSoSVHubExpertsCount) ? (
            <Module.Footer
              links={[
                {
                  name: `View all ${data?.getSoSVHubExpertsCount} experts`,
                  id: 'experts-by-geography',
                  href: expertsByGeographyFullPageUrl,
                },
              ]}
            />
          ) : null}
        </>
      ) : (
        <ChartEmptyState isStackedBar />
      )}
    </Module.Container>
  )
}
