import React from 'react'
import { getFormatedStartAndEndDate, removeAnyHTMLTags, smartTrim } from '~common'
import { CheckMarkIcon } from '~components/ui/atoms/check-mark-icon'
import { Divider } from '~components/ui/atoms/divider'
import { Metadata } from '~components/ui/atoms/metadata'
import { ProductBadge } from '~components/ui/atoms/product-badge'
import { InfoTooltip, TooltipBehavior } from '~components/ui/molecules/info-tooltip'
import {
  ContentIdentifierText,
  StyledContentTeaser,
  StyledCustomLink,
  StyledHeading,
  StyledHeadingCount,
  StyledHeadingList,
  StyledMentionedProducts,
  StyledOnTopicIconWrapper,
  StyledText,
} from './styles'

export interface ContentTeaserProps extends React.HTMLAttributes<HTMLDivElement> {
  heading: string | null
  headingCount?: number
  startDate?: string | number | Date | null
  endDate?: string | number | Date | null
  subheading?: string | null
  linkTo?: string
  teaserPhaseOrType?: string | null
  isOnTopic?: boolean | null
  asTag?: string
  onClick?: () => void
  isWithDivider?: boolean
  isSelected?: boolean
  isTouchable?: boolean
  mentionedProducts?: {
    name: string
    count: number
    denominationColor: string
  }[]
  displayMentionsCount?: boolean
  contentIdentifier?: string | null
  tooltipBehavior?: TooltipBehavior
}

export const ContentTeaser = ({
  linkTo,
  heading,
  headingCount,
  subheading,
  startDate,
  endDate,
  teaserPhaseOrType,
  isOnTopic,
  asTag,
  onClick,
  style,
  isSelected,
  isWithDivider = true,
  mentionedProducts,
  displayMentionsCount,
  isTouchable,
  contentIdentifier,
  tooltipBehavior,
  ...rest
}: ContentTeaserProps): JSX.Element => {
  const getTeaserHeading = () => {
    if (!heading) return <StyledHeading level="3">Title unavailable</StyledHeading>
    if (!linkTo) return <StyledHeading level="3" dangerouslySetInnerHTML={{ __html: `${smartTrim(heading, 175)}` }} />
    return (
      <StyledHeading level="3">
        <StyledCustomLink
          title={removeAnyHTMLTags(heading)}
          href={linkTo}
          dangerouslySetInnerHTML={{ __html: `${smartTrim(heading, 175)}` }}
          $isTeaserClickable={!!onClick}
          tabIndex={!!onClick ? -1 : 0}
        />
        {headingCount && <StyledHeadingCount>{headingCount}</StyledHeadingCount>}
      </StyledHeading>
    )
  }

  const isDate = startDate || endDate
  return (
    <StyledContentTeaser
      as={asTag as never}
      onClick={onClick}
      style={style}
      isClickable={!!onClick}
      isActive={isSelected}
      tabIndex={!!onClick ? 0 : undefined}
      isTouchable={isTouchable}
      isWithDivider={isWithDivider}
      {...rest}
    >
      {contentIdentifier && <ContentIdentifierText>{contentIdentifier}</ContentIdentifierText>}
      <StyledHeadingList>
        <li>{getTeaserHeading()}</li>
        {isOnTopic && (
          <>
            <li>
              <StyledOnTopicIconWrapper>
                <CheckMarkIcon data-testid="onTopicIcon" fillColor={'#007D26'} />
              </StyledOnTopicIconWrapper>
            </li>
            <li>
              <InfoTooltip
                content={'On topic'}
                tooltipWidth={70}
                data-testid="onTopicIconTooltip"
                behavior={tooltipBehavior}
              />
            </li>
          </>
        )}
      </StyledHeadingList>
      {subheading && (
        <StyledText
          hasHighlight={subheading.includes(`class='hlt'`)}
          dangerouslySetInnerHTML={{ __html: `${subheading}` }}
        />
      )}
      {(isDate || teaserPhaseOrType || isOnTopic) && (
        <Metadata
          primary={isDate ? <time>{getFormatedStartAndEndDate(startDate, endDate)}</time> : undefined}
          secondary={teaserPhaseOrType}
        />
      )}
      {!!mentionedProducts?.length && (
        <StyledMentionedProducts>
          {mentionedProducts.map((item) => (
            <ProductBadge
              key={item.name}
              name={item.name}
              count={displayMentionsCount ? item.count : undefined}
              denominationColor={item.denominationColor}
            />
          ))}
        </StyledMentionedProducts>
      )}
      {isWithDivider && <Divider borderColor="muted" borderType="dotted" />}
    </StyledContentTeaser>
  )
}
