import styled, { css } from 'styled-components'
import { browserTruncateMixin, Flex } from '~common'
import { Heading } from '~components/ui/atoms/heading'
import { Metadata } from '~components/ui/atoms/metadata'

const reusableMixin = css`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

export const StyledContentWrapper = styled(Flex)<{ isWithPaddingAroundContent: boolean }>`
  align-items: center;
  padding: ${({ isWithPaddingAroundContent }) => isWithPaddingAroundContent && '0.5rem'};
`

export const StyledContentInfo = styled.div<{
  isWithImg: boolean
  isWithChildren: boolean
}>`
  width: ${({ isWithImg }) => (isWithImg ? `calc(100% - 5.5rem)` : '100%')};
  ${({ isWithImg, isWithChildren }) =>
    !!isWithImg || isWithChildren
      ? css`
          ${StyledHeading} {
            @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
              ${browserTruncateMixin}
            }
          }
        `
      : css`
          ${StyledHeading} {
            & > a {
              @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
                white-space: normal;
              }
            }
          }
        `};
`

export const StyledHeading = styled(Heading)`
  ${({ theme }) => theme.textSizes.desktop.subscription}
`

export const StyledCollectionText = styled.p`
  ${reusableMixin};
  ${browserTruncateMixin};
  ${({ theme }) => theme.textSizes.desktop.label};
`

export const StyledContentImage = styled.div`
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.neutrals.neutral5};
  margin-right: 0.5rem;
  float: left;
  position: relative;
  > img {
    margin: 0 auto;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const StyledMetadata = styled(Metadata)`
  li:first-child {
    flex-shrink: 0;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    li:last-child {
      display: inline-block;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`
