import { OptStatus } from '~apollo/generated-global-types'
import { WeighteningType } from '~common'
import { MentionsCount } from '~components/ui/organisms/charts'

interface EntityMentionsData {
  gdprExcluded?: boolean | null
  optStatus?: OptStatus | null
  mentions: {
    productName: string
    count: number
    countWeighted: number
    share: number
    shareWeighted: number
  }[]
}

export const getMentions = (drugSelection: string | string[], getEntities: EntityMentionsData[]): MentionsCount[] => {
  const acc: Record<string, MentionsCount> = {}
  for (let i = 0; i < getEntities.length; i++) {
    const entity = getEntities[i]
    for (let j = 0; j < entity.mentions.length; j++) {
      if (entity.gdprExcluded || entity.optStatus === OptStatus.out) {
        acc[entity.mentions[j].productName] = {
          counts: [...(acc[entity.mentions[j].productName]?.counts || []), 0],
          countsWeighted: [...(acc[entity.mentions[j].productName]?.countsWeighted || []), 0],
          share: [...(acc[entity.mentions[j].productName]?.share || []), 0],
          shareWeighted: [...(acc[entity.mentions[j].productName]?.shareWeighted || []), 0],
          productName: entity.mentions[j].productName,
        }
        continue
      }
      if (!acc[entity.mentions[j].productName]) {
        acc[entity.mentions[j].productName] = {
          counts: drugSelection.includes(entity.mentions[j].productName) ? [entity.mentions[j].count] : [],
          countsWeighted: drugSelection.includes(entity.mentions[j].productName)
            ? [entity.mentions[j].countWeighted]
            : [],
          share: drugSelection.includes(entity.mentions[j].productName) ? [entity.mentions[j].share] : [],
          shareWeighted: drugSelection.includes(entity.mentions[j].productName)
            ? [entity.mentions[j].shareWeighted]
            : [],
          productName: entity.mentions[j].productName,
        }
      } else {
        acc[entity.mentions[j].productName] = {
          ...acc[entity.mentions[j].productName],
          counts: drugSelection.includes(entity.mentions[j].productName)
            ? [...acc[entity.mentions[j].productName].counts, entity.mentions[j].count]
            : [],
          countsWeighted: drugSelection.includes(entity.mentions[j].productName)
            ? [...acc[entity.mentions[j].productName].countsWeighted, entity.mentions[j].countWeighted]
            : [],
          share: drugSelection.includes(entity.mentions[j].productName)
            ? [...acc[entity.mentions[j].productName].share, entity.mentions[j].share]
            : [],
          shareWeighted: drugSelection.includes(entity.mentions[j].productName)
            ? [...acc[entity.mentions[j].productName].shareWeighted, entity.mentions[j].shareWeighted]
            : [],
        }
      }
    }
  }

  return Object.values(acc)
}

export const convertGeographyToName = ({
  city,
  state,
  country,
}: {
  city?: string | null
  state?: string | null
  country: string
}): string => (city ? `${city}${state ? `, ${state}` : ''}` : country)

export const convertGeographyToId = ({
  city,
  state,
  country,
}: {
  city?: string | null
  state?: string | null
  country: string
}): string => (city ? `${city}${state ? `-${state}` : ''}` : country).replace(/ +/g, '-').toLowerCase()

export const convertGeographyToFullName = ({
  city,
  state,
  country,
}: {
  city?: string | null
  state?: string | null
  country: string
}): string => (city ? `${city},${state ? ` ${state}, ` : ' '}${country}` : country)

export const getSortByOptions = (
  weighteningType: WeighteningType,
  drugSelection: string | string[],
  sortByDrugs: string[],
  { profileRank = false, impactScore = false }: { profileRank?: boolean; impactScore?: boolean } = {},
): { id: string; name: string; isDisabled?: boolean; direction?: 'desc' | 'asc' }[] => {
  const sortOptions = [
    { name: `Total ${weighteningType === 'weighted' ? 'score ' : 'mentions'}`, id: 'all' },
    ...(typeof drugSelection === 'string'
      ? [
          {
            id: drugSelection,
            name: drugSelection,
            isDisabled: !sortByDrugs.includes(drugSelection),
          },
        ]
      : drugSelection.map((drug) => ({
          id: drug,
          name: drug,
          isDisabled: !sortByDrugs.includes(drug),
        }))),
  ]

  if (profileRank) {
    const [topScore, ...rest] = sortOptions
    return [topScore, { name: 'Rank', id: 'profileRank', direction: 'asc' }, ...rest]
  }

  if (impactScore) {
    const [topScore, ...rest] = sortOptions
    return [topScore, { name: 'SJR score', id: 'impactScore', direction: 'desc' }, ...rest]
  }

  return sortOptions
}
