import { useCallback, useEffect, useMemo, useRef } from 'react'

export type EventKey = string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventHandler<T = any> = (payload: T) => void
export type EventMap = Record<EventKey, EventHandler>
export type Bus<E> = Record<keyof E, E[keyof E][]>

export interface EventBus<T extends EventMap> {
  on<Key extends keyof T>(key: Key, handler: T[Key]): () => void
  off<Key extends keyof T>(key: Key, handler: T[Key]): void
  emit<Key extends keyof T>(window: WindowProxy, key: Key, ...payload: Parameters<T[Key]>): void
}

export const useFrameMessageBus = <E extends EventMap>(): EventBus<E> => {
  const bus = useRef<Partial<Bus<E>>>({})

  const off: EventBus<E>['off'] = useCallback((key, handler) => {
    const index = bus.current[key]?.indexOf(handler) ?? -1
    if (index > -1) {
      bus.current[key]?.splice(index, 1)
    }
  }, [])

  const on: EventBus<E>['on'] = useCallback(
    (key, handler) => {
      if (!bus.current[key]) {
        bus.current[key] = []
      }
      bus.current[key]?.push(handler)
      return () => {
        off(key, handler)
      }
    },
    [off],
  )

  const emit: EventBus<E>['emit'] = useCallback((window, key, payload) => {
    if (window) {
      window?.postMessage(
        {
          type: key,
          payload,
        },
        '*',
      )
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (me: MessageEvent<{ type: keyof E; payload: any }>): void => {
      if (typeof me.data !== 'object') return
      if (!me.data.type) return
      if (!me.data.payload) return
      bus.current[me.data.type]?.forEach((handler) => handler(me.data.payload))
    }

    if (window) {
      window.addEventListener('message', handler)
    }
    return () => {
      if (window) {
        window.removeEventListener('message', handler)
      }
    }
  }, [])

  return useMemo(
    () => ({
      on,
      off,
      emit,
    }),
    [on, emit, off],
  )
}
